 :root {
    --theme-default: #FE495F;
    --theme-secondary: #4BE2A5;
    --theme-secondary2: #08C5F9;
    --theme-accent: #2884FF;
    --theme-buholog: #f97316;
  }
  
  /**=====================
       Reset css start
  ==========================**/
  .font-roboto {
    font-family: Roboto, sans-serif;
  }
  
  /*====== Padding css starts ======*/
  .p-0 {
    padding: 0px;
  }
  
  .p-5 {
    padding: 5px;
  }
  
  .p-10 {
    padding: 10px;
  }
  
  .p-15 {
    padding: 15px;
  }
  
  .p-20 {
    padding: 20px;
  }
  
  .p-25 {
    padding: 25px;
  }
  
  .p-30 {
    padding: 30px;
  }
  
  .p-35 {
    padding: 35px;
  }
  
  .p-40 {
    padding: 40px;
  }
  
  .p-45 {
    padding: 45px;
  }
  
  .p-50 {
    padding: 50px;
  }
  
  /*====== Padding css ends ======*/
  /*====== Padding-left css starts ======*/
  .p-l-0 {
    padding-left: 0px;
  }
  
  .p-l-5 {
    padding-left: 5px;
  }
  
  .p-l-10 {
    padding-left: 10px;
  }
  
  .p-l-15 {
    padding-left: 15px;
  }
  
  .p-l-20 {
    padding-left: 20px;
  }
  
  .p-l-25 {
    padding-left: 25px;
  }
  
  .p-l-30 {
    padding-left: 30px;
  }
  
  .p-l-35 {
    padding-left: 35px;
  }
  
  .p-l-40 {
    padding-left: 40px;
  }
  
  .p-l-45 {
    padding-left: 45px;
  }
  
  .p-l-50 {
    padding-left: 50px;
  }
  
  /*====== Padding-left css ends ======*/
  /*====== Padding-top css starts ======*/
  .p-t-0 {
    padding-top: 0px !important;
  }
  
  .p-t-5 {
    padding-top: 5px !important;
  }
  
  .p-t-10 {
    padding-top: 10px !important;
  }
  
  .p-t-15 {
    padding-top: 15px !important;
  }
  
  .p-t-20 {
    padding-top: 20px !important;
  }
  
  .p-t-25 {
    padding-top: 25px !important;
  }
  
  .p-t-30 {
    padding-top: 30px !important;
  }
  
  .p-t-35 {
    padding-top: 35px !important;
  }
  
  .p-t-40 {
    padding-top: 40px !important;
  }
  
  .p-t-45 {
    padding-top: 45px !important;
  }
  
  .p-t-50 {
    padding-top: 50px !important;
  }
  
  /*====== Padding-top css ends ======*/
  /*====== Padding-bottom css starts ======*/
  .p-b-0 {
    padding-bottom: 0px !important;
  }
  
  .p-b-5 {
    padding-bottom: 5px !important;
  }
  
  .p-b-10 {
    padding-bottom: 10px !important;
  }
  
  .p-b-15 {
    padding-bottom: 15px !important;
  }
  
  .p-b-20 {
    padding-bottom: 20px !important;
  }
  
  .p-b-25 {
    padding-bottom: 25px !important;
  }
  
  .p-b-30 {
    padding-bottom: 30px !important;
  }
  
  .p-b-35 {
    padding-bottom: 35px !important;
  }
  
  .p-b-40 {
    padding-bottom: 40px !important;
  }
  
  .p-b-45 {
    padding-bottom: 45px !important;
  }
  
  .p-b-50 {
    padding-bottom: 50px !important;
  }
  
  /*====== Padding-bottom css ends ======*/
  /*====== Padding-right css starts ======*/
  .p-r-0 {
    padding-right: 0px;
  }
  
  .p-r-5 {
    padding-right: 5px;
  }
  
  .p-r-10 {
    padding-right: 10px;
  }
  
  .p-r-15 {
    padding-right: 15px;
  }
  
  .p-r-20 {
    padding-right: 20px;
  }
  
  .p-r-25 {
    padding-right: 25px;
  }
  
  .p-r-30 {
    padding-right: 30px;
  }
  
  .p-r-35 {
    padding-right: 35px;
  }
  
  .p-r-40 {
    padding-right: 40px;
  }
  
  .p-r-45 {
    padding-right: 45px;
  }
  
  .p-r-50 {
    padding-right: 50px;
  }
  
  /*====== Padding-right css ends ======*/
  /*====== Margin css starts ======*/
  .m-0 {
    margin: 0px !important;
  }
  
  .m-5 {
    margin: 5px !important;
  }
  
  .m-10 {
    margin: 10px !important;
  }
  
  .m-15 {
    margin: 15px !important;
  }
  
  .m-20 {
    margin: 20px !important;
  }
  
  .m-25 {
    margin: 25px !important;
  }
  
  .m-30 {
    margin: 30px !important;
  }
  
  .m-35 {
    margin: 35px !important;
  }
  
  .m-40 {
    margin: 40px !important;
  }
  
  .m-45 {
    margin: 45px !important;
  }
  
  .m-50 {
    margin: 50px !important;
  }
  
  /*====== Margin css ends ======*/
  /*====== Margin-top css starts ======*/
  .m-t-0 {
    margin-top: 0px !important;
  }
  
  .m-t-5 {
    margin-top: 5px !important;
  }
  
  .m-t-10 {
    margin-top: 10px !important;
  }
  
  .m-t-15 {
    margin-top: 15px !important;
  }
  
  .m-t-20 {
    margin-top: 20px !important;
  }
  
  .m-t-25 {
    margin-top: 25px !important;
  }
  
  .m-t-30 {
    margin-top: 30px !important;
  }
  
  .m-t-35 {
    margin-top: 35px !important;
  }
  
  .m-t-40 {
    margin-top: 40px !important;
  }
  
  .m-t-45 {
    margin-top: 45px !important;
  }
  
  .m-t-50 {
    margin-top: 50px !important;
  }
  
  /*====== Margin-top css ends ======*/
  /*====== Margin-Bottom css starts ======*/
  .m-b-0 {
    margin-bottom: 0px !important;
  }
  
  .m-b-5 {
    margin-bottom: 5px !important;
  }
  
  .m-b-10 {
    margin-bottom: 10px !important;
  }
  
  .m-b-15 {
    margin-bottom: 15px !important;
  }
  
  .m-b-20 {
    margin-bottom: 20px !important;
  }
  
  .m-b-25 {
    margin-bottom: 25px !important;
  }
  
  .m-b-30 {
    margin-bottom: 30px !important;
  }
  
  .m-b-35 {
    margin-bottom: 35px !important;
  }
  
  .m-b-40 {
    margin-bottom: 40px !important;
  }
  
  .m-b-45 {
    margin-bottom: 45px !important;
  }
  
  .m-b-50 {
    margin-bottom: 50px !important;
  }
  
  /*====== Margin-Bottom css ends ======*/
  /*====== Margin-left css starts ======*/
  .m-l-0 {
    margin-left: 0px !important;
  }
  
  .m-l-5 {
    margin-left: 5px !important;
  }
  
  .m-l-10 {
    margin-left: 10px !important;
  }
  
  .m-l-15 {
    margin-left: 15px !important;
  }
  
  .m-l-20 {
    margin-left: 20px !important;
  }
  
  .m-l-25 {
    margin-left: 25px !important;
  }
  
  .m-l-30 {
    margin-left: 30px !important;
  }
  
  .m-l-35 {
    margin-left: 35px !important;
  }
  
  .m-l-40 {
    margin-left: 40px !important;
  }
  
  .m-l-45 {
    margin-left: 45px !important;
  }
  
  .m-l-50 {
    margin-left: 50px !important;
  }
  
  /*====== Margin-left css ends ======*/
  /*====== Margin-right css starts ======*/
  .m-r-0 {
    margin-right: 0px;
  }
  
  .m-r-5 {
    margin-right: 5px;
  }
  
  .m-r-10 {
    margin-right: 10px;
  }
  
  .m-r-15 {
    margin-right: 15px;
  }
  
  .m-r-20 {
    margin-right: 20px;
  }
  
  .m-r-25 {
    margin-right: 25px;
  }
  
  .m-r-30 {
    margin-right: 30px;
  }
  
  .m-r-35 {
    margin-right: 35px;
  }
  
  .m-r-40 {
    margin-right: 40px;
  }
  
  .m-r-45 {
    margin-right: 45px;
  }
  
  .m-r-50 {
    margin-right: 50px;
  }
  
  /*====== Margin-right css ends ======*/
  /*====== Border-radius css starts ======*/
  .b-r-0 {
    border-radius: 0px !important;
  }
  
  .b-r-1 {
    border-radius: 1px !important;
  }
  
  .b-r-2 {
    border-radius: 2px !important;
  }
  
  .b-r-3 {
    border-radius: 3px !important;
  }
  
  .b-r-4 {
    border-radius: 4px !important;
  }
  
  .b-r-5 {
    border-radius: 5px !important;
  }
  
  .b-r-6 {
    border-radius: 6px !important;
  }
  
  .b-r-7 {
    border-radius: 7px !important;
  }
  
  .b-r-8 {
    border-radius: 8px !important;
  }
  
  .b-r-9 {
    border-radius: 9px !important;
  }
  
  .b-r-10 {
    border-radius: 10px !important;
  }
  
  /*====== Border-radius css ends ======*/
  /*====== Font-size css starts ======*/
  .f-12 {
    font-size: 12px !important;
  }
  
  .f-14 {
    font-size: 14px !important;
  }
  
  .f-16 {
    font-size: 16px !important;
  }
  
  .f-18 {
    font-size: 18px !important;
  }
  
  .f-20 {
    font-size: 20px !important;
  }
  
  .f-22 {
    font-size: 22px !important;
  }
  
  .f-24 {
    font-size: 24px !important;
  }
  
  .f-26 {
    font-size: 26px !important;
  }
  
  .f-28 {
    font-size: 28px !important;
  }
  
  .f-30 {
    font-size: 30px !important;
  }
  
  .f-32 {
    font-size: 32px !important;
  }
  
  .f-34 {
    font-size: 34px !important;
  }
  
  .f-36 {
    font-size: 36px !important;
  }
  
  .f-38 {
    font-size: 38px !important;
  }
  
  .f-40 {
    font-size: 40px !important;
  }
  
  .f-42 {
    font-size: 42px !important;
  }
  
  .f-44 {
    font-size: 44px !important;
  }
  
  .f-46 {
    font-size: 46px !important;
  }
  
  .f-48 {
    font-size: 48px !important;
  }
  
  .f-50 {
    font-size: 50px !important;
  }
  
  .f-52 {
    font-size: 52px !important;
  }
  
  .f-54 {
    font-size: 54px !important;
  }
  
  .f-56 {
    font-size: 56px !important;
  }
  
  .f-58 {
    font-size: 58px !important;
  }
  
  .f-60 {
    font-size: 60px !important;
  }
  
  .f-62 {
    font-size: 62px !important;
  }
  
  .f-64 {
    font-size: 64px !important;
  }
  
  .f-66 {
    font-size: 66px !important;
  }
  
  .f-68 {
    font-size: 68px !important;
  }
  
  .f-70 {
    font-size: 70px !important;
  }
  
  .f-72 {
    font-size: 72px !important;
  }
  
  .f-74 {
    font-size: 74px !important;
  }
  
  .f-76 {
    font-size: 76px !important;
  }
  
  .f-78 {
    font-size: 78px !important;
  }
  
  .f-80 {
    font-size: 80px !important;
  }
  
  .f-82 {
    font-size: 82px !important;
  }
  
  .f-84 {
    font-size: 84px !important;
  }
  
  .f-86 {
    font-size: 86px !important;
  }
  
  .f-88 {
    font-size: 88px !important;
  }
  
  .f-90 {
    font-size: 90px !important;
  }
  
  .f-92 {
    font-size: 92px !important;
  }
  
  .f-94 {
    font-size: 94px !important;
  }
  
  .f-96 {
    font-size: 96px !important;
  }
  
  .f-98 {
    font-size: 98px !important;
  }
  
  .f-100 {
    font-size: 100px !important;
  }
  
  /*====== Font-size css ends ======*/
  /*====== Font-weight css starts ======*/
  .f-w-100 {
    font-weight: 100;
  }
  
  .f-w-300 {
    font-weight: 300;
  }
  
  .f-w-500 {
    font-weight: 500;
  }
  
  .f-w-400 {
    font-weight: 400;
  }
  
  .f-w-600 {
    font-weight: 600;
  }
  
  .f-w-700 {
    font-weight: 700;
  }
  
  .f-w-900 {
    font-weight: 900;
  }
  
  /*====== Font-weight css ends ======*/
  /*====== Font-style css starts ======*/
  .f-s-normal {
    font-style: normal;
  }
  
  .f-s-italic {
    font-style: italic;
  }
  
  .f-s-oblique {
    font-style: oblique;
  }
  
  .f-s-initial {
    font-style: initial;
  }
  
  .f-s-inherit {
    font-style: inherit;
  }
  
  /*====== Font-style css ends ======*/
  /*====== Text-Decoration css starts ======*/
  .text-overline {
    text-decoration: overline;
  }
  
  .text-line-through {
    text-decoration: line-through;
  }
  
  .text-underline {
    text-decoration: underline;
  }
  
  .text-dashed {
    text-decoration: dashed;
  }
  
  .text-blink {
    text-decoration: blink;
  }
  
  .text-dotted {
    text-decoration: dotted;
  }
  
  .text-initial {
    text-decoration: initial;
  }
  
  .text-none {
    text-decoration: none;
  }
  
  .text-solid {
    text-decoration: solid;
  }
  
  .text-wavy {
    text-decoration: wavy;
  }
  
  .text-inherit {
    text-decoration: inherit;
  }
  
  .text-double {
    text-decoration: double;
  }
  
  /*====== Text-Decoration css ends ======*/
  /*====== Vertical-Align css starts ======*/
  .baseline {
    vertical-align: baseline;
  }
  
  .sub {
    vertical-align: sub;
  }
  
  .super {
    vertical-align: super;
  }
  
  .top {
    vertical-align: top;
  }
  
  .text-top {
    vertical-align: text-top;
  }
  
  .middle {
    vertical-align: middle;
  }
  
  .bottom {
    vertical-align: bottom;
  }
  
  .text-bottom {
    vertical-align: text-bottom;
  }
  
  .initial {
    vertical-align: initial;
  }
  
  .inherit {
    vertical-align: inherit;
  }
  
  /*====== Vertical-Align css ends ======*/
  /*====== Position css starts ======*/
  .p-static {
    position: static;
  }
  
  .p-absolute {
    position: absolute;
  }
  
  .p-fixed {
    position: fixed;
  }
  
  .p-relative {
    position: relative;
  }
  
  .p-initial {
    position: initial;
  }
  
  .p-inherit {
    position: inherit;
  }
  
  /*====== Position css ends ======*/
  /*====== Float css starts ======*/
  .f-left {
    float: left;
  }
  
  .f-right {
    float: right;
  }
  
  .f-none {
    float: none;
  }
  
  /*====== Float css ends ======*/
  /*====== Overflow css starts ======*/
  .o-hidden {
    overflow: hidden;
  }
  
  .o-visible {
    overflow: visible;
  }
  
  .o-auto {
    overflow: auto;
  }
  
  /*====== Overflow css ends ======*/
  /*====== Image-sizes css starts ======*/
  .img-10 {
    width: 10px !important;
  }
  
  .img-20 {
    width: 20px !important;
  }
  
  .img-30 {
    width: 30px !important;
  }
  
  .img-40 {
    width: 40px !important;
  }
  
  .img-50 {
    width: 50px !important;
  }
  
  .img-60 {
    width: 60px !important;
  }
  
  .img-70 {
    width: 70px !important;
  }
  
  .img-80 {
    width: 80px !important;
  }
  
  .img-90 {
    width: 90px !important;
  }
  
  .img-100 {
    width: 100px !important;
  }
  
  /*====== Image-sizes css ends ======*/
  /*======= Text css starts ===========*/
  .font-primary {
    color: #7366ff !important;
  }
  
  .font-secondary {
    color: #f73164 !important;
  }
  
  .font-success {
    color: #51bb25 !important;
  }
  
  .font-danger {
    color: #dc3545 !important;
  }
  
  .font-info {
    color: #a927f9 !important;
  }
  
  .font-light {
    color: #f4f4f4 !important;
  }
  
  .font-dark {
    color: #2c323f !important;
  }

  .font-gray {
    color: #757575 !important;
  }

  .font-purple {
    color: #9c27b0 !important;
  }
  
  .font-warning {
    color: #f8d62b !important;
  }
  
  .font-primary {
    color: var(--theme-default) !important;
  }
  
  .font-secondary {
    color: var(--theme-secondary) !important;
  }
  
  /*======= Text css ends ===========*/
  /*======= Label-color css starts  ======= */
  .label {
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  
  .label-theme {
    background-color: #7366ff;
  }
  
  .label-primary {
    background-color: #7366ff;
  }
  
  .label-secondary {
    background-color: #f73164;
  }
  
  .label-success {
    background-color: #51bb25;
  }
  
  .label-danger {
    background-color: #dc3545;
  }
  
  .label-info {
    background-color: #a927f9;
  }
  
  .label-light {
    background-color: #f4f4f4;
  }
  
  .label-dark {
    background-color: #2c323f;
  }
  
  .label-warning {
    background-color: #f8d62b;
  }
  
  /*======= Label-color css ends  ======= */
  /*======= Badge-color css starts  ======= */
  .badge-primary {
    background-color: #7366ff;
  }
  
  .badge-secondary {
    background-color: #f73164;
  }
  
  .badge-success {
    background-color: #51bb25;
  }
  
  .badge-danger {
    background-color: #dc3545;
  }
  
  .badge-info {
    background-color: #a927f9;
  }
  
  .badge-light {
    background-color: #f4f4f4;
  }
  
  .badge-dark {
    background-color: #2c323f;
  }
  
  .badge-warning {
    background-color: #f8d62b;
  }
  
  .badge-primary {
    background-color: var(--theme-default) !important;
  }
  
  .badge-secondary {
    background-color: var(--theme-secondary) !important;
  }
  
  /*======= Badge-color css end  ======= */
  /*======= Background-color css starts  ======= */
  .bg-primary {
    background-color: #7366ff !important;
    color: #fff;
  }
  
  .bg-light-primary {
    background-color: rgba(115, 102, 255, 0.1) !important;
    color: #fff;
  }
  
  .bg-secondary {
    background-color: #f73164 !important;
    color: #fff;
  }
  
  .bg-light-secondary {
    background-color: rgba(247, 49, 100, 0.1) !important;
    color: #fff;
  }
  
  .bg-success {
    background-color: #51bb25 !important;
    color: #fff;
  }
  
  .bg-light-success {
    background-color: rgba(81, 187, 37, 0.1) !important;
    color: #fff;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
    color: #fff;
  }
  
  .bg-light-danger {
    background-color: rgba(220, 53, 69, 0.1) !important;
    color: #fff;
  }
  
  .bg-info {
    background-color: #2789f9 !important;
    color: #fff;
  }
  
  .bg-light-info {
    background-color: #2789f944 !important;
    color: #fff;
  }
  
  .bg-light {
    background-color: #f4f4f4 !important;
    color: #fff;
  }
  
  .bg-light-light {
    background-color: rgba(244, 244, 244, 0.1) !important;
    color: #fff;
  }
  
  .bg-dark {
    background-color: #2c323f !important;
    color: #fff;
  }
  
  .bg-light-dark {
    background-color: rgba(44, 50, 63, 0.1) !important;
    color: #fff;
  }
  
  .bg-warning {
    background-color: #e9ad00 !important;
    color: #fff;
  }
  
  .bg-light-warning {
    background-color: rgba(248, 214, 43, 0.1) !important;
    color: #fff;
  }
  
  .bg-primary {
    background-color: var(--theme-default) !important;
  }
  
  .bg-secondary {
    background-color: var(--theme-secondary) !important;
  }

  .bg-buholog {
    background-color: var(--theme-buholog) !important;
  }
  
  /*======= Background-color css end  ======= */
  /*======= Font-color css starts  ======= */
  .txt-primary {
    color: #7366ff !important;
  }
  
  .txt-secondary {
    color: #f73164 !important;
  }
  
  .txt-success {
    color: #51bb25 !important;
  }
  
  .txt-danger {
    color: #dc3545 !important;
  }
  
  .txt-info {
    color: #a927f9 !important;
  }
  
  .txt-light {
    color: #f4f4f4 !important;
  }
  
  .txt-dark {
    color: #2c323f !important;
  }
  
  .txt-warning {
    color: #f8d62b !important;
  }
  
  .txt-google-plus {
    color: #c64e40 !important;
  }
  
  .txt-twitter {
    color: #6fa2d8 !important;
  }
  
  .txt-linkedin {
    color: #0077B5 !important;
  }
  
  .txt-fb {
    color: #50598e !important;
  }
  
  .txt-primary {
    color: var(--theme-default) !important;
  }
  
  .txt-secondary {
    color: var(--theme-secondary) !important;
  }
  
  /*======= Font-color css end  ======= */
  /*======= Button-color css starts  ======= */
  .btn-primary {
    background-color: #7366ff !important;
    border-color: #7366ff !important;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #7366ff !important;
    border-color: #7366ff !important;
  }
  .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem white;
  }
  
  .btn-secondary {
    background-color: #f73164 !important;
    border-color: #f73164 !important;
  }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #f73164 !important;
    border-color: #f73164 !important;
  }
  .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem #fdc4d3;
  }
  
  .btn-success {
    background-color: #51bb25 !important;
    border-color: #51bb25 !important;
  }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #51bb25 !important;
    border-color: #51bb25 !important;
  }
  .btn-success:focus {
    box-shadow: 0 0 0 0.2rem #aae990;
  }
  
  .btn-danger {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
  }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
  }
  .btn-danger:focus {
    box-shadow: 0 0 0 0.2rem #f3b7bd;
  }
  
  .btn-info {
    background-color: #a927f9 !important;
    border-color: #a927f9 !important;
    color: #fff;
  }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
    color: #fff;
  }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #a927f9 !important;
    border-color: #a927f9 !important;
  }
  .btn-info:focus {
    box-shadow: 0 0 0 0.2rem #e4bcfd;
  }
  
  .btn-light {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
  }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
  }
  .btn-light:focus {
    box-shadow: 0 0 0 0.2rem white;
  }
  
  .btn-dark {
    background-color: #2c323f !important;
    border-color: #2c323f !important;
  }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #2c323f !important;
    border-color: #2c323f !important;
  }
  .btn-dark:focus {
    box-shadow: 0 0 0 0.2rem #6c7a98;
  }
  
  .btn-warning {
    background-color: #f8d62b !important;
    border-color: #f8d62b !important;
  }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #f8d62b !important;
    border-color: #f8d62b !important;
  }
  .btn-warning:focus {
    box-shadow: 0 0 0 0.2rem #fdf3bf;
  }
  
  .btn-light {
    background-color: rgba(115, 102, 255, 0.06) !important;
    border-color: rgba(115, 102, 255, 0.06) !important;
  }
  .btn-light:hover {
    background-color: rgba(115, 102, 255, 0.1) !important;
    border-color: rgba(115, 102, 255, 0.1) !important;
  }
  
  .btn-primary {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
  }
  
  .btn-secondary {
    background-color: var(--theme-secondary) !important;
    border-color: var(--theme-secondary) !important;
  }
  
  /*======= Button-color css ends  ======= */
  .btn-outline-primary-2x {
    border-width: 2px;
    border-color: #7366ff;
    color: #7366ff;
    background-color: transparent;
  }
  .btn-outline-primary-2x:hover, .btn-outline-primary-2x:focus, .btn-outline-primary-2x:active, .btn-outline-primary-2x.active {
    color: white;
    background-color: #4433ff !important;
    border-color: #4433ff !important;
    box-shadow: none;
  }
  
  .btn-outline-secondary-2x {
    border-width: 2px;
    border-color: #f73164;
    color: #f73164;
    background-color: transparent;
  }
  .btn-outline-secondary-2x:hover, .btn-outline-secondary-2x:focus, .btn-outline-secondary-2x:active, .btn-outline-secondary-2x.active {
    color: white;
    background-color: #ec0944 !important;
    border-color: #ec0944 !important;
    box-shadow: none;
  }
  
  .btn-outline-success-2x {
    border-width: 2px;
    border-color: #51bb25;
    color: #51bb25;
    background-color: transparent;
  }
  .btn-outline-success-2x:hover, .btn-outline-success-2x:focus, .btn-outline-success-2x:active, .btn-outline-success-2x.active {
    color: white;
    background-color: #3f901d !important;
    border-color: #3f901d !important;
    box-shadow: none;
  }
  
  .btn-outline-danger-2x {
    border-width: 2px;
    border-color: #dc3545;
    color: #dc3545;
    background-color: transparent;
  }
  .btn-outline-danger-2x:hover, .btn-outline-danger-2x:focus, .btn-outline-danger-2x:active, .btn-outline-danger-2x.active {
    color: white;
    background-color: #bd2130 !important;
    border-color: #bd2130 !important;
    box-shadow: none;
  }
  
  .btn-outline-info-2x {
    border-width: 2px;
    border-color: #a927f9;
    color: #a927f9;
    background-color: transparent;
  }
  .btn-outline-info-2x:hover, .btn-outline-info-2x:focus, .btn-outline-info-2x:active, .btn-outline-info-2x.active {
    color: white;
    background-color: #9106e7 !important;
    border-color: #9106e7 !important;
    box-shadow: none;
  }
  
  .btn-outline-light-2x {
    border-width: 2px;
    border-color: #f4f4f4;
    color: #f4f4f4;
    background-color: transparent;
  }
  .btn-outline-light-2x:hover, .btn-outline-light-2x:focus, .btn-outline-light-2x:active, .btn-outline-light-2x.active {
    color: white;
    background-color: #dbdbdb !important;
    border-color: #dbdbdb !important;
    box-shadow: none;
  }
  
  .btn-outline-dark-2x {
    border-width: 2px;
    border-color: #2c323f;
    color: #2c323f;
    background-color: transparent;
  }
  .btn-outline-dark-2x:hover, .btn-outline-dark-2x:focus, .btn-outline-dark-2x:active, .btn-outline-dark-2x.active {
    color: white;
    background-color: #171a21 !important;
    border-color: #171a21 !important;
    box-shadow: none;
  }
  
  .btn-outline-warning-2x {
    border-width: 2px;
    border-color: #f8d62b;
    color: #f8d62b;
    background-color: transparent;
  }
  .btn-outline-warning-2x:hover, .btn-outline-warning-2x:focus, .btn-outline-warning-2x:active, .btn-outline-warning-2x.active {
    color: white;
    background-color: #e8c308 !important;
    border-color: #e8c308 !important;
    box-shadow: none;
  }
  
  .btn-outline-primary {
    border-color: #7366ff;
    color: #7366ff;
    background-color: transparent;
  }
  .btn-outline-primary.disabled {
    color: #7366ff;
  }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
    color: white;
    background-color: #4433ff !important;
    border-color: #4433ff !important;
  }
  
  .btn-outline-secondary {
    border-color: #f73164;
    color: #f73164;
    background-color: transparent;
  }
  .btn-outline-secondary.disabled {
    color: #f73164;
  }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
    color: white;
    background-color: #ec0944 !important;
    border-color: #ec0944 !important;
  }
  
  .btn-outline-success {
    border-color: #51bb25;
    color: #51bb25;
    background-color: transparent;
  }
  .btn-outline-success.disabled {
    color: #51bb25;
  }
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active {
    color: white;
    background-color: #3f901d !important;
    border-color: #3f901d !important;
  }
  
  .btn-outline-danger {
    border-color: #dc3545;
    color: #dc3545;
    background-color: transparent;
  }
  .btn-outline-danger.disabled {
    color: #dc3545;
  }
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active {
    color: white;
    background-color: #bd2130 !important;
    border-color: #bd2130 !important;
  }
  
  .btn-outline-info {
    border-color: #a927f9;
    color: #a927f9;
    background-color: transparent;
  }
  .btn-outline-info.disabled {
    color: #a927f9;
  }
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active {
    color: white;
    background-color: #9106e7 !important;
    border-color: #9106e7 !important;
  }
  
  .btn-outline-light {
    border-color: #f4f4f4;
    color: #f4f4f4;
    background-color: transparent;
    color: #2b2b2b;
  }
  .btn-outline-light.disabled {
    color: #f4f4f4;
  }
  .btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active {
    color: white;
    background-color: #dbdbdb !important;
    border-color: #dbdbdb !important;
  }
  
  .btn-outline-dark {
    border-color: #2c323f;
    color: #2c323f;
    background-color: transparent;
  }
  .btn-outline-dark.disabled {
    color: #2c323f;
  }
  .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active {
    color: white;
    background-color: #171a21 !important;
    border-color: #171a21 !important;
  }
  
  .btn-outline-warning {
    border-color: #f8d62b;
    color: #f8d62b;
    background-color: transparent;
  }
  .btn-outline-warning.disabled {
    color: #f8d62b;
  }
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active {
    color: white;
    background-color: #e8c308 !important;
    border-color: #e8c308 !important;
  }
  
  .btn-primary:not([disabled]):not(.disabled).active {
    background-color: #4433ff;
    border-color: #4433ff;
    box-shadow: none !important;
  }
  .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active:active, .btn-primary:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #4433ff;
    border-color: #4433ff;
  }
  
  .btn-secondary:not([disabled]):not(.disabled).active {
    background-color: #ec0944;
    border-color: #ec0944;
    box-shadow: none !important;
  }
  .btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #ec0944;
    border-color: #ec0944;
  }
  
  .btn-success:not([disabled]):not(.disabled).active {
    background-color: #3f901d;
    border-color: #3f901d;
    box-shadow: none !important;
  }
  .btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #3f901d;
    border-color: #3f901d;
  }
  
  .btn-danger:not([disabled]):not(.disabled).active {
    background-color: #bd2130;
    border-color: #bd2130;
    box-shadow: none !important;
  }
  .btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #bd2130;
    border-color: #bd2130;
  }
  
  .btn-info:not([disabled]):not(.disabled).active {
    background-color: #9106e7;
    border-color: #9106e7;
    box-shadow: none !important;
  }
  .btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #9106e7;
    border-color: #9106e7;
  }
  
  .btn-light:not([disabled]):not(.disabled).active {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    box-shadow: none !important;
  }
  .btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #dbdbdb;
    border-color: #dbdbdb;
  }
  
  .btn-dark:not([disabled]):not(.disabled).active {
    background-color: #171a21;
    border-color: #171a21;
    box-shadow: none !important;
  }
  .btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #171a21;
    border-color: #171a21;
  }
  
  .btn-warning:not([disabled]):not(.disabled).active {
    background-color: #e8c308;
    border-color: #e8c308;
    box-shadow: none !important;
  }
  .btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #e8c308;
    border-color: #e8c308;
  }
  
  .btn-outline-primary-2x:not([disabled]):not(.disabled).active {
    background-color: #7366ff;
    border-color: #7366ff;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #4433ff !important;
    border-color: #4433ff !important;
  }
  
  .btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
    background-color: #f73164;
    border-color: #f73164;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #ec0944 !important;
    border-color: #ec0944 !important;
  }
  
  .btn-outline-success-2x:not([disabled]):not(.disabled).active {
    background-color: #51bb25;
    border-color: #51bb25;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #3f901d !important;
    border-color: #3f901d !important;
  }
  
  .btn-outline-danger-2x:not([disabled]):not(.disabled).active {
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #bd2130 !important;
    border-color: #bd2130 !important;
  }
  
  .btn-outline-info-2x:not([disabled]):not(.disabled).active {
    background-color: #a927f9;
    border-color: #a927f9;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #9106e7 !important;
    border-color: #9106e7 !important;
  }
  
  .btn-outline-light-2x:not([disabled]):not(.disabled).active {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #dbdbdb !important;
    border-color: #dbdbdb !important;
  }
  
  .btn-outline-dark-2x:not([disabled]):not(.disabled).active {
    background-color: #2c323f;
    border-color: #2c323f;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #171a21 !important;
    border-color: #171a21 !important;
  }
  
  .btn-outline-warning-2x:not([disabled]):not(.disabled).active {
    background-color: #f8d62b;
    border-color: #f8d62b;
    box-shadow: none !important;
    color: white;
  }
  .btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #e8c308 !important;
    border-color: #e8c308 !important;
  }
  
  /*======= Table-Border-Bottom-color css starts  ======= */
  table thead .border-bottom-primary th,
  table tbody .border-bottom-primary th,
  table tbody .border-bottom-primary td {
    border-bottom: 1px solid #7366ff !important;
  }
  
  table thead .border-bottom-secondary th,
  table tbody .border-bottom-secondary th,
  table tbody .border-bottom-secondary td {
    border-bottom: 1px solid #f73164 !important;
  }
  
  table thead .border-bottom-success th,
  table tbody .border-bottom-success th,
  table tbody .border-bottom-success td {
    border-bottom: 1px solid #51bb25 !important;
  }
  
  table thead .border-bottom-danger th,
  table tbody .border-bottom-danger th,
  table tbody .border-bottom-danger td {
    border-bottom: 1px solid #dc3545 !important;
  }
  
  table thead .border-bottom-info th,
  table tbody .border-bottom-info th,
  table tbody .border-bottom-info td {
    border-bottom: 1px solid #a927f9 !important;
  }
  
  table thead .border-bottom-light th,
  table tbody .border-bottom-light th,
  table tbody .border-bottom-light td {
    border-bottom: 1px solid #f4f4f4 !important;
  }
  
  table thead .border-bottom-dark th,
  table tbody .border-bottom-dark th,
  table tbody .border-bottom-dark td {
    border-bottom: 1px solid #2c323f !important;
  }
  
  table thead .border-bottom-warning th,
  table tbody .border-bottom-warning th,
  table tbody .border-bottom-warning td {
    border-bottom: 1px solid #f8d62b !important;
  }
  
  /*======= Table-Border-Bottom-color css ends  ======= */
  /*======= Table styling css starts  ======= */
  .table-styling .table-primary,
  .table-styling.table-primary {
    background-color: #7366ff;
    color: #fff;
    border: 3px solid #7366ff;
  }
  .table-styling .table-primary thead,
  .table-styling.table-primary thead {
    background-color: #4433ff;
    border: 3px solid #4433ff;
  }
  .table-styling .table-primary th,
  .table-styling .table-primary td,
  .table-styling.table-primary th,
  .table-styling.table-primary td {
    background-color: transparent;
  }
  
  .table-styling .table-secondary,
  .table-styling.table-secondary {
    background-color: #f73164;
    color: #fff;
    border: 3px solid #f73164;
  }
  .table-styling .table-secondary thead,
  .table-styling.table-secondary thead {
    background-color: #ec0944;
    border: 3px solid #ec0944;
  }
  .table-styling .table-secondary th,
  .table-styling .table-secondary td,
  .table-styling.table-secondary th,
  .table-styling.table-secondary td {
    background-color: transparent;
  }
  
  .table-styling .table-success,
  .table-styling.table-success {
    background-color: #51bb25;
    color: #fff;
    border: 3px solid #51bb25;
  }
  .table-styling .table-success thead,
  .table-styling.table-success thead {
    background-color: #3f901d;
    border: 3px solid #3f901d;
  }
  .table-styling .table-success th,
  .table-styling .table-success td,
  .table-styling.table-success th,
  .table-styling.table-success td {
    background-color: transparent;
  }
  
  .table-styling .table-danger,
  .table-styling.table-danger {
    background-color: #dc3545;
    color: #fff;
    border: 3px solid #dc3545;
  }
  .table-styling .table-danger thead,
  .table-styling.table-danger thead {
    background-color: #bd2130;
    border: 3px solid #bd2130;
  }
  .table-styling .table-danger th,
  .table-styling .table-danger td,
  .table-styling.table-danger th,
  .table-styling.table-danger td {
    background-color: transparent;
  }
  
  .table-styling .table-info,
  .table-styling.table-info {
    background-color: #a927f9;
    color: #fff;
    border: 3px solid #a927f9;
  }
  .table-styling .table-info thead,
  .table-styling.table-info thead {
    background-color: #9106e7;
    border: 3px solid #9106e7;
  }
  .table-styling .table-info th,
  .table-styling .table-info td,
  .table-styling.table-info th,
  .table-styling.table-info td {
    background-color: transparent;
  }
  
  .table-styling .table-light,
  .table-styling.table-light {
    background-color: #f4f4f4;
    color: #fff;
    border: 3px solid #f4f4f4;
  }
  .table-styling .table-light thead,
  .table-styling.table-light thead {
    background-color: #dbdbdb;
    border: 3px solid #dbdbdb;
  }
  .table-styling .table-light th,
  .table-styling .table-light td,
  .table-styling.table-light th,
  .table-styling.table-light td {
    background-color: transparent;
  }
  
  .table-styling .table-dark,
  .table-styling.table-dark {
    background-color: #2c323f;
    color: #fff;
    border: 3px solid #2c323f;
  }
  .table-styling .table-dark thead,
  .table-styling.table-dark thead {
    background-color: #171a21;
    border: 3px solid #171a21;
  }
  .table-styling .table-dark th,
  .table-styling .table-dark td,
  .table-styling.table-dark th,
  .table-styling.table-dark td {
    background-color: transparent;
  }
  
  .table-styling .table-warning,
  .table-styling.table-warning {
    background-color: #f8d62b;
    color: #fff;
    border: 3px solid #f8d62b;
  }
  .table-styling .table-warning thead,
  .table-styling.table-warning thead {
    background-color: #e8c308;
    border: 3px solid #e8c308;
  }
  .table-styling .table-warning th,
  .table-styling .table-warning td,
  .table-styling.table-warning th,
  .table-styling.table-warning td {
    background-color: transparent;
  }
  
  /*======= Table styling css ends  ======= */
  /*======= All-Borders-color css starts  ======= */
  .b-primary {
    border: 1px solid #7366ff !important;
  }
  
  .b-t-primary {
    border-top: 1px solid #7366ff !important;
  }
  
  .b-b-primary {
    border-bottom: 1px solid #7366ff !important;
  }
  
  .b-l-primary {
    border-left: 1px solid #7366ff !important;
  }
  
  .b-r-primary {
    border-right: 1px solid #7366ff !important;
  }
  
  .b-secondary {
    border: 1px solid #f73164 !important;
  }
  
  .b-t-secondary {
    border-top: 1px solid #f73164 !important;
  }
  
  .b-b-secondary {
    border-bottom: 1px solid #f73164 !important;
  }
  
  .b-l-secondary {
    border-left: 1px solid #f73164 !important;
  }
  
  .b-r-secondary {
    border-right: 1px solid #f73164 !important;
  }
  
  .b-success {
    border: 1px solid #51bb25 !important;
  }
  
  .b-t-success {
    border-top: 1px solid #51bb25 !important;
  }
  
  .b-b-success {
    border-bottom: 1px solid #51bb25 !important;
  }
  
  .b-l-success {
    border-left: 1px solid #51bb25 !important;
  }
  
  .b-r-success {
    border-right: 1px solid #51bb25 !important;
  }
  
  .b-danger {
    border: 1px solid #dc3545 !important;
  }
  
  .b-t-danger {
    border-top: 1px solid #dc3545 !important;
  }
  
  .b-b-danger {
    border-bottom: 1px solid #dc3545 !important;
  }
  
  .b-l-danger {
    border-left: 1px solid #dc3545 !important;
  }
  
  .b-r-danger {
    border-right: 1px solid #dc3545 !important;
  }
  
  .b-info {
    border: 1px solid #a927f9 !important;
  }
  
  .b-t-info {
    border-top: 1px solid #a927f9 !important;
  }
  
  .b-b-info {
    border-bottom: 1px solid #a927f9 !important;
  }
  
  .b-l-info {
    border-left: 1px solid #a927f9 !important;
  }
  
  .b-r-info {
    border-right: 1px solid #a927f9 !important;
  }
  
  .b-light {
    border: 1px solid #f4f4f4 !important;
  }
  
  .b-t-light {
    border-top: 1px solid #f4f4f4 !important;
  }
  
  .b-b-light {
    border-bottom: 1px solid #f4f4f4 !important;
  }
  
  .b-l-light {
    border-left: 1px solid #f4f4f4 !important;
  }
  
  .b-r-light {
    border-right: 1px solid #f4f4f4 !important;
  }
  
  .b-dark {
    border: 1px solid #2c323f !important;
  }
  
  .b-t-dark {
    border-top: 1px solid #2c323f !important;
  }
  
  .b-b-dark {
    border-bottom: 1px solid #2c323f !important;
  }
  
  .b-l-dark {
    border-left: 1px solid #2c323f !important;
  }
  
  .b-r-dark {
    border-right: 1px solid #2c323f !important;
  }
  
  .b-warning {
    border: 1px solid #f8d62b !important;
  }
  
  .b-t-warning {
    border-top: 1px solid #f8d62b !important;
  }
  
  .b-b-warning {
    border-bottom: 1px solid #f8d62b !important;
  }
  
  .b-l-warning {
    border-left: 1px solid #f8d62b !important;
  }
  
  .b-r-warning {
    border-right: 1px solid #f8d62b !important;
  }
  
  /*======= All-Borders-color css ends  ======= */
  /*====== Border width css starts ======*/
  .border-1 {
    border-width: 1px !important;
  }
  
  .border-2 {
    border-width: 2px !important;
  }
  
  .border-3 {
    border-width: 3px !important;
  }
  
  .border-4 {
    border-width: 4px !important;
  }
  
  .border-5 {
    border-width: 5px !important;
  }
  
  .border-6 {
    border-width: 6px !important;
  }
  
  .border-7 {
    border-width: 7px !important;
  }
  
  .border-8 {
    border-width: 8px !important;
  }
  
  .border-9 {
    border-width: 9px !important;
  }
  
  .border-10 {
    border-width: 10px !important;
  }
  
  /*====== Border width css ends ======*/
  .opacity-0 {
    opacity: 0;
  }
  
  .map-block {
    height: 350px;
    width: 100%;
  }
  
  .map-js-height {
    height: 500px;
  }
  .map-js-height .btn-group input {
    background-color: #7366ff;
  }
  .map-js-height .btn-group input + input {
    background-color: #f73164;
  }
  
  /**====== custom scrollbar css start ======**/
  .custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ecf3fa;
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
  
  /**====== Custom scrollbar css end ======**/
  /**====== Animation css Start ======**/
  .comment {
    color: #9f9ba5;
    font-style: italic;
  }
  
  .line {
    color: #2c323f;
  }
  .line pre {
    font-size: 100%;
  }
  
  /**====== Animation css end ======**/
  /**====== list style css Start ======**/
  .list-circle {
    list-style: circle;
  }
  
  /**====== list style css end ======**/
  /**====== Modal style css Start ======**/
  .theme-close {
    opacity: 1;
    height: 40px;
    width: 40px;
    position: absolute;
    font-weight: 400;
    z-index: 1;
    right: 0;
    background-color: #fff !important;
    border-radius: 5px;
  }
  
  /**====== Modal style css end ======**/
  /**====== Animation css start ======**/
  .options > div {
    color: #cccccc;
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;
  }
  .options > div:hover {
    background-color: #7366ff;
    color: #fff;
    transition: all 0.3s ease;
  }
  
  /**====== Animation css Ends ======**/
  .modal-footer {
    flex-wrap: wrap;
  }
  
  .img-cropper #putData {
    margin-bottom: 0;
  }
  .img-cropper .img-container {
    min-height: auto;
    margin-bottom: 0;
  }
  .img-cropper .docs-data > .input-group:last-child {
    margin-bottom: 0;
  }
  .img-cropper .docs-preview {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .dropzone .dz-preview .dz-error-message {
    background: #fff !important;
    color: #7366ff !important;
    border: 1px solid #7366ff;
  }
  .dropzone .dz-preview .dz-error-message:after {
    border-bottom: 6px solid #7366ff !important;
  }
  
  .typeahead .theme-form .form-group {
    margin-bottom: 0;
  }
  
  .editor-statusbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .page-builder .ge-canvas.ge-editing .row {
    padding: 30px;
    margin-bottom: 0;
    background-color: rgba(115, 102, 255, 0.02);
  }
  .page-builder .btn-screen {
    padding: 0 18px 0 0;
  }
  
  .bg-overlay.active {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 8;
    top: 0;
  }
  
  button:focus {
    outline: none !important;
  }
  
  .dotted {
    border-style: dotted;
  }
  
  .dashed {
    border-style: dashed;
  }
  
  .double {
    border-style: double;
  }
  
  .groove {
    border-style: groove;
  }
  
  .ridge {
    border-style: ridge;
  }
  
  .inset {
    border-style: inset;
  }
  
  .outset {
    border-style: outset;
  }
  
  @keyframes fadeIncustom {
    0% {
      opacity: 0;
      top: 50px;
    }
    75% {
      opacity: 0.6;
      top: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    color: #898989;
    padding: 5px 10px;
    border-radius: 5px;
    width: 46%;
  }
  
  /**=====================
       Reset css Ends
  ==========================**/
  /**=====================
      Typography css start
  ==========================**/
  body {
    font-size: 14px;
    overflow-x: hidden;
    font-family: Rubik, sans-serif;
    color: #2b2b2b;
  }
  
  ul {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 0;
  }
  
  * a {
    color: var(--theme-default);
    text-decoration: none;
  }
  .a-ship{
    color: #000000DE;
    text-decoration: none;
  }
  .a-ship:hover{
    color: #000000;
  }
  
  a:hover {
    text-decoration: none;
    color: var(--theme-default);
  }
  a[class*=badge-]:hover {
    color: #fff;
  }
  
  .btn {
    font-size: 14px;
  }
  
  *.btn:focus {
    box-shadow: none !important;
  }
  
  p {
    font-size: 13px;
    line-height: 1.7;
    letter-spacing: 0.7px;
  }
  
  code {
    color: var(--theme-default) !important;
    background-color: rgba(115, 102, 255, 0.03);
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px;
  }
  
  .media {
    display: flex;
    align-items: flex-start;
  }
  .media .media-body {
    flex: 1;
  }
  
  blockquote {
    border-left: 4px solid #ecf3fa;
    padding: 15px;
  }
  
  .figure.text-center blockquote {
    border: none !important;
  }
  .figure.text-end blockquote {
    border-left: none !important;
    border-right: 4px solid #ecf3fa;
  }
  
  .row > div {
    position: relative;
  }
  
  :focus {
    outline-color: var(--theme-default);
  }
  
  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  svg {
    vertical-align: baseline;
  }
  
  input:focus {
    outline-color: transparent;
  }
  
  // label {
  //   margin-bottom: 0.5rem;
  // }
  
  .media-widgets .media-body {
    margin-bottom: 30px;
  }
  
  .typography h1,
  .typography .h1,
  .typography h2,
  .typography .h2,
  .typography h3,
  .typography .h3,
  .typography h4,
  .typography .h4,
  .typography h5,
  .typography .h5,
  .typography h6,
  .typography .h6 {
    margin: 20px 0;
    margin-top: 0;
  }
  .typography h1:last-child,
  .typography .h1:last-child,
  .typography h2:last-child,
  .typography .h2:last-child,
  .typography h3:last-child,
  .typography .h3:last-child,
  .typography h4:last-child,
  .typography .h4:last-child,
  .typography h5:last-child,
  .typography .h5:last-child,
  .typography h6:last-child,
  .typography .h6:last-child {
    margin-bottom: 0;
  }
  .typography small {
    padding-left: 10px;
    color: #2c323f;
  }
  
  pre {
    background-color: rgba(115, 102, 255, 0.03);
    padding: 20px 0;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    background: #d0ccff;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--theme-default);
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-bottom: -10px;
    margin-top: 25px;
  }
  
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 30px;
  }
  
  .note-editor.note-frame {
    border-color: #f4f4f4;
  }
  
  .note-editor.note-frame {
    border-color: #f4f4f4;
  }
  
  .modal .modal-body .card {
    box-shadow: none !important;
  }
  
  .modal-dialog .modal-content .modal-body p a {
    margin-right: 0;
  }
  
  .alert-theme button {
    top: 25px !important;
    right: 20px !important;
    color: var(--theme-default);
  }
  .alert-theme span + span + span {
    background-color: #fff;
    color: var(--theme-default);
    border-radius: 4px;
    box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
  }
  .alert-theme i {
    padding: 20px;
    padding-right: 0 !important;
    margin-right: 20px !important;
  }
  .alert-theme .progress {
    margin-top: -2px !important;
    height: 2px;
    width: 100%;
  }
  .alert-theme .progress .progress-bar {
    background-color: var(--theme-default);
  }
  
  .alert-copy {
    border: 1px solid var(--theme-default);
    line-height: 1;
    display: inline-block;
    width: 300px;
    color: #fff;
    background-color: var(--theme-default);
  }
  .alert-copy .close {
    box-shadow: none;
    color: #fff;
    line-height: 1.4;
    font-weight: 100;
  }
  .alert-copy .close:focus {
    outline: none;
  }
  
  .ace-editor {
    height: 400px;
    width: 100%;
  }
  
  .editor-toolbar {
    width: 100% !important;
  }
  .editor-toolbar.fullscreen {
    z-index: 100;
  }
  
  .CodeMirror {
    top: 0 !important;
  }
  
  .CodeMirror-fullscreen {
    top: 50px !important;
  }
  
  .irs-from,
  .irs-to,
  .irs-single {
    background: var(--theme-default);
  }
  .irs-from:after,
  .irs-to:after,
  .irs-single:after {
    border-top-color: var(--theme-default);
  }
  
  .irs-line-mid,
  .irs-line-left,
  .irs-line-right,
  .irs-bar,
  .irs-bar-edge {
    background-color: var(--theme-default);
  }
  
  .swal-button {
    background-color: var(--theme-default);
  }
  .swal-button:active {
    background-color: #5c4dff;
  }
  
  .swal-button--cancel {
    background-color: #EFEFEE !important;
  }
  
  .clipboaard-container h6 {
    line-height: 1.6;
    font-size: 13px;
  }
  
  fieldset .bootstrap-touchspin-postfix,
  fieldset .bootstrap-touchspin-prefix {
    border-radius: 0;
    padding: 0.4rem 0.75rem;
  }
  fieldset .input-group-lg .touchspin {
    height: 50px;
  }
  
  .f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
  }
  
  .form-inline .form-group {
    display: flex;
  }
  
  .input-group-text i {
    line-height: 1.5;
  }
  
  .animated-modal .form-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  
  /**=====================
      Typography css Ends
  ==========================**/
  /**=====================
      24. According CSS start
  ==========================**/
  .default-according .card {
    margin-bottom: 0;
    box-shadow: none;
  }
  .default-according .card + .card {
    margin-top: 10px;
  }
  .default-according .card .btn-link {
    font-weight: 500;
    color: #2b2b2b;
    text-decoration: none;
  }
  .default-according .card .btn-link:focus, .default-according .card .btn-link:hover {
    text-decoration: none;
  }
  .default-according .card .card-header {
    padding: 0.75rem 1.25rem;
    border: 1px solid #ecf3fa;
  }
  .default-according .card .card-header i {
    position: absolute;
    left: 18px;
    font-size: 20px;
    top: 20px;
  }
  .default-according .card .card-header h5 {
    margin-top: 2px;
  }
  .default-according .card .card-body {
    border: 1px solid #ecf3fa;
    border-top: none;
  }
  
  .default-according.style-1 button {
    width: 100%;
    text-align: left;
  }
  .default-according.style-1 button:before {
    right: 20px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .default-according.style-1 button[aria-expanded=true]:before {
    content: "\eb73";
    font-family: IcoFont;
  }
  .default-according.style-1 button[aria-expanded=false]:before {
    content: "\eb7a";
    font-family: IcoFont;
  }
  
  /**=====================
       24. According CSS Ends
  ==========================**/
  /**=====================
       15. Alert CSS start
  ==========================**/
  .notify-alert .close {
    background: unset;
    border: none;
    font-size: 25px;
  }
  
  .alert {
    border-radius: 0.15rem;
    padding: 15px;
  }
  .alert svg {
    vertical-align: middle;
  }
  .alert .progress {
    margin-top: 15px;
  }
  .alert [data-notify=icon] {
    margin-right: 10px;
    line-height: 20px;
    position: absolute;
  }
  .alert [data-notify=message] {
    display: inline-block;
    width: 100%;
  }
  .alert i {
    margin-right: 5px;
    font-size: 16px;
  }
  .alert p {
    margin-bottom: 0;
  }
  .alert .close {
    opacity: 1;
  }
  .alert .close span {
    font-size: 24px;
    font-weight: 400;
    display: inline-block;
    border-radius: 3px;
    text-shadow: none;
    padding: 0 5px;
    padding-right: 0;
  }
  
  .alert-dismissible .close {
    padding: 10px 1.25rem;
    top: 4px;
    padding-right: 13px;
    opacity: 0.5;
    transition: all 0.3s ease;
  }
  .alert-dismissible .close:hover {
    transition: all 0.3s ease;
    opacity: 1;
    color: inherit;
  }
  
  .card-body button:focus {
    outline: none;
  }
  .card-body .alert:last-child {
    margin-bottom: 0;
  }
  .card-body .alert svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    position: absolute;
    top: 18px;
  }
  .card-body .alert svg ~ p {
    padding-left: 20px;
  }
  
  strong {
    display: inline-block;
  }
  strong ~ p {
    display: inline-block;
  }
  
  .alert-primary {
    background-color: rgba(115, 102, 255, 0.8);
    border-color: rgba(115, 102, 255, 0.9);
    color: #fff;
  }
  .alert-primary .progress {
    height: 5px;
    background-color: #6e61ff;
    border-radius: 0;
  }
  .alert-primary .progress-bar {
    background-color: white;
  }
  .alert-primary .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-primary .alert-link {
    color: #7366ff;
  }
  .alert-primary hr {
    border-top-color: #7366ff;
  }
  
  .alert-primary.dark {
    background-color: #7366ff;
    border-color: #7366ff;
    color: #fafafa;
  }
  .alert-primary.dark .alert-link {
    color: #fff;
  }
  
  .alert-primary.outline, .alert-primary.outline-2x {
    background-color: transparent;
    border-color: #7366ff;
    color: #7366ff;
  }
  .alert-primary.outline .btn-close, .alert-primary.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-primary.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #7366ff;
    color: #2b2b2b;
  }
  .alert-primary.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #7366ff;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-primary.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #7366ff;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-primary.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-secondary {
    background-color: rgba(247, 49, 100, 0.8);
    border-color: rgba(247, 49, 100, 0.9);
    color: #fff;
  }
  .alert-secondary .progress {
    height: 5px;
    background-color: #f72c60;
    border-radius: 0;
  }
  .alert-secondary .progress-bar {
    background-color: white;
  }
  .alert-secondary .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-secondary .alert-link {
    color: #f73164;
  }
  .alert-secondary hr {
    border-top-color: #f73164;
  }
  
  .alert-secondary.dark {
    background-color: #f73164;
    border-color: #f73164;
    color: #fafafa;
  }
  .alert-secondary.dark .alert-link {
    color: #fff;
  }
  
  .alert-secondary.outline, .alert-secondary.outline-2x {
    background-color: transparent;
    border-color: #f73164;
    color: #f73164;
  }
  .alert-secondary.outline .btn-close, .alert-secondary.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-secondary.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #f73164;
    color: #2b2b2b;
  }
  .alert-secondary.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #f73164;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-secondary.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #f73164;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-secondary.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-success {
    background-color: rgba(81, 187, 37, 0.8);
    border-color: rgba(81, 187, 37, 0.9);
    color: #fff;
  }
  .alert-success .progress {
    height: 5px;
    background-color: #4fb724;
    border-radius: 0;
  }
  .alert-success .progress-bar {
    background-color: #ebfae5;
  }
  .alert-success .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-success .alert-link {
    color: #51bb25;
  }
  .alert-success hr {
    border-top-color: #51bb25;
  }
  
  .alert-success.dark {
    background-color: #51bb25;
    border-color: #51bb25;
    color: #fafafa;
  }
  .alert-success.dark .alert-link {
    color: #fff;
  }
  
  .alert-success.outline, .alert-success.outline-2x {
    background-color: transparent;
    border-color: #51bb25;
    color: #51bb25;
  }
  .alert-success.outline .btn-close, .alert-success.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-success.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #51bb25;
    color: #2b2b2b;
  }
  .alert-success.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #51bb25;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-success.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #51bb25;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-success.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-danger {
    background-color: rgba(220, 53, 69, 0.8);
    border-color: rgba(220, 53, 69, 0.9);
    color: #fff;
  }
  .alert-danger .progress {
    height: 5px;
    background-color: #db3141;
    border-radius: 0;
  }
  .alert-danger .progress-bar {
    background-color: white;
  }
  .alert-danger .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-danger .alert-link {
    color: #dc3545;
  }
  .alert-danger hr {
    border-top-color: #dc3545;
  }
  
  .alert-danger.dark {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fafafa;
  }
  .alert-danger.dark .alert-link {
    color: #fff;
  }
  
  .alert-danger.outline, .alert-danger.outline-2x {
    background-color: transparent;
    border-color: #dc3545;
    color: #dc3545;
  }
  .alert-danger.outline .btn-close, .alert-danger.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-danger.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #dc3545;
    color: #2b2b2b;
  }
  .alert-danger.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #dc3545;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-danger.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #dc3545;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-danger.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-warning {
    background-color: rgba(248, 214, 43, 0.8);
    border-color: rgba(248, 214, 43, 0.9);
    color: #fff;
  }
  .alert-warning .progress {
    height: 5px;
    background-color: #f8d526;
    border-radius: 0;
  }
  .alert-warning .progress-bar {
    background-color: white;
  }
  .alert-warning .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-warning .alert-link {
    color: #f8d62b;
  }
  .alert-warning hr {
    border-top-color: #f8d62b;
  }
  
  .alert-warning.dark {
    background-color: #f8d62b;
    border-color: #f8d62b;
    color: #fafafa;
  }
  .alert-warning.dark .alert-link {
    color: #fff;
  }
  
  .alert-warning.outline, .alert-warning.outline-2x {
    background-color: transparent;
    border-color: #f8d62b;
    color: #f8d62b;
  }
  .alert-warning.outline .btn-close, .alert-warning.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-warning.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #f8d62b;
    color: #2b2b2b;
  }
  .alert-warning.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #f8d62b;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-warning.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #f8d62b;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-warning.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-info {
    background-color: rgba(169, 39, 249, 0.8);
    border-color: rgba(169, 39, 249, 0.9);
    color: #fff;
  }
  .alert-info .progress {
    height: 5px;
    background-color: #a722f9;
    border-radius: 0;
  }
  .alert-info .progress-bar {
    background-color: white;
  }
  .alert-info .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-info .alert-link {
    color: #a927f9;
  }
  .alert-info hr {
    border-top-color: #a927f9;
  }
  
  .alert-info.dark {
    background-color: #a927f9;
    border-color: #a927f9;
    color: #fafafa;
  }
  .alert-info.dark .alert-link {
    color: #fff;
  }
  
  .alert-info.outline, .alert-info.outline-2x {
    background-color: transparent;
    border-color: #a927f9;
    color: #a927f9;
  }
  .alert-info.outline .btn-close, .alert-info.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-info.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #a927f9;
    color: #2b2b2b;
  }
  .alert-info.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #a927f9;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-info.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #a927f9;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-info.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-light {
    background-color: rgba(244, 244, 244, 0.8);
    border-color: rgba(244, 244, 244, 0.9);
    color: #fff;
    color: #2c323f;
    background-color: #f4f4f4;
  }
  .alert-light .progress {
    height: 5px;
    background-color: #f1f1f1;
    border-radius: 0;
  }
  .alert-light .progress-bar {
    background-color: white;
  }
  .alert-light .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-light .alert-link {
    color: #f4f4f4;
    color: #2c323f;
  }
  .alert-light .btn-close {
    filter: unset;
  }
  .alert-light hr {
    border-top-color: #f4f4f4;
  }
  
  .alert-light.dark {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    color: #fafafa;
    color: #2c323f;
  }
  .alert-light.dark .alert-link {
    color: #fff;
    color: #2c323f;
  }
  
  .alert-light.outline, .alert-light.outline-2x {
    background-color: transparent;
    border-color: #f4f4f4;
    color: #f4f4f4;
    color: #2c323f;
  }
  .alert-light.outline .btn-close, .alert-light.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-light.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #f4f4f4;
    color: #2b2b2b;
  }
  .alert-light.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #f4f4f4;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-light.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #f4f4f4;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-light.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .alert-dark {
    background-color: rgba(44, 50, 63, 0.8);
    border-color: rgba(44, 50, 63, 0.9);
    color: #fff;
  }
  .alert-dark .progress {
    height: 5px;
    background-color: #2a303c;
    border-radius: 0;
  }
  .alert-dark .progress-bar {
    background-color: #a8b0c2;
  }
  .alert-dark .btn-close {
    filter: brightness(1) invert(1);
  }
  .alert-dark .alert-link {
    color: #2c323f;
  }
  .alert-dark hr {
    border-top-color: #2c323f;
  }
  
  .alert-dark.dark {
    background-color: #2c323f;
    border-color: #2c323f;
    color: #fafafa;
  }
  .alert-dark.dark .alert-link {
    color: #fff;
  }
  
  .alert-dark.outline, .alert-dark.outline-2x {
    background-color: transparent;
    border-color: #2c323f;
    color: #2c323f;
  }
  .alert-dark.outline .btn-close, .alert-dark.outline-2x .btn-close {
    filter: unset;
  }
  
  .alert-dark.inverse {
    background-color: transparent;
    padding: 13px 20px 13px 65px;
    border-color: #2c323f;
    color: #2b2b2b;
  }
  .alert-dark.inverse:before {
    top: 16px;
    content: "";
    position: absolute;
    left: 54px;
    width: 0;
    height: 0;
    border-left: 7px solid #2c323f;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  .alert-dark.inverse i {
    padding: 17px 20px;
    display: inline-block;
    background-color: #2c323f;
    color: #fff;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .alert-dark.inverse .btn-close {
    filter: unset;
    top: -3px;
  }
  
  .dismiss-text .alert .btn-close {
    background: unset;
    opacity: 1;
    top: -6px;
    right: 40px;
  }
  .dismiss-text .alert .btn-close span {
    display: inline-block;
    border-radius: 3px;
    text-shadow: none;
    padding: 0 5px;
  }
  
  .outline-2x {
    border-width: 2px;
    padding: 11px 1.25rem;
  }
  
  /**=====================
       15. Alert CSS end
  ==========================**/
  /**=====================
      80. avatars CSS start
  ==========================**/
  .avatars .avatar {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    width: auto;
  }
  .avatars .avatar:last-child {
    margin-right: 0;
  }
  .avatars .avatar .b-r-30 {
    border-radius: 30px;
  }
  .avatars .avatar .b-r-35 {
    border-radius: 35px;
  }
  .avatars .avatar .b-r-25 {
    border-radius: 25px;
  }
  .avatars .avatar .b-r-15 {
    border-radius: 15px;
  }
  .avatars .avatar.ratio img {
    width: auto;
  }
  .avatars .avatar .status {
    position: absolute;
    background-color: #999999;
    bottom: 0;
    right: 4px;
    border-radius: 100%;
    border: 1px solid #fff;
  }
  .avatars .avatar .status {
    position: absolute;
    height: 12%;
    width: 12%;
    background-color: #51bb25;
    border-radius: 100%;
    bottom: 12%;
    right: 4%;
  }
  
  .customers ul {
    display: inline-block;
  }
  .customers ul li img {
    border: 2px solid #fff;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
  .customers ul li img:hover {
    transform: translateY(-4px) scale(1.02);
    transition: 0.5s;
  }
  .customers ul li + li {
    margin-left: -10%;
  }
  .customers ul li:nth-child(4) {
    margin-left: -4px;
  }
  .customers.avatar-group {
    margin-right: 30px;
  }
  .customers.avatar-group:last-child {
    margin-right: 0;
  }
  
  .avatar-showcase {
    margin-bottom: -10px;
  }
  .avatar-showcase .avatars .avatar {
    margin-bottom: 10px;
  }
  .avatar-showcase .avatar-group {
    margin-bottom: 10px;
  }
  .avatar-showcase .ratio > * {
    position: relative;
    top: unset;
    left: unset;
  }
  .avatar-showcase .add-friend .d-block {
    margin: 8px 0;
  }
  
  /**=====================
       80. avatars CSS Ends
  ==========================**/
  /**=====================
      49. Badge CSS start
  ==========================**/
  .badge {
    padding: 0.44em 0.7em;
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
  .badge + .badge {
    margin-left: 5px;
  }
  .badge svg {
    width: 10px;
    height: 10px;
    padding-top: 3px;
  }
  
  .pill-badge-primary {
    background-color: var(--theme-default);
    box-shadow: -0.5px 4.33px 16px 0px rgba(var(--theme-default), 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-secondary {
    background-color: #f73164;
    box-shadow: -0.5px 4.33px 16px 0px rgba(247, 49, 100, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-success {
    background-color: #51bb25;
    box-shadow: -0.5px 4.33px 16px 0px rgba(81, 187, 37, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-danger {
    background-color: #dc3545;
    box-shadow: -0.5px 4.33px 16px 0px rgba(220, 53, 69, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-info {
    background-color: #a927f9;
    box-shadow: -0.5px 4.33px 16px 0px rgba(169, 39, 249, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-light {
    background-color: #f4f4f4;
    box-shadow: -0.5px 4.33px 16px 0px rgba(244, 244, 244, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-dark {
    background-color: #2c323f;
    box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-warning {
    background-color: #f8d62b;
    box-shadow: -0.5px 4.33px 16px 0px rgba(248, 214, 43, 0.25);
    color: #fff;
    border-radius: 0;
  }
  
  .pill-badge-primary {
    background-color: var(--theme-default);
  }
  
  .pill-badge-secondary {
    background-color: var(--theme-secondary);
  }
  
  .round-badge-primary {
    border-radius: 6px;
    background-color: var(--theme-default);
    box-shadow: -0.5px -6.67px 14px 0px rgba(var(--theme-default), 0.18);
    color: #fff !important;
  }
  
  .round-badge-secondary {
    border-radius: 6px;
    background-color: #f73164;
    box-shadow: -0.5px -6.67px 14px 0px rgba(247, 49, 100, 0.18);
    color: #fff !important;
  }
  
  .round-badge-success {
    border-radius: 6px;
    background-color: #51bb25;
    box-shadow: -0.5px -6.67px 14px 0px rgba(81, 187, 37, 0.18);
    color: #fff !important;
  }
  
  .round-badge-danger {
    border-radius: 6px;
    background-color: #dc3545;
    box-shadow: -0.5px -6.67px 14px 0px rgba(220, 53, 69, 0.18);
    color: #fff !important;
  }
  
  .round-badge-info {
    border-radius: 6px;
    background-color: #a927f9;
    box-shadow: -0.5px -6.67px 14px 0px rgba(169, 39, 249, 0.18);
    color: #fff !important;
  }
  
  .round-badge-light {
    border-radius: 6px;
    background-color: #f4f4f4;
    box-shadow: -0.5px -6.67px 14px 0px rgba(244, 244, 244, 0.18);
    color: #fff !important;
  }
  
  .round-badge-dark {
    border-radius: 6px;
    background-color: #2c323f;
    box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
    color: #fff !important;
  }
  
  .round-badge-warning {
    border-radius: 6px;
    background-color: #f8d62b;
    box-shadow: -0.5px -6.67px 14px 0px rgba(248, 214, 43, 0.18);
    color: #fff !important;
  }
  
  .round-badge-primary {
    background-color: var(--theme-default);
  }
  
  .round-badge-secondary {
    background-color: var(--theme-secondary);
  }
  
  /**=====================
      49. Badge CSS Ends
  ==========================**/
  /**=====================
      81. Bookmark CSS start
  ==========================**/
  .bookmark ul li {
    display: inline-block;
  }
  .bookmark ul li .search-form {
    position: absolute;
    bottom: 53px;
    z-index: 1;
  }
  .bookmark ul li .search-form .form-control-search {
    top: 65px;
    position: absolute;
    transition: all linear 0.3s;
    right: -20px;
    transform: translateY(-35px) scaleY(0);
    opacity: 0;
    visibility: hidden;
  }
  .bookmark ul li .search-form .form-control-search input {
    width: 240px;
    background-color: #fff;
    box-shadow: 0 5px 21px rgba(0, 0, 0, 0.1);
  }
  .bookmark ul li .search-form .form-control-search.open {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
    visibility: visible;
    transition: all linear 0.3s;
    z-index: 100;
  }
  .bookmark ul li .search-form .form-control-search:after {
    top: 12px;
    left: 20px;
    z-index: 1;
  }
  .bookmark ul li .search-form .form-control-search:before {
    left: 50px;
    top: 9px;
    z-index: 2;
  }
  .bookmark ul li a {
    position: relative;
    color: #2b2b2b;
  }
  .bookmark ul li a svg {
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }
  .bookmark ul li + li {
    margin-left: 10px;
  }
  .bookmark ul li:last-child {
    padding-right: 0;
  }
  .bookmark ul li:last-child a {
    border-radius: 5px;
  }
  .bookmark ul li:last-child a svg {
    color: #ff9f40;
  }
  
  .offcanvas-bookmark.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body .bookmark ul li a {
    z-index: 99999;
    color: rgba(255, 255, 255, 0.5);
  }
  .offcanvas-bookmark:before {
    position: fixed;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 1;
    right: 0;
    left: 0;
  }
  
  /**=====================
      81. Bookmark CSS Ends
  ==========================**/
  /**=====================
      57. breadcrumb CSS Start
  ==========================**/
  .breadcrumb-colored {
    padding: 12px 16px;
    border-radius: 4px;
  }
  .breadcrumb-colored li a {
    color: #fff;
  }
  .breadcrumb-colored li.active {
    color: #fff;
    opacity: 0.7;
  }
  .breadcrumb-colored .breadcrumb-item:before {
    color: #fff;
  }
  .breadcrumb-colored .breadcrumb-item.txt-dark:before {
    color: #000;
  }
  
  .breadcrumb-icon {
    --bs-breadcrumb-divider: ">";
  }
  .breadcrumb-icon li {
    display: inline-block;
  }
  
  .breadcrumb-no-divider {
    --bs-breadcrumb-divider: "";
  }
  
  .breadcrumb-item.active {
    text-transform: capitalize;
  }
  
  /**=====================
      57. breadcrumb CSS ends
  ==========================**/
  /**=====================
       35. Form builder css start
  ==========================**/
  .form-builder .help-block {
    margin-bottom: 0;
    color: #cccccc;
    text-transform: capitalize;
  }
  .form-builder .btn-light {
    color: #cccccc;
  }
  .form-builder .copy-btn {
    padding: 8px;
  }
  .form-builder .nav-primary .nav-link.active, .form-builder .nav-primary .nav-primary .show > .nav-link, .form-builder .nav-primary .nav-pills.nav-primary .nav-link.active, .form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
    border-radius: 5px;
  }
  .form-builder .nav-primary .nav-link, .form-builder .nav-primary .nav-pills.nav-primary .nav-link {
    color: #000;
    font-weight: 500;
  }
  .form-builder .theme-form {
    margin-top: 30px;
  }
  
  /**=====================
      35. Form builder css Ends
  ==========================**/
  /**=====================
      06. Button CSS start
  ==========================**/
  .btn-link {
    color: var(--theme-default);
  }
  
  .btn-primary-gradien {
    background-image: linear-gradient(to right, #988fff 0%, #4e3dff 51%, #988fff 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
    color: #fff;
  }
  .btn-primary-gradien:focus, .btn-primary-gradien:active, .btn-primary-gradien.active {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #988fff, 0%, #4e3dff, 100%, #fff) !important;
  }
  
  .btn-secondary-gradien {
    background-image: linear-gradient(to right, #f95882 0%, #f50a46 51%, #f95882 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active, .btn-secondary-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #f95882, 0%, #f50a46, 100%, #fff) !important;
  }
  
  .btn-success-gradien {
    background-image: linear-gradient(to right, #62d632 0%, #42991e 51%, #62d632 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active, .btn-success-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #62d632, 0%, #42991e, 100%, #fff) !important;
  }
  
  .btn-danger-gradien {
    background-image: linear-gradient(to right, #e25865 0%, #c62232 51%, #e25865 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active, .btn-danger-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #e25865, 0%, #c62232, 100%, #fff) !important;
  }
  
  .btn-warning-gradien {
    background-image: linear-gradient(to right, #f9de52 0%, #f2cb08 51%, #f9de52 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active, .btn-warning-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #f9de52, 0%, #f2cb08, 100%, #fff) !important;
  }
  
  .btn-info-gradien {
    background-image: linear-gradient(to right, #b94ffa 0%, #9707f1 51%, #b94ffa 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active, .btn-info-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #b94ffa, 0%, #9707f1, 100%, #fff) !important;
  }
  
  .btn-light-gradien {
    background-image: linear-gradient(to right, white 0%, #e0e0e0 51%, white 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active, .btn-light-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, white, 0%, #e0e0e0, 100%, #fff) !important;
  }
  
  .btn-dark-gradien {
    background-image: linear-gradient(to right, #3d4557 0%, #1b1f27 51%, #3d4557 100%);
    border: none;
    color: #fff;
    background-size: auto 200%;
    transition: all 0.3s ease;
  }
  .btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active, .btn-dark-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #3d4557, 0%, #1b1f27, 100%, #fff) !important;
  }
  
  .btn {
    padding: 0.375rem 1.75rem;
  }
  
  .btn-lg {
    font-size: 18px;
  }
  
  .btn-sm {
    font-size: 12px;
  }
  
  .btn-xs {
    padding: 0.05rem 0.4rem;
    font-size: 11px;
  }
  
  .large-btn {
    margin-bottom: -20px;
  }
  .large-btn .btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
  
  .btn-air-primary {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-primary:hover, .btn-air-primary:active, .btn-air-primary:not([disabled]):not(.disabled):active {
    background-color: #4433ff;
    border-color: #4433ff;
  }
  
  .input-air-primary {
    box-shadow: 0 3px 5px 1px rgba(115, 102, 255, 0.1) !important;
  }
  .input-air-primary:focus {
    border-color: #7366ff;
  }
  
  .btn-air-secondary {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-secondary:hover, .btn-air-secondary:active, .btn-air-secondary:not([disabled]):not(.disabled):active {
    background-color: #ec0944;
    border-color: #ec0944;
  }
  
  .input-air-secondary {
    box-shadow: 0 3px 5px 1px rgba(247, 49, 100, 0.1) !important;
  }
  .input-air-secondary:focus {
    border-color: #f73164;
  }
  
  .btn-air-success {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-success:hover, .btn-air-success:active, .btn-air-success:not([disabled]):not(.disabled):active {
    background-color: #3f901d;
    border-color: #3f901d;
  }
  
  .input-air-success {
    box-shadow: 0 3px 5px 1px rgba(81, 187, 37, 0.1) !important;
  }
  .input-air-success:focus {
    border-color: #51bb25;
  }
  
  .btn-air-danger {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-danger:hover, .btn-air-danger:active, .btn-air-danger:not([disabled]):not(.disabled):active {
    background-color: #bd2130;
    border-color: #bd2130;
  }
  
  .input-air-danger {
    box-shadow: 0 3px 5px 1px rgba(220, 53, 69, 0.1) !important;
  }
  .input-air-danger:focus {
    border-color: #dc3545;
  }
  
  .btn-air-info {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
    background-color: #9106e7;
    border-color: #9106e7;
  }
  
  .input-air-info {
    box-shadow: 0 3px 5px 1px rgba(169, 39, 249, 0.1) !important;
  }
  .input-air-info:focus {
    border-color: #a927f9;
  }
  
  .btn-air-light {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-light:hover, .btn-air-light:active, .btn-air-light:not([disabled]):not(.disabled):active {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
  }
  
  .input-air-light {
    box-shadow: 0 3px 5px 1px rgba(244, 244, 244, 0.1) !important;
  }
  .input-air-light:focus {
    border-color: #f4f4f4;
  }
  
  .btn-air-dark {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-dark:hover, .btn-air-dark:active, .btn-air-dark:not([disabled]):not(.disabled):active {
    background-color: #171a21;
    border-color: #171a21;
  }
  
  .input-air-dark {
    box-shadow: 0 3px 5px 1px rgba(44, 50, 63, 0.1) !important;
  }
  .input-air-dark:focus {
    border-color: #2c323f;
  }
  
  .btn-air-warning {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
  }
  .btn-air-warning:hover, .btn-air-warning:active, .btn-air-warning:not([disabled]):not(.disabled):active {
    background-color: #e8c308;
    border-color: #e8c308;
  }
  
  .input-air-warning {
    box-shadow: 0 3px 5px 1px rgba(248, 214, 43, 0.1) !important;
  }
  .input-air-warning:focus {
    border-color: #f8d62b;
  }
  
  .btn-gradient {
    color: #fff !important;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 5px;
    background: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
    letter-spacing: 0.06rem;
  }
  .btn-gradient:hover {
    background-size: 100% !important;
  }
  
  .btn-warning {
    color: #fff;
  }
  .btn-warning:hover, .btn-warning.disabled {
    color: #fff;
  }
  
  [class*=-gradien]:hover {
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: #fff;
  }
  
  .btn-square {
    border-radius: 0px;
  }
  
  .btn-pill {
    border-radius: 60px;
  }
  
  .btn-group-pill .btn:first-child {
    border-radius: 60px 0 0 60px;
  }
  .btn-group-pill .btn:last-child {
    border-radius: 0 60px 60px 0;
  }
  
  .btn-group-square .btn:first-child {
    border-radius: 0px 0 0 0px;
  }
  .btn-group-square .btn:last-child {
    border-radius: 0 0px 0px 0;
  }
  
  .btn-showcase {
    margin-bottom: -10px;
  }
  .btn-showcase .btn {
    margin-bottom: 10px;
    margin-right: 18px;
  }
  
  .btn-dropdown-showcase {
    margin-bottom: -10px;
  }
  .btn-dropdown-showcase .dropdown {
    margin-bottom: 10px;
    margin-right: 18px;
  }
  .btn-dropdown-showcase .btn-group {
    margin-bottom: 10px;
    margin-right: 18px;
  }
  
  .btn-group .radio label::before {
    top: 2px;
  }
  .btn-group .radio label::after {
    top: 7px;
  }
  .btn-group .checkbox label {
    margin-top: 3px;
  }
  
  .btn-group-showcase > div {
    margin-bottom: -20px;
  }
  .btn-group-showcase > div label {
    margin-bottom: 0;
  }
  .btn-group-showcase .btn-group {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .btn-group-showcase .checkbox input[type=checkbox] {
    display: none;
  }
  
  .tooltiptext {
    visibility: visible;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /**=====================
       06. Button CSS end
  ==========================**/
  /**=====================
      03.  Card CSS Start
  ==========================**/
  .card {
    margin-bottom: 30px;
    border: none;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    // border-radius: 15px;
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
  }
  .card:hover {
    box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
    transition: all 0.3s ease;
  }
  .card .card-header {
    background-color: #fff;
    padding: 25px;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
  }
  .card .card-header.card-no-border {
    border-bottom: none !important;
  }
  .card .card-header h5 {
    font-size: 22px;
  }
  .card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
    margin-bottom: 0;
    // text-transform: capitalize;
  }
  .card .card-header > span {
    font-size: 12px;
    color: rgba(43, 43, 43, 0.54);
    margin-top: 5px;
    display: block;
    letter-spacing: 1px;
  }
  .card .card-header .card-header-right {
    border-radius: 0 0 0 7px;
    right: 35px;
    top: 33px;
    display: inline-block;
    float: right;
    padding: 8px 0;
    position: absolute;
    background-color: #fff;
    z-index: 1;
  }
  .card .card-header .card-header-right .card-option {
    text-align: right;
    width: 35px;
    height: 20px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }
  .card .card-header .card-header-right .card-option li {
    display: inline-block;
  }
  .card .card-header .card-header-right .card-option li:first-child i {
    transition: 1s;
    font-size: 16px;
    color: var(--theme-default);
  }
  .card .card-header .card-header-right .card-option li:first-child i.icofont {
    color: unset;
  }
  .card .card-header .card-header-right i {
    margin: 0 5px;
    cursor: pointer;
    color: #2c323f;
    line-height: 20px;
  }
  .card .card-header .card-header-right i.icofont-refresh {
    font-size: 13px;
  }
  .card .card-body {
    padding: 25px;
    background-color: transparent;
  }
  .card .card-body p:last-child {
    margin-bottom: 0;
  }
  .card .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .card .card-footer {
    background-color: #fff;
    border-top: 1px solid #ecf3fa;
    padding: 40px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .card.card-load .card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 8;
    align-items: center;
    justify-content: center;
  }
  .card.card-load .card-loader i {
    margin: 0 auto;
    color: var(--theme-default);
    font-size: 20px;
  }
  .card.full-card {
    position: fixed;
    top: 0;
    z-index: 99999;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid #efefef;
    width: calc(100vw - 12px);
    height: 100vh;
  }
  .card.full-card .card-body {
    overflow: auto;
  }
  
  .card-absolute {
    margin-top: 20px;
  }
  .card-absolute .card-header {
    position: absolute;
    top: -20px;
    left: 15px;
    border-radius: 0.25rem;
    padding: 10px 15px;
  }
  .card-absolute .card-header h5 {
    font-size: 17px;
  }
  .card-absolute .card-body {
    margin-top: 10px;
  }
  
  .card-header .border-tab {
    margin-bottom: -13px;
  }
  
  .custom-card {
    overflow: hidden;
    padding: 30px;
  }
  .custom-card .card-header {
    padding: 0;
  }
  .custom-card .card-header img {
    border-radius: 50%;
    margin-top: -100px;
    transform: scale(1.5);
  }
  .custom-card .card-profile {
    text-align: center;
  }
  .custom-card .card-profile img {
    height: 110px;
    padding: 7px;
    background-color: #fff;
    z-index: 1;
    position: relative;
  }
  .custom-card .card-social {
    text-align: center;
  }
  .custom-card .card-social li {
    display: inline-block;
    padding: 15px 0;
  }
  .custom-card .card-social li:last-child a {
    margin-right: 0;
  }
  .custom-card .card-social li a {
    padding: 0;
    margin-right: 15px;
    color: rgb(188, 198, 222);
    font-size: 16px;
    transition: all 0.3s ease;
  }
  .custom-card .card-social li a:hover {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .custom-card .profile-details h6 {
    margin-bottom: 30px;
    margin-top: 10px;
    color: rgba(43, 43, 43, 0.54);
    font-size: 14px;
  }
  .custom-card .card-footer {
    padding: 0;
  }
  .custom-card .card-footer > div {
    padding: 15px;
    text-align: center;
  }
  .custom-card .card-footer > div + div {
    border-left: 1px solid #efefef;
  }
  .custom-card .card-footer > div h3 {
    margin-bottom: 0;
    font-size: 24px;
  }
  .custom-card .card-footer > div h6 {
    font-size: 14px;
    color: rgba(43, 43, 43, 0.54);
  }
  .custom-card .card-footer > div h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .custom-card .card-footer > div i {
    font-size: 24px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .custom-card .card-footer > div .m-b-card {
    margin-bottom: 10px;
  }
  
  .custom-profile {
    padding: 0;
  }
  .custom-profile > div {
    padding: 30px;
  }
  .custom-profile .card-profile {
    position: relative;
  }
  .custom-profile .card-profile img {
    height: unset;
    background-color: transparent;
  }
  .custom-profile .profile-details {
    margin-top: 20px;
  }
  .custom-profile .profile-details h4 {
    margin-bottom: 0;
  }
  .custom-profile .profile-details h6 {
    margin-top: 4px;
    margin-bottom: 0 !important;
  }
  .custom-profile .card-social {
    padding: 20px 0;
  }
  .custom-profile .card-social .social-link {
    padding: 0;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7F6FF;
    border: 1px solid #EFEEFF;
    border-radius: 100%;
  }
  .custom-profile .card-social .social-link img {
    width: 22px;
    height: 22px;
  }
  .custom-profile .card-social li {
    padding: 0;
  }
  .custom-profile .card-social li a {
    margin: 0;
  }
  .custom-profile .card-social li + li {
    margin-left: 10px;
  }
  .custom-profile .card-footer {
    padding: 30px;
  }
  .custom-profile .card-footer .row {
    padding: 0;
  }
  .custom-profile .card-footer > div h5 {
    margin-bottom: 0;
  }
  .custom-profile .card-footer > div + div {
    border: none;
  }
  
  /**=====================
      03. Card CSS End
  ==========================**/
  /**=====================
       07. Color CSS Start
  ==========================**/
  .color-box {
    margin-bottom: -10px;
  }
  .color-box button {
    margin-bottom: 10px;
  }
  .color-box button + button {
    margin-left: 5px;
  }
  
  .light-font {
    color: rgba(43, 43, 43, 0.54);
  }
  
  /**=====================
       07. Color CSS Ends
  ==========================**/
  /**=====================
      30. Data-table CSS Start
  ==========================**/
  div.dt-button-background {
    display: none !important;
  }
  div.DTS div.dataTables_scrollBody table {
    z-index: 1;
  }
  
  #auto-generate-content_wrapper.dataTables_wrapper button {
    background-color: white;
    border: 1px solid var(--theme-default);
    color: var(--theme-default);
  }
  
  .dataTables_wrapper {
    padding: 0;
    width: 100%;
  }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #ecf3fa;
  }
  .dataTables_wrapper .dt-buttons .btn {
    border-radius: 0.25rem !important;
  }
  .dataTables_wrapper .btn-group button {
    margin-right: 5px;
  }
  .dataTables_wrapper button {
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.25rem;
    color: #fff;
  }
  .dataTables_wrapper + .dataTables_wrapper {
    margin-top: 20px;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-left: 15px !important;
    border: 1px solid #f4f4f4;
    border-radius: 0.25rem;
    padding-top: 0;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    margin: 0;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    background: var(--theme-default);
    color: #fff !important;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid var(--theme-default);
    color: #2c323f !important;
    background: transparent !important;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 30px;
  }
  .dataTables_wrapper .dataTables_length select {
    border-color: #f4f4f4;
    color: #2c323f;
    padding: 0 10px;
    margin: 0 10px;
    height: 2.7142em;
    background-color: #fff;
  }
  .dataTables_wrapper table.dataTable {
    border: 1px solid #efefef;
  }
  .dataTables_wrapper table.dataTable tbody td.select-checkbox, .dataTables_wrapper table.dataTable tbody th.select-checkbox {
    padding-right: 40px !important;
  }
  .dataTables_wrapper table.dataTable tbody td.select-checkbox:before, .dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
    right: 20px;
    top: 22px;
    left: unset;
  }
  .dataTables_wrapper table.dataTable tbody td.select-checkbox:after, .dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
    margin-top: -5px;
    margin-left: 21px;
  }
  .dataTables_wrapper table.dataTable thead th, .dataTables_wrapper table.dataTable thead td {
    border-bottom: 2px solid #efefef;
  }
  .dataTables_wrapper table.dataTable th, .dataTables_wrapper table.dataTable td {
    padding: 0.75rem;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-bottom: 25px;
    margin-left: 15px;
  }
  .dataTables_wrapper .dataTables_filter input[type=search] {
    border: 1px solid #efefef;
    padding: 0 10px;
    margin-left: 10px;
    height: 37px;
    border-radius: 0;
  }
  
  .dataTables_scrollHeadInner {
    width: 100% !important;
  }
  
  table .fixedHeader-floating {
    position: fixed !important;
    background-color: #fff;
  }
  table .box > div {
    padding: 5px 15px;
    background-color: var(--theme-default);
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
    width: calc(100vw - 250px) !important;
    max-width: calc(100vw - 250px) !important;
    overflow: hidden !important;
    right: 0 !important;
    z-index: 99;
    left: 260px !important;
  }
  table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
    width: 100% !important;
  }
  table.dataTable {
    border-collapse: collapse !important;
    margin-top: 0 !important;
  }
  table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    bottom: 12px !important;
  }
  table.dataTable input, table.dataTable select {
    border: 1px solid #efefef;
    height: 37px;
  }
  
  .toolbar {
    padding: 5px 15px;
    background-color: var(--theme-default);
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  
  code.option {
    color: #dc3545 !important;
  }
  code.api {
    color: #51bb25 !important;
  }
  
  .dt-ext .dataTables_wrapper .page-item.active .page-link {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    color: #fff;
  }
  .dt-ext .dataTables_wrapper .dataTables_paginate {
    border: none;
  }
  .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0;
    border: none;
  }
  .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
    border: none !important;
  }
  .dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
    margin-left: 0px;
    color: var(--theme-default);
  }
  .dt-ext .dataTables_wrapper button.dt-button, .dt-ext .dataTables_wrapper div.dt-button, .dt-ext .dataTables_wrapper a.dt-button, .dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px;
  }
  .dt-ext .dataTables_wrapper button.dt-button.btn-success, .dt-ext .dataTables_wrapper div.dt-button.btn-success, .dt-ext .dataTables_wrapper a.dt-button.btn-success {
    background-color: #51bb25;
    border-color: #51bb25;
  }
  .dt-ext .dataTables_wrapper button.dt-button.btn-primary, .dt-ext .dataTables_wrapper div.dt-button.btn-primary, .dt-ext .dataTables_wrapper a.dt-button.btn-primary {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
  }
  .dt-ext .dataTables_wrapper button.dt-button.btn-danger, .dt-ext .dataTables_wrapper div.dt-button.btn-danger, .dt-ext .dataTables_wrapper a.dt-button.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .dt-ext .dataTables_wrapper table.dataTable th.focus, .dt-ext .dataTables_wrapper table.dataTable td.focus {
    outline: 3px solid var(--theme-default);
  }
  .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected > .sorting_1, .dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
    background-color: white;
  }
  .dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected, .dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected, .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected, .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
    background-color: white;
  }
  .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.even.selected > .sorting_1, .dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
    background-color: white;
  }
  .dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected, .dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
    background-color: white;
  }
  
  td.highlight {
    background-color: #f4f4f4 !important;
  }
  
  td.details-control {
    // background: url("../images/details_open.png") no-repeat center center;
    cursor: pointer;
  }
  
  tr.shown td.details-control {
    // background: url("../images/details_close.png") no-repeat center center;
  }
  
  .dataTables_scroll ~ .dataTables_paginate {
    margin-top: 20px !important;
  }
  
  .product-table th:last-child {
    min-width: 120px;
  }
  .product-table h6 {
    font-weight: 600;
    color: #2c323f;
  }
  
  table.dataTable.display tbody tr.odd > .sorting_1 {
    background-color: #fdfeff;
  }
  
  /**=====================
       30. Data-table CSS Ends
  ==========================**/
  /**=====================
      52. Datepicker CSS Start
  ==========================**/
  .clockpicker-canvas line {
    stroke: var(--theme-default);
  }
  
  .clockpicker-canvas-fg, .clockpicker-canvas-bearing {
    fill: var(--theme-default);
  }
  
  .daterangepicker {
    border: none;
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
  }
  .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: var(--theme-default);
  }
  
  .datepicker {
    z-index: 99;
  }
  
  .datepicker--day-name {
    color: #7366ff;
    font-weight: bold;
  }
  
  .datepicker--cell.-current- {
    color: #000;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid var(--theme-default);
  }
  .datepicker--cell.-focus- {
    background: var(--theme-default);
    color: #fff;
  }
  .datepicker--cell.-selected- {
    background: var(--theme-default) !important;
  }
  
  .daterangepicker.ltr {
    color: #898989;
    z-index: 8;
  }
  .daterangepicker.ltr tr {
    font-size: 13px;
  }
  .daterangepicker.ltr tr td {
    height: 34px;
    width: 34px;
  }
  .daterangepicker.ltr tr td.in-range {
    background-color: #fafafa;
  }
  .daterangepicker.ltr tr td.active {
    background-color: var(--theme-default);
  }
  .daterangepicker.ltr .ranges {
    float: none;
  }
  .daterangepicker.ltr .ranges li {
    color: var(--theme-default);
  }
  .daterangepicker.ltr .ranges li:hover {
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
    color: #fff;
  }
  .daterangepicker.ltr .ranges li.active {
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
    color: #fff;
  }
  .daterangepicker.ltr .calendar-time select {
    color: #898989;
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30%;
  }
  
  .datepickers-container, .bootstrap-datetimepicker-widget {
    z-index: 7;
  }
  
  .datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
  }
  .datetime-picker .input-group-text i {
    line-height: 1.3;
  }
  
  .date-range-picker .theme-form {
    margin-bottom: 30px;
  }
  .date-range-picker > div:last-child .theme-form {
    margin-bottom: 0px;
  }
  
  .daterangepicker:before {
    border-bottom: 7px solid #efefef;
  }
  .daterangepicker .daterangepicker_input i {
    font-size: 14px;
    color: #59667a;
  }
  .daterangepicker .input-mini {
    border-color: #efefef !important;
    font-size: 14px;
    color: #59667a;
  }
  .daterangepicker th, .daterangepicker td {
    height: 34px;
    width: 34px;
  }
  .daterangepicker th:hover, .daterangepicker td:hover {
    color: #2c323f;
  }
  .daterangepicker th.prev, .daterangepicker th.next, .daterangepicker td.prev, .daterangepicker td.next {
    color: #fff;
    height: 34px;
    width: 34px;
    background-color: var(--theme-default);
  }
  .daterangepicker th.month, .daterangepicker td.month {
    color: var(--theme-default);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .clockpicker-popover {
    border: none;
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
    z-index: 7;
  }
  .clockpicker-popover .popover-content {
    background-color: rgba(115, 102, 255, 0.05);
  }
  
  .clockpicker-canvas-bg {
    fill: white;
  }
  
  .clockpicker-tick:hover {
    color: inherit;
    background-color: white;
  }
  
  /**=====================
     52. Datepicker CSS End
  ==========================**/
  /**=====================
      37. Dropdown CSS Start
  ==========================**/
  .dropdown-basic {
    margin-bottom: -10px;
  }
  .dropdown-basic .btn-group {
    margin-right: 18px;
  }
  .dropdown-basic .btn-group .btn-round {
    border-radius: 50px;
  }
  .dropdown-basic .separated-btn {
    margin-left: -6px;
  }
  .dropdown-basic .separated-btn .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
  }
  .dropdown-basic button {
    max-height: 43px;
  }
  .dropdown-basic .dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
  }
  .dropdown-basic .dropdown .dropbtn {
    color: white;
    padding: 12px 35px;
    border: none;
    cursor: pointer;
  }
  .dropdown-basic .dropdown .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 175px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    top: 45px;
  }
  .dropdown-basic .dropdown .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-basic .dropdown .dropdown-content .dropdown-header {
    padding: 12px 16px;
  }
  .dropdown-basic .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  .dropdown-basic .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropup-basic .dropup {
    position: relative;
    display: inline-block;
  }
  .dropup-basic .dropup .dropbtn {
    color: white;
    padding: 12px;
    border: none;
  }
  .dropup-basic .dropup .dropup-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 170px;
    bottom: 45px;
    z-index: 999;
    left: 0;
  }
  .dropup-basic .dropup .dropup-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropup-basic .dropup .dropup-content a:hover {
    background-color: #f1f1f1;
  }
  .dropup-basic .dropup:hover .dropup-content {
    display: block;
  }
  .dropup-basic .dropup:hover .dropup-content a.active {
    background-color: #f1f1f1;
  }
  
  /**=====================
      37. Dropdown CSS Ends
  ==========================**/
  /**=====================
       11. Form Builder 1 CSS Start
  ==========================**/
  .form-builder .drag-box {
    user-select: none;
  }
  .form-builder .drag-box fieldset {
    border: 1px dotted #ecf3fa;
    min-height: 655px;
    padding: 30px;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
    border-radius: 4px;
  }
  .form-builder .component {
    cursor: pointer;
  }
  .form-builder .component label {
    padding-top: 8px;
  }
  .form-builder .component .form-group input, .form-builder .component .form-group textarea, .form-builder .component .form-group select {
    border-radius: 4px;
  }
  .form-builder .component .form-group .input-group #prependedcheckbox, .form-builder .component .form-group .input-group #prependedtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-builder .component .form-group .input-group #appendedcheckbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
  }
  .form-builder .component .form-group .input-group #appendedtext, .form-builder .component .form-group .input-group #buttondropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-builder .component .form-group .input-group #buttondropdown {
    margin-right: -1px;
  }
  .form-builder .component .form-group .input-group .input-group-btn button {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-builder .component .form-group .input-group .input-group-prepend .btn-right, .form-builder .component .form-group .input-group .input-group-prepend .checkbox-radius {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-builder .component .form-group .pl-md-radios {
    padding-left: 37px;
  }
  .form-builder input {
    cursor: pointer;
  }
  .form-builder textarea {
    resize: vertical;
  }
  .form-builder .popover {
    z-index: 8;
    opacity: 1;
    left: -290px !important;
    top: 95px !important;
  }
  .form-builder .popover .control-group {
    cursor: default;
  }
  .form-builder .popover input {
    cursor: pointer;
  }
  .form-builder .render {
    min-height: 500px;
    width: 100%;
    border-radius: 4px;
  }
  .form-builder .popover .controls {
    margin-left: 0;
  }
  .form-builder .popover .control-label {
    text-align: left;
  }
  .form-builder .popover form {
    width: 100%;
  }
  .form-builder .popover .fade.left.in {
    left: -215px;
  }
  .form-builder .target:after {
    float: left;
    content: " ";
    height: 77px;
    margin-top: 3px;
    background: #f4f4f4;
    width: 100%;
    border: 1px dashed #ecf3fa;
  }
  
  .drag form {
    padding: 10px 5px;
    border: 1px dotted #ecf3fa;
    position: absolute;
    background: #fff;
    box-shadow: 0 0 30px #ecf3fa;
    overflow: hidden;
  }
  .drag form input {
    border-radius: 5px;
  }
  
  .form-builder-header-1 {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
    min-height: 67px;
  }
  .form-builder-header-1 h6 {
    margin-bottom: 0;
    margin-top: 10px;
  }
  
  .form-builder .help-block {
    font-size: 12px;
  }
  .form-builder input {
    border-radius: 4px !important;
  }
  .form-builder .form-group .col-md-12 {
    padding: 0;
  }
  .form-builder .popover {
    width: 300px;
  }
  .form-builder .popover-header {
    background-color: var(--theme-default);
    padding: 15px;
  }
  .form-builder .popover-body {
    padding: 15px;
  }
  .form-builder .popover-body .form-group.row {
    padding: 0 15px;
  }
  .form-builder .popover-body .form-group.row .col-lg-12 {
    padding: 0;
  }
  
  /**=====================
      11. Form Builder 1 CSS Ends
  ==========================**/
  /**=====================
       29. Form Input CSS Start
  ==========================**/
  .icon-addon .addon-md .fa, .icon-addon .addon-md .glyphicon {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px;
  }
  
  .icon-addon .glyphicon, .icon-addon .fa {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px;
  }
  
  .input-group-square .input-group-text, .input-group-square .form-control {
    border-radius: 0;
  }
  
  .input-group-solid .input-group-text, .input-group-solid .form-control {
    background: #f4f4f4;
  }
  
  .input-group-air {
    box-shadow: 0 3px 20px 0 #efefef;
  }
  
  .icon-addon {
    position: relative;
    color: #4c5667;
    display: block;
  }
  .icon-addon:after {
    display: table;
    content: "";
    clear: both;
  }
  .icon-addon:before {
    display: table;
    content: "";
  }
  .icon-addon .addon-md .form-control {
    padding-left: 30px;
    float: left;
    font-weight: normal;
  }
  .icon-addon .form-control {
    padding-left: 30px;
    float: left;
    font-weight: normal;
  }
  .pill-input-group span:last-child {
    border-top-right-radius: 1.3rem;
    border-bottom-right-radius: 1.3rem;
  }
  .pill-input-group span:first-child {
    border-top-left-radius: 1.3rem;
    border-bottom-left-radius: 1.3rem;
  }
  
  /**=====================
       29. Form Input CSS Ends
  ==========================**/
  /**=====================
       48. Form Wizard CSS Start
  ==========================**/
  /*Form Wizard One Start*/
  .form-wizard .tab {
    display: none;
  }
  .form-wizard .btn-mb {
    margin-bottom: 30px;
  }
  .form-wizard .step {
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: var(--theme-default);
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
  }
  .form-wizard .step.active {
    opacity: 1;
  }
  .form-wizard .step.finish {
    background-color: var(--theme-secondary);
  }
  
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
  }
  .stepwizard .stepwizard-row {
    display: table-row;
  }
  .stepwizard .stepwizard-row:before {
    top: 16px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ecf3fa;
  }
  .stepwizard .stepwizard-row .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
  }
  .stepwizard .stepwizard-row .stepwizard-step p {
    margin-top: 10px;
  }
  .stepwizard .stepwizard-row .stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 fieldset {
    display: none;
  }
  .f1 .input-error {
    border-color: #dc3545;
  }
  .f1 .f1-steps {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .f1 .f1-steps .f1-progress {
    position: absolute;
    top: 24px;
    width: 100%;
    height: 1px;
    background: #898989;
  }
  .f1 .f1-steps .f1-progress .f1-progress-line {
    height: 1px;
    background: var(--theme-default);
    width: 16%;
  }
  .f1 .f1-steps .f1-step {
    position: relative;
    float: left;
    width: 33.333333%;
    text-align: center;
  }
  .f1 .f1-steps .f1-step.activated .f1-step-icon {
    background: #fff;
    border: 1px solid var(--theme-default);
    color: var(--theme-default);
  }
  .f1 .f1-steps .f1-step.activated p {
    color: var(--theme-default);
  }
  .f1 .f1-steps .f1-step.active .f1-step-icon {
    width: 48px;
    height: 48px;
    background: var(--theme-default);
    font-size: 22px;
    line-height: 20px;
  }
  .f1 .f1-steps .f1-step.active p {
    color: var(--theme-default);
  }
  .f1 .f1-steps .f1-step p {
    color: #898989;
  }
  .f1 .f1-steps .f1-step .f1-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #898989;
    font-size: 16px;
    color: #fff;
    line-height: 20px;
    border-radius: 50%;
  }
  .f1 .f1-buttons {
    text-align: right;
  }
  
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  .wizard-4 .action-bar {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }
  .wizard-4 .action-bar .btn {
    float: right;
    margin: 0 5px;
    background-color: #e3e0ff !important;
    border-color: #e3e0ff !important;
    color: var(--theme-default);
  }
  .wizard-4 .action-bar .buttonDisabled {
    color: #59667a;
    background-color: rgba(89, 102, 122, 0.05) !important;
    border: 1px solid rgba(89, 102, 122, 0.05) !important;
    cursor: text;
  }
  .wizard-4 .step-container {
    background: #f8f9fe;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0 solid #e8ebf2;
    overflow: hidden;
    clear: right;
    width: 70%;
  }
  .wizard-4 .step-container div.content {
    display: block;
    position: absolute;
    float: left;
    height: 445px;
    text-align: left;
    overflow: auto;
    z-index: 1;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
  }
  .wizard-4 .step-container div.content .wizard-title {
    width: 450px;
    margin: 0 auto;
  }
  .wizard-4 .loader {
    display: none;
  }
  .wizard-4 .msg-box {
    position: fixed;
    display: none;
    padding: 5px 10px;
    background-color: #a299ff;
    top: 30px;
    border-radius: 5px;
    right: 60px;
    padding: 10px 20px;
  }
  .wizard-4 .msg-box .content {
    padding: 0;
    float: left;
    padding-top: 3px;
    color: #fff;
  }
  .wizard-4 .msg-box .close {
    color: #fff;
    margin-left: 10px;
    opacity: 1;
    font-size: 18px;
    line-height: 1.4;
  }
  .wizard-4 ul.anchor {
    float: left;
    width: 30%;
    height: 100vh;
    padding: 80px;
  }
  .wizard-4 ul.anchor .disabled {
    opacity: 0.7;
    color: #59667a;
  }
  .wizard-4 ul.anchor .disabled h4 {
    background-color: rgba(89, 102, 122, 0.15);
  }
  .wizard-4 ul.anchor .logo {
    padding-bottom: 20px;
  }
  .wizard-4 ul.anchor li {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-bottom: 19px;
    float: left;
    clear: both;
    width: 100%;
    box-sizing: border-box;
    z-index: 0;
  }
  .wizard-4 ul.anchor li + li a {
    padding-left: 80px;
  }
  .wizard-4 ul.anchor li:last-child {
    position: absolute;
    bottom: 0;
  }
  .wizard-4 ul.anchor li:last-child img {
    height: 300px;
  }
  .wizard-4 ul.anchor li h4 {
    display: inline-block;
    background-color: rgba(115, 102, 255, 0.2);
    padding: 16px 23px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 6px;
  }
  .wizard-4 ul.anchor li a {
    display: block;
    position: relative;
    float: left;
    margin: 0;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    outline-style: none;
    z-index: 1;
    font-size: 18px;
  }
  .wizard-4 ul.anchor li a small {
    display: block;
    font-size: 14px;
  }
  .wizard-4 ul.anchor li a .stepNumber {
    position: relative;
    float: left;
    width: 30px;
    text-align: center;
    padding: 5px;
    padding-top: 0;
  }
  .wizard-4 ul.anchor li a.done h4 {
    color: #fff;
    background: var(--theme-default);
  }
  .wizard-4 ul.anchor li a.done h4:before {
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: -3px;
    content: "\e64c";
    font-family: "themify";
    background-color: #fff;
    color: var(--theme-default);
    border-radius: 15px;
    padding: 2px;
    border: 1px solid;
  }
  .wizard-4 ul.anchor li a.error {
    color: #898989;
    background: #fe8a7d;
    border: 1px solid #dc3545;
  }
  .wizard-4 ul.anchor li a.error:hover {
    color: #000;
  }
  
  /*Form Wizard Four Ends*/
  /**=====================
      48. Form Wizard CSS Ends
  ==========================**/
  /**=====================
      26. Form CSS Start
  ==========================**/
  .form-space {
    margin-bottom: -10px;
  }
  .form-space > div {
    margin-bottom: 10px;
  }
  
  .theme-form .form-control :focus {
    box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  }
  .theme-form textarea {
    border-color: #efefef;
  }
  .theme-form textarea::-webkit-input-placeholder {
    color: #898989;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 100;
  }
  // .theme-form input[type=text],
  // .theme-form input[type=email],
  // .theme-form input[type=search],
  // .theme-form input[type=password],
  // .theme-form input[type=number],
  // .theme-form input[type=tel],
  // .theme-form input[type=date],
  // .theme-form input[type=datetime-local],
  // .theme-form input[type=time],
  // .theme-form input[type=datetime-local],
  // .theme-form input[type=month],
  // .theme-form input[type=week],
  // .theme-form input[type=url],
  // .theme-form input[type=file],
  // .theme-form textarea,
  // .theme-form select {
  //   border-color: #efefef;
  //   background-color: #fff;
  //   font-size: 14px;
  //   color: #898989;
  //   padding: 12px 10px;
  //   -webkit-box-shadow: none;
  //   box-shadow: none;
  // }
  // .theme-form input[type=text]::-webkit-input-placeholder,
  // .theme-form input[type=email]::-webkit-input-placeholder,
  // .theme-form input[type=search]::-webkit-input-placeholder,
  // .theme-form input[type=password]::-webkit-input-placeholder,
  // .theme-form input[type=number]::-webkit-input-placeholder,
  // .theme-form input[type=tel]::-webkit-input-placeholder,
  // .theme-form input[type=date]::-webkit-input-placeholder,
  // .theme-form input[type=datetime-local]::-webkit-input-placeholder,
  // .theme-form input[type=time]::-webkit-input-placeholder,
  // .theme-form input[type=datetime-local]::-webkit-input-placeholder,
  // .theme-form input[type=month]::-webkit-input-placeholder,
  // .theme-form input[type=week]::-webkit-input-placeholder,
  // .theme-form input[type=url]::-webkit-input-placeholder,
  // .theme-form input[type=file]::-webkit-input-placeholder,
  // .theme-form textarea::-webkit-input-placeholder,
  // .theme-form select::-webkit-input-placeholder {
  //   color: #898989;
  //   font-size: 14px;
  //   letter-spacing: 1px;
  //   font-weight: 100;
  // }
  .theme-form input[type=file] {
    height: auto;
  }
  .theme-form select.form-control:not([size]):not([multiple]) {
    border-color: #efefef;
    font-size: 14px;
  }
  .theme-form .checkbox label {
    padding-left: 10px;
  }
  .theme-form .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid #efefef;
  }
  .theme-form .form-divider:before {
    position: absolute;
    top: -11px;
    left: 40%;
    margin-left: -10px;
    padding: 0 5px;
    content: "Or Signup With";
    color: #242934;
    background: #fff;
  }
  .theme-form .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid #efefef;
  }
  .theme-form .login-divider:before {
    position: absolute;
    top: -11px;
    left: 40%;
    margin-left: -10px;
    padding: 0 5px;
    color: #242934;
    background: #fff;
    content: "Or Login With";
  }
  .theme-form .btn-fb,
  .theme-form .btn-twitter,
  .theme-form .btn-google,
  .theme-form .btn-linkedin {
    color: #fff;
    width: 100%;
  }
  .theme-form .btn-fb {
    background-color: #50598e;
  }
  .theme-form .btn-twitter {
    background-color: #6fa2d8;
  }
  .theme-form .btn-google {
    background-color: #c64e40;
  }
  .theme-form .btn-linkedin {
    background-color: #0077B5;
  }
  
  .form-inline {
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .col-form-label {
    margin-right: 5px;
  }
  
  .search-form .form-group:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 25px;
    background: #e8ebf2;
    left: 82px;
    top: 50px;
  }
  .search-form .form-group:after {
    position: absolute;
    content: "\f002";
    font-family: "FontAwesome";
    top: 53px;
    left: 53px;
    color: #8e8e8e;
  }
  .search-form input {
    border: 1px solid #efefef;
    padding: 10px 10px 10px 70px;
    border-radius: 10px;
    background-color: rgba(244, 244, 244, 0.3);
  }
  .search-form input:focus {
    outline: none !important;
  }
  
  .form-label-align-right label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
  
  /**=====================
      26. Form CSS Ends
  ==========================**/
  /**=====================
       12. Form Builder 2 CSS Start
  ==========================**/
  .form-builder-column #pills-tabContent .theme-form .form-group,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable {
    margin-bottom: 0 !important;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .checkbox label,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .checkbox label {
    margin-top: 0;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group input,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable input {
    border-radius: 4px;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .input-group #prependedcheckbox,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group #prependedcheckbox {
    margin-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .input-group input,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group input {
    margin-right: 15px;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .input-group .input-group-prepend .input-group-text input,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group .input-group-prepend .input-group-text input {
    margin-right: 0;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .input-group .input-group-btn button,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group .input-group-btn button {
    border-radius: 4px;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group select,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable select {
    border-radius: 4px;
  }
  .form-builder-column #pills-tabContent .theme-form .form-group .m-t-help-block,
  .form-builder-column #pills-tabContent .theme-form .ui-draggable .m-t-help-block {
    margin-top: -3px;
  }
  .form-builder-column #pills-tabContent #pills-button .theme-form button {
    margin-bottom: 6px;
  }
  .form-builder-column .droppable-active {
    background-color: rgba(115, 102, 255, 0.2) !important;
    opacity: 0.1;
  }
  .form-builder-column .form-body {
    min-height: 540px;
  }
  .form-builder-column .draggable {
    cursor: move;
  }
  .form-builder-column .drag-bx {
    border: 1px dotted #ecf3fa;
    border-radius: 4px;
  }
  .form-builder-column .tools a {
    cursor: pointer;
    font-size: 80%;
  }
  
  .ui-front textarea {
    font-family: Rubik, sans-serif !important;
  }
  
  .form-builder-2 .form-group .checkbox label {
    margin-top: 0;
  }
  .form-builder-2 .form-group input {
    border-radius: 4px;
  }
  .form-builder-2 .form-group .input-group #prependedcheckbox {
    margin-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-builder-2 .form-group .input-group input {
    margin-right: 15px;
  }
  .form-builder-2 .form-group .input-group .input-group-prepend .input-group-text input {
    margin-right: 0;
  }
  .form-builder-2 .form-group .input-group .input-group-btn button {
    border-radius: 4px;
  }
  .form-builder-2 .form-group select {
    border-radius: 4px;
  }
  .form-builder-2 .form-group .m-t-help-block {
    margin-top: -3px;
  }
  
  .form-builder-2-header {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    margin-bottom: 30px;
  }
  .form-builder-2-header > div {
    display: inline-block;
    width: 49%;
  }
  .form-builder-2-header > div nav {
    text-align: right;
    float: right;
  }
  
  .draggable input {
    border-radius: 5px;
  }
  .draggable select {
    border-radius: 4px;
  }
  
  /**=====================
       12. Form Builder 2 CSS Ends
  ==========================**/
  /**=====================
      10. ICON CSS Start
  ==========================**/
  .icon-lists div {
    cursor: pointer;
    line-height: 60px;
    white-space: nowrap;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  .icon-lists div:hover {
    background-color: #fafafa;
  }
  .icon-lists div i {
    margin: 0 15px 0 10px;
    font-size: 24px;
  }
  .icon-lists.whether-icon svg {
    height: 50px;
    width: 50px;
  }
  .icon-lists.feather-icons .media svg {
    margin: 0 15px 0 10px;
  }
  .icon-lists .feather-icon .icon-first h6 {
    display: none;
  }
  
  .flag-icons, .feather-icons {
    margin-bottom: -15px;
    margin-top: -15px;
  }
  .flag-icons div, .feather-icons div {
    line-height: 1;
    word-wrap: break-word;
    overflow: auto;
  }
  .flag-icons div .flag-icon, .feather-icons div .flag-icon {
    height: 50px;
    width: 50px;
  }
  .flag-icons div .media, .feather-icons div .media {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
  }
  .flag-icons div .media-body h6, .feather-icons div .media-body h6 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .flag-icons div .media-body h5, .feather-icons div .media-body h5 {
    background-color: var(--theme-default);
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    border-radius: 3px;
    margin-bottom: 5px;
    margin-right: 5px;
    color: #fff;
  }
  
  .icon-hover-bottom {
    background-color: #fdfeff;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 1px 1px #f4f4f4;
    z-index: 999;
  }
  .icon-hover-bottom .icon-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  .icon-hover-bottom .icon-popup .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #cccccc;
    cursor: pointer;
  }
  .icon-hover-bottom .icon-popup > div {
    display: inline-block;
  }
  .icon-hover-bottom .icon-popup > div .flag-icon {
    width: 60px;
    height: 60px;
  }
  .icon-hover-bottom .icon-popup .icon-class {
    margin: 0 20px;
    cursor: pointer;
  }
  .icon-hover-bottom .icon-popup .icon-last .form-inline .form-group {
    display: initial;
  }
  .icon-hover-bottom .icon-title {
    display: block;
    color: #2c323f;
    text-transform: uppercase;
    font-weight: 500;
  }
  .icon-hover-bottom span, .icon-hover-bottom label, .icon-hover-bottom .btn {
    font-family: Roboto, sans-serif;
  }
  .icon-hover-bottom .form-group input {
    display: inline-block;
    width: 300px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
  }
  
  .fa-fa-icon-show-div, .icons-show-div {
    display: none;
  }
  
  .icon-first i {
    color: #898989;
  }
  .icon-first h6 {
    display: none;
  }
  
  /**=====================
       10. ICON CSS Ends
  ==========================**/
  /**=====================
       17. List CSS Start
  ==========================**/
  .list-group-item.active {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
  }
  
  .list-group i {
    margin-right: 10px;
  }
  .list-group img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
  
  /**=====================
       17. List CSS Ends
  ==========================**/
  /**=====================
       04. Loader CSS Start
  ==========================**/
  .loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: #fff;
    z-index: 11;
    top: 0;
    /*Color Loader*/
    /*Color Loader*/
  }
  .loader-wrapper .loader-index {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    filter: url("#goo");
  }
  .loader-wrapper .loader-index:after {
    content: "";
    width: 4em;
    height: 4em;
    background: var(--theme-default);
    border-radius: 50%;
    position: absolute;
    transform: scale(0.5);
    animation: grow 2s cubic-bezier(0.14, 0.05, 0.55, 0.5) infinite alternate;
  }
  .loader-wrapper .loader-index span {
    width: 2em;
    height: 2em;
    background: var(--theme-secondary);
    border-radius: 50%;
    margin-right: 2em;
    position: relative;
    transform: translateX(7em);
    animation: move 4s ease-in-out infinite;
  }
  .loader-wrapper .loader-index span:before {
    content: "";
    width: 2em;
    height: 2em;
    background: var(--theme-default);
    border-radius: 50%;
    position: absolute;
    left: 3em;
    transform: translateX(0em);
    animation: shrink 2s ease-in-out infinite;
  }
  .loader-wrapper .loader-index span:after {
    content: "";
    width: 2em;
    height: 2em;
    background: #a927f9;
    border-radius: 50%;
    position: absolute;
    right: 3em;
    transform: translateX(0em);
    animation: shrink 2s ease-in-out infinite;
  }
  @keyframes grow {
    0% {
      transform: scale(0.5);
      background: #a927f9;
    }
    50% {
      transform: scale(1);
      background: var(--theme-secondary);
    }
  }
  @keyframes move {
    0% {
      transform: translateX(7em);
    }
    50% {
      transform: translateX(-5em);
    }
  }
  @keyframes shrink {
    0% {
      transform: translateX(0em);
    }
    50% {
      transform: translateX(-1em);
    }
  }
  .loader-wrapper svg {
    width: 0;
    height: 0;
  }
  .loader-wrapper body {
    font-family: sans;
    min-height: 100vh;
    margin: 0;
    display: grid;
    place-content: center;
    background: black;
  }
  .loader-wrapper h1 {
    color: #fff;
    text-align: center;
    margin-bottom: 2em;
    letter-spacing: 3px;
    position: relative;
    z-index: 2;
  }
  .loader-wrapper h1:before {
    position: absolute;
    content: "";
    height: 1px;
    background: linear-gradient(to right, var(--theme-secondary), var(--theme-default), #a927f9);
    color: #fff;
    top: 2.75em;
    left: 0.75em;
    width: 2.5em;
    font-weight: 400;
    font-variant: small-caps;
    font-size: 0.5em;
  }
  
  .loader-box {
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  }
  .loader-box [class*=loader-] {
    display: inline-block;
    width: 50px;
    height: 50px;
    color: inherit;
    vertical-align: middle;
  }
  .loader-box .loader-1 {
    border: 0.2em dotted var(--theme-default);
    border-radius: 50%;
    animation: 1s loader-01 linear infinite;
  }
  @keyframes loader-01 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-2 {
    border: 0.2em solid transparent;
    border-left-color: var(--theme-secondary);
    border-right-color: var(--theme-secondary);
    border-radius: 50%;
    animation: 1s loader-02 linear infinite;
  }
  @keyframes loader-02 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-3 {
    border: 0.2em solid #a927f9;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-03 linear infinite;
    position: relative;
  }
  @keyframes loader-03 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-4 {
    border: 1px solid #a927f9;
    border-radius: 50%;
    animation: 1s loader-04 linear infinite;
    position: relative;
  }
  .loader-box .loader-4:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -0.2em;
    left: 50%;
    border: 0.2em solid #a927f9;
    border-radius: 50%;
  }
  @keyframes loader-04 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-5 {
    border: 0.2em solid transparent;
    border-top-color: #f8d62b;
    border-radius: 50%;
    animation: 1s loader-05 linear infinite;
    position: relative;
  }
  .loader-box .loader-5:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid #f8d62b;
    border-radius: 50%;
    opacity: 0.5;
  }
  @keyframes loader-05 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-6 {
    border: 0.2em solid #dc3545;
    border-radius: 50%;
    animation: loader-06 1s ease-out infinite;
  }
  @keyframes loader-06 {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .loader-box .loader-7 {
    border: 0 solid transparent;
    border-radius: 50%;
    position: relative;
  }
  .loader-box .loader-7:before, .loader-box .loader-7:after {
    content: "";
    border: 0.2em solid var(--theme-default);
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    animation: loader-07 1s linear infinite;
    opacity: 0;
  }
  .loader-box .loader-7:before {
    animation-delay: 1s;
  }
  .loader-box .loader-7:after {
    animation-delay: 0.5s;
  }
  @keyframes loader-07 {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .loader-box .loader-8 {
    position: relative;
  }
  .loader-box .loader-8:before, .loader-box .loader-8:after {
    content: "";
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-color: var(--theme-secondary);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: loader-08 2s infinite ease-in-out;
  }
  .loader-box .loader-8:after {
    animation-delay: -1s;
  }
  @keyframes loader-08 {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  .loader-box .loader-9 {
    background-color: #a927f9;
    border-radius: 50%;
    animation: loader-09 1s infinite ease-in-out;
  }
  @keyframes loader-09 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .loader-box .loader-10 {
    position: relative;
    animation: loader-10-1 2s infinite linear;
  }
  .loader-box .loader-10:before, .loader-box .loader-10:after {
    content: "";
    width: 0;
    height: 0;
    border: 0.5em solid #a927f9;
    display: block;
    position: absolute;
    border-radius: 100%;
    animation: loader-10-2 2s infinite ease-in-out;
  }
  .loader-box .loader-10:before {
    top: 0;
    left: 50%;
  }
  .loader-box .loader-10:after {
    bottom: 0;
    right: 50%;
    animation-delay: -1s;
  }
  @keyframes loader-10-1 {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-10-2 {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  .loader-box .loader-11 {
    background-color: #f8d62b;
    animation: loader-11 1.2s infinite ease-in-out;
  }
  @keyframes loader-11 {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  .loader-box .loader-12 {
    position: relative;
  }
  .loader-box .loader-12:before, .loader-box .loader-12:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #dc3545;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 50%;
    box-shadow: -0.5em 0 0 #dc3545;
    animation: loader-12 1s linear infinite;
  }
  .loader-box .loader-12:after {
    top: 50%;
    bottom: 0;
    animation-delay: 0.25s;
  }
  @keyframes loader-12 {
    0%, 100% {
      box-shadow: -0.5em 0 0 transparent;
      background-color: #dc3545;
    }
    50% {
      box-shadow: -0.5em 0 0 #dc3545;
      background-color: transparent;
    }
  }
  .loader-box .loader-13:before,
  .loader-box .loader-13:after,
  .loader-box .loader-13 {
    border-radius: 50%;
    animation-fill-mode: both;
    animation: loader-13 1.8s infinite ease-in-out;
  }
  .loader-box .loader-13 {
    color: var(--theme-default);
    position: relative;
    transform: translateZ(0);
    animation-delay: -0.16s;
    top: -1em;
  }
  .loader-box .loader-13:before {
    right: 100%;
    animation-delay: -0.32s;
  }
  .loader-box .loader-13:after {
    left: 100%;
  }
  .loader-box .loader-13:before, .loader-box .loader-13:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
  }
  @keyframes loader-13 {
    0%, 80%, 100% {
      box-shadow: 0 1em 0 -1em;
    }
    40% {
      box-shadow: 0 1em 0 -0.2em;
    }
  }
  .loader-box .loader-14 {
    border-radius: 50%;
    box-shadow: 0 1em 0 -0.2em var(--theme-secondary);
    position: relative;
    animation: loader-14 0.8s ease-in-out alternate infinite;
    animation-delay: 0.32s;
    top: -1em;
  }
  .loader-box .loader-14:after, .loader-box .loader-14:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    animation: inherit;
  }
  .loader-box .loader-14:before {
    left: -1em;
    animation-delay: 0.48s;
  }
  .loader-box .loader-14:after {
    right: -1em;
    animation-delay: 0.16s;
  }
  @keyframes loader-14 {
    0% {
      box-shadow: 0 2em 0 -0.2em var(--theme-secondary);
    }
    100% {
      box-shadow: 0 1em 0 -0.2em var(--theme-secondary);
    }
  }
  .loader-box .loader-15 {
    background: #a927f9;
    position: relative;
    animation: loader-15 1s ease-in-out infinite;
    animation-delay: 0.4s;
    width: 0.25em;
    height: 0.5em;
    margin: 0 0.5em;
  }
  .loader-box .loader-15:after, .loader-box .loader-15:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    animation: inherit;
  }
  .loader-box .loader-15:before {
    right: 0.5em;
    animation-delay: 0.2s;
  }
  .loader-box .loader-15:after {
    left: 0.5em;
    animation-delay: 0.6s;
  }
  @keyframes loader-15 {
    0%, 100% {
      box-shadow: 0 0 0 #a927f9, 0 0 0 #a927f9;
    }
    50% {
      box-shadow: 0 -0.25em 0 #a927f9, 0 0.25em 0 #a927f9;
    }
  }
  .loader-box .loader-16 {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
  }
  .loader-box .loader-16:before, .loader-box .loader-16:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    animation: 1s spin linear infinite;
  }
  .loader-box .loader-16:before {
    transform: rotateX(70deg);
  }
  .loader-box .loader-16:after {
    transform: rotateY(70deg);
    animation-delay: 0.4s;
  }
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  @keyframes spin {
    0%, 100% {
      box-shadow: 0.2em 0px 0 0px #a927f9;
    }
    12% {
      box-shadow: 0.2em 0.2em 0 0 #a927f9;
    }
    25% {
      box-shadow: 0 0.2em 0 0px #a927f9;
    }
    37% {
      box-shadow: -0.2em 0.2em 0 0 #a927f9;
    }
    50% {
      box-shadow: -0.2em 0 0 0 #a927f9;
    }
    62% {
      box-shadow: -0.2em -0.2em 0 0 #a927f9;
    }
    75% {
      box-shadow: 0px -0.2em 0 0 #a927f9;
    }
    87% {
      box-shadow: 0.2em -0.2em 0 0 #a927f9;
    }
  }
  .loader-box .loader-17 {
    position: relative;
    background-color: #f8d62b;
    border-radius: 50%;
  }
  .loader-box .loader-17:after, .loader-box .loader-17:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0.8;
  }
  .loader-box .loader-17:after {
    left: -0.5em;
    top: -0.25em;
    background-color: #f8d62b;
    transform-origin: 30px 35px;
    animation: loader-17 1s linear infinite;
    opacity: 0.6;
  }
  .loader-box .loader-17:before {
    left: -1.25em;
    top: -0.75em;
    background-color: #f8d62b;
    transform-origin: 40px 40px;
    animation: loader-17 2s linear infinite;
  }
  @keyframes loader-17 {
    0% {
      transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
  }
  .loader-box .loader-18 {
    position: relative;
  }
  .loader-box .loader-18:before, .loader-box .loader-18:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 0.1em solid transparent;
    border-bottom-color: #dc3545;
    top: 0;
    left: 0;
    animation: 1s loader-18 linear infinite;
  }
  .loader-box .loader-18:before {
    width: 40px;
    height: 40px;
  }
  .loader-box .loader-18:after {
    width: 30px;
    height: 30px;
    top: 0.1em;
    left: 0.1em;
    animation-direction: reverse;
  }
  @keyframes loader-18 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-19 {
    border-top: 0.2em solid var(--theme-default);
    border-right: 0.2em solid transparent;
    animation: loader-19 1s linear infinite;
    border-radius: 100%;
    position: relative;
  }
  @keyframes loader-19 {
    to {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-20 {
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 0.1em var(--theme-secondary);
    border-radius: 50%;
    position: relative;
  }
  .loader-box .loader-20:after, .loader-box .loader-20:before {
    position: absolute;
    content: "";
    background-color: var(--theme-secondary);
    top: 24px;
    left: 24px;
    height: 0.1em;
    transform-origin: left center;
  }
  .loader-box .loader-20:after {
    width: 0.4em;
    animation: loader-20 2s linear infinite;
  }
  .loader-box .loader-20:before {
    width: 20px;
    animation: loader-20 8s linear infinite;
  }
  @keyframes loader-20 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-21 {
    position: relative;
  }
  .loader-box .loader-21:before, .loader-box .loader-21:after {
    position: absolute;
    content: "";
  }
  .loader-box .loader-21:before {
    width: 80%;
    height: 80%;
    left: 10%;
    bottom: 10%;
    border-radius: 100% 100% 100% 0;
    box-shadow: 0px 0px 0px 0.1em #a927f9;
    animation: loader-21 1s linear infinite;
    transform: rotate(-46deg);
  }
  .loader-box .loader-21:after {
    width: 1em;
    height: 0.3em;
    border-radius: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    bottom: -0.2em;
    z-index: -1;
  }
  @keyframes loader-21 {
    0% {
      top: 0;
    }
    50% {
      top: -5px;
    }
    100% {
      top: 0;
    }
  }
  .loader-box .loader-22 {
    border: 0.1em #a927f9 solid;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .loader-box .loader-22:after, .loader-box .loader-22:before {
    position: absolute;
    content: "";
    background-color: #a927f9;
  }
  .loader-box .loader-22:after {
    width: 50%;
    height: 0.1em;
    left: 50%;
    top: 50%;
    transform-origin: left center;
    animation: loader-22 2s linear infinite alternate;
  }
  .loader-box .loader-22:before {
    width: 100%;
    height: 40%;
    left: 0;
    bottom: 0;
  }
  @keyframes loader-22 {
    0% {
      transform: rotate(-160deg);
    }
    100% {
      transform: rotate(-20deg);
    }
  }
  .loader-box .loader-23 {
    height: 0.5em;
    border: 0.1em #f8d62b solid;
    border-radius: 0.1em;
    position: relative;
    animation: loader-23 5s linear infinite;
  }
  .loader-box .loader-23:after {
    width: 0.07em;
    height: 100%;
    background-color: #f8d62b;
    border-radius: 0px 0.5em 0.5em 0px;
    position: absolute;
    content: "";
    top: 0;
    left: calc(100% + 0.1em);
  }
  @keyframes loader-23 {
    0% {
      box-shadow: inset 0px 0px 0px #f8d62b;
    }
    100% {
      box-shadow: inset 1em 0px 0px #f8d62b;
    }
  }
  .loader-box .loader-24 {
    width: 0.8em;
    height: 1em;
    border: 0.1em #dc3545 solid;
    border-radius: 0px 0px 0.2em 0.2em;
    position: relative;
  }
  .loader-box .loader-24:after, .loader-box .loader-24:before {
    position: absolute;
    content: "";
  }
  .loader-box .loader-24:after {
    width: 0.2em;
    height: 50%;
    border: 0.1em #dc3545 solid;
    border-left: none;
    border-radius: 0px 0.5em 0.5em 0px;
    left: calc(100% + 0.1em);
    top: 0.1em;
  }
  .loader-box .loader-24:before {
    width: 0.1em;
    height: 0.3em;
    background-color: #dc3545;
    top: -0.3em;
    left: 0.05em;
    box-shadow: 0.2em 0px 0px 0px #dc3545, 0.2em -0.2em 0px 0px #dc3545, 0.4em 0px 0px 0px #dc3545;
    animation: loader-24 1s linear infinite alternate;
  }
  @keyframes loader-24 {
    0% {
      height: 0px;
    }
    100% {
      height: 6px;
    }
  }
  .loader-box .loader-25 {
    border: 0.1em var(--theme-default) solid;
    position: relative;
    animation: loader-25-1 5s linear infinite;
  }
  .loader-box .loader-25:after {
    width: 0.2em;
    height: 0.2em;
    position: absolute;
    content: "";
    background-color: var(--theme-default);
    bottom: calc(100% + 0.2em);
    left: -0.4em;
    animation: loader-25-2 1s ease-in-out infinite;
  }
  @keyframes loader-25-1 {
    0% {
      box-shadow: inset 0 0 0 0 var(--theme-default);
    }
    100% {
      box-shadow: inset 0 -1em 0 0 var(--theme-default);
    }
  }
  @keyframes loader-25-2 {
    25% {
      left: calc(100% + 0.2em);
      bottom: calc(100% + 0.2em);
    }
    50% {
      left: calc(100% + 0.2em);
      bottom: -0.4em;
    }
    75% {
      left: -0.4em;
      bottom: -0.4em;
    }
    100% {
      left: -0.4em;
      bottom: calc(100% + 0.2em);
    }
  }
  .loader-box .loader-26 {
    width: 0.5em;
    height: 0.5em;
    background-color: var(--theme-secondary);
    box-shadow: 1em 0px 0px var(--theme-secondary);
    border-radius: 50%;
    animation: loader-26 1s ease-in-out infinite alternate;
  }
  @keyframes loader-26 {
    0% {
      opacity: 0.1;
      transform: rotate(0deg) scale(0.5);
    }
    100% {
      opacity: 1;
      transform: rotate(360deg) scale(1.2);
    }
  }
  .loader-box .loader-29 {
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.1em #a927f9, -0.5em -0.5em 0 -0.4em #a927f9, 0 -0.7em 0 -0.4em #a927f9, 0.5em -0.5em 0 -0.4em #a927f9, -0.5em 0.5em 0 -0.4em #a927f9, 0 0.7em 0 -0.4em #a927f9, 0.5em 0.5em 0 -0.4em #a927f9, -0.7em 0 0 -0.4em #a927f9, 0.7em 0 0 -0.4em #a927f9;
    animation: 5s loader-29 linear infinite;
  }
  @keyframes loader-29 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-30 {
    border: 0.2em solid transparent;
    border-top-color: #a927f9;
    border-bottom-color: #a927f9;
    border-radius: 50%;
    position: relative;
    animation: 1s loader-30 linear infinite;
  }
  .loader-box .loader-30:before, .loader-box .loader-30:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: 0.2em solid transparent;
    border-bottom-color: #a927f9;
  }
  .loader-box .loader-30:before {
    transform: rotate(135deg);
    right: -0.3em;
    top: -0.05em;
  }
  .loader-box .loader-30:after {
    transform: rotate(-45deg);
    left: -0.3em;
    bottom: -0.05em;
  }
  @keyframes loader-30 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-box .loader-31 {
    box-shadow: 0 0 2em #f8d62b;
    background-color: #f8d62b;
    position: relative;
    border-radius: 50%;
    transform: rotateX(-60deg) perspective(1000px);
  }
  .loader-box .loader-31:before, .loader-box .loader-31:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    animation: 1s loader-31 ease-out infinite;
  }
  .loader-box .loader-31:after {
    animation-delay: 0.4s;
  }
  @keyframes loader-31 {
    0% {
      opacity: 1;
      transform: rotate(0deg);
      box-shadow: 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b, 0 0 0 -0.5em #f8d62b;
    }
    100% {
      opacity: 0;
      transform: rotate(180deg);
      box-shadow: -1em -1em 0 -0.35em #f8d62b, 0 -1.5em 0 -0.35em #f8d62b, 1em -1em 0 -0.35em #f8d62b, -1.5em 0 0 -0.35em #f8d62b, 1.5em 0 0 -0.35em #f8d62b, -1em 1em 0 -0.35em #f8d62b, 0 1.5em 0 -0.35em #f8d62b, 1em 1em 0 -0.35em #f8d62b;
    }
  }
  .loader-box .loader-32 {
    position: relative;
    border-radius: 50%;
    box-shadow: 0 0 1em 0 #dc3545, inset 0 0 1em 0 #dc3545;
    animation: 1s loader-32 linear infinite;
  }
  .loader-box .loader-32:before, .loader-box .loader-32:after {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    border-radius: 50%;
  }
  .loader-box .loader-32:before {
    border-top: 0.2em solid #dc3545;
    border-right: 0.2em solid transparent;
    top: 0.28em;
    right: calc(50% - 0.22em);
  }
  .loader-box .loader-32:after {
    border-bottom: 0.2em solid #dc3545;
    border-left: 0.2em solid transparent;
    bottom: 0.28em;
    left: calc(50% - 0.22em);
  }
  @keyframes loader-32 {
    0% {
      transform: rotateX(-60deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(-60deg) rotateZ(360deg);
    }
  }
  .loader-box .loader-34 {
    position: relative;
    width: 1em;
    height: 0.5em;
  }
  .loader-box .loader-34:after, .loader-box .loader-34:before {
    position: absolute;
    content: "";
    height: 0.4em;
    width: 0.4em;
    top: 0;
    background-color: var(--theme-default);
    border-radius: 50%;
  }
  .loader-box .loader-34:after {
    right: 0;
    animation: loader-34-2 0.5s ease-in-out infinite;
    animation-direction: alternate;
  }
  .loader-box .loader-34:before {
    left: 0;
    animation: loader-34-1 0.5s ease-in-out infinite;
    animation-direction: alternate;
  }
  @keyframes loader-34-1 {
    0% {
      transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      transform: translatex(0.2em);
    }
  }
  @keyframes loader-34-2 {
    0% {
      transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      transform: translatex(-0.2em);
    }
  }
  .loader-box .loader-35 {
    margin: 0 0.5em;
    position: relative;
  }
  .loader-box .loader-35:before {
    border-radius: 50%;
    background-color: var(--theme-secondary);
    animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    content: "";
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    position: absolute;
  }
  @keyframes loader-35 {
    0% {
      transform: translateX(0) scale(1);
    }
    25% {
      transform: translateX(-100%) scale(0.3);
    }
    50% {
      transform: translateX(0) scale(1);
    }
    75% {
      transform: translateX(100%) scale(0.3);
    }
    100% {
      transform: translateX(0) scale(1);
    }
  }
  .loader-box .loader-37 {
    border-right: 0.1em solid #a927f9;
    border-radius: 100%;
    animation: loader-37 800ms linear infinite;
  }
  .loader-box .loader-37:before, .loader-box .loader-37:after {
    content: "";
    width: 0.8em;
    height: 0.8em;
    display: block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    border-left: 0.08em solid #a927f9;
    border-radius: 100%;
    animation: loader-37 400ms linear infinite reverse;
  }
  .loader-box .loader-37:after {
    width: 0.6em;
    height: 0.6em;
    top: calc(50% - 0.3em);
    left: calc(50% - 0.3em);
    border: 0;
    border-right: 0.05em solid #a927f9;
    animation: none;
  }
  @keyframes loader-37 {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .loader-box .loader-38 {
    height: 0.1em;
    width: 0.1em;
    box-shadow: -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9;
    animation: loader-38 6s infinite;
  }
  @keyframes loader-38 {
    0% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9;
    }
    8.33% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9;
    }
    16.66% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9;
    }
    24.99% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    33.32% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9;
    }
    41.65% {
      box-shadow: 0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9;
    }
    49.98% {
      box-shadow: 0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9;
    }
    58.31% {
      box-shadow: -0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    66.64% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    74.97% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    83.3% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, 0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    91.63% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9, -0.2em 0.2em 0 0.1em #a927f9;
    }
    100% {
      box-shadow: -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9, -0.2em -0.2em 0 0.1em #a927f9;
    }
  }
  .loader-box .loader-39 {
    position: relative;
    width: 0.15em;
    height: 0.15em;
    background-color: #f8d62b;
    border-radius: 100%;
    animation: loader-39-1 30s infinite linear;
  }
  .loader-box .loader-39:before, .loader-box .loader-39:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loader-box .loader-39:before {
    width: 20px;
    height: 60px;
    animation: loader-39-2 0.8s linear infinite;
  }
  .loader-box .loader-39:after {
    width: 60px;
    height: 20px;
    animation: loader-39-2 1.2s linear infinite;
  }
  @keyframes loader-39-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-39-2 {
    0% {
      box-shadow: 0.04em -0.04em 0 0.02em #f8d62b;
    }
    25% {
      box-shadow: 0.04em 0.04em 0 0.02em #f8d62b;
    }
    50% {
      box-shadow: -0.04em 0.04em 0 0.02em #f8d62b;
    }
    75% {
      box-shadow: -0.04em -0.04em 0 0.02em #f8d62b;
    }
    100% {
      box-shadow: 0.04em -0.04em 0 0.02em #f8d62b;
    }
  }
  .loader-box .loader-40 {
    border: 0.05em #dc3545 solid;
    border-radius: 0.2em;
    overflow: hidden;
    position: relative;
  }
  .loader-box .loader-40:after, .loader-box .loader-40:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: inherit;
    height: inherit;
    animation: loader-40 2s infinite linear;
  }
  .loader-box .loader-40:before {
    border-top: 0.2em #dc3545 solid;
    top: -0.15em;
    left: calc(-50% - 0.15em);
    transform-origin: right center;
  }
  .loader-box .loader-40:after {
    border-bottom: 0.2em #dc3545 solid;
    top: 0.15em;
    right: calc(-50% - 0.15em);
    transform-origin: left center;
  }
  @keyframes loader-40 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .loader-box .loader-41 {
    border: 0.05em var(--theme-default) solid;
    border-radius: 0.2em;
    position: relative;
    background: linear-gradient(45deg, transparent 48%, var(--theme-default) 50%, var(--theme-default) 50%, transparent 52%, transparent), linear-gradient(-45deg, transparent 48%, var(--theme-default) 50%, var(--theme-default) 50%, transparent 52%, transparent);
    background-size: 0.5em 0.5em;
    background-position: 0% 0%;
    animation: loader-41 1s infinite linear;
  }
  @keyframes loader-41 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -1em 0;
    }
  }
  .loader-box .loader-42 {
    width: 2em;
    height: 0.66em;
    border: 0.05em var(--theme-secondary) solid;
    border-radius: 0.1em;
    background: linear-gradient(-60deg, transparent 0%, transparent 50%, var(--theme-secondary) 50%, var(--theme-secondary) 75%, transparent 75%, transparent);
    background-size: 1em 2em;
    background-position: 0 0;
    animation: loader-42 0.8s infinite linear;
  }
  @keyframes loader-42 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2em 0;
    }
  }
  
  /**=====================
       04. Loader CSS Ends
  ==========================**/
  /**=====================
       16. Popover CSS Start
  ==========================**/
  .popover-header {
    background-color: #2c323f;
    color: #fff;
  }
  
  .popover-main .btn-showcase a {
    color: #fff;
  }
  .popover-main .btn-showcase button:last-child {
    margin-right: 0;
  }
  
  /**=====================
       16. Popover CSS Ends
  ==========================**/
  /**=====================
       77. Print CSS Start
  ==========================**/
  @media print {
    .page-wrapper .page-title {
      visibility: hidden;
    }
    .page-wrapper .page-body-wrapper .page-title {
      display: none;
    }
    table.fixedHeader-floating {
      display: none;
    }
  }
  .invoice-table {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .invoice .text-right input {
    margin-top: 6px;
  }
  .invoice .media {
    align-items: center;
  }
  
  /**=====================
      77. Print CSS Ends
  ==========================**/
  /**=====================
       28. Radio CSS Start
  ==========================**/
  .animate-chk label {
    line-height: 1.6;
    cursor: pointer;
  }
  .animate-chk label:last-child {
    margin-bottom: 0;
  }
  
  .checkbox_animated {
    cursor: pointer;
    position: relative;
    margin: 0 1rem 0 0;
  }
  .checkbox_animated:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid var(--theme-default);
    border-top-style: none;
    border-right-style: none;
  }
  .checkbox_animated:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: #fff;
    border: 2px solid #ecf3fa;
    cursor: pointer;
  }
  .checkbox_animated:checked:before {
    transform: rotate(-45deg) scale(1, 1);
  }
  
  .radio_animated {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
  }
  .radio_animated:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: var(--theme-default);
    border-radius: 50%;
  }
  .radio_animated:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: #fff;
    border: 2px solid #ecf3fa;
    border-radius: 50%;
  }
  .radio_animated:checked:before {
    transform: scale(1, 1);
  }
  
  .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -16px;
    border: 1px solid #efefef;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #242934;
  }
  .checkbox input[type=checkbox] {
    opacity: 0;
  }
  .checkbox input[type=checkbox]:focus {
    outline-offset: -2px;
  }
  .checkbox input[type=checkbox]:focus + label::before {
    outline-offset: -2px;
  }
  .checkbox input[type=checkbox]:checked + label::before {
    font-family: themify;
    content: "\e64c";
    text-align: center;
    line-height: 1.2;
  }
  .checkbox input[type=checkbox]:disabled + label {
    opacity: 0.65;
  }
  .checkbox input[type=checkbox]:disabled + label::before {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
  .checkbox .checkbox-circle label::before {
    border-radius: 50%;
  }
  .checkbox .checkbox-inline {
    margin-top: 0;
  }
  
  .m-squar label::before {
    border-radius: 0;
  }
  .m-squar .checkbox-circle label::before {
    border-radius: 50%;
  }
  .m-squar .checkbox-inline {
    margin-top: 0;
  }
  
  .checkbox-primary label::before {
    border-color: var(--theme-default);
  }
  .checkbox-primary input[type=checkbox]:checked + label::before {
    border-color: var(--theme-default);
    color: var(--theme-default);
  }
  .checkbox-primary input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-primary label:before {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    color: #fff;
  }
  .checkbox-solid-primary input[type=checkbox]:checked + label::before {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    color: #fff;
  }
  .checkbox-solid-primary input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-primary input[type=radio] + label::before {
    border-color: var(--theme-default);
  }
  .radio-primary input[type=radio] + label::after {
    background-color: var(--theme-default);
  }
  .radio-primary input[type=radio]:checked + label::before {
    border-color: var(--theme-default);
  }
  .radio-primary input[type=radio]:checked + label::after {
    background-color: var(--theme-default);
  }
  
  .checkbox-secondary label::before {
    border-color: #f73164;
  }
  .checkbox-secondary input[type=checkbox]:checked + label::before {
    border-color: #f73164;
    color: #f73164;
  }
  .checkbox-secondary input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-secondary label:before {
    background-color: #f73164;
    border-color: #f73164;
    color: #fff;
  }
  .checkbox-solid-secondary input[type=checkbox]:checked + label::before {
    background-color: #f73164;
    border-color: #f73164;
    color: #fff;
  }
  .checkbox-solid-secondary input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-secondary input[type=radio] + label::before {
    border-color: #f73164;
  }
  .radio-secondary input[type=radio] + label::after {
    background-color: #f73164;
  }
  .radio-secondary input[type=radio]:checked + label::before {
    border-color: #f73164;
  }
  .radio-secondary input[type=radio]:checked + label::after {
    background-color: #f73164;
  }
  
  .checkbox-success label::before {
    border-color: #51bb25;
  }
  .checkbox-success input[type=checkbox]:checked + label::before {
    border-color: #51bb25;
    color: #51bb25;
  }
  .checkbox-success input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-success label:before {
    background-color: #51bb25;
    border-color: #51bb25;
    color: #fff;
  }
  .checkbox-solid-success input[type=checkbox]:checked + label::before {
    background-color: #51bb25;
    border-color: #51bb25;
    color: #fff;
  }
  .checkbox-solid-success input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-success input[type=radio] + label::before {
    border-color: #51bb25;
  }
  .radio-success input[type=radio] + label::after {
    background-color: #51bb25;
  }
  .radio-success input[type=radio]:checked + label::before {
    border-color: #51bb25;
  }
  .radio-success input[type=radio]:checked + label::after {
    background-color: #51bb25;
  }
  
  .checkbox-danger label::before {
    border-color: #dc3545;
  }
  .checkbox-danger input[type=checkbox]:checked + label::before {
    border-color: #dc3545;
    color: #dc3545;
  }
  .checkbox-danger input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-danger label:before {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
  }
  .checkbox-solid-danger input[type=checkbox]:checked + label::before {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
  }
  .checkbox-solid-danger input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-danger input[type=radio] + label::before {
    border-color: #dc3545;
  }
  .radio-danger input[type=radio] + label::after {
    background-color: #dc3545;
  }
  .radio-danger input[type=radio]:checked + label::before {
    border-color: #dc3545;
  }
  .radio-danger input[type=radio]:checked + label::after {
    background-color: #dc3545;
  }
  
  .checkbox-info label::before {
    border-color: #a927f9;
  }
  .checkbox-info input[type=checkbox]:checked + label::before {
    border-color: #a927f9;
    color: #a927f9;
  }
  .checkbox-info input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-info label:before {
    background-color: #a927f9;
    border-color: #a927f9;
    color: #fff;
  }
  .checkbox-solid-info input[type=checkbox]:checked + label::before {
    background-color: #a927f9;
    border-color: #a927f9;
    color: #fff;
  }
  .checkbox-solid-info input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-info input[type=radio] + label::before {
    border-color: #a927f9;
  }
  .radio-info input[type=radio] + label::after {
    background-color: #a927f9;
  }
  .radio-info input[type=radio]:checked + label::before {
    border-color: #a927f9;
  }
  .radio-info input[type=radio]:checked + label::after {
    background-color: #a927f9;
  }
  
  .checkbox-light label::before {
    border-color: #f4f4f4;
  }
  .checkbox-light input[type=checkbox]:checked + label::before {
    border-color: #f4f4f4;
    color: #f4f4f4;
  }
  .checkbox-light input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-light label:before {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    color: #fff;
  }
  .checkbox-solid-light input[type=checkbox]:checked + label::before {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    color: #fff;
  }
  .checkbox-solid-light input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-light input[type=radio] + label::before {
    border-color: #f4f4f4;
  }
  .radio-light input[type=radio] + label::after {
    background-color: #f4f4f4;
  }
  .radio-light input[type=radio]:checked + label::before {
    border-color: #f4f4f4;
  }
  .radio-light input[type=radio]:checked + label::after {
    background-color: #f4f4f4;
  }
  
  .checkbox-dark label::before {
    border-color: #2c323f;
  }
  .checkbox-dark input[type=checkbox]:checked + label::before {
    border-color: #2c323f;
    color: #2c323f;
  }
  .checkbox-dark input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-dark label:before {
    background-color: #2c323f;
    border-color: #2c323f;
    color: #fff;
  }
  .checkbox-solid-dark input[type=checkbox]:checked + label::before {
    background-color: #2c323f;
    border-color: #2c323f;
    color: #fff;
  }
  .checkbox-solid-dark input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-dark input[type=radio] + label::before {
    border-color: #2c323f;
  }
  .radio-dark input[type=radio] + label::after {
    background-color: #2c323f;
  }
  .radio-dark input[type=radio]:checked + label::before {
    border-color: #2c323f;
  }
  .radio-dark input[type=radio]:checked + label::after {
    background-color: #2c323f;
  }
  
  .checkbox-warning label::before {
    border-color: #f8d62b;
  }
  .checkbox-warning input[type=checkbox]:checked + label::before {
    border-color: #f8d62b;
    color: #f8d62b;
  }
  .checkbox-warning input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .checkbox-solid-warning label:before {
    background-color: #f8d62b;
    border-color: #f8d62b;
    color: #fff;
  }
  .checkbox-solid-warning input[type=checkbox]:checked + label::before {
    background-color: #f8d62b;
    border-color: #f8d62b;
    color: #fff;
  }
  .checkbox-solid-warning input[type=checkbox]:checked + label::after {
    color: #fff;
  }
  
  .radio-warning input[type=radio] + label::before {
    border-color: #f8d62b;
  }
  .radio-warning input[type=radio] + label::after {
    background-color: #f8d62b;
  }
  .radio-warning input[type=radio]:checked + label::before {
    border-color: #f8d62b;
  }
  .radio-warning input[type=radio]:checked + label::after {
    background-color: #f8d62b;
  }
  
  .m-checkbox-inline .checkbox {
    display: inline-block;
  }
  .m-checkbox-inline .radio {
    display: inline-block;
  }
  .m-checkbox-inline label {
    margin-right: 20px;
  }
  
  .radio label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
  }
  .radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #efefef;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
  }
  .radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 5px;
    top: 5px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #898989;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  }
  .radio input[type=radio] {
    opacity: 0;
  }
  .radio input[type=radio]:focus + label::before {
    outline-offset: -2px;
  }
  .radio input[type=radio]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .radio input[type=radio]:disabled + label {
    opacity: 0.65;
  }
  .radio input[type=radio]:disabled + label::before {
    cursor: not-allowed;
  }
  .radio .radio-inline {
    margin-top: 0;
  }
  
  /**=====================
       28. Radio CSS Ends
  ==========================**/
  /**=====================
      56.  Ribbon CSS Start
  ==========================**/
  [class*=ribbon-wrapper] {
    position: relative;
    padding-top: 40px;
  }
  
  .ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: 0px;
    color: #fff;
    z-index: 2;
  }
  
  .ribbon-clip {
    left: -14px;
  }
  .ribbon-clip:before {
    position: absolute;
    top: 30px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: #2c323f;
    border-right-color: #2c323f;
  }
  
  .ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative;
  }
  
  .ribbon-vertical-right-wrapper {
    padding-right: 40px;
  }
  
  .ribbon-wrapper-bottom {
    padding-bottom: 40px;
    padding-top: 0;
  }
  
  .ribbon-wrapper-right-bottom {
    padding-right: 40px;
  }
  
  .ribbon-space-bottom {
    top: 0px;
  }
  
  .ribbon-bookmark:before {
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border: 15px solid #2c323f;
    border-right: 10px solid transparent;
  }
  
  .ribbon-right {
    left: auto;
    right: -2px;
  }
  
  .ribbon-clip-right {
    right: -14px;
  }
  .ribbon-clip-right:before {
    position: absolute;
    top: 30px;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: #2c323f;
    border-right-color: #2c323f;
  }
  
  .ribbon-clip-bottom {
    left: -14px;
    bottom: 12px;
    top: auto;
  }
  .ribbon-clip-bottom:before {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: #2c323f;
    border-right-color: #2c323f;
  }
  
  .ribbon-clip-bottom-right {
    right: -14px;
    left: auto;
    bottom: 12px;
    top: auto;
  }
  .ribbon-clip-bottom-right:before {
    position: absolute;
    bottom: 30px;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: #2c323f;
    border-right-color: #2c323f;
  }
  
  .ribbon-bookmark.ribbon-right:before {
    right: 100%;
    left: auto;
    border-right: 15px solid #2c323f;
    border-left: 10px solid transparent;
  }
  
  .ribbon-vertical-left {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    left: 12px;
    top: -2px;
  }
  
  .ribbon-vertical-right {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    top: -2px;
    left: auto;
    right: 12px;
  }
  
  .ribbon-bookmark.ribbon-vertical-left:before, .ribbon-bookmark.ribbon-vertical-right:before {
    top: 100%;
    left: 0;
    margin-top: -14px;
    border-right: 15px solid #2c323f;
    border-bottom: 10px solid transparent;
  }
  
  .ribbon-primary {
    background: #7366ff;
  }
  
  .ribbon-primary {
    background: #7366ff;
  }
  
  .ribbon-bookmark.ribbon-primary:before {
    border-color: #7366ff;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-bottom-color: transparent;
  }
  
  .ribbon-primary.ribbon-corner:before {
    border-top-color: #7366ff;
    border-left-color: #7366ff;
  }
  .ribbon-primary.ribbon-corner.ribbon-right:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
  }
  .ribbon-primary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #7366ff;
  }
  
  .ribbon-primary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #4433ff;
    border-right-color: #4433ff;
  }
  
  .ribbon-primary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #4433ff;
    border-left-color: #4433ff;
  }
  
  .ribbon-primary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #4433ff;
    border-right-color: #4433ff;
  }
  
  .ribbon-primary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #4433ff;
    border-right-color: transparent;
    border-left-color: #4433ff;
  }
  
  .ribbon-secondary {
    background: #f73164;
  }
  
  .ribbon-secondary {
    background: #f73164;
  }
  
  .ribbon-bookmark.ribbon-secondary:before {
    border-color: #f73164;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-bottom-color: transparent;
  }
  
  .ribbon-secondary.ribbon-corner:before {
    border-top-color: #f73164;
    border-left-color: #f73164;
  }
  .ribbon-secondary.ribbon-corner.ribbon-right:before {
    border-right-color: #f73164;
    border-left-color: transparent;
  }
  .ribbon-secondary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f73164;
  }
  
  .ribbon-secondary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #ec0944;
    border-right-color: #ec0944;
  }
  
  .ribbon-secondary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #ec0944;
    border-left-color: #ec0944;
  }
  
  .ribbon-secondary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: #ec0944;
  }
  
  .ribbon-secondary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: transparent;
    border-left-color: #ec0944;
  }
  
  .ribbon-success {
    background: #51bb25;
  }
  
  .ribbon-success {
    background: #51bb25;
  }
  
  .ribbon-bookmark.ribbon-success:before {
    border-color: #51bb25;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-success:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-bottom-color: transparent;
  }
  
  .ribbon-success.ribbon-corner:before {
    border-top-color: #51bb25;
    border-left-color: #51bb25;
  }
  .ribbon-success.ribbon-corner.ribbon-right:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
  }
  .ribbon-success.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #51bb25;
  }
  
  .ribbon-success.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #3f901d;
    border-right-color: #3f901d;
  }
  
  .ribbon-success.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #3f901d;
    border-left-color: #3f901d;
  }
  
  .ribbon-success.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: #3f901d;
  }
  
  .ribbon-success.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: transparent;
    border-left-color: #3f901d;
  }
  
  .ribbon-danger {
    background: #dc3545;
  }
  
  .ribbon-danger {
    background: #dc3545;
  }
  
  .ribbon-bookmark.ribbon-danger:before {
    border-color: #dc3545;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-bottom-color: transparent;
  }
  
  .ribbon-danger.ribbon-corner:before {
    border-top-color: #dc3545;
    border-left-color: #dc3545;
  }
  .ribbon-danger.ribbon-corner.ribbon-right:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
  }
  .ribbon-danger.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dc3545;
  }
  
  .ribbon-danger.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #bd2130;
    border-right-color: #bd2130;
  }
  
  .ribbon-danger.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #bd2130;
    border-left-color: #bd2130;
  }
  
  .ribbon-danger.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: #bd2130;
  }
  
  .ribbon-danger.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: transparent;
    border-left-color: #bd2130;
  }
  
  .ribbon-info {
    background: #a927f9;
  }
  
  .ribbon-info {
    background: #a927f9;
  }
  
  .ribbon-bookmark.ribbon-info:before {
    border-color: #a927f9;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-bottom-color: transparent;
  }
  
  .ribbon-info.ribbon-corner:before {
    border-top-color: #a927f9;
    border-left-color: #a927f9;
  }
  .ribbon-info.ribbon-corner.ribbon-right:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
  }
  .ribbon-info.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #a927f9;
  }
  
  .ribbon-info.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #9106e7;
    border-right-color: #9106e7;
  }
  
  .ribbon-info.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #9106e7;
    border-left-color: #9106e7;
  }
  
  .ribbon-info.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: #9106e7;
  }
  
  .ribbon-info.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: transparent;
    border-left-color: #9106e7;
  }
  
  .ribbon-light {
    background: #f4f4f4;
  }
  
  .ribbon-light {
    background: #f4f4f4;
  }
  
  .ribbon-bookmark.ribbon-light:before {
    border-color: #f4f4f4;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-light:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-bottom-color: transparent;
  }
  
  .ribbon-light.ribbon-corner:before {
    border-top-color: #f4f4f4;
    border-left-color: #f4f4f4;
  }
  .ribbon-light.ribbon-corner.ribbon-right:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
  }
  .ribbon-light.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f4f4f4;
  }
  
  .ribbon-light.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #dbdbdb;
    border-right-color: #dbdbdb;
  }
  
  .ribbon-light.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #dbdbdb;
    border-left-color: #dbdbdb;
  }
  
  .ribbon-light.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: #dbdbdb;
  }
  
  .ribbon-light.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: transparent;
    border-left-color: #dbdbdb;
  }
  
  .ribbon-dark {
    background: #2c323f;
  }
  
  .ribbon-dark {
    background: #2c323f;
  }
  
  .ribbon-bookmark.ribbon-dark:before {
    border-color: #2c323f;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-bottom-color: transparent;
  }
  
  .ribbon-dark.ribbon-corner:before {
    border-top-color: #2c323f;
    border-left-color: #2c323f;
  }
  .ribbon-dark.ribbon-corner.ribbon-right:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
  }
  .ribbon-dark.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #2c323f;
  }
  
  .ribbon-dark.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #171a21;
    border-right-color: #171a21;
  }
  
  .ribbon-dark.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #171a21;
    border-left-color: #171a21;
  }
  
  .ribbon-dark.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: #171a21;
  }
  
  .ribbon-dark.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: transparent;
    border-left-color: #171a21;
  }
  
  .ribbon-warning {
    background: #f8d62b;
  }
  
  .ribbon-warning {
    background: #f8d62b;
  }
  
  .ribbon-bookmark.ribbon-warning:before {
    border-color: #f8d62b;
    border-right-color: transparent;
  }
  .ribbon-bookmark.ribbon-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
  }
  .ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-bottom-color: transparent;
  }
  
  .ribbon-warning.ribbon-corner:before {
    border-top-color: #f8d62b;
    border-left-color: #f8d62b;
  }
  .ribbon-warning.ribbon-corner.ribbon-right:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
  }
  .ribbon-warning.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f8d62b;
  }
  
  .ribbon-warning.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #e8c308;
    border-right-color: #e8c308;
  }
  
  .ribbon-warning.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #e8c308;
    border-left-color: #e8c308;
  }
  
  .ribbon-warning.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: #e8c308;
  }
  
  .ribbon-warning.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: transparent;
    border-left-color: #e8c308;
  }
  
  /**=====================
       56. Ribbon CSS Ends
  ==========================**/
  /**=====================
      18. Switch  CSS Start
  ==========================**/
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input {
    display: none;
  }
  .switch input:checked + .switch-state {
    background-color: var(--theme-default);
  }
  .switch input:checked + .switch-state:before {
    transform: translateX(26px);
  }
  .switch input:focus + .switch-state {
    box-shadow: 0 0 1px var(--theme-default);
  }
  
  .switch-state {
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f4f4;
    transition: 0.4s;
  }
  .switch-state:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 20px;
  }
  
  .icon-state .switch-state:after {
    content: "\efb8";
    font-family: IcoFont;
    left: 10px;
    position: absolute;
    top: 10px;
    color: #2c323f;
    transition: 0.4s;
    opacity: 0.3;
  }
  .icon-state input:checked + .switch-state:after {
    content: "\efad";
    left: 36px;
    opacity: 1;
    transition: 0.4s;
  }
  
  .switch-lg .switch {
    width: 75px;
    height: 40px;
  }
  .switch-lg .switch .switch-state:before {
    height: 30px;
    width: 30px;
    left: 7px;
    bottom: 5px;
    top: 4px;
  }
  
  .switch-lg.icon-state .switch .switch-state:after {
    left: 12px;
    font-size: 18px;
  }
  .switch-lg.icon-state .switch input:checked + .switch-state:after {
    left: 47px;
  }
  .switch-lg.icon-state .switch input:checked + .switch-state:before {
    transform: translateX(32px);
  }
  
  .switch-sm.icon-state .switch .switch-state:after {
    top: 4px;
    left: 4px;
    font-size: 8px;
  }
  .switch-sm.icon-state .switch input:checked + .switch-state:after {
    left: 13px;
    top: 4px;
  }
  .switch-sm .switch {
    width: 25px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .switch-sm .switch .switch-state:before {
    height: 8px;
    width: 8px;
    left: 4px;
    bottom: 4px;
  }
  .switch-sm input:checked + .switch-state:before {
    left: -13px;
  }
  
  .switch-outline .switch-state {
    background-color: #fff;
    border: 1px solid var(--theme-default);
    transition: 0.4s;
  }
  .switch-outline .switch-state:before {
    background-color: #f4f4f4;
    bottom: 3px;
    color: #fff;
  }
  
  .switch-outline .switch input {
    display: none;
  }
  .switch-outline .switch input:checked + .switch-state {
    background-color: transparent;
    border: 1px solid var(--theme-default);
  }
  .switch-outline .switch input:checked + .switch-state:before {
    background-color: var(--theme-default);
    bottom: 3px;
    transform: translateX(26px);
  }
  .switch-outline .switch input:checked + .switch-state:after {
    color: #fff;
  }
  
  .switch-outline .switch-state.bg-primary {
    border: 1px solid var(--theme-default);
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-primary {
    background-color: transparent !important;
    border: 1px solid var(--theme-default);
  }
  .switch-outline .switch input:checked + .switch-state.bg-primary:before {
    background-color: var(--theme-default);
  }
  .switch-outline .switch-state.bg-dark {
    border: 1px solid #2c323f;
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-dark {
    background-color: transparent !important;
    border: 1px solid #2c323f;
  }
  .switch-outline .switch input:checked + .switch-state.bg-dark:before {
    background-color: #2c323f;
  }
  .switch-outline .switch-state.bg-warning {
    border: 1px solid #f8d62b;
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-warning {
    background-color: transparent !important;
    border: 1px solid #f8d62b;
  }
  .switch-outline .switch input:checked + .switch-state.bg-warning:before {
    background-color: #f8d62b;
  }
  .switch-outline .switch-state.bg-success {
    border: 1px solid #51bb25;
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-success {
    background-color: transparent !important;
    border: 1px solid #51bb25;
  }
  .switch-outline .switch input:checked + .switch-state.bg-success:before {
    background-color: #51bb25;
  }
  .switch-outline .switch-state.bg-info {
    border: 1px solid #a927f9;
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-info {
    background-color: transparent !important;
    border: 1px solid #a927f9;
  }
  .switch-outline .switch input:checked + .switch-state.bg-info:before {
    background-color: #a927f9;
  }
  .switch-outline .switch-state.bg-danger {
    border: 1px solid #dc3545;
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-danger {
    background-color: transparent !important;
    border: 1px solid #dc3545;
  }
  .switch-outline .switch input:checked + .switch-state.bg-danger:before {
    background-color: #dc3545;
  }
  .switch-outline .switch-state.bg-primary {
    border: 1px solid var(--theme-default);
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-primary {
    background-color: transparent !important;
    border: 1px solid var(--theme-default);
  }
  .switch-outline .switch input:checked + .switch-state.bg-primary:before {
    background-color: var(--theme-default);
  }
  .switch-outline .switch-state.bg-secondary {
    border: 1px solid var(--theme-secondary);
    background-color: transparent !important;
  }
  .switch-outline .switch input:checked + .switch-state.bg-secondary {
    background-color: transparent !important;
    border: 1px solid var(--theme-secondary);
  }
  .switch-outline .switch input:checked + .switch-state.bg-secondary:before {
    background-color: var(--theme-secondary);
  }
  
  .switch-showcase {
    margin-bottom: -8px;
  }
  .switch-showcase .media {
    line-height: 1;
  }
  .switch-showcase .form-check .form-check-input {
    float: right;
  }
  
  .form-switch .form-check-input:checked {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
  }
  
  /**=====================
       18. Switch  CSS Ends
  ==========================**/
  /**=====================
      44. Tabs CSS Start
  ==========================**/
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: var(--theme-default);
  }
  
  .nav i {
    margin-right: 10px;
  }
  
  .nav-right {
    justify-content: flex-end;
  }
  
  .nav-theme .nav-link, .nav-pills.nav-theme .nav-link {
    color: var(--theme-default);
  }
  .nav-theme .nav-link.active, .nav-theme .show > .nav-link, .nav-pills.nav-theme .nav-link.active, .nav-pills.nav-theme .show > .nav-link {
    background-color: var(--theme-default);
    color: #fff;
  }
  
  .nav-primary .nav-link, .nav-pills.nav-primary .nav-link {
    color: var(--theme-default);
  }
  .nav-primary .nav-link.active, .nav-primary .show > .nav-link, .nav-pills.nav-primary .nav-link.active, .nav-pills.nav-primary .show > .nav-link {
    background-color: var(--theme-default);
    color: #fff;
  }
  
  .nav-secondary .nav-link, .nav-pills.nav-secondary .nav-link {
    color: #f73164;
  }
  .nav-secondary .nav-link.active, .nav-secondary .show > .nav-link, .nav-pills.nav-secondary .nav-link.active, .nav-pills.nav-secondary .show > .nav-link {
    background-color: #f73164;
    color: #fff;
  }
  
  .nav-success .nav-link, .nav-pills.nav-success .nav-link {
    color: #51bb25;
  }
  .nav-success .nav-link.active, .nav-success .show > .nav-link, .nav-pills.nav-success .nav-link.active, .nav-pills.nav-success .show > .nav-link {
    background-color: #51bb25;
    color: #fff;
  }
  
  .nav-danger .nav-link, .nav-pills.nav-danger .nav-link {
    color: #dc3545;
  }
  .nav-danger .nav-link.active, .nav-danger .show > .nav-link, .nav-pills.nav-danger .nav-link.active, .nav-pills.nav-danger .show > .nav-link {
    background-color: #dc3545;
    color: #fff;
  }
  
  .nav-info .nav-link, .nav-pills.nav-info .nav-link {
    color: #a927f9;
  }
  .nav-info .nav-link.active, .nav-info .show > .nav-link, .nav-pills.nav-info .nav-link.active, .nav-pills.nav-info .show > .nav-link {
    background-color: #a927f9;
    color: #fff;
  }
  
  .nav-light .nav-link, .nav-pills.nav-light .nav-link {
    color: #f4f4f4;
  }
  .nav-light .nav-link.active, .nav-light .show > .nav-link, .nav-pills.nav-light .nav-link.active, .nav-pills.nav-light .show > .nav-link {
    background-color: #f4f4f4;
    color: #fff;
  }
  
  .nav-dark .nav-link, .nav-pills.nav-dark .nav-link {
    color: #2c323f;
  }
  .nav-dark .nav-link.active, .nav-dark .show > .nav-link, .nav-pills.nav-dark .nav-link.active, .nav-pills.nav-dark .show > .nav-link {
    background-color: #2c323f;
    color: #fff;
  }
  
  .nav-warning .nav-link, .nav-pills.nav-warning .nav-link {
    color: #f8d62b;
  }
  .nav-warning .nav-link.active, .nav-warning .show > .nav-link, .nav-pills.nav-warning .nav-link.active, .nav-pills.nav-warning .show > .nav-link {
    background-color: #f8d62b;
    color: #fff;
  }
  
  .nav-tabs.nav-bottom .nav-item .nav-link.active, .nav-tabs.nav-bottom .nav-item .nav-link.focus, .nav-tabs.nav-bottom .nav-item .nav-link.hover {
    border-color: #fff #dee2e6 #dee2e6;
  }
  
  .tab-content ~ .nav-tabs.border-tab {
    margin-bottom: 0;
    margin-top: 30px;
  }
  
  .border-tab.nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;
  }
  .border-tab.nav-tabs .nav-item .nav-link {
    font-size: 16px;
    color: #2c323f;
    text-align: center;
    padding: 10px 25px;
    border: none;
    border-bottom: none;
    transition: 0.3s;
  }
  .border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus, .border-tab.nav-tabs .nav-item .nav-link:hover {
    color: var(--theme-default);
  }
  .border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus {
    border-bottom: none;
  }
  
  .border-tab.nav-left .nav-link {
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
    color: #2c323f;
  }
  .border-tab.nav-left .nav-link.active, .border-tab.nav-left .show > .nav-link {
    background-color: transparent;
    border-left-color: var(--theme-default);
    color: var(--theme-default);
    border-radius: 0;
    transition: all 0.3s ease;
  }
  
  .border-tab.nav-right .nav-link {
    border-right: 2px solid transparent;
    transition: all 0.3s ease;
    color: #2c323f;
    text-align: right;
  }
  .border-tab.nav-right .nav-link.active, .border-tab.nav-right .show > .nav-link {
    background-color: transparent;
    border-right-color: var(--theme-default);
    color: var(--theme-default);
    border-radius: 0;
    transition: all 0.3s ease;
  }
  
  .border-tab.nav-theme .nav-link.active, .border-tab.nav-theme .nav-item.show, .border-tab.nav-theme .nav-link:focus, .border-tab.nav-theme .nav-link:hover {
    color: var(--theme-default);
    background-color: transparent;
  }
  .border-tab.nav-theme .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus {
    border-bottom: 2px solid var(--theme-default);
  }
  .border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus, .border-tab.nav-theme .nav-item .nav-link:hover {
    color: var(--theme-default);
  }
  
  .border-tab.nav-left.nav-theme .nav-link.active, .border-tab.nav-left.nav-theme .show > .nav-link {
    border-left-color: var(--theme-default);
    color: var(--theme-default);
  }
  
  .border-tab.nav-right.nav-theme .nav-link.active, .border-tab.nav-right.nav-theme .show > .nav-link {
    border-right-color: var(--theme-default);
    color: var(--theme-default);
  }
  
  .border-tab.nav-primary .nav-link.active, .border-tab.nav-primary .nav-item.show, .border-tab.nav-primary .nav-link:focus, .border-tab.nav-primary .nav-link:hover {
    color: var(--theme-default);
    background-color: transparent;
  }
  .border-tab.nav-primary .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus {
    border-bottom: 2px solid var(--theme-default);
  }
  .border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus, .border-tab.nav-primary .nav-item .nav-link:hover {
    color: var(--theme-default);
  }
  
  .border-tab.nav-left.nav-primary .nav-link.active, .border-tab.nav-left.nav-primary .show > .nav-link {
    border-left-color: var(--theme-default);
    color: var(--theme-default);
  }
  
  .border-tab.nav-right.nav-primary .nav-link.active, .border-tab.nav-right.nav-primary .show > .nav-link {
    border-right-color: var(--theme-default);
    color: var(--theme-default);
  }
  
  .border-tab.nav-secondary .nav-link.active, .border-tab.nav-secondary .nav-item.show, .border-tab.nav-secondary .nav-link:focus, .border-tab.nav-secondary .nav-link:hover {
    color: #f73164;
    background-color: transparent;
  }
  .border-tab.nav-secondary .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus {
    border-bottom: 2px solid #f73164;
  }
  .border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus, .border-tab.nav-secondary .nav-item .nav-link:hover {
    color: #f73164;
  }
  
  .border-tab.nav-left.nav-secondary .nav-link.active, .border-tab.nav-left.nav-secondary .show > .nav-link {
    border-left-color: #f73164;
    color: #f73164;
  }
  
  .border-tab.nav-right.nav-secondary .nav-link.active, .border-tab.nav-right.nav-secondary .show > .nav-link {
    border-right-color: #f73164;
    color: #f73164;
  }
  
  .border-tab.nav-success .nav-link.active, .border-tab.nav-success .nav-item.show, .border-tab.nav-success .nav-link:focus, .border-tab.nav-success .nav-link:hover {
    color: #51bb25;
    background-color: transparent;
  }
  .border-tab.nav-success .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus {
    border-bottom: 2px solid #51bb25;
  }
  .border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus, .border-tab.nav-success .nav-item .nav-link:hover {
    color: #51bb25;
  }
  
  .border-tab.nav-left.nav-success .nav-link.active, .border-tab.nav-left.nav-success .show > .nav-link {
    border-left-color: #51bb25;
    color: #51bb25;
  }
  
  .border-tab.nav-right.nav-success .nav-link.active, .border-tab.nav-right.nav-success .show > .nav-link {
    border-right-color: #51bb25;
    color: #51bb25;
  }
  
  .border-tab.nav-danger .nav-link.active, .border-tab.nav-danger .nav-item.show, .border-tab.nav-danger .nav-link:focus, .border-tab.nav-danger .nav-link:hover {
    color: #dc3545;
    background-color: transparent;
  }
  .border-tab.nav-danger .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus {
    border-bottom: 2px solid #dc3545;
  }
  .border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus, .border-tab.nav-danger .nav-item .nav-link:hover {
    color: #dc3545;
  }
  
  .border-tab.nav-left.nav-danger .nav-link.active, .border-tab.nav-left.nav-danger .show > .nav-link {
    border-left-color: #dc3545;
    color: #dc3545;
  }
  
  .border-tab.nav-right.nav-danger .nav-link.active, .border-tab.nav-right.nav-danger .show > .nav-link {
    border-right-color: #dc3545;
    color: #dc3545;
  }
  
  .border-tab.nav-info .nav-link.active, .border-tab.nav-info .nav-item.show, .border-tab.nav-info .nav-link:focus, .border-tab.nav-info .nav-link:hover {
    color: #a927f9;
    background-color: transparent;
  }
  .border-tab.nav-info .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus {
    border-bottom: 2px solid #a927f9;
  }
  .border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus, .border-tab.nav-info .nav-item .nav-link:hover {
    color: #a927f9;
  }
  
  .border-tab.nav-left.nav-info .nav-link.active, .border-tab.nav-left.nav-info .show > .nav-link {
    border-left-color: #a927f9;
    color: #a927f9;
  }
  
  .border-tab.nav-right.nav-info .nav-link.active, .border-tab.nav-right.nav-info .show > .nav-link {
    border-right-color: #a927f9;
    color: #a927f9;
  }
  
  .border-tab.nav-light .nav-link.active, .border-tab.nav-light .nav-item.show, .border-tab.nav-light .nav-link:focus, .border-tab.nav-light .nav-link:hover {
    color: #f4f4f4;
    background-color: transparent;
  }
  .border-tab.nav-light .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus {
    border-bottom: 2px solid #f4f4f4;
  }
  .border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus, .border-tab.nav-light .nav-item .nav-link:hover {
    color: #f4f4f4;
  }
  
  .border-tab.nav-left.nav-light .nav-link.active, .border-tab.nav-left.nav-light .show > .nav-link {
    border-left-color: #f4f4f4;
    color: #f4f4f4;
  }
  
  .border-tab.nav-right.nav-light .nav-link.active, .border-tab.nav-right.nav-light .show > .nav-link {
    border-right-color: #f4f4f4;
    color: #f4f4f4;
  }
  
  .border-tab.nav-dark .nav-link.active, .border-tab.nav-dark .nav-item.show, .border-tab.nav-dark .nav-link:focus, .border-tab.nav-dark .nav-link:hover {
    color: #2c323f;
    background-color: transparent;
  }
  .border-tab.nav-dark .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus {
    border-bottom: 2px solid #2c323f;
  }
  .border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus, .border-tab.nav-dark .nav-item .nav-link:hover {
    color: #2c323f;
  }
  
  .border-tab.nav-left.nav-dark .nav-link.active, .border-tab.nav-left.nav-dark .show > .nav-link {
    border-left-color: #2c323f;
    color: #2c323f;
  }
  
  .border-tab.nav-right.nav-dark .nav-link.active, .border-tab.nav-right.nav-dark .show > .nav-link {
    border-right-color: #2c323f;
    color: #2c323f;
  }
  
  .border-tab.nav-warning .nav-link.active, .border-tab.nav-warning .nav-item.show, .border-tab.nav-warning .nav-link:focus, .border-tab.nav-warning .nav-link:hover {
    color: #f8d62b;
    background-color: transparent;
  }
  .border-tab.nav-warning .nav-item .nav-link {
    transition: 0.3s;
  }
  .border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus {
    border-bottom: 2px solid #f8d62b;
  }
  .border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus, .border-tab.nav-warning .nav-item .nav-link:hover {
    color: #f8d62b;
  }
  
  .border-tab.nav-left.nav-warning .nav-link.active, .border-tab.nav-left.nav-warning .show > .nav-link {
    border-left-color: #f8d62b;
    color: #f8d62b;
  }
  
  .border-tab.nav-right.nav-warning .nav-link.active, .border-tab.nav-right.nav-warning .show > .nav-link {
    border-right-color: #f8d62b;
    color: #f8d62b;
  }
  
  .tabbed-card ul {
    position: absolute;
    right: 22px;
    top: 33px;
    border-bottom: none;
  }
  .tabbed-card ul.border-tab.nav-tabs {
    top: 12px;
  }
  .tabbed-card ul.border-tab.nav-tabs .nav-item {
    float: right;
  }
  .tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
    padding: 32px 15px;
  }
  
  /**=====================
       44. Tabs CSS Ends
  ==========================**/
  /**=====================
       39. Table  CSS Start
  ==========================**/
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: #dee2e6;
  }
  
  .pills-component {
    width: 20%;
  }
  
  .table {
    margin-bottom: 0px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #ecf3fa;
  }
  .table th, .table td {
    padding: 0.75rem;
  }
  .table.table-primary th {
    color: #fff;
  }
  .table thead.bg-primary tr th {
    color: #fff;
  }
  .table th {
    color: #2b2b2b;
    font-weight: 500;
  }
  .table td {
    color: #2b2b2b;
  }
  .table [class*=bg-] {
    color: #fff;
  }
  .table .bg-light {
    color: #2b2b2b;
  }
  .table tfoot {
    font-weight: bold;
  }
  
  .table-dark th {
    color: #fff;
  }
  
  .table-inverse {
    color: #fff;
    background-color: #292b2c;
  }
  .table-inverse th, .table-inverse td {
    border-color: #fff;
    color: #fff;
  }
  .table-inverse.table-bordered {
    border: 0;
  }
  
  .table[class*=bg-] th, .table[class*=bg-] td {
    color: #fff;
  }
  
  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: #f6f6f6;
  }
  
  .table-double {
    border-left: 4px double #dee2e6;
    border-right: 4px double #dee2e6;
  }
  .table-double thead,
  .table-double tbody,
  .table-double tfoot,
  .table-double tr,
  .table-double td,
  .table-double th {
    border-style: double;
    border-width: 4px;
    border-right: 0;
    border-left: 0;
  }
  
  .table-dotted {
    border-left: 2px dotted #dee2e6;
    border-right: 2px dotted #dee2e6;
  }
  .table-dotted thead,
  .table-dotted tbody,
  .table-dotted tfoot,
  .table-dotted tr,
  .table-dotted td,
  .table-dotted th {
    border-style: dotted !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
  
  .table-dashed {
    border-left: 2px dashed #dee2e6;
    border-right: 2px dashed #dee2e6;
  }
  .table-dashed thead,
  .table-dashed tbody,
  .table-dashed tfoot,
  .table-dashed tr,
  .table-dashed td,
  .table-dashed th {
    border-style: dashed !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
  
  .table-xl td, .table-xl th {
    padding: 1.25rem 2rem;
    font-size: 120%;
  }
  
  .table-lg td, .table-lg th {
    padding: 0.9rem 2rem;
    font-size: 110%;
  }
  
  .table-de td, .table-de th {
    padding: 0.75rem 2rem;
  }
  
  .table-sm th, .table-sm td {
    padding: 0.5rem 2rem;
    font-size: 90%;
  }
  
  .table-xs th, .table-xs td {
    padding: 0.2rem 2rem;
    font-size: 80%;
  }
  
  .table-border-horizontal tr, .table-border-horizontal th, .table-border-horizontal td {
    border-top: 1px solid #f2f4ff;
    padding: 0.75rem 2rem;
  }
  
  .table-border-vertical tr, .table-border-vertical th, .table-border-vertical td {
    border-top: 0;
    border-right: 1px solid #f2f4ff;
  }
  .table-border-vertical tr td {
    border-bottom: 0;
  }
  .table-border-vertical tr td:last-child {
    border-right: none;
  }
  .table-border-vertical tbody tr th {
    border-bottom: 0;
  }
  
  .table-bordernone td {
    border: none !important;
  }
  .table-bordernone thead th {
    border: none !important;
  }
  .table-bordernone .u-s-tb {
    padding: 11px;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    --bs-table-accent-bg: rgba(245, 247, 250, 0.5);
  }
  
  .table-bordered thead,
  .table-bordered tbody,
  .table-bordered tfoot,
  .table-bordered tr,
  .table-bordered td,
  .table-bordered th {
    border-color: #f2f4ff;
  }
  
  /**=====================
       39.Table CSS Ends
  ==========================**/
  /**=====================
      20.  Touchspin  CSS Start
  ==========================**/
  .bootstrap-touchspin input.touchspin {
    padding: 0 12px;
  }
  .bootstrap-touchspin .input-group .btn {
    padding: 0.5rem 1.75rem;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical i {
    position: absolute;
    top: 4px;
    left: 7px;
    font-size: 10px;
    font-weight: normal;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 9px;
    margin-left: -1px;
    position: relative;
  }
  .bootstrap-touchspin .input-group {
    font-family: Rubik, sans-serif;
  }
  .bootstrap-touchspin .input-group .btn {
    border-radius: 0 !important;
  }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropbtn {
    padding: 9px 30px;
  }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
    z-index: 9999;
  }
  
  /**=====================
       20. Touchspin  CSS Ends
  ==========================**/
  /**=====================
       54. Tour  CSS Start
  ==========================**/
  .introjs-helperLayer {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
  }
  
  .introjs-tooltipReferenceLayer {
    background-color: transparent;
  }
  
  .introjs-helperNumberLayer {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background: #dc3545;
    background: linear-gradient(to bottom, var(--theme-default) 0%, var(--theme-default) 100%);
    border: 3px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  }
  
  .introjs-arrow {
    border: 12px solid #fff;
  }
  .introjs-arrow.top {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--theme-default);
    border-left-color: transparent;
  }
  .introjs-arrow.top-right {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--theme-default);
    border-left-color: transparent;
  }
  .introjs-arrow.top-middle {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--theme-default);
    border-left-color: transparent;
  }
  .introjs-arrow.right {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--theme-default);
  }
  .introjs-arrow.right-bottom {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--theme-default);
  }
  .introjs-arrow.bottom {
    border-top-color: var(--theme-default);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .introjs-arrow.left {
    border-top-color: transparent;
    border-right-color: var(--theme-default);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .introjs-arrow.left-bottom {
    border-top-color: transparent;
    border-right-color: var(--theme-default);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  
  .introjs-tooltip {
    background-color: var(--theme-default);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
    color: #fff;
    border-color: var(--theme-default);
  }
  
  .introjs-button {
    border: 1px solid #efefef;
    text-shadow: 1px 1px 0 #fff;
    color: #2b2b2b !important;
    background-color: #fff;
    background-image: linear-gradient(#fff, #fff);
  }
  .introjs-button:hover {
    border-color: #efefef;
  }
  .introjs-button:focus, .introjs-button:active {
    background-image: linear-gradient(#ecf3fa, #efefef);
  }
  
  .introjs-skipbutton {
    color: var(--theme-default);
  }
  
  .introjs-prevbutton.introjs-fullbutton {
    border: 1px solid #efefef;
  }
  
  .introjs-disabled {
    color: var(--theme-default);
    border-color: #efefef;
  }
  .introjs-disabled:hover, .introjs-disabled:focus {
    color: var(--theme-default);
    border-color: #efefef;
  }
  
  .introjs-bullets ul li a {
    background: #ecf3fa;
  }
  .introjs-bullets ul li a:hover {
    background: #fff;
  }
  .introjs-bullets ul li a.active {
    background: #fff;
  }
  
  .introjs-progress {
    background-color: #f4f4f4;
  }
  
  .introjs-progressbar {
    background-color: var(--theme-default);
  }
  
  .introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57);
  }
  
  .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.27);
    background-color: rgba(136, 136, 136, 0.24);
  }
  
  .introjs-hint-dot {
    border: 10px solid rgba(146, 146, 146, 0.36);
  }
  
  /**=====================
      54. Tour  CSS Ends
  ==========================**/
  /**=====================
       50. Tree  CSS Start
  ==========================**/
  .jstree-default .jstree-clicked {
    background-color: white;
    box-shadow: none;
    color: #2c323f;
  }
  
  .vakata-context, .vakata-context ul {
    background-color: #fff;
    border: none;
    box-shadow: 0 0 2px 2px #efefef;
  }
  .vakata-context li a .vakata-contextmenu-sep, .vakata-context ul li a .vakata-contextmenu-sep {
    display: none;
  }
  .vakata-context li a:hover, .vakata-context ul li a:hover {
    background-color: white;
    box-shadow: None;
  }
  .vakata-context .vakata-context-separator, .vakata-context ul .vakata-context-separator {
    display: none;
  }
  
  /**=====================
       50. Tree CSS Ends
  ==========================**/
  /**=====================
       typahead-search  CSS Start
  ==========================**/
  .Typeahead {
    width: 100%;
  }
  .Typeahead * {
    box-sizing: border-box;
  }
  .Typeahead *:before {
    box-sizing: border-box;
  }
  .Typeahead *:after {
    box-sizing: border-box;
  }
  .Typeahead .tt-hint {
    display: none !important;
  }
  
  .u-cf:before {
    content: " ";
    display: table;
  }
  .u-cf:after {
    content: " ";
    display: table;
    clear: both;
  }
  
  .u-hidden {
    display: none !important;
  }
  
  .u-posRelative {
    position: relative;
  }
  
  .Typeahead-spinner {
    position: absolute;
    top: 14px;
    right: 18px;
    display: none;
    color: #c5c5c5;
  }
  
  .ProfileCard-avatar svg {
    height: 18px;
    vertical-align: bottom;
  }
  .ProfileCard-avatar svg path {
    color: #7e7c7c !important;
  }
  .ProfileCard-avatar svg polygon {
    color: #7e7c7c !important;
  }
  
  .Typeahead-hint {
    width: 100%;
    padding: 5px 8px;
    font-size: 24px;
    line-height: 30px;
    border: 1px solid #024e6a;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ccd6dd;
    opacity: 1;
  }
  
  .Typeahead-menu {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px;
    z-index: 100;
    display: none;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .Typeahead-menu.is-open {
    display: block;
  }
  
  .Typeahead-selectable {
    cursor: pointer;
  }
  .Typeahead-selectable + .Typeahead-selectable {
    border-top: 1px solid #ccd6dd;
  }
  
  .ProfileCard {
    position: relative;
    padding: 10px;
    border: 1px solid #cccccc !important;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .ProfileCard:hover {
    color: #414345;
    background: #f8f8f8;
  }
  .ProfileCard:hover .ProfileCard-avatar {
    border-color: #f5f8fa;
  }
  .ProfileCard:hover .ProfileCard-screenName {
    color: #fff;
  }
  .ProfileCard:hover .ProfileCard-stat-label {
    color: #fff;
  }
  
  .ProfileCard-avatar {
    position: absolute;
    border-radius: 5px;
  }
  
  .ProfileCard-details {
    padding-left: 35px;
    float: left;
  }
  
  .ProfileCard-realName {
    display: inline-block;
    color: #898989;
  }
  
  .ProfileCard-screenName {
    display: inline-block;
    color: #898989;
  }
  
  .ProfileCard-description {
    margin-top: 5px;
    font-size: 14px;
    line-height: 18px;
  }
  
  .ProfileCard-stats {
    float: right;
    text-align: right;
  }
  
  .ProfileCard-stat {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }
  .ProfileCard-stat + .ProfileCard-stat {
    margin-left: 5px;
  }
  
  .ProfileCard-stat-label {
    color: #898989;
    font-weight: 500;
  }
  
  .ProfileCard.is-active {
    color: #fff;
    background: var(--theme-default);
  }
  .ProfileCard.is-active .ProfileCard-screenName {
    color: #fff;
  }
  .ProfileCard.is-active .ProfileCard-stat-label {
    color: #fff;
  }
  
  .EmptyMessage {
    position: relative;
    padding: 10px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }
  
  .Typeahead-spinner {
    width: 1rem;
    height: 1rem;
  }
  
  /**=====================
      typahead-search CSS Ends
  ==========================**/
  /**=====================
      Scrollbar CSS Start
  ==========================**/
  [data-simplebar] {
    position: unset;
  }
  
  .simplebar-mask {
    top: 100px;
  }
  
  .simplebar-track {
    right: -2px;
  }
  .simplebar-track.simplebar-vertical {
    top: 100px;
    width: 10px;
  }
  .simplebar-track.simplebar-horizontal {
    visibility: hidden !important;
  }
  
  .simplebar-scrollbar:before {
    background: rgba(115, 102, 255, 0.2);
  }
  
  /**=====================
       Scrollbar CSS Ends
  ==========================**/
  /**=====================
      46. Blog CSS start
  ==========================**/
  .blog-box img {
    border-radius: 0.25rem;
  }
  .blog-box .top-radius-blog {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .blog-box h6 {
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-box .blog-details {
    padding-right: 20px;
  }
  .blog-box .blog-details .blog-social {
    margin-top: 30px;
  }
  .blog-box .blog-details .blog-social li {
    display: inline-block;
    font-size: 12px;
    color: rgba(43, 43, 43, 0.54);
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 20px;
    line-height: 1;
    padding-right: 0;
  }
  .blog-box .blog-details .blog-social li i {
    padding-right: 5px;
    font-weight: 300;
  }
  .blog-box .blog-details .blog-social li:first-child {
    border-right: 1px solid gray;
    padding-right: 20px;
  }
  .blog-box .blog-details-main hr {
    background-color: #cccccc;
  }
  .blog-box .blog-details-main .blog-social {
    margin: 20px 0;
  }
  .blog-box .blog-details-main .blog-social li {
    display: inline-block;
    font-size: 12px;
    color: rgba(43, 43, 43, 0.54);
    padding: 0 24px;
    border-right: 1px dotted;
  }
  .blog-box .blog-details-main .blog-social li:last-child {
    border-right: 0;
  }
  .blog-box .blog-details-main .blog-social li i {
    padding-right: 5px;
  }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px;
    font-size: 14px;
    line-height: 1.714;
    font-weight: 500;
  }
  .blog-box .blog-date {
    color: rgba(43, 43, 43, 0.54);
  }
  .blog-box .blog-date span {
    font-size: 36px;
    font-weight: 500;
    padding-right: 5px;
  }
  
  .blog-box.blog-shadow:before {
    box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    border-radius: 5px;
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    bottom: 0;
  }
  .blog-box.blog-shadow:hover:before {
    box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
    transition: all 0.3s ease;
  }
  .blog-box.blog-shadow .blog-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 40px;
  }
  .blog-box.blog-shadow .blog-details h4 {
    color: #fff;
    margin-bottom: 0;
  }
  .blog-box.blog-shadow .blog-details p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }
  .blog-box.blog-shadow .blog-details .blog-social li {
    color: #fff;
  }
  
  .blog-list .blog-details .blog-social {
    margin-top: 0;
  }
  .blog-list .blog-details h6 {
    margin-top: 0;
    color: gray;
    font-weight: 500;
  }
  .blog-list .blog-details hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blog-list img {
    padding: 17px;
  }
  
  .blog-grid img {
    width: 100%;
  }
  .blog-grid .blog-details {
    padding: 20px;
  }
  .blog-grid .blog-details h6 {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.8;
  }
  
  .blog-box {
    position: relative;
    max-height: 100%;
    align-items: center;
  }
  .blog-box .blog-details p {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.7px;
    margin-top: 30px;
    margin-bottom: 0;
    color: rgba(43, 43, 43, 0.54);
  }
  .blog-box .blog-details h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .blog-box .blog-details .single-blog-content-top {
    margin-top: 20px;
    border-top: 1px solid #efefef;
  }
  .blog-box .blog-details .blog-social li, .blog-box .blog-details .comment-social li {
    font-size: 14px;
  }
  
  .comment-box {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .comment-box hr {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .comment-box li {
    margin-bottom: 30px;
  }
  .comment-box li:last-child {
    margin-bottom: 0;
  }
  .comment-box ul ul {
    margin-left: 135px;
  }
  .comment-box p {
    font-size: 14px;
    line-height: 2;
    color: rgba(43, 43, 43, 0.54);
    text-align: justify;
    margin-bottom: 0;
  }
  .comment-box h4 {
    font-weight: 600;
  }
  .comment-box .media img {
    margin-right: 30px;
    border-radius: 10px;
    height: 90px;
    padding: 7px;
    width: 90px;
    border: 1px solid #efefef;
  }
  .comment-box .media h6 {
    font-weight: 500;
    margin-bottom: 20px;
  }
  .comment-box .media h6 span {
    padding-left: 10px;
    font-size: 13px;
    color: rgba(43, 43, 43, 0.54);
    font-weight: 400;
  }
  .comment-box .comment-social li {
    display: inline-block;
    color: #cccccc;
    padding-left: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .comment-box .comment-social li:first-child {
    border-right: 1px solid #efefef;
    padding-right: 20px;
  }
  .comment-box .comment-social li i {
    padding-right: 10px;
  }
  
  /**=====================
      46. Blog CSS ends
  ==========================**/
  /**=====================
       85. bookmark app CSS Start
  ==========================**/
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 30px;
  }
  
  .bookmark-wrap .btn-mail {
    color: var(--theme-default) !important;
  }
  .bookmark-wrap .details-bookmark .row > div + div {
    padding-right: 15px !important;
  }
  .bookmark-wrap .left-bookmark {
    height: 700px;
  }
  .bookmark-wrap .left-bookmark ul li button {
    border: none;
    cursor: pointer;
  }
  .bookmark-wrap .left-bookmark ul li button:focus {
    outline: none;
  }
  .bookmark-wrap .left-bookmark ul li svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  .bookmark-wrap .left-bookmark ul li > .main-title {
    color: var(--theme-default);
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    font-size: 16px;
  }
  .bookmark-wrap .left-bookmark ul li > .main-title span a:hover {
    background-color: transparent;
  }
  .bookmark-wrap .left-bookmark ul li > .main-title svg {
    color: #898989;
    stroke-width: 1px;
  }
  .bookmark-wrap .left-bookmark ul li a > .title {
    text-transform: capitalize;
  }
  
  .form-bookmark a {
    text-transform: capitalize !important;
    color: var(--theme-default) !important;
    line-height: 1 !important;
    font-weight: 500;
  }
  .form-bookmark a:hover {
    background-color: transparent !important;
  }
  .form-bookmark input#index_var {
    display: none;
  }
  .form-bookmark input,
  .form-bookmark select {
    font-size: 14px;
  }
  .form-bookmark .select2-container .select2-selection--single {
    border-color: #ced4da;
  }
  .form-bookmark .select2 {
    width: 100% !important;
  }
  .form-bookmark .error {
    margin: 0 !important;
    font-size: 11px;
    color: red !important;
  }
  .form-bookmark button {
    margin-top: 10px;
  }
  
  @keyframes displayTransition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .bookmark-tabcontent .details-bookmark {
    height: auto;
    min-height: 359px;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card {
    position: relative;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
    padding: 3px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
    color: #fff;
    font-size: 16px;
    vertical-align: text-bottom;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon.favourite i {
    color: #fed112;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
    width: 100%;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data .content-general {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
    transition: 0.5s;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
    text-align: left;
    padding: 15px;
    border-top: 1px solid #f4f4f4;
    position: relative;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
    animation: displayTransition 1s;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block {
    opacity: 0;
    position: absolute;
    width: 100%;
    padding: 15px;
    top: 30px;
    left: 0;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li {
    display: inline-block;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li + li {
    margin-left: 5px;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li svg {
    width: 16px;
    height: 16px;
    color: #898989;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card:hover {
    box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
    transition: 0.5s;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark p {
    opacity: 0;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark .hover-block {
    opacity: 1;
    animation: displayTransition 2s;
  }
  .bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .favourite-icon {
    opacity: 1;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .row .col-xl-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .desciption-data .content-general {
    display: block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
    display: flex;
    align-items: center;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
    opacity: 1;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
    position: absolute;
    right: 30px;
    opacity: 1;
    text-align: right;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block {
    opacity: 1;
    animation: displayTransition 0s;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark {
    border-top: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark p,
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
    margin-top: 0;
    display: inline-block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
    position: relative;
    padding-right: 15px;
    margin-bottom: -5px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6::after {
    position: absolute;
    content: "-";
    right: 0;
    color: #898989;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general {
    margin-top: 5px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
    width: 68%;
    margin-right: 20px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 20%;
  }
  .bookmark-tabcontent .tab-content .card .d-flex {
    justify-content: space-between;
  }
  .bookmark-tabcontent .tab-content .card .d-flex svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  .bookmark-tabcontent .tab-content .card .d-flex ul li {
    display: inline-block;
  }
  .bookmark-tabcontent .tab-content .card .d-flex ul li + li {
    margin-left: 5px;
  }
  .bookmark-tabcontent .tab-content .tab-pane > .card {
    box-shadow: none;
  }
  
  @media only screen and (max-width: 1660px) {
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
      display: none;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
      margin-top: 6px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .bookmark-wrap .left-bookmark ul li .btn-block svg {
      display: none;
    }
  }
  @media only screen and (max-width: 1366px) {
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
      width: 40%;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
      top: 80px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .bookmark-wrap .left-bookmark {
      height: auto;
    }
    .bookmark-tabcontent .details-bookmark {
      min-height: auto;
    }
  }
  @media only screen and (max-width: 991px) {
    .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
      padding: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 172px;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block p {
      display: none;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block ul {
      position: relative;
      right: unset;
      top: unset;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
      width: 45%;
    }
  }
  @media only screen and (max-width: 575px) {
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
      width: 100%;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
      display: block;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark {
      padding-left: 15px;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
      position: relative;
      right: unset;
      text-align: left;
      top: unset;
      padding: 0;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .content-general {
      display: none;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p, .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6 {
      display: block;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6::after {
      display: none;
    }
    .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
      margin-top: 15px;
    }
    .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
      padding: 15px;
    }
  }
  /**=====================
     85. bookmark app CSS Ends
  ==========================**/
  /**=====================
      82. cart CSS start
  ==========================**/
  .cart .total-amount {
    text-align: right !important;
    margin-top: 30px;
  }
  .cart .amount-space {
    margin-left: 40px;
  }
  .cart .cart-btn-transform {
    text-transform: capitalize;
  }
  .cart .qty-box {
    width: 25%;
    margin: 0 auto;
  }
  .cart .qty-box .input-group .btn {
    background-color: #ddd !important;
    border-color: #ddd !important;
    padding: 8px 12px;
  }
  
  .cart-buttons {
    margin-top: 30px;
  }
  .cart-buttons .cart-btn {
    float: right;
    padding-right: 85px;
  }
  
  /**=====================
      82. cart CSS Ends
  ==========================**/
  /**=====================
      41. Chart CSS Start
  ==========================**/
  .radial-bar.radial-bar-lg > img, .radial-bar.radial-bar-lg:after {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 15px;
    line-height: 70px;
  }
  
  .radial-bar.radial-bar-sm > img, .radial-bar.radial-bar-sm:after {
    width: 35px;
    height: 35px;
    margin-left: 7.5px;
    margin-top: 7.5px;
    line-height: 35px;
  }
  
  .radial-bar.radial-bar-xs > img, .radial-bar.radial-bar-xs:after {
    width: 20px;
    height: 20px;
    margin-left: 5.5px;
    margin-top: 4.5px;
    line-height: 21px;
  }
  
  .chart-block canvas {
    width: 100% !important;
  }
  .chart-block .chart-overflow {
    overflow: hidden;
    text-align: center;
  }
  
  .candidcahrt {
    width: 100%;
    height: 470px;
  }
  
  .amcharts-export-canvas {
    position: absolute;
    display: none;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
  }
  .amcharts-export-canvas .canvas-container {
    transition: opacity 0.5s ease-out;
  }
  .amcharts-export-canvas.active {
    display: block;
  }
  .amcharts-export-canvas.dropbox .canvas-container {
    opacity: 0.5;
  }
  
  .flot-chart-container {
    box-sizing: border-box;
    width: 100%;
    height: 400px;
  }
  .flot-chart-container #toggling-series-flot {
    width: 79% !important;
  }
  
  .all-chart .flot-chart-container {
    height: 350px;
  }
  
  .flot-chart-placeholder {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1.2em;
    text-align: center;
  }
  .flot-chart-placeholder .legend table {
    border-spacing: 5px;
  }
  
  .morris-hover {
    position: absolute;
    z-index: 8;
  }
  .morris-hover.morris-default-style {
    border-radius: 10px;
    padding: 6px;
    color: #898989;
    background: #fff;
    border: solid 1px #ecf3fa;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
  }
  .morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: bold;
    margin: 0.25em 0;
  }
  .morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: 0.1em 0;
  }
  
  .chart-sparkline {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    background: rgba(49, 49, 49, 0.7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    color: #fff;
    font: 10px Rubik, sans-serif;
    text-align: left;
    white-space: nowrap;
    padding: 5px;
    border: 1px solid rgba(49, 49, 49, 0.75);
    box-sizing: content-box;
    z-index: 10000;
    border-radius: 5px;
  }
  .chart-sparkline .jqsfield {
    color: #fff;
    font: 10px Rubik, sans-serif;
    text-align: left;
  }
  
  .radial-bar {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: content-box;
    width: 80px;
    height: 80px;
    font-size: 18px;
    background-clip: content-box;
  }
  .radial-bar:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    content: attr(data-label);
    background-color: #fff;
    z-index: 1;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px;
  }
  .radial-bar > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    z-index: 3;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px;
  }
  
  .radial-bar-danger.radial-bar-20 {
    background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(162deg, #dc3545 50%, #efefef 50%, #efefef);
  }
  
  .radial-bar.radial-bar-lg {
    width: 100px;
    height: 100px;
    font-size: 20px;
  }
  .radial-bar.radial-bar-sm {
    width: 50px;
    height: 50px;
    font-size: 12px;
  }
  .radial-bar.radial-bar-xs {
    width: 30px;
    height: 30px;
    font-size: 8px;
  }
  .pieLabel div {
    color: #fff !important;
  }
  .pieLabel div input[type=checkbox] {
    margin: 7px;
  }
  
  .knob-chart {
    min-height: 255px;
  }
  .knob-chart .chart-clock-main {
    position: relative;
    width: 200px;
    margin: 45px auto;
  }
  .knob-chart .chart-clock-main .clock-large {
    position: absolute;
    left: -48px;
    top: -45px;
  }
  .knob-chart .chart-clock-main .clock-medium {
    position: absolute;
    left: 2px;
    top: 5px;
  }
  .knob-chart .chart-clock-main .clock-small {
    position: absolute;
    left: 15px;
    top: 55px;
  }
  
  .peity-charts svg {
    width: 100%;
    height: 250px;
  }
  .peity-charts.peity-chart-primary svg polygon {
    fill: rgba(115, 102, 255, 0.2);
  }
  .peity-charts.peity-chart-primary svg polyline {
    stroke: var(--theme-default);
  }
  
  .bar-odd-even svg rect:nth-child(odd) {
    fill: var(--theme-default);
  }
  .bar-odd-even svg rect:nth-child(even) {
    fill: var(--theme-secondary);
  }
  
  .donut-peity svg path:nth-child(odd) {
    fill: var(--theme-default);
  }
  .donut-peity svg path:nth-child(even) {
    fill: var(--theme-secondary);
  }
  
  .small-donut ~ svg path:nth-child(odd),
  .small-donut svg path:nth-child(odd),
  .donut-peity2 ~ svg path:nth-child(odd),
  .donut-peity2 svg path:nth-child(odd),
  .pie-peity ~ svg path:nth-child(odd),
  .pie-peity svg path:nth-child(odd) {
    fill: var(--theme-default);
  }
  .small-donut ~ svg path:nth-child(even),
  .small-donut svg path:nth-child(even),
  .donut-peity2 ~ svg path:nth-child(even),
  .donut-peity2 svg path:nth-child(even),
  .pie-peity ~ svg path:nth-child(even),
  .pie-peity svg path:nth-child(even) {
    fill: #efefef;
  }
  
  .small-donut2 ~ svg path:nth-child(odd) {
    fill: var(--theme-secondary);
  }
  
  .small-donut3 ~ svg path:nth-child(odd) {
    fill: #51bb25;
  }
  
  .small-donut4 ~ svg path:nth-child(odd) {
    fill: #a927f9;
  }
  
  .small-donut5 ~ svg path:nth-child(odd) {
    fill: #f8d62b;
  }
  
  .small-donut6 ~ svg path:nth-child(odd) {
    fill: #dc3545;
  }
  
  p.data-attributes svg {
    margin-right: 10px;
  }
  
  .knob-block {
    position: relative;
  }
  .knob-block input {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin-left: 0 !important;
    margin-top: -3px !important;
  }
  
  .knob-input-disable input {
    display: none !important;
  }
  
  .apex-chart {
    margin: 0 auto;
  }
  
  .apexcharts-toolbar {
    z-index: 1 !important;
  }
  
  .apexcharts-menu-item {
    width: 110px;
  }
  
  .word-tree {
    height: 400px;
  }
  
  .ct-series-a .ct-slice-pie,
  .ct-series-a .ct-slice-donut-solid,
  .ct-series-a .ct-area {
    fill: var(--theme-default);
  }
  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: var(--theme-default);
  }
  
  .ct-series-b .ct-point,
  .ct-series-b .ct-line,
  .ct-series-b .ct-bar,
  .ct-series-b .ct-slice-donut {
    stroke: var(--theme-secondary);
  }
  .ct-series-b .ct-slice-pie,
  .ct-series-b .ct-slice-donut-solid,
  .ct-series-b .ct-area {
    fill: var(--theme-secondary);
  }
  
  .apache-cotainer {
    width: 100%;
    height: 350px;
    margin: 0;
  }
  
  .boxplot-cotainer {
    height: 420px;
  }
  
  .apache-cotainer-large {
    width: 100%;
    height: 500px;
    margin: 0;
  }
  
  .test-buttons button + button {
    margin-left: 10px;
  }
  
  .clip-chart-wrapper .test-chart-block {
    margin-bottom: -30px;
  }
  
  #echart-gauge .test-chart {
    width: 100%;
    height: 395px;
  }
  
  .polar-line {
    height: 350px;
  }
  
  #dotted {
    height: 390px;
  }
  
  /**=====================
       41. Chart CSS End
  ==========================**/
  /**=====================
       14. Chat CSS Start
  ==========================**/
  .chat-box .toogle-bar {
    display: none;
  }
  .chat-box .chat-menu {
    max-width: 340px;
  }
  .chat-box .people-list .search {
    position: relative;
  }
  .chat-box .people-list .search .form-control {
    background-color: #fafdff;
    border: 1px solid #f4f4f4;
  }
  .chat-box .people-list .search .form-control::placeholder {
    color: #59667a;
  }
  .chat-box .people-list .search i {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #ecf3fa;
  }
  .chat-box .people-list ul {
    padding: 0;
  }
  .chat-box .people-list ul li {
    padding-bottom: 20px;
  }
  .chat-box .people-list ul li:last-child {
    padding-bottom: 0;
  }
  .chat-box .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .chat-box .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
  }
  .chat-box .about .name {
    color: #2c323f;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .chat-box .status {
    color: #59667a;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
  }
  .chat-box .status .chat-status {
    font-weight: 600;
    color: #2b2b2b;
  }
  .chat-box .status p {
    font-size: 14px;
  }
  .chat-box .chat-right-aside .chat .chat-header {
    padding: 15px;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chat-box .chat-right-aside .chat .chat-header img {
    float: left;
    width: 50px;
    height: 50px;
    box-shadow: 1px 1px 4px 1px #ecf3fa;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 24px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    color: rgba(43, 43, 43, 0.54);
    font-size: 25px;
    cursor: pointer;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
    margin-left: auto;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    padding: 20px;
    overflow-y: auto;
    height: 560px;
    margin-bottom: 80px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
    margin-top: -35px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .message-data {
    margin-bottom: 10px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
    letter-spacing: 1px;
    font-size: 12px;
    color: #59667a;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .message {
    color: #2c323f;
    padding: 20px;
    line-height: 1.9;
    letter-spacing: 1px;
    font-size: 14px;
    margin-bottom: 30px;
    width: 50%;
    position: relative;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    border-top-left-radius: 0;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
    background-color: #fdfeff;
    border-radius: 10px;
    border-top-right-radius: 0;
  }
  .chat-box .chat-right-aside .chat .chat-message {
    padding: 20px;
    border-top: 1px solid #fafdff;
    position: absolute;
    width: calc(100% - 15px);
    background-color: #fff;
    bottom: 0;
  }
  .chat-box .chat-right-aside .chat .chat-message .smiley-box {
    background: #efefef;
    padding: 10px;
    display: block;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box {
    position: relative;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .input-txt-bx {
    height: 50px;
    border: 2px solid var(--theme-default);
    padding-left: 18px;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box i {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: #ecf3fa;
    cursor: pointer;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 16px;
    font-weight: 500;
    padding: 0.74rem 1.75rem;
    border-radius: 0 5px 5px 0;
  }
  .chat-box .chat-menu {
    border-left: 1px solid #f4f4f4;
  }
  .chat-box .chat-menu .tab-pane {
    padding: 0 15px;
  }
  .chat-box .chat-menu ul li .about .status i {
    font-size: 10px;
  }
  .chat-box .chat-menu .user-profile {
    margin-top: 30px;
  }
  .chat-box .chat-menu .user-profile .user-content h5 {
    margin: 25px 0;
  }
  .chat-box .chat-menu .user-profile .user-content hr {
    margin: 25px 0;
  }
  .chat-box .chat-menu .user-profile .user-content p {
    font-size: 16px;
  }
  .chat-box .chat-menu .user-profile .border-tab.nav-tabs .nav-item .nav-link.active, .chat-box .chat-menu .user-profile .border-tab.nav-tabs .nav-item .nav-link.show, .chat-box .chat-menu .user-profile .border-tab.nav-tabs .nav-item .nav-link:focus {
    border-bottom: 0;
  }
  .chat-box .chat-menu .user-profile .image {
    position: relative;
  }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    position: absolute;
    bottom: 0;
    left: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto;
    font-size: 14px;
    box-shadow: 1px 1px 3px 1px #f4f4f4;
  }
  .chat-box .chat-menu .user-profile .image .avatar img {
    border-radius: 50%;
    border: 5px solid #f4f4f4;
  }
  .chat-box .chat-menu .user-profile .border-right {
    border-right: 1px solid #dee2e6;
  }
  .chat-box .chat-menu .user-profile .follow {
    margin-top: 0;
  }
  .chat-box .chat-menu .user-profile .follow .follow-num {
    font-size: 20px;
    color: #000;
  }
  .chat-box .chat-menu .user-profile .follow span {
    color: #242934;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .chat-box .chat-menu .user-profile .social-media a {
    color: #59667a;
    font-size: 15px;
    padding: 0 7px;
  }
  .chat-box .chat-menu .user-profile .chat-profile-contact p {
    font-size: 14px;
    color: #59667a;
  }
  .chat-box .chat-menu .nav {
    margin-bottom: 20px;
  }
  .chat-box .chat-menu .nav-tabs .nav-item {
    width: 33.33%;
  }
  .chat-box .chat-menu .nav-tabs .nav-item a {
    padding: 15px !important;
    color: #59667a !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat-box .chat-menu .nav-tabs .nav-item .material-border {
    border-width: 1px;
    border-color: var(--theme-default);
  }
  .chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
    color: #000 !important;
  }
  .chat-box .chat-history .call-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 50px;
    color: #efefef;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 55px;
  }
  .chat-box .chat-history .receiver-img img {
    border-radius: 5px;
  }
  .chat-box .chat-history .call-icons {
    margin-bottom: 35px;
  }
  .chat-box .chat-history .call-icons ul li {
    width: 60px;
    height: 60px;
    border: 1px solid #f4f4f4;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
  }
  .chat-box .chat-history .call-icons ul li + li {
    margin-left: 10px;
  }
  .chat-box .chat-history .call-icons ul li a {
    color: #999;
    font-size: 25px;
  }
  
  .chat-left-aside > .media {
    margin-bottom: 15px;
  }
  .chat-left-aside .people-list {
    height: 625px;
  }
  .chat-left-aside ul li {
    position: relative;
  }
  
  .status-circle {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 4px;
    left: 44px;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  
  .away {
    background-color: #f8d62b;
  }
  
  .online {
    background-color: #51bb25;
  }
  
  .offline {
    background-color: #dc3545;
  }
  
  .chat-container .aside-chat-left {
    width: 320px;
  }
  .chat-container .chat-right-aside {
    width: 320px;
  }
  
  .call-chat-sidebar {
    max-width: 320px;
  }
  
  .call-chat-sidebar .card .card-body, .chat-body .card .card-body {
    padding: 15px;
  }
  
  /**=====================
      14.  Chat CSS Ends
  ==========================**/
  /**=====================
      84. Checkout CSS start
  ==========================**/
  .checkout .checkout-details {
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    padding: 40px;
  }
  .checkout .checkout-details .order-place {
    margin-top: 15px;
  }
  
  .order-box .title-box {
    padding-bottom: 20px;
    color: #444444;
    font-size: 22px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
  }
  .order-box .title-box span {
    width: 35%;
    float: right;
    font-weight: 600;
    text-align: right;
  }
  .order-box .title-box h4 {
    font-weight: 600;
  }
  .order-box .title-box .checkbox-title {
    display: flex;
    justify-content: space-between;
  }
  .order-box .sub-total li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  .order-box .sub-total li .count {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    width: 35%;
    float: right;
    text-align: right;
  }
  .order-box .sub-total .shipping-class {
    margin-bottom: 12px;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    margin-top: -4px;
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: var(--theme-default);
    font-weight: 400;
    width: 35%;
    float: right;
    text-align: right;
  }
  .order-box .total {
    position: relative;
    margin-bottom: 30px;
  }
  .order-box .total li {
    position: relative;
    display: block;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    font-size: 18px;
  }
  .order-box .qty {
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px;
  }
  .order-box .qty li {
    position: relative;
    display: block;
    font-size: 15px;
    color: #444444;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .order-box .qty li span {
    float: right;
    font-size: 18px;
    line-height: 20px;
    color: #232323;
    font-weight: 400;
    width: 35%;
    text-align: right;
  }
  
  .radio-option {
    position: relative;
  }
  
  .img-paypal {
    width: 30%;
    margin-left: 15px;
  }
  
  /**=====================
      86. Checkout CSS Ends
  ==========================**/
  /**=====================
      Responsive CSS Start
  ==========================**/
  @media screen and (max-width: 1199px) {
    .checkout .checkout-details {
      padding: 25px;
    }
    .order-box .title-box span {
      width: 30%;
    }
    .order-box .sub-total li .count {
      width: 38%;
    }
    .order-box .sub-total .shipping-class .shopping-checkout-option {
      width: 46%;
    }
    .order-box .qty li span {
      width: 38%;
    }
    .order-box .total li .count {
      width: 38%;
    }
  }
  @media screen and (max-width: 991px) {
    .order-box .sub-total .shipping-class .shopping-checkout-option {
      width: 38%;
    }
  }
  @media screen and (max-width: 575px) {
    .checkout .checkout-details {
      padding: 15px;
    }
    .order-box .sub-total .shipping-class .shopping-checkout-option {
      width: 50%;
    }
    .order-box .sub-total li .count {
      width: 50%;
    }
  }
  /**=====================
      84. Checkout CSS ends
  ==========================**/
  /**=====================
       27. Coming Soon CSS Start
  ==========================**/
  .comingsoon-bgimg {
    // background: url(../images/other-images/coming-soon-bg.jpg);
    background-position: bottom;
    background-size: cover;
  }
  
  .comingsoon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 50px 0;
  }
  .comingsoon .comingsoon-inner h5 {
    font-size: 18px;
    letter-spacing: 1px;
    color: rgba(43, 43, 43, 0.54);
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .comingsoon .comingsoon-inner .countdown {
    padding: 30px 0;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
  }
  .comingsoon .comingsoon-inner .countdown .time {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    width: 100px;
    height: 100px;
    font-size: 36px;
    background: var(--theme-default);
  }
  .comingsoon .comingsoon-inner .countdown .title {
    padding-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(43, 43, 43, 0.54);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .comingsoon .comingsoon-inner .countdown ul li {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 20px;
  }
  .comingsoon .comingsoon-inner .countdown ul li span {
    display: block;
  }
  .comingsoon video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
  }
  
  /**=====================
      27. Coming Soon CSS Ends
  ==========================**/
  /**=====================
        86. contacts CSS Start
  ==========================**/
  .alert-contactadd {
    background-color: #fff;
    border: 1px solid var(--theme-default);
    width: 12%;
    padding: 10px;
  }
  
  .updateimg {
    position: absolute;
    left: 15px;
    width: 100px;
    top: 32px;
    line-height: 93px;
    opacity: 0;
  }
  
  #right-history {
    top: 81px;
    right: -320px;
    height: 100%;
    position: fixed;
    width: 320px;
    z-index: 9;
    background-color: #fff;
    transition: 0.5s;
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  }
  #right-history h6 span a {
    color: #2b2b2b;
  }
  #right-history.show {
    right: 0;
    transition: 0.3s;
  }
  
  .history-details > div {
    padding: 30px 0;
  }
  .history-details .media {
    padding: 30px 15px;
    border-top: 1px solid #f4f4f4;
  }
  .history-details .media i {
    padding: 5px;
    background: #fed112;
    color: #fff;
    border-radius: 100%;
  }
  .history-details .media h6 {
    margin-bottom: 5px;
  }
  
  .delete-contact {
    display: none;
  }
  
  .more-data {
    animation: displayTransition 1s;
  }
  
  .contact-editform {
    padding: 30px;
    animation: displayTransition 1s;
  }
  .contact-editform a {
    display: block;
  }
  .contact-editform button {
    margin-top: 30px;
  }
  .contact-editform .select2-container .select2-selection--single {
    height: 39px !important;
    border-color: #ced4da !important;
  }
  
  #deletemodal .modal-header {
    border-bottom: none;
  }
  #deletemodal .modal-header .delete-data span svg {
    vertical-align: middle;
    margin-right: 15px;
  }
  
  .contact-options li .btn-category {
    line-height: 39px;
    border-radius: 3px;
    padding-left: 40px !important;
    padding: 0;
    color: var(--theme-default);
    font-weight: 500;
  }
  
  .list-persons p {
    font-family: Roboto, sans-serif;
  }
  .list-persons .profile-mail {
    padding: 30px 0;
  }
  .list-persons .profile-mail .media img {
    height: 100px;
  }
  .list-persons .profile-mail .media .media-body h5 {
    margin-bottom: 5px;
  }
  .list-persons .profile-mail .media .media-body ul {
    margin-top: 5px;
  }
  .list-persons .profile-mail .media .media-body ul li {
    display: inline-block;
  }
  .list-persons .profile-mail .media .media-body ul li a {
    font-size: 13px;
  }
  .list-persons .profile-mail .media .media-body ul li + li {
    padding-left: 10px;
    margin-left: 5px;
    border-left: 2px solid #f4f4f4;
  }
  .list-persons .profile-mail .email-general {
    padding-top: 50px;
  }
  .list-persons .profile-mail .email-general ul {
    padding-right: 20px;
  }
  .list-persons .profile-mail .email-general ul li {
    color: #898989;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .list-persons .profile-mail .email-general ul li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .list-persons .profile-mail .email-general ul li > span {
    float: right;
  }
  .list-persons .profile-mail .email-general p span {
    margin-left: 30px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 30px;
  }
  
  .contacts-tabs .nav-pills {
    border-right: 1px solid #f4f4f4;
    height: 100%;
  }
  .contacts-tabs .nav-pills .nav-link.active,
  .contacts-tabs .nav-pills .nav-link > .nav-link,
  .contacts-tabs .nav-pills .show.active,
  .contacts-tabs .nav-pills .show > .nav-link {
    background-color: rgba(115, 102, 255, 0.05);
    color: #2b2b2b;
  }
  .contacts-tabs .nav-pills .nav-link {
    color: #2b2b2b;
    padding: 0;
  }
  .contacts-tabs .nav-pills .nav-link .media .img-50 {
    height: 50px;
  }
  .contacts-tabs .nav-pills .nav-link + .nav-link {
    border-top: 1px solid #f4f4f4;
  }
  .contacts-tabs .nav-pills .media {
    padding: 20px;
  }
  .contacts-tabs .tab-content .tab-content-child {
    animation: displayTransition 1s;
  }
  
  @media only screen and (max-width: 991px) {
    #right-history {
      top: 60px;
    }
    .updateimg {
      top: 21px;
    }
    .list-persons .profile-mail {
      padding: 20px;
    }
    .list-persons .profile-mail .email-general .gender {
      margin-top: 20px;
    }
    .contact-editform button {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .contact-editform {
      padding: 20px;
    }
    .contact-editform.pl-0 {
      padding-left: 20px !important;
    }
    .contacts-tabs .nav-pills {
      border-right: none;
      border-bottom: 1px solid #f4f4f4;
    }
  }
  @media only screen and (max-width: 575px) {
    #right-history {
      width: 270px;
    }
    .updateimg {
      top: 16px;
      left: 29px;
    }
    .contacts-tabs .media img.m-r-20 {
      margin-right: 15px;
    }
    .contacts-tabs .nav-pills .media {
      padding: 15px;
    }
    .list-persons .profile-mail {
      padding: 15px;
    }
    .list-persons .profile-mail .email-general .gender {
      margin-top: 15px;
    }
    .list-persons .nav-pills .nav-link .media .media-body {
      text-align: left;
    }
    .contact-editform {
      padding: 15px;
    }
    .contact-editform button {
      margin-top: 15px;
    }
    .contact-editform.pl-0 {
      padding-left: 15px !important;
    }
    .contact-editform form .row > div .row > div > div,
  .contact-options form .row > div .row > div > div {
      margin-bottom: 0;
    }
    .contact-editform form .row > div .row > div + div,
  .contact-options form .row > div .row > div + div {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 360px) {
    .updateimg {
      line-height: 73px;
      width: 80px;
    }
    .list-persons .profile-mail .email-general p span {
      margin-left: 10px;
    }
    .list-persons .profile-mail .media {
      display: block;
    }
    .list-persons .profile-mail .media img {
      height: 80px;
      width: 80px !important;
      margin-bottom: 15px;
    }
  }
  /**=====================
      86. contacts CSS Ends
  ==========================**/
  /**=====================
        67. Dashboard 2 CSS Start
  ==========================**/
  .dash-chart .profit-card {
    margin: -30px 0 -14px;
  }
  .dash-chart .profit-card #negative-chart .apexcharts-tooltip.light .apexcharts-tooltip-title {
    display: none;
  }
  .dash-chart .profit-card #negative-chart .apexcharts-tooltip-series-group.active {
    padding-bottom: 0;
  }
  .dash-chart .media .media-body p {
    color: rgba(43, 43, 43, 0.54);
  }
  .dash-chart .media .media-body p span {
    display: inline-block;
  }
  .dash-chart .media .media-body p .font-roboto {
    color: rgba(43, 43, 43, 0.54);
  }
  .dash-chart .progress-animate {
    margin-top: -6px;
    margin-right: 2px;
  }
  
  .support-ticket-font ul {
    font-size: 12px;
  }
  
  .negative-container {
    margin: -36px -15px -29px;
  }
  .negative-container .apexcharts-xaxis .apexcharts-xaxis-texts-g text {
    font-size: 13px;
    fill: rgba(43, 43, 43, 0.4);
    font-family: Roboto, sans-serif !important;
  }
  
  .ecommerce-widgets {
    align-items: center;
    position: relative;
  }
  .ecommerce-widgets .ecommerce-box {
    width: 60px;
    height: 60px;
  }
  .ecommerce-widgets .ecommerce-box.light-bg-primary {
    background-color: rgba(115, 102, 255, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
  .ecommerce-widgets .fa-heart {
    font-size: 24px;
    background: linear-gradient(to right, var(--theme-default) 10%, #a927f9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 2px;
  }
  
  .dash-map #map {
    height: 300px;
    width: 100%;
  }
  .dash-map #map .gmnoprint {
    display: none;
  }
  
  .monthly-visit {
    margin: -4px 0 -35px;
  }
  
  .new-update .media .media-body span,
  .new-update .media .media-body p {
    font-weight: 500;
  }
  
  .activity-timeline .media {
    align-items: center;
  }
  .activity-timeline .media .activity-line {
    top: 132px;
    height: 57%;
    position: absolute;
    width: 4px;
    background-color: #f8f8f8;
    margin: 0 auto;
    left: 46px;
    z-index: 1;
  }
  .activity-timeline .media .circle-dot-primary {
    background-color: rgba(var(--theme-default), 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: var(--theme-default);
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-secondary {
    background-color: rgba(247, 49, 100, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #f73164;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-success {
    background-color: rgba(81, 187, 37, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #51bb25;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-danger {
    background-color: rgba(220, 53, 69, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #dc3545;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-info {
    background-color: rgba(169, 39, 249, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #a927f9;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-light {
    background-color: rgba(244, 244, 244, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #f4f4f4;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-dark {
    background-color: rgba(44, 50, 63, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #2c323f;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media .circle-dot-warning {
    background-color: rgba(248, 214, 43, 0.25);
    border-radius: 100%;
    padding: 6px;
    font-size: 5px;
    color: #f8d62b;
    animation: round 1.3s ease-in-out infinite;
  }
  .activity-timeline .media + .media {
    margin-top: 45px;
  }
  .activity-timeline .media .media-body {
    margin-left: 20px;
  }
  .activity-timeline .media .media-body p {
    color: rgba(43, 43, 43, 0.6);
    font-size: 12px;
    margin-top: 2px;
  }
  
  .activity-dot-primary {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid var(--theme-default);
    box-shadow: 0.5px 4.33px 15px 0px rgba(var(--theme-default), 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-secondary {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #f73164;
    box-shadow: 0.5px 4.33px 15px 0px rgba(247, 49, 100, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-success {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #51bb25;
    box-shadow: 0.5px 4.33px 15px 0px rgba(81, 187, 37, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-danger {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #dc3545;
    box-shadow: 0.5px 4.33px 15px 0px rgba(220, 53, 69, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-info {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #a927f9;
    box-shadow: 0.5px 4.33px 15px 0px rgba(169, 39, 249, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-light {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #f4f4f4;
    box-shadow: 0.5px 4.33px 15px 0px rgba(244, 244, 244, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-dark {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #2c323f;
    box-shadow: 0.5px 4.33px 15px 0px rgba(44, 50, 63, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .activity-dot-warning {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #f8d62b;
    box-shadow: 0.5px 4.33px 15px 0px rgba(248, 214, 43, 0.25);
    position: relative;
    z-index: 2;
  }
  
  .offer-box {
    background: transparent;
    box-shadow: none;
  }
  .offer-box .offer-slider {
    // background-image: url(../../assets/images/dashboard-2/bg.jpg);
    background-size: cover;
    border-radius: 15px;
    height: 200px;
    width: 100%;
    position: relative;
  }
  .offer-box .offer-slider::after {
    position: absolute;
    content: "";
    background: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
    opacity: 0.92;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .offer-box .offer-slider .carousel {
    z-index: 2;
  }
  .offer-box .offer-slider .carousel-control-prev {
    right: 0;
    left: unset;
  }
  .offer-box .offer-slider .carousel-control-prev-icon {
    top: 116px;
    position: absolute;
  }
  .offer-box .offer-slider .carousel-control-next-icon {
    top: 75px;
    position: absolute;
  }
  .offer-box .offer-slider .carousel-control-prev-icon,
  .offer-box .offer-slider .carousel-control-next-icon {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  .offer-box .offer-slider .carousel-control-prev,
  .offer-box .offer-slider .carousel-control-next {
    width: 10%;
  }
  .offer-box .offer-slider .selling-slide {
    z-index: 1;
    position: relative;
  }
  .offer-box .offer-slider .selling-slide .d-flex {
    height: 73%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .offer-box .offer-slider .selling-slide p {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-family: Roboto, sans-serif;
    margin-bottom: 5px;
  }
  .offer-box .offer-slider .selling-slide .badge-white {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .offer-box .offer-slider .selling-slide .badge-dotted {
    border: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .offer-box .offer-slider .selling-slide .badge {
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    padding: 8px 15px;
  }
  .offer-box .offer-slider .selling-slide h4 {
    color: #fff;
    margin-bottom: 14px;
  }
  .offer-box .offer-slider .selling-slide .center-img {
    margin-top: -60px;
  }
  
  .total-users .goal-chart {
    margin: -35px -15px -30px;
  }
  .total-users .goal-chart .apexcharts-datalabels-group text {
    font-weight: 700;
  }
  .total-users .goal-chart .apexcharts-datalabels-group text:last-child {
    fill: #2b2b2b;
  }
  .total-users .goal-status ul li + li {
    margin-top: 30px;
    padding-top: 30px;
  }
  .total-users .goal-status ul li .goal-detail {
    margin-bottom: 30px;
  }
  .total-users .goal-status ul li .goal-detail h6 {
    margin-bottom: 16px;
  }
  .total-users .goal-status ul:last-child li {
    border-top: 1px solid #ecf3fa;
    margin-top: 27px;
    padding-top: 27px;
  }
  .total-users .goal-status .rate-card > ul li:last-child {
    border-top: none;
  }
  .total-users .goal-status > ul li:last-child {
    border-top: none;
  }
  .total-users .goal-status .goal-end-point ul li:first-child {
    border-top: none;
  }
  .total-users .goal-status .progress {
    background-image: linear-gradient(to right, transparent 15%, rgba(169, 39, 249, 0.1) 25%, transparent);
  }
  .total-users .goal-status .progress-gradient-primary {
    border-radius: 15%;
    background-image: linear-gradient(to right, transparent 25%, rgba(169, 39, 249, 0.1), var(--theme-default));
  }
  .total-users .goal-status .progress-gradient-primary:after {
    display: none;
  }
  
  .best-seller-table table {
    margin-bottom: 0px;
  }
  .best-seller-table table th,
  .best-seller-table table td {
    border: none;
  }
  .best-seller-table table thead tr th {
    padding-top: 0;
    font-size: 16px;
    font-weight: 500;
  }
  .best-seller-table table thead tr th h5 {
    font-size: 22px;
  }
  .best-seller-table table tr th,
  .best-seller-table table tr td {
    padding: 19px 10px;
  }
  .best-seller-table table tr th:first-child,
  .best-seller-table table tr td:first-child {
    padding-left: 0;
  }
  .best-seller-table table tr th:last-child,
  .best-seller-table table tr td:last-child {
    padding-right: 0;
  }
  .best-seller-table table tbody tr td {
    vertical-align: middle;
    transition: 0.5s;
    position: relative;
    font-weight: 500;
  }
  .best-seller-table table tbody tr td .flag-icon {
    font-size: 18px;
    position: relative;
    display: inline-block;
    width: 1.33333em;
    line-height: 1em;
  }
  .best-seller-table table tbody tr td p {
    font-size: 11px;
    color: rgba(43, 43, 43, 0.8);
    transition: 0.5s;
  }
  .best-seller-table table tbody tr td .label {
    padding: 8px 12px;
    color: #2b2b2b;
    border-radius: 10px;
    transition: 0.5s;
    font-size: 13px;
  }
  .best-seller-table table tbody tr td .badge {
    padding: 8px 12px;
    min-width: 100px;
    text-align: left;
  }
  .best-seller-table table tbody tr td .badge i, .best-seller-table table tbody tr td .badge svg {
    padding-top: 0;
    width: 14px;
    height: 14px;
    vertical-align: bottom;
  }
  .best-seller-table table tbody tr td .align-middle {
    position: relative;
  }
  .best-seller-table table tbody tr td .align-middle .status-circle {
    width: 10px;
    height: 10px;
    top: 2px;
    left: 32px;
    opacity: 0;
    transition: 0.5s;
  }
  .best-seller-table table tbody tr:last-child td {
    padding-bottom: 0;
  }
  
  #riskfactorchart path {
    stroke-width: 10px;
    stroke-dasharray: 10, 20;
    stroke-linecap: round;
  }
  #riskfactorchart #apexcharts-radialbarTrack-0 {
    stroke: #ecd8d8;
    stroke-linecap: round;
  }
  
  .our-product table thead tr th,
  .our-product table thead tr td {
    border: none;
    padding-top: 0;
    padding-bottom: 40px;
  }
  .our-product table thead tr th span,
  .our-product table thead tr td span {
    color: rgba(43, 43, 43, 0.3);
    font-weight: 500;
  }
  .our-product table tr td {
    padding: 20px 20px 0;
    border-top: none;
  }
  .our-product table tr td:first-child {
    padding-left: 0;
  }
  .our-product table tr td:last-child {
    padding-right: 0;
    text-align: right;
  }
  .our-product table tr td p {
    color: rgba(43, 43, 43, 0.6);
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .our-product table tr td .btn {
    font-size: 12px;
    font-weight: 500;
  }
  .our-product table tr td .flag-icon {
    font-size: 18px;
  }
  .our-product table tr td .badge {
    font-size: 13px;
    font-weight: 600;
    padding: 7px 14px;
  }
  .our-product table tr td .btn-secondary {
    background-color: #feecf0 !important;
    border-color: #feecf0 !important;
    color: var(--theme-secondary);
    font-weight: 700;
  }
  .our-product table tr:first-child td {
    padding-top: 0;
  }
  .our-product table tr:last-child td {
    padding-bottom: 0;
  }
  
  @media only screen and (max-width: 1660px) {
    .risk-col .goal-status.row > div,
  .risk-col .goal-status.row > ul {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .total-users .goal-status > ul li:first-child {
      border-top: none;
    }
  }
  @media only screen and (max-width: 1460px) {
    .offer-box .offer-slider .selling-slide .d-flex {
      height: 80%;
    }
    .offer-box .offer-slider .selling-slide .center-img {
      margin-top: -49px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .offer-box .offer-slider .selling-slide .d-flex {
      height: 81%;
    }
  }
  @media only screen and (max-width: 1366px) {
    .best-seller-table table tbody tr td .fa-check-circle {
      left: 2px;
    }
    .offer-box .offer-slider {
      height: 100%;
    }
    .offer-box .offer-slider .selling-slide .d-flex {
      height: 100%;
    }
    .offer-box .offer-slider .selling-slide .d-flex .left-content {
      padding-left: 15px;
    }
    .offer-box .offer-slider .selling-slide .center-img {
      margin-top: 0;
    }
  }
  @media (max-width: 1366px) and (min-width: 1200px) {
    .offer-box .offer-slider .selling-slide > div {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .offer-box .offer-slider .selling-slide > div:first-child {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .offer-box .offer-slider .selling-slide > div:nth-child(2) {
      flex: 0 0 28%;
      max-width: 28%;
    }
  }
  @media only screen and (max-width: 1305px) {
    .size-column > .xl-50 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  @media only screen and (max-width: 1199px) {
    .best-seller-table table tbody tr td {
      min-width: 150px;
    }
    .best-seller-table table tbody tr td .fa-check-circle {
      left: 18px;
    }
    .best-seller-table table tbody tr td:first-child {
      min-width: 200px;
    }
    .best-seller-table table tbody tr td:nth-child(4) {
      min-width: 100px;
    }
    .best-seller-table table tbody tr td:last-child {
      min-width: 100px;
    }
    .offer-box .offer-slider .selling-slide {
      padding-bottom: 40px;
    }
    .offer-box .offer-slider .selling-slide > div:nth-child(2) {
      order: -1;
    }
    .offer-box .offer-slider .selling-slide .center-img {
      text-align: center;
    }
  }
  @media only screen and (max-width: 991px) {
    .profit-card .progress-animate {
      margin-bottom: 15px;
      overflow: inherit;
      background-color: transparent;
    }
    .activity-timeline .media + .media {
      margin-top: 30px;
    }
    .activity-timeline .media .activity-line {
      top: 78px;
      height: 63%;
      left: 26px;
    }
    .monthly-visit {
      margin-top: 0;
    }
    .ecommerce-widgets .ecommerce-box {
      width: 50px;
      height: 50px;
    }
    .ecommerce-widgets .fa-heart {
      font-size: 20px;
      margin-top: 4px;
    }
    .offer-box .offer-slider .selling-slide {
      padding-bottom: 20px;
    }
    .offer-box .offer-slider .selling-slide .d-flex .left-content {
      padding-left: 0;
    }
    .offer-box .offer-slider .carousel-control-prev-icon,
  .offer-box .offer-slider .carousel-control-next-icon {
      right: 20px;
    }
    .best-seller-table table thead tr th h5 {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 767px) {
    .offer-box .offer-slider .selling-slide {
      padding-bottom: 20px;
    }
    .offer-box .offer-slider .selling-slide .d-flex .left-content,
  .offer-box .offer-slider .selling-slide .d-flex .right-content {
      text-align: center;
      width: 100%;
    }
    .offer-box .offer-slider .selling-slide .d-flex .right-content {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 575px) {
    .activity-timeline .media + .media {
      margin-top: 20px;
    }
    .offer-box .offer-slider .selling-slide .center-img img {
      width: 50%;
    }
    .offer-box .offer-slider .selling-slide h4 {
      font-size: 18px;
    }
    .offer-box .offer-slider .selling-slide .badge {
      font-size: 12px;
    }
    .risk-col .goal-status.row > div,
  .risk-col .goal-status.row > ul {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .total-users .goal-status > ul li:first-child {
      border-top: 1px solid #ecf3fa;
    }
    .total-users .goal-status ul:last-child li {
      padding-top: 20px;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 480px) {
    .our-product table tr td:first-child, .our-product table tr td:nth-child(2) {
      min-width: 160px;
    }
  }
  @media only screen and (max-width: 360px) {
    .profit-card span {
      display: block;
      margin-top: 5px;
    }
    .activity-timeline .media .activity-line {
      height: 67%;
    }
  }
  /**=====================
       67. Dashboard 2  CSS End
  ==========================**/
  /**=====================
  53. Dashboard CSS Start
  ==========================**/
  .texts-light {
    color: rgba(43, 43, 43, 0.54);
  }
  
  .form-control-plaintext {
    width: auto !important;
  }
  
  .badge-light-primary {
    background-color: white;
    color: #7366ff;
  }
  
  .badge-light-secondary {
    background-color: #feecf0;
    color: #f73164;
  }
  
  .badge-light-success {
    background-color: #c4f0b2;
    color: #51bb25;
  }
  
  .badge-light-danger {
    background-color: #f9dadd;
    color: #dc3545;
  }
  
  .badge-light-info {
    background-color: #f4e4fe;
    color: #a927f9;
  }
  
  .badge-light-light {
    background-color: white;
    color: #f4f4f4;
  }
  
  .badge-light-dark {
    background-color: #8490a9;
    color: #2c323f;
  }
  
  .badge-light-warning {
    background-color: #fefae7;
    color: #f8d62b;
  }
  
  .badge-light-primary {
    background-color: rgba(115, 102, 255, 0.1);
  }
  
  .badge-light-success {
    background-color: rgba(81, 187, 37, 0.2);
  }
  
  .table thead th {
    border-bottom: 2px solid #ecf3fa;
  }
  
  .profile-greeting {
    position: relative;
  }
  .profile-greeting .cartoon {
    margin: -35px -60px -20px 0;
  }
  .profile-greeting .card-body {
    padding: 40px;
  }
  .profile-greeting .badge-groups .badge.f-10 svg {
    padding-top: 0;
    width: 12px;
    height: 12px;
    vertical-align: bottom;
    stroke-width: 3px;
  }
  .profile-greeting .media {
    justify-content: space-between;
    position: relative;
  }
  .profile-greeting .media .badge-groups .badge {
    color: #7366ff;
    padding: 10px 14px !important;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 2%, rgba(115, 102, 255, 0.2) 3.07%, rgba(255, 255, 255, 0.2) 94.5%);
    border-radius: 5px;
    text-align: left;
  }
  .profile-greeting .greeting-user h4 {
    margin-bottom: 15px;
  }
  .profile-greeting .greeting-user p {
    font-weight: 500;
    margin-bottom: 15px;
    width: 72%;
  }
  .profile-greeting .greeting-user .profile-vector {
    filter: drop-shadow(15px 17px 12px rgba(0, 0, 0, 0.1));
  }
  .profile-greeting .greeting-user .right-circle i {
    padding: 5px 6px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
  }
  
  .earning-card.card .card-body {
    overflow: hidden;
  }
  .earning-card.card .card-body .chart-left {
    padding: 40px 0 40px 40px;
  }
  .earning-card.card .card-body .chart-right {
    padding: 0 40px;
  }
  .earning-card.card .card-body .chart-right .weekly-data {
    padding-bottom: 40px;
  }
  .earning-card.card .card-body .chart-right .p-tb {
    padding: 40px 0;
  }
  .earning-card.card .card-body .left_side_earning {
    margin-bottom: 30px;
  }
  .earning-card.card .card-body .left_side_earning:last-child {
    margin-bottom: 0;
  }
  .earning-card.card .card-body .left_side_earning h5 {
    line-height: 36px;
    font-weight: 500;
    margin: 0;
    font-size: 1rem;
  }
  .earning-card.card .card-body .left_side_earning p {
    font-size: 14px;
    color: rgba(43, 43, 43, 0.54);
  }
  .earning-card.card .card-body .left-btn a.btn {
    padding: 10px 16px;
  }
  .earning-card.card .card-body .inner-top-left ul li,
  .earning-card.card .card-body .inner-top-right ul li {
    line-height: 22px;
    color: rgba(43, 43, 43, 0.54);
    font-weight: 500;
    margin-left: 35px;
    letter-spacing: 1px;
  }
  .earning-card.card .card-body .inner-top-left ul li.active,
  .earning-card.card .card-body .inner-top-right ul li.active {
    color: var(--theme-default);
  }
  .earning-card.card .card-body .inner-top-left ul li:first-child {
    margin-left: 0;
  }
  .earning-card.card .card-body .inner-top-right ul li {
    position: relative;
  }
  .earning-card.card .card-body .inner-top-right ul li:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--theme-default);
    left: -40%;
    top: 27%;
  }
  .earning-card.card .card-body .inner-top-right ul li:last-child:before {
    background-color: #dc3545;
  }
  .earning-card.card .card-body .border-top {
    border-top: 1px solid #ecf3fa !important;
    padding: 38px 40px 37px;
  }
  .earning-card.card .card-body .earning-content {
    border-right: 1px solid #ecf3fa;
  }
  .earning-card.card .card-body .media .media-left {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: var(--theme-default);
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
  .earning-card.card .card-body .media .media-left i {
    font-size: 18px;
    transition: 0.3s all linear;
  }
  .earning-card.card .card-body .media .media-left:hover {
    animation: tada 1.5s ease infinite;
  }
  .earning-card.card .card-body .media .media-body h6 {
    margin-bottom: 2px;
    line-height: 24px;
  }
  .earning-card.card .card-body .media .media-body p {
    font-size: 14px;
    color: rgba(43, 43, 43, 0.54);
    font-family: Roboto, sans-serif;
  }
  
  .chart_data_left .card-body .chart-main .media,
  .chart_data_right .card-body .chart-main .media {
    padding: 45px 40px;
    border-right: 1px solid #ecf3fa;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart {
    align-items: center;
    margin-bottom: -25px;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar {
    position: relative;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container {
    height: 74px;
    width: 75px;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip {
    position: absolute;
    opacity: 0;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip .chartist-tooltip-value,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip .chartist-tooltip-value {
    font-size: 10px;
    padding: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip.tooltip-show,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container .chartist-tooltip.tooltip-show {
    opacity: 1;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container svg,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .flot-chart-container svg {
    position: absolute;
    bottom: 23px;
    width: 100%;
    height: 95%;
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-slice-donut,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-a .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-a .ct-slice-donut {
    stroke: var(--theme-default);
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-slice-donut,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart svg .ct-series-b .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 svg .ct-series-b .ct-slice-donut {
    stroke: rgba(115, 102, 255, 0.1);
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart .chartist-tooltip .chartist-tooltip-value,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 .chartist-tooltip .chartist-tooltip-value,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart .chartist-tooltip .chartist-tooltip-value,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart2 .chartist-tooltip .chartist-tooltip-value {
    background-color: var(--theme-default);
    box-shadow: 1px 1.732px 8px 0px rgba(115, 102, 255, 0.5);
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 .chartist-tooltip .chartist-tooltip-value,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 .chartist-tooltip .chartist-tooltip-value,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 .chartist-tooltip .chartist-tooltip-value,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 .chartist-tooltip .chartist-tooltip-value {
    background-color: var(--theme-secondary);
    box-shadow: 1px 1.732px 8px 0px rgba(247, 49, 100, 0.5);
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-slice-donut,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-a .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-a .ct-slice-donut {
    stroke: var(--theme-secondary);
  }
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-slice-donut,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-point,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-line,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-bar,
  .chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart1 svg .ct-series-b .ct-slice-donut,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-point,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-line,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-bar,
  .chart_data_right .card-body .chart-main .media .hospital-small-chart .small-bar .small-chart3 svg .ct-series-b .ct-slice-donut {
    stroke: rgba(247, 49, 100, 0.1);
  }
  .chart_data_left .card-body .chart-main .media .media-body .right-chart-content,
  .chart_data_right .card-body .chart-main .media .media-body .right-chart-content {
    margin-left: 10px;
  }
  .chart_data_left .card-body .chart-main .media .media-body .right-chart-content h4,
  .chart_data_right .card-body .chart-main .media .media-body .right-chart-content h4 {
    line-height: 1;
    font-weight: 500;
  }
  .chart_data_left .card-body .chart-main .media .media-body .right-chart-content span,
  .chart_data_right .card-body .chart-main .media .media-body .right-chart-content span {
    color: rgba(43, 43, 43, 0.54);
  }
  .chart_data_left .card-body .chart-main .media.border-none,
  .chart_data_right .card-body .chart-main .media.border-none {
    border-right: none !important;
  }
  
  .chart_data_right .card-body .media .right-chart-content {
    margin-left: 0 !important;
  }
  .chart_data_right .card-body .media .right-chart-content h4 {
    display: flex;
    align-items: center;
  }
  .chart_data_right .card-body .media .right-chart-content h4 .new-box {
    padding: 3px 5px;
    background-color: var(--theme-default);
    font-size: 10px;
    color: #fff;
    margin-left: 10px;
  }
  .chart_data_right .card-body .media .right-chart-content span {
    color: rgba(43, 43, 43, 0.54);
  }
  .chart_data_right .card-body .knob-block {
    margin-bottom: -5px;
  }
  .chart_data_right .card-body .knob-block input {
    font-weight: 400 !important;
    height: 40% !important;
    width: 40% !important;
    left: 50% !important;
    top: 2% !important;
    transform: translate(-50%) !important;
    margin-left: 0 !important;
    margin-top: 17px !important;
    color: #242934 !important;
  }
  .chart_data_right .card-body .knob-block canvas {
    width: 100%;
    height: 100%;
  }
  .chart_data_right .card-body .knob-block .chart-overflow {
    overflow: hidden;
    text-align: center;
  }
  
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .third-news-update span,
  .news span,
  .appointment-sec span {
    font-weight: 500;
  }
  .third-news-update .card-header-right-icon select,
  .news .card-header-right-icon select,
  .appointment-sec .card-header-right-icon select {
    background-color: rgba(115, 102, 255, 0.08) !important;
    border: none;
    color: var(--theme-default) !important;
    width: auto !important;
    height: 32px !important;
    border-radius: 10px;
    padding: 5px 10px;
    line-height: 50px !important;
    font-weight: 500;
    border: none;
    outline: none !important;
  }
  .third-news-update .card-header-right-icon select:hover,
  .news .card-header-right-icon select:hover,
  .appointment-sec .card-header-right-icon select:hover {
    background-color: rgba(115, 102, 255, 0.08) !important;
    color: var(--theme-default) !important;
  }
  .third-news-update .news-update,
  .news .news-update,
  .appointment-sec .news-update {
    padding: 20px 40px;
    border-bottom: 1px solid #ecf3fa;
  }
  .third-news-update .news-update span,
  .news .news-update span,
  .appointment-sec .news-update span {
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }
  .third-news-update .news-update .time-detail,
  .news .news-update .time-detail,
  .appointment-sec .news-update .time-detail {
    font-weight: 500;
    font-size: 10px;
    color: rgba(43, 43, 43, 0.54);
    margin-top: 8px;
  }
  .third-news-update .news-update .time-detail svg,
  .news .news-update .time-detail svg,
  .appointment-sec .news-update .time-detail svg {
    width: 12px;
    height: 12px;
    vertical-align: text-bottom;
    margin-right: 5px;
  }
  .third-news-update .news-update:last-child,
  .news .news-update:last-child,
  .appointment-sec .news-update:last-child {
    border: none !important;
  }
  .third-news-update .card-header-right-icon .dropdown .dropdown-toggle,
  .news .card-header-right-icon .dropdown .dropdown-toggle,
  .appointment-sec .card-header-right-icon .dropdown .dropdown-toggle {
    background-color: rgba(115, 102, 255, 0.08);
    color: #7366ff;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100px;
    text-align: left;
  }
  .third-news-update .card-header-right-icon .dropdown .dropdown-toggle:after,
  .news .card-header-right-icon .dropdown .dropdown-toggle:after,
  .appointment-sec .card-header-right-icon .dropdown .dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "\f107";
    border: none;
    font-family: "FontAwesome";
    font-size: 18px;
    margin: 0;
  }
  .third-news-update .card-header-right-icon .dropdown .dropdown-menu,
  .news .card-header-right-icon .dropdown .dropdown-menu,
  .appointment-sec .card-header-right-icon .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  .third-news-update .card-header-right-icon .dropdown .dropdown-menu .dropdown-item,
  .news .card-header-right-icon .dropdown .dropdown-menu .dropdown-item,
  .appointment-sec .card-header-right-icon .dropdown .dropdown-menu .dropdown-item {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid #efefef;
    background: #fff;
  }
  .third-news-update .card-header-right-icon .dropdown .dropdown-menu .dropdown-item:hover,
  .news .card-header-right-icon .dropdown .dropdown-menu .dropdown-item:hover,
  .appointment-sec .card-header-right-icon .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #fff;
  }
  .third-news-update .bottom-btn,
  .news .bottom-btn,
  .appointment-sec .bottom-btn {
    text-align: center;
  }
  .third-news-update .bottom-btn a,
  .news .bottom-btn a,
  .appointment-sec .bottom-btn a {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    text-transform: uppercase;
  }
  .third-news-update .news .card .card-footer,
  .news .news .card .card-footer,
  .appointment-sec .news .card .card-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .third-news-update .appointment .radar-chart .apexcharts-datalabel,
  .news .appointment .radar-chart .apexcharts-datalabel,
  .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
    font-size: 13px;
    font-weight: 600;
    fill: #2b2b2b;
  }
  .third-news-update .alert-sec .card.bg-img,
  .news .alert-sec .card.bg-img,
  .appointment-sec .alert-sec .card.bg-img {
    // background-image: url(../images/dashboard/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .third-news-update .alert-sec .card .card-header,
  .news .alert-sec .card .card-header,
  .appointment-sec .alert-sec .card .card-header {
    background-color: transparent !important;
    border-bottom: none !important;
    padding-bottom: 0;
  }
  .third-news-update .alert-sec .card .card-header .header-top h5,
  .news .alert-sec .card .card-header .header-top h5,
  .appointment-sec .alert-sec .card .card-header .header-top h5 {
    color: #fff;
  }
  .third-news-update .alert-sec .card .card-header .header-top p,
  .news .alert-sec .card .card-header .header-top p,
  .appointment-sec .alert-sec .card .card-header .header-top p {
    opacity: 35%;
    color: #fff;
  }
  .third-news-update .alert-sec .card .card-header .header-top .dot-right-icon,
  .news .alert-sec .card .card-header .header-top .dot-right-icon,
  .appointment-sec .alert-sec .card .card-header .header-top .dot-right-icon {
    color: #fff;
    width: 50px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third-news-update .alert-sec .card .card-header .header-top .dot-right-icon svg,
  .news .alert-sec .card .card-header .header-top .dot-right-icon svg,
  .appointment-sec .alert-sec .card .card-header .header-top .dot-right-icon svg {
    width: 30px;
    height: 30px;
  }
  .third-news-update .alert-sec .card .card-body .body-bottom h6,
  .news .alert-sec .card .card-body .body-bottom h6,
  .appointment-sec .alert-sec .card .card-body .body-bottom h6 {
    color: #fff;
    margin-bottom: 8px;
  }
  .third-news-update .alert-sec .card .card-body .body-bottom span,
  .news .alert-sec .card .card-body .body-bottom span,
  .appointment-sec .alert-sec .card .card-body .body-bottom span {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .notification .card .media {
    margin-bottom: 34px;
  }
  .notification .card .media .media-body p {
    color: var(--theme-secondary);
  }
  .notification .card .media .media-body p span {
    color: #2b2b2b;
    font-weight: 400;
  }
  .notification .card .media .media-body p .badge {
    color: #fff;
    margin-left: 10px;
    font-weight: 500;
  }
  .notification .card .media .media-body h6 {
    margin-top: 16px;
    margin-bottom: 5px;
    position: relative;
  }
  .notification .card .media .media-body h6 .dot-notification {
    float: right;
    width: 15px;
    height: 15px;
    background-color: var(--theme-secondary);
    border: 5px solid #fdc4d3;
    border-radius: 50%;
    animation: round 1.3s ease-in-out infinite;
  }
  @keyframes round {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .notification .card .media .media-body span {
    color: rgba(43, 43, 43, 0.54);
  }
  .notification .card .media .media-body .inner-img {
    width: 110px;
    height: 72px;
    background-color: #fff;
    border-radius: 5px;
  }
  .notification .card .media .media-body .inner-img:first-child {
    margin-right: 20px;
  }
  .notification .card .media .media-body .inner-img img {
    border-radius: 5px;
    transition: 0.3s all linear;
  }
  .notification .card .media .media-body .inner-img:hover img {
    animation: tada 1.5s ease-out infinite;
    transition: 0.3s all linear;
  }
  .notification .card .media:nth-child(2) .media-body h6 .dot-notification {
    background-color: #f8d62b;
    border: 5px solid #fdf3bf;
  }
  .notification .card .media:last-child {
    margin-bottom: 0 !important;
  }
  
  .appointment-table table tr td {
    position: relative;
    border-top: none;
    padding: 0;
  }
  .appointment-table table tr td .status-circle {
    top: 1px;
    left: 35px;
    border-width: 3px;
  }
  .appointment-table table tr td p,
  .appointment-table table tr td .btn {
    font-weight: 500;
    font-size: 12px;
  }
  .appointment-table table tr td .btn {
    padding: 4px 12px;
  }
  .appointment-table table tr td.img-content-box .font-roboto {
    color: rgba(43, 43, 43, 0.54);
    font-weight: 400;
  }
  
  .icon-box {
    width: 50px;
    height: 32px;
    background-color: rgba(115, 102, 255, 0.08);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-box i {
    color: var(--theme-default);
    font-size: 22px;
  }
  .icon-box svg {
    stroke: var(--theme-default);
  }
  
  .chat-default .media-dashboard {
    align-items: center;
  }
  .chat-default .card-body {
    padding-top: 0;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .img-profile {
    width: 40px;
    height: 40px;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .img-profile img {
    border-radius: 100%;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat {
    margin-left: 15px;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat .message-main span {
    background-color: var(--theme-default);
    border-radius: 30px;
    border-bottom-left-radius: 0;
    color: #fff;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat .sub-message {
    margin-top: 10px;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat .sub-message span {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 0;
  }
  .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat:last-child .sub-message span {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .chat-default .card-body.chat-box .right-side-chat {
    margin: 15px 0;
  }
  .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span {
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(25, 124, 207, 0.1);
    border-radius: 30px;
    color: var(--theme-default);
    opacity: 100%;
    border-bottom-right-radius: 0;
    text-align: center;
  }
  .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 4px;
    background: var(--theme-default);
    animation: wave 1.3s linear infinite;
    padding: 0;
  }
  .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot:nth-child(2) {
    animation-delay: -1.1s;
  }
  .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  @keyframes wave {
    0%, 60%, 100% {
      transform: initial;
    }
    30% {
      transform: translateY(-9px);
    }
  }
  .chat-default .card-body.chat-box .media .media-body .message-main span {
    display: inline-block;
    padding: 12px 18px;
    font-weight: 400;
  }
  .chat-default .card-body.chat-box .media p {
    font-size: 10px;
    margin-top: 14px;
    color: var(--theme-default);
  }
  .chat-default .card-body.chat-box .input-group {
    margin: 30px 0;
    margin-bottom: 0;
    align-items: center;
  }
  .chat-default .card-body.chat-box .input-group .form-control {
    border: none;
    border-radius: 0;
    border-left: 1px solid var(--theme-default);
    font-size: 16px;
  }
  .chat-default .card-body.chat-box .input-group .form-control::placeholder {
    color: #2b2b2b;
    font-weight: 500;
    font-size: 16px;
  }
  .chat-default .card-body.chat-box .input-group .form-control:focus {
    box-shadow: none;
  }
  .chat-default .card-body.chat-box .input-group .send-msg {
    width: 50px;
    height: 45px;
    background-color: rgba(115, 102, 255, 0.1);
    border-radius: 25% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat-default .card-body.chat-box .input-group .send-msg svg {
    width: 20px;
    color: var(--theme-default);
    vertical-align: middle;
    transform: rotate(45deg);
    margin-left: -3px;
  }
  
  .default-datepicker .datepicker-inline .datepicker {
    width: auto;
    background: #fff;
    box-shadow: none;
    padding: 0;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
    margin: 40px 0 0;
    padding: 15px 0;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name {
    color: #2b2b2b;
    font-size: 14px;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
    height: 55px;
    color: #2b2b2b;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-other-month- {
    color: #2b2b2b;
    opacity: 20%;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 100%;
    z-index: 0;
    letter-spacing: 2px;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-selected- {
    background: var(--theme-default);
    color: #fff;
    position: relative;
    box-shadow: 0px 0px 13px 0 rgba(115, 102, 255, 0.5);
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-current- {
    box-shadow: 0px 0px 15px -5px rgba(115, 102, 255, 0.5);
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-focus- {
    color: #fff;
    box-shadow: 0px 0px 13px 0 rgba(115, 102, 255, 0.5);
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker-cell .datepicker--cell-month.-current-.-selected- {
    background-color: var(--theme-default);
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav {
    border-bottom: none;
    padding: 0;
    text-transform: capitalize;
    margin-top: 0;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action {
    display: none;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
    color: #2b2b2b;
    font-size: 22px;
    font-weight: 600;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
    margin-left: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #2b2b2b;
  }
  
  @media only screen and (max-width: 360px) {
    .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-selected-:before {
      height: 36px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
      height: 36px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
      margin: 13px 0 13px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name {
      font-size: 12px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
      margin-left: 15px;
      font-size: 25px;
    }
  }
  .btn-light-secondary {
    background-color: rgba(var(--theme-secondary), 0.1);
    font-size: 12px;
    padding: 6px 12px;
    font-weight: 600;
  }
  
  .crm-activity > li + li {
    border-top: 1px solid #efefef;
    margin-top: 10px;
    padding-top: 10px;
  }
  .crm-activity > li + li h6 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .crm-activity > li h6 {
    font-size: 14px;
  }
  .crm-activity span {
    font-size: 40px;
  }
  .crm-activity ul.dates h6 {
    color: #2b2b2b;
  }
  .crm-activity ul.dates li {
    color: rgba(43, 43, 43, 0.54);
    font-size: 12px;
    display: inline-block;
    line-height: 1;
  }
  .crm-activity ul.dates li + li {
    border-left: 1px solid #efefef;
    padding-left: 10px;
    margin-left: 5px;
  }
  
  .fill-primary {
    fill: #7366ff;
  }
  
  .fill-secondary {
    fill: #f73164;
  }
  
  .fill-success {
    fill: #51bb25;
  }
  
  .fill-warning {
    fill: #f8d62b;
  }
  
  .fill-info {
    fill: #a927f9;
  }
  
  .fill-danger {
    fill: #dc3545;
  }
  
  .widget-feedback .apexcharts-canvas {
    margin: 0 auto;
  }
  .widget-feedback h6 {
    color: #6C757D;
  }
  .widget-feedback ul {
    display: flex;
    align-items: center;
    margin-top: 9px;
  }
  .widget-feedback ul li {
    width: 100%;
    text-align: center;
  }
  .widget-feedback ul li h5 {
    margin-bottom: 0;
    font-weight: 600;
  }
  .widget-feedback .feedback-top {
    margin-bottom: 20px;
  }
  
  .activity-media {
    margin: -12px 0;
  }
  .activity-media .media {
    padding: 12px 0;
  }
  .activity-media .media .recent-circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-top: 8px;
  }
  .activity-media .media .media-body {
    margin-left: 20px;
  }
  .activity-media .media .media-body h6 {
    line-height: 1.6;
  }
  .activity-media .media .media-body i {
    vertical-align: middle;
  }
  .activity-media .media .media-body svg {
    width: 12px;
    height: 12px;
    vertical-align: -2px;
  }
  .activity-media .media .media-body span {
    font-size: 10px;
    color: rgba(43, 43, 43, 0.54);
    font-weight: 500;
  }
  .activity-media .media + .media {
    border-top: 1px solid #EFEEFF;
  }
  
  .emplyoee-table .u-s-tb {
    padding: 20px;
  }
  
  .new-order h6, .new-order span {
    color: #6C757D;
  }
  .new-order .progress {
    margin-top: 20px;
  }
  .new-order ul {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }
  .new-order ul li {
    width: 100%;
  }
  .new-order ul li h5 {
    margin-bottom: 0;
  }
  
  .browser-table table thead th {
    border-top: 0;
    font-size: calc(16px + 4 * (100vw - 320px) / 1600);
    padding-top: 0;
    border-bottom-width: 1px;
  }
  .browser-table table tr th, .browser-table table tr td {
    vertical-align: middle;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    border-color: #EFEEFF;
  }
  .browser-table table tr td {
    font-size: calc(14px + 4 * (100vw - 320px) / 1600);
    color: #6C757D;
    padding: 20px;
  }
  .browser-table table tr td img {
    height: 46px;
    transition: 0.5s;
  }
  .browser-table table tr:hover td img {
    filter: grayscale(100%);
    transition: 0.5s;
  }
  .browser-table table tr:last-child td {
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .ecommerce-widget {
    box-shadow: none;
    border: 1px solid #f1f3ff !important;
    border-radius: 5px;
  }
  .ecommerce-widget .total-num {
    color: #2b2b2b;
    margin: 0;
    letter-spacing: 1px;
  }
  .ecommerce-widget .total-num span {
    color: #000;
  }
  .ecommerce-widget .row {
    align-items: center;
  }
  .ecommerce-widget .progress-showcase {
    margin-top: 30px;
  }
  .ecommerce-widget span {
    color: rgba(43, 43, 43, 0.54);
    margin: 0;
    cursor: pointer;
  }
  .ecommerce-widget .icon {
    color: #efefef;
    font-size: 40px;
  }
  .ecommerce-widget .flot-chart-container {
    height: 100px;
    padding: 0;
    margin: 0;
    border: transparent;
  }
  .ecommerce-widget .morris-default-style {
    display: none !important;
  }
  .ecommerce-widget svg {
    -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  }
  
  .static-top-widget {
    margin-bottom: 40px;
  }
  .static-top-widget h6 {
    font-weight: 400;
    color: rgba(43, 43, 43, 0.54);
  }
  .static-top-widget div.align-self-center svg {
    width: 30px;
    height: 30px;
  }
  .static-top-widget .media-body {
    align-self: center !important;
  }
  .static-top-widget .icon-bg {
    font-size: 30px;
  }
  
  .bg-info .media.static-top-widget .align-self-center {
    background-color: #a927f9;
  }
  
  .bg-primary .media.static-top-widget .align-self-center {
    background-color: var(--theme-default);
  }
  
  .bg-secondary .media.static-top-widget .align-self-center {
    background-color: var(--theme-secondary);
  }
  
  .bg-danger .media.static-top-widget .align-self-center {
    background-color: #dc3545;
  }
  
  .widget-joins .widget-card {
    border: 1px solid #EFEEFF;
    padding: 30px;
    border-radius: 18px;
    position: relative;
  }
  .widget-joins .widget-card .widget-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .widget-joins .widget-card .widget-icon i {
    font-size: 30px;
  }
  .widget-joins .widget-card .widget-icon svg {
    width: 20px;
    height: 20px;
  }
  .widget-joins .widget-card .icon-bg {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .widget-joins .widget-card .icon-bg svg {
    opacity: 0.05;
    fill: #6C757D;
  }
  .widget-joins .widget-card h6 {
    color: #6C757D;
    margin-left: 15px;
    margin-bottom: 0;
  }
  .widget-joins .widget-card .font-roboto {
    color: #6C757D;
  }
  .widget-joins .widget-card .font-roboto i {
    font-size: 20px;
    vertical-align: middle;
  }
  .widget-joins .widget-card .font-roboto span {
    font-weight: 600;
  }
  .widget-joins .media {
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .widget-joins .media .details {
    padding: 1px 0;
  }
  .widget-joins .media .media-body {
    text-align: left;
  }
  .widget-joins .media .media-body > span {
    color: rgba(43, 43, 43, 0.54);
  }
  .widget-joins .media .media-body svg {
    width: 40px;
    height: 40px;
  }
  
  .redial-social-widget {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin: 0 auto;
    background-image: linear-gradient(90deg, #f4f4f4 50%, transparent 50%, transparent), linear-gradient(90deg, #ecf3fa 50%, #f4f4f4 50%, #f4f4f4);
  }
  .redial-social-widget i {
    background-color: #fff;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .redial-social-widget.radial-bar-70 {
    background-image: linear-gradient(342deg, var(--theme-default) 50%, transparent 50%, transparent), linear-gradient(270deg, var(--theme-default) 50%, #f4f4f4 50%, #f4f4f4);
  }
  
  .social-widget-card h6 {
    color: rgba(43, 43, 43, 0.54);
    margin-bottom: 0;
  }
  .social-widget-card h5 {
    text-align: center;
    font-weight: bold;
  }
  .social-widget-card .media {
    align-items: center;
  }
  .social-widget-card .media .social-font {
    width: 70px;
    height: 70px;
    background-color: #F7F6FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .social-widget-card .media .media-body {
    margin-left: 20px;
  }
  .social-widget-card .media h4 {
    margin-bottom: 0;
  }
  
  .browser-widget:hover img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    transition: all 0.3s ease;
    transform: scale(1.1) rotate(5deg);
  }
  .browser-widget img {
    height: 80px;
    filter: grayscale(100%);
    transform: scale(1) rotate(0deg);
    transition: all 0.3s ease;
  }
  .browser-widget .media-body {
    text-align: center;
    column-count: 3;
    column-rule: 1px solid #f4f4f4;
  }
  .browser-widget .media-body p {
    margin-bottom: 5px;
  }
  .browser-widget .media-body span {
    margin-bottom: 0;
    color: rgba(43, 43, 43, 0.54);
  }
  .browser-widget .media-body h4 {
    color: #2b2b2b;
    margin-bottom: 0;
    font-size: 18px;
  }
  .browser-widget .media-body h4 span {
    color: #333333;
  }
  
  .testimonial #owl-carousel-testimonial .owl-stage-outer .owl-stage .owl-item .item img,
  .testimonial #owl-carousel-testimonial-rtl .owl-stage-outer .owl-stage .owl-item .item img {
    border-radius: 100%;
  }
  .testimonial i {
    font-size: 60px;
    color: rgba(43, 43, 43, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.1;
    z-index: -1;
  }
  .testimonial p {
    color: rgba(43, 43, 43, 0.54);
    font-style: italic;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .testimonial h5 {
    color: #2b2b2b;
    padding-top: 10px;
  }
  .testimonial span {
    color: rgba(43, 43, 43, 0.54);
  }
  .testimonial img {
    margin: 0 auto;
  }
  
  .hour, .min, .mobile-clock-widget .sec {
    position: absolute;
    width: 24px;
    height: 208px;
    top: 0;
    left: 88px;
    margin-top: -30px;
    margin-left: -25px;
  }
  
  .cal-date-widget .datepicker {
    padding: 40px;
    border-radius: 20px;
    box-shadow: none;
    width: auto;
  }
  .cal-date-widget .datepicker .datepicker--nav {
    justify-content: center;
    border-bottom: none;
  }
  .cal-date-widget .datepicker .datepicker--nav-action {
    display: none;
  }
  .cal-date-widget .datepicker .datepicker--day-name, .cal-date-widget .datepicker .datepicker--nav, .cal-date-widget .datepicker .datepicker--nav-title i {
    color: #2C323F;
  }
  .cal-date-widget .datepicker .datepicker--days-names {
    margin-top: 18px;
  }
  .cal-date-widget .datepicker .datepicker--cell-day.-other-month-,
  .cal-date-widget .datepicker .datepicker--cell-year.-other-decade- {
    opacity: 0.5;
  }
  .cal-date-widget .datepicker .datepicker--cell-day {
    height: 43px;
    border-radius: 25px;
  }
  .cal-date-widget .cal-info h2 {
    font-size: 100px;
    color: #ecf3fa;
  }
  
  .weather-widget-two {
    // background: url(../images/other-images/wallpaper.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: relative;
    color: #fff;
  }
  .weather-widget-two::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.3;
    filter: blur(30px);
    background: #1F3DD8;
    border-radius: 20px;
    top: 0;
  }
  .weather-widget-two .card-body {
    position: relative;
    z-index: 1;
  }
  .weather-widget-two svg path.climacon_component-stroke {
    fill: #fff;
  }
  .weather-widget-two .widget-list .climacon_component-fill {
    fill: #fff;
  }
  .weather-widget-two .widget-list svg.climacon {
    width: 70px;
    height: 70px;
  }
  .weather-widget-two .widget-list ul li {
    padding: 16px 0;
  }
  .weather-widget-two .widget-list ul li + li {
    border-top: 1px solid rgba(239, 238, 255, 0.2);
  }
  .weather-widget-two .widget-list .media {
    align-items: center;
  }
  .weather-widget-two .widget-list .media .media-body {
    margin-left: 30px;
  }
  .weather-widget-two .widget-list .media .media-body h5 {
    margin-bottom: 0;
  }
  .weather-widget-two .top-bg-whether {
    position: absolute;
    top: -50px;
    right: -28px;
    opacity: 0.1;
  }
  .weather-widget-two .top-bg-whether svg {
    width: 200px;
    height: 200px;
  }
  .weather-widget-two .num {
    font-weight: 600;
  }
  .weather-widget-two .climacon_component-stroke {
    stroke: #fff;
  }
  .weather-widget-two .bottom-whetherinfo svg {
    width: 150px;
    height: 150px;
    opacity: 0.1;
    color: #fff;
    position: relative;
    top: 0;
    left: -35px;
  }
  .weather-widget-two .bottom-whetherinfo .whether-content {
    text-align: right;
    position: relative;
    top: 5px;
    color: #fff;
  }
  
  .mobile-clock-widget {
    position: relative;
    padding: 30px;
    z-index: 1;
    text-align: center;
  }
  .mobile-clock-widget::after {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #19191B;
    opacity: 0.3;
    border-radius: 10px;
  }
  .mobile-clock-widget .bg-svg {
    position: absolute;
    top: -4px;
    left: -25px;
  }
  .mobile-clock-widget .bg-svg svg {
    width: 150px;
    height: 150px;
    opacity: 0.08;
  }
  .mobile-clock-widget .clock {
    padding: 0;
    position: relative;
    list-style: none;
    margin: 0 auto;
    height: 150px;
    width: 150px;
    display: block;
    // background: url(../images/other-images/clock-face.png) 5% center no-repeat;
    background-size: cover;
  }
  .mobile-clock-widget .sec {
    // background: url(../images/sec.svg);
    z-index: 3;
  }
  .mobile-clock-widget .min {
    // background: url(../images/min.svg);
    z-index: 2;
  }
  .mobile-clock-widget .hour {
    // background: url(../images/hour.svg);
    z-index: 1;
  }
  
  .mobile-clock-widget #date {
    margin-top: 30px;
  }
  
  .flot-chart-container {
    height: 250px;
  }
  
  .calender-widget {
    // background: url(../images/other-images/calender-widget.jpg);
    background-size: cover;
    height: 560px;
    background-repeat: no-repeat;
    position: relative;
  }
  .calender-widget .card-body {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  .calender-widget .cal-date {
    position: absolute;
    top: 40px;
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(44, 50, 63, 0.5);
    border-radius: 20px;
  }
  .calender-widget .cal-date h5 {
    margin-bottom: 0;
    line-height: 1.5;
    padding: 17px;
    font-weight: 600;
    color: #fff;
  }
  .calender-widget p {
    color: #fff;
  }
  .calender-widget .cal-desc h6 {
    padding-bottom: 6px;
  }
  .calender-widget .cal-desc span {
    line-height: 1.6;
  }
  
  .contact-form .btn {
    padding: 10px 30px;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .chart-widget-top #chart-widget1,
  .chart-widget-top #chart-widget2,
  .chart-widget-top #chart-widget3 {
    margin-bottom: -14px;
  }
  .chart-widget-top #chart-widget1 .apexcharts-xaxistooltip,
  .chart-widget-top #chart-widget2 .apexcharts-xaxistooltip,
  .chart-widget-top #chart-widget3 .apexcharts-xaxistooltip {
    display: none;
  }
  .chart-widget-top span {
    color: #6C757D;
  }
  
  .bar-chart-widget .apexcharts-legend {
    bottom: 0 !important;
  }
  .bar-chart-widget .apexcharts-legend .apexcharts-legend-series {
    margin: 0 10px !important;
  }
  .bar-chart-widget .apexcharts-legend .apexcharts-legend-marker {
    margin-right: 5px;
  }
  .bar-chart-widget .top-content {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .bar-chart-widget .earning-details {
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    letter-spacing: 1px;
  }
  .bar-chart-widget .earning-details i {
    font-size: 230px;
    position: absolute;
    opacity: 0.1;
    right: -30px;
    top: 0;
  }
  .bar-chart-widget .earning-details i:hover {
    transform: rotate(-5deg) scale(1.1);
    transition: all 0.3s ease;
  }
  .bar-chart-widget .num {
    font-weight: 600;
  }
  .bar-chart-widget .num .color-bottom {
    color: #000;
  }
  
  .skill-chart {
    margin-bottom: -48px;
  }
  .skill-chart .apexcharts-legend .apexcharts-legend-series span {
    display: block;
  }
  .skill-chart .apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text {
    margin: 10px 0 20px;
  }
  
  .progress-chart {
    margin: -11px 0 -20px;
  }
  .progress-chart .apexcharts-canvas svg path, .progress-chart .apexcharts-canvas svg rect {
    clip-path: inset(1% 0% 0% 0% round 1rem);
  }
  
  .bottom-content span {
    color: rgba(43, 43, 43, 0.54);
  }
  .bottom-content .block-bottom {
    display: block;
  }
  
  .user-status table tbody tr td {
    vertical-align: middle;
  }
  .user-status table tbody tr td .d-inline-block {
    margin-top: 11px;
  }
  .user-status table tbody tr td .image-sm-size img {
    width: 41px;
  }
  .user-status table tbody tr:last-child td {
    padding-bottom: 0;
  }
  .user-status table thead tr th {
    border-top: 0;
    font-size: 16px;
    color: #2c323f;
    font-weight: 500;
    padding-top: 0;
  }
  
  @keyframes am-moving-dashes {
    100% {
      stroke-dashoffset: -30px;
    }
  }
  @-webkit-keyframes am-pulsating {
    0% {
      stroke-opacity: 1;
      stroke-width: 0;
    }
    100% {
      stroke-opacity: 0;
      stroke-width: 50px;
    }
  }
  @keyframes am-pulsating {
    0% {
      stroke-opacity: 1;
      stroke-width: 0;
    }
    100% {
      stroke-opacity: 0;
      stroke-width: 50px;
    }
  }
  @-webkit-keyframes am-draw {
    0% {
      stroke-dashoffset: 500%;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes am-draw {
    0% {
      stroke-dashoffset: 500%;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  .serial-chart .chart-container {
    width: 100%;
    height: 500px;
  }
  .serial-chart .chart-container .lastBullet {
    animation: am-pulsating 1s ease-out infinite;
  }
  .serial-chart .chart-container .amcharts-graph-column-front {
    transition: all 0.3s 0.3s ease-out;
  }
  .serial-chart .chart-container .amcharts-graph-column-front:hover {
    fill: var(--theme-secondary);
    stroke: var(--theme-secondary);
    transition: all 0.3s ease-out;
  }
  .serial-chart .chart-container .amcharts-graph-g3 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: am-draw 40s;
  }
  .serial-chart .chart-container .amcharts-graph-g2 .amcharts-graph-stroke {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    animation: am-moving-dashes 1s linear infinite;
  }
  
  .chart-container .apexcharts-xaxis-label, .chart-container .apexcharts-yaxis-label {
    font-size: 14px !important;
    color: #6C757D !important;
  }
  .chart-container .apexcharts-xaxis-annotations rect {
    clip-path: inset(1% 0% 0% 0% round 0.2rem);
  }
  
  .crypto-chart {
    margin-left: -20px;
  }
  .crypto-chart .apexcharts-tooltip-title {
    display: none;
  }
  .crypto-chart .apexcharts-tooltip-series-group:last-child, .crypto-chart .apexcharts-tooltip-series-group.active {
    padding-bottom: 0;
  }
  
  .speed-chart .chart-container {
    width: 100%;
    height: 530px;
  }
  .speed-chart .content {
    margin-top: -200px;
    margin-bottom: 20px;
  }
  .speed-chart .content h4 {
    font-weight: 600;
    padding-top: 2px;
  }
  
  .status-widget svg {
    width: 20px;
    height: 20px;
    vertical-align: text-top;
  }
  .status-widget .card .card-header h5 {
    line-height: 1.38;
  }
  .status-widget .status-chart {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  
  .small-chart-widget .chart-container {
    height: 311px;
    padding: 0;
    margin: 0;
    border: none;
  }
  
  .small-chart-widget .card .card-body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .donut-chart-widget .chart-container {
    height: 300px;
    border: 0;
    margin: 0;
    padding: 0;
  }
  
  .status-details h4 {
    font-weight: 600;
  }
  .status-details h4 span {
    color: inherit;
  }
  .status-details span {
    color: rgba(43, 43, 43, 0.54);
  }
  
  .status-chart .chart-container {
    height: 200px;
    border: 0;
    padding: 0;
    margin: 0;
  }
  
  .map-chart .chart-container {
    width: 100%;
    height: 500px;
  }
  
  .serial-chart {
    width: 100%;
  }
  
  .live-products,
  .turnover,
  .uses,
  .monthly {
    height: 300px;
  }
  
  .live-products .ct-series-a .ct-area,
  .live-products .ct-series-a .ct-point,
  .live-products .ct-series-a .ct-line,
  .live-products .ct-series-a .ct-bar,
  .live-products .ct-series-a .ct-slice-donut {
    stroke: rgba(255, 255, 255, 0.77);
  }
  .live-products .ct-label {
    fill: #fff;
    color: #fff;
  }
  .live-products .ct-grid {
    stroke: rgba(255, 255, 255, 0.7);
  }
  
  .turnover .ct-series-a .ct-area,
  .turnover .ct-series-a .ct-point,
  .turnover .ct-series-a .ct-line,
  .turnover .ct-series-a .ct-bar,
  .turnover .ct-series-a .ct-slice-donut,
  .uses .ct-series-a .ct-area,
  .uses .ct-series-a .ct-point,
  .uses .ct-series-a .ct-line,
  .uses .ct-series-a .ct-bar,
  .uses .ct-series-a .ct-slice-donut,
  .monthly .ct-series-a .ct-area,
  .monthly .ct-series-a .ct-point,
  .monthly .ct-series-a .ct-line,
  .monthly .ct-series-a .ct-bar,
  .monthly .ct-series-a .ct-slice-donut {
    stroke: rgb(255, 255, 255);
    fill: transparent;
  }
  .turnover .ct-series-b .ct-area,
  .turnover .ct-series-b .ct-point,
  .turnover .ct-series-b .ct-line,
  .turnover .ct-series-b .ct-bar,
  .turnover .ct-series-b .ct-slice-donut,
  .uses .ct-series-b .ct-area,
  .uses .ct-series-b .ct-point,
  .uses .ct-series-b .ct-line,
  .uses .ct-series-b .ct-bar,
  .uses .ct-series-b .ct-slice-donut,
  .monthly .ct-series-b .ct-area,
  .monthly .ct-series-b .ct-point,
  .monthly .ct-series-b .ct-line,
  .monthly .ct-series-b .ct-bar,
  .monthly .ct-series-b .ct-slice-donut {
    stroke: rgba(255, 255, 255, 0.7);
    fill: transparent;
  }
  .turnover .ct-series-c .ct-area,
  .turnover .ct-series-c .ct-point,
  .turnover .ct-series-c .ct-line,
  .turnover .ct-series-c .ct-bar,
  .turnover .ct-series-c .ct-slice-donut,
  .uses .ct-series-c .ct-area,
  .uses .ct-series-c .ct-point,
  .uses .ct-series-c .ct-line,
  .uses .ct-series-c .ct-bar,
  .uses .ct-series-c .ct-slice-donut,
  .monthly .ct-series-c .ct-area,
  .monthly .ct-series-c .ct-point,
  .monthly .ct-series-c .ct-line,
  .monthly .ct-series-c .ct-bar,
  .monthly .ct-series-c .ct-slice-donut {
    stroke: rgba(255, 255, 255, 0.4);
    fill: transparent;
  }
  .turnover .ct-label,
  .uses .ct-label,
  .monthly .ct-label {
    fill: #fff;
    color: #fff;
  }
  .turnover .ct-grid,
  .uses .ct-grid,
  .monthly .ct-grid {
    stroke: rgba(255, 255, 255, 0.7);
  }
  
  #draggableMultiple .ui-sortable-handle .card {
    cursor: move;
  }
  
  .tilt-showcase ul li .line {
    padding-top: 10px;
  }
  .tilt-showcase .pre-mt {
    margin-top: 5px;
  }
  
  .wow-title h5 {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .wow-title h5 .badge {
    -webkit-text-fill-color: #fff;
  }
  
  .alert-center {
    display: flex !important;
    align-items: center;
  }
  
  .box-shadow-title .sub-title {
    margin: 30px 0;
  }
  
  .hovercard .info .social-media ul li {
    padding-top: 3px;
  }
  .hovercard .cardheader {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .sticky-header-main .card .card-header h5 {
    line-height: 35px;
  }
  .sticky-header-main .card .card-header h5 a {
    color: #fff;
    -webkit-text-fill-color: #fff;
  }
  
  .custom-radio-ml {
    margin-left: 3px;
  }
  
  .daterangepicker .ltr tr td.active {
    color: #fff;
  }
  
  .twitter-typeahead {
    display: block !important;
  }
  
  span.twitter-typeahead .league-name {
    font-size: 16px;
    padding: 6px 10px 0;
  }
  span.twitter-typeahead .tt-menu {
    float: left;
    width: 100%;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  span.twitter-typeahead .tt-suggestion {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid #efefef;
    background: #fff;
    cursor: pointer;
  }
  span.twitter-typeahead .tt-suggestion:hover, span.twitter-typeahead .tt-suggestion:focus {
    background-color: #fff;
  }
  span.twitter-typeahead .tt-suggestion.active, span.twitter-typeahead .tt-suggestion:active {
    color: #fff;
    background-color: var(--theme-default);
  }
  
  #scrollable-dropdown-menu .twitter-typeahead .tt-menu .tt-dataset {
    max-height: 100px;
    overflow-y: auto;
  }
  
  .current-sale-container .apexcharts-xaxistooltip {
    color: var(--theme-default);
    background: rgba(115, 102, 255, 0.1);
    border: 1px solid var(--theme-default);
  }
  .current-sale-container .apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: var(--theme-default);
  }
  .current-sale-container .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: rgba(115, 102, 255, 0.1);
    color: var(--theme-default);
  }
  
  .card-block .table-responsive .table caption {
    padding-left: 10px;
  }
  .card-block .table-responsive .table-bordered td {
    vertical-align: middle;
  }
  .card-block .table-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .card-block .default-checkbox-align #checkbox1 {
    margin-right: 10px;
  }
  .card-block .default-checkbox-align #radio {
    margin-right: 5px;
  }
  
  #example-style-3_wrapper #example-style-3 tfoot {
    border-top: 2px solid #efefef;
  }
  
  .chart-vertical-center {
    display: flex;
    justify-content: center;
  }
  .chart-vertical-center #myDoughnutGraph,
  .chart-vertical-center #myPolarGraph {
    width: auto !important;
  }
  
  .cke_focus {
    padding: 15px;
    margin-top: 13px;
  }
  
  #editor .ace_scroller .ace_content .ace_layer .ace_print-margin {
    visibility: hidden !important;
  }
  
  .helper-classes {
    padding: 30px;
    margin-bottom: 0;
    white-space: pre-line;
  }
  
  .starter-kit-fix .page-body {
    margin-bottom: 52px;
  }
  
  .starter-main .card-body p {
    font-size: 14px;
  }
  .starter-main .card-body ul {
    padding-left: 30px;
    list-style-type: disc;
    margin-bottom: 15px;
  }
  .starter-main .card-body h5 {
    font-size: 18px;
  }
  .starter-main .card-body pre {
    white-space: pre-line;
    padding: 30px;
  }
  .starter-main .card-body .alert-primary.inverse:before {
    top: 32px;
  }
  .starter-main .alert {
    background-color: rgba(68, 102, 242, 0.2) !important;
  }
  .starter-main .alert i {
    display: flex;
    align-items: center;
  }
  
  footer.blockquote-footer {
    bottom: unset;
  }
  
  @media screen and (min-width: 1440px) and (max-width: 1660px) {
    .morning-sec {
      order: -2;
    }
    .calendar-sec {
      order: -1;
    }
    .chart_data_right.second {
      display: block !important;
    }
  }
  @media only screen and (min-width: 1367px) and (max-width: 1439px) {
    .morning-sec {
      order: -2;
    }
    .calendar-sec {
      order: -1;
    }
    .chart_data_right.second {
      display: block !important;
    }
    .chart_data_left .row > div .media .right-chart-content {
      margin-left: 10px !important;
    }
    .chart_data_left .chart_data_right.second {
      display: block !important;
    }
  }
  @media screen and (max-width: 1366px) {
    .custom-profile .card-social .social-link {
      width: 45px;
      height: 45px;
    }
    .custom-profile .card-social .social-link img {
      width: 18px;
      height: 18px;
    }
    .static-top-widget svg {
      width: 30px;
      height: 30px;
    }
    .chart_data_left .row > div:first-child .media {
      padding-left: 40px;
    }
    .chart_data_left .row > div:last-child .media {
      padding-right: 40px;
    }
    .chart_data_left .card-body .chart-main .media {
      padding: 40px 10px;
    }
    .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-left: 4px;
    }
    .chart_data_right.second {
      display: block !important;
    }
    .earning-card {
      overflow: hidden;
    }
    .earning-card .card-body > .row > div {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .earning-card .card-body > .row > div .chart-left {
      padding: 40px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning {
      margin-bottom: 0;
      display: inline-block;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning + .left_side_earning {
      flex: 0 0 33%;
      max-width: 33%;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:first-child {
      flex: 0 0 50%;
      max-width: 50%;
      order: -2;
      margin-bottom: 30px;
    }
    .earning-card .card-body > .row > div .chart-left .left-btn {
      flex: 0 0 50%;
      max-width: 50%;
      order: -1;
      text-align: right;
    }
    .earning-card .card-body > .row .chart-right {
      padding-bottom: 40px;
    }
    .earning-card.card .card-body .earning-content {
      border-right: none;
      border-bottom: 1px solid #ecf3fa;
    }
    .morning-sec {
      order: -2;
    }
    .calendar-sec {
      order: -1;
    }
    .calendar-sec .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
      margin: 20px 0 19px;
      padding: 12px 0;
    }
    .appointment .radar-chart .apexcharts-datalabel {
      font-size: 10px !important;
    }
  }
  @media only screen and (max-width: 1199px) {
    .widget-joins .widget-card .widget-icon i {
      font-size: 25px;
    }
    .widget-joins .widget-card .icon-bg svg {
      width: 80px;
      height: 80px;
    }
    .profile-greeting .cartoon {
      margin-right: 0;
    }
    .morning-sec {
      order: unset;
    }
    .calendar-sec {
      order: unset;
    }
    .earning-card {
      margin-top: 0;
    }
    .earning-card .card-body > .row > div .row > div:last-child {
      padding-left: 0;
    }
    .earning-card .card-body > .row > div .row > div .current-sale-container {
      padding-left: 15px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning + .left_side_earning {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(3) {
      text-align: right;
      margin-bottom: 30px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(4) {
      margin-bottom: 0;
    }
    .earning-card .card-body > .row > div .media {
      margin-left: 0;
    }
    .earning-card .card-body > .row .border-top > div:last-child {
      margin-top: 40px;
    }
    .chart_data_left .card-body .chart-main .media {
      padding: 40px;
    }
    .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-left: 30px;
    }
    .chart_data_left .row > div + div + div .media {
      padding-top: 0 !important;
    }
    .appointment-sec .alert-sec {
      margin-top: 0;
    }
    .chart_data_right.second {
      display: none !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .calender-widget {
      height: 450px;
    }
    .earning-card {
      margin-top: 0;
    }
    .earning-card.card .card-body .border-top {
      padding: 20px;
    }
    .earning-card.card .card-body .chart-right {
      padding: 0 20px;
    }
    .earning-card.card .card-body .chart-right .p-tb {
      padding: 20px 0;
    }
    .earning-card .card-body > .row .border-top > div:last-child {
      margin-top: 20px;
    }
    .earning-card .card-body > .row .chart-right {
      padding-bottom: 20px;
    }
    .earning-card .card-body > .row > div .chart-left {
      padding: 20px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning {
      margin-bottom: 20px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(3) {
      margin-bottom: 20px;
    }
    .chart_data_left .card-body .chart-main .media {
      padding: 20px;
    }
    .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-left: 20px;
    }
    .chart_data_left .row > div .media:first-child {
      padding-left: 20px;
    }
    .chart_data_left .row > div .media:last-child {
      padding-right: 20px;
    }
    .news .card .card-body .news-update {
      padding: 20px;
    }
    .notification .card .card-body .media {
      margin-bottom: 20px;
    }
    .notification .card .card-body .media .media-body p {
      margin: 0;
    }
    .notification .card .card-body .media .media-body h6 {
      margin-top: 10px;
    }
    .chat-default .card-body.chat-box .input-group {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .morning-sec .profile-greeting .greeting-user .profile-vector {
      width: 70px;
      margin: 0 auto;
    }
    .morning-sec .profile-greeting .greeting-user h4 {
      margin-bottom: 10px;
    }
    .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row ul li + li {
      margin-left: 25px;
    }
    .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row .inner-top-right ul li {
      margin-left: 30px;
    }
    .chart_data_left .card-body .chart-main .media {
      border-right: none;
    }
    .chart_data_left .card-body .chart-main > div:nth-child(3) {
      border-right: 1px solid #ecf3fa;
    }
    .chart_data_left .card-body .chart-main > div:first-child {
      border-right: 1px solid #ecf3fa;
    }
    .footer-fix .pull-right {
      float: none !important;
      text-align: center !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .profile-greeting .greeting-user p {
      width: 100%;
    }
    .profile-greeting .cartoon {
      margin-bottom: -15px;
    }
    .chart_data_left .card .card-body .row > div .media {
      padding: 15px;
    }
    .chart_data_left .card .card-body .row > div:first-child .media {
      padding-top: 15px !important;
    }
    .chart_data_left .card .card-body .row:last-child .media {
      padding-top: 0 !important;
    }
    .earning-card .card-body > .row > div .chart-left {
      padding: 15px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning {
      margin-bottom: 15px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning + .left_side_earning, .earning-card .card-body > .row > div .chart-left .left_side_earning:first-child {
      flex: 0 0 100%;
      max-width: 100%;
      order: unset;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:first-child {
      margin-bottom: 15px;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(4), .earning-card .card-body > .row > div .chart-left .left_side_earning:last-child {
      margin-bottom: 0;
    }
    .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(3) {
      text-align: left;
    }
    .earning-card .card-body > .row > div .chart-left .left-btn {
      order: unset;
      text-align: left;
      margin-top: 15px;
    }
    .earning-card .card-body > .row .chart-right {
      padding: 15px;
    }
    .earning-card .card-body > .row .chart-right .p-tb {
      padding-top: 0;
      padding-bottom: 15px;
    }
    .earning-card .card-body > .row .border-top > div + div {
      margin-top: 15px;
      padding: 0;
    }
    .earning-card .card-body > .row .border-top > div + div:last-child {
      margin-top: 15px;
    }
    .earning-card .card-body .inner-top-right {
      margin-top: 15px;
    }
    .earning-card.card .card-body .border-top {
      padding: 15px;
    }
    .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row ul li + li {
      margin-left: 15px;
    }
    .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row .inner-top-right ul li + li {
      margin-left: 28px;
    }
    .notification .card .card-body .media {
      margin-bottom: 15px;
    }
    .news .card .card-body > .news-update {
      padding: 15px;
    }
    .appointment-sec .alert-sec .card-body {
      padding-top: 0 !important;
    }
    .media-dropdown {
      display: block;
    }
    .media-dropdown .media-body {
      margin-bottom: 15px;
    }
    .chat-sec .chat-default .card-body.chat-box .media .media-body .message-main span {
      font-size: 13px;
      width: 95%;
    }
    .chat-sec .chat-default .card-body.chat-box .media.right-side-chat .media-body .message-main span {
      width: 100%;
    }
    .chat-sec .chat-default .card-body.chat-box .media.right-side-chat .media-body .message-main span span {
      width: 5px;
    }
    .chat-sec .chat-default .card-body.chat-box .media.right-side-chat p {
      margin-top: 10px;
    }
    .chat-default .card-body.chat-box .input-group .form-control {
      font-size: 14px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
      font-size: 17px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
      font-size: 17px;
      margin-left: 10px;
    }
    .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell {
      font-size: 13px;
    }
    .calendar-sec .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
      margin: 10px 0 10px;
      padding: 10px 0;
    }
  }
  @media only screen and (max-width: 360px) {
    .footer {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .footer p {
      font-size: 12px;
    }
    .appointment-table table tr td {
      min-width: 103px;
    }
    .appointment-table table tr td:first-child {
      min-width: 63px;
    }
    .appointment-table table tr td:nth-child(2) {
      min-width: 120px;
    }
  }
  /**=====================
  53. Dashboard CSS Ends
  ==========================**/
  /**=====================
      33.  Ecommerce  CSS Start
  ==========================**/
  /**====== Product Start ======**/
  .toggle-data {
    cursor: pointer;
  }
  
  .filter-block ul li {
    padding-top: 10px;
    opacity: 0.5;
  }
  
  .collection-filter-block svg {
    width: 50px;
    height: 25px;
    stroke: var(--theme-default);
    fill: #e8e5ff;
    margin-right: 10px;
  }
  .collection-filter-block h5 {
    margin-bottom: 0;
    font-size: 16px;
  }
  .collection-filter-block p {
    font-size: 13px;
  }
  .collection-filter-block .media-body {
    opacity: 0.5;
  }
  .collection-filter-block li + li {
    margin-top: 15px;
  }
  .collection-filter-block ul.pro-services li svg {
    width: auto;
  }
  
  .qty-box .btn {
    background-color: transparent !important;
    border-color: #ecf3fa !important;
    padding: 8px 12px;
  }
  .qty-box .btn i {
    color: #898989;
  }
  .qty-box .btn svg {
    width: 14px !important;
    stroke: #2c323f;
  }
  .qty-box span {
    padding: 0 4px;
  }
  .qty-box .input-group {
    background-color: rgba(44, 50, 63, 0.1);
  }
  .qty-box input {
    background-color: rgba(44, 50, 63, 0.1);
    color: #2c323f;
    max-width: 44px;
    padding: 0;
    text-align: center;
    border: none;
    max-height: 37px;
  }
  
  .product-social li a {
    color: #898989;
    font-size: 15px;
    border: 1px solid #cccccc;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    display: inline-block;
    text-align: center;
    line-height: 2.3;
    transition: all 0.3s ease;
  }
  .product-social li a:hover {
    border-color: #7366ff;
    color: #7366ff;
    background-color: rgba(115, 102, 255, 0.1);
    transition: all 0.3s ease;
  }
  .product-social li:nth-child(n+2) {
    margin-left: 10px;
  }
  
  .product-sidebar .filter-section .card .card-header {
    padding: 16px 30px;
    border-radius: 5px;
  }
  .product-sidebar .filter-section .card .card-header h6 {
    position: relative;
  }
  .product-sidebar .filter-section .card .card-header h6 .pull-right i {
    position: absolute;
    top: 4px;
    left: 0;
    font-size: 10px;
    width: 100%;
    height: 10px;
    text-align: right;
    cursor: pointer;
  }
  
  .product-wrapper .product-sidebar .filter-section .card .left-filter {
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: 0.3s;
  }
  .product-wrapper .product-sidebar .filter-section .card .left-filter .product-filter .irs-with-grid {
    margin-bottom: 15px;
  }
  .product-wrapper .product-grid .product-wrapper-grid {
    margin-left: 0;
    transition: 0.3s;
  }
  .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: absolute;
    width: 100%;
    background-color: white;
    top: 53px;
    height: auto;
  }
  .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-left: calc(25% + 9px);
  }
  
  .d-none-productlist {
    display: none;
  }
  .d-none-productlist svg {
    vertical-align: middle;
    cursor: pointer;
  }
  
  .product-wrapper-grid.list-view .product-box {
    display: flex;
    align-items: center;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 20%;
  }
  .product-wrapper-grid.list-view .product-box .product-details {
    text-align: left;
  }
  
  .slider-product {
    padding: 15px 0;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 15px;
  }
  
  .products-total {
    display: flex;
    align-items: center;
  }
  
  .grid-options {
    height: 33px;
    margin-left: 10px;
  }
  .grid-options ul li a {
    position: relative;
  }
  .grid-options ul li a .line-grid {
    position: absolute;
    width: 4px;
    height: 15px;
    top: 9px;
  }
  .grid-options ul li a .line-grid-1 {
    left: 12px;
  }
  .grid-options ul li a .line-grid-2 {
    left: 18px;
  }
  .grid-options ul li a .line-grid-3 {
    left: 36px;
  }
  .grid-options ul li a .line-grid-4 {
    left: 42px;
  }
  .grid-options ul li a .line-grid-5 {
    left: 48px;
  }
  .grid-options ul li a .line-grid-6 {
    left: 66px;
  }
  .grid-options ul li a .line-grid-7 {
    left: 72px;
  }
  .grid-options ul li a .line-grid-8 {
    left: 78px;
  }
  .grid-options ul li a .line-grid-9 {
    left: 84px;
  }
  .grid-options ul li a .line-grid-10 {
    left: 103px;
  }
  .grid-options ul li a .line-grid-11 {
    left: 109px;
  }
  .grid-options ul li a .line-grid-12 {
    left: 115px;
  }
  .grid-options ul li a .line-grid-13 {
    left: 121px;
  }
  .grid-options ul li a .line-grid-14 {
    left: 127px;
  }
  .grid-options ul li a .line-grid-15 {
    left: 133px;
  }
  
  .square-product-setting {
    height: 36px;
    vertical-align: middle;
  }
  .square-product-setting a {
    color: #2b2b2b;
  }
  .square-product-setting .icon-grid {
    padding: 7px;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  .square-product-setting .icon-grid svg {
    width: 20px;
    height: 20px;
  }
  
  .product-filter .banner-product {
    margin-top: 15px;
  }
  .product-filter h6 {
    margin-bottom: 15px;
  }
  .product-filter .color-selector {
    line-height: 0.9;
  }
  .product-filter .color-selector ul li {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #444;
    border-radius: 100%;
    cursor: pointer;
  }
  .product-filter .color-selector ul li.white {
    background-color: #fff;
  }
  .product-filter .color-selector ul li.gray {
    background-color: rgba(43, 43, 43, 0.54);
  }
  .product-filter .color-selector ul li.black {
    background-color: #000;
  }
  .product-filter .color-selector ul li.orange {
    background-color: #ffb17a;
  }
  .product-filter .color-selector ul li.green {
    background-color: #6fb866;
  }
  .product-filter .color-selector ul li.pink {
    background-color: pink;
  }
  .product-filter .color-selector ul li.yellow {
    background-color: #f2f896;
  }
  .product-filter .color-selector ul li.blue {
    background-color: #63b4f2;
  }
  .product-filter .color-selector ul li.red {
    background-color: #ff647f;
  }
  .product-filter.new-products {
    margin-top: 20px;
  }
  .product-filter.new-products button {
    width: auto;
  }
  .product-filter.new-products .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    top: -46px;
    right: 0;
  }
  .product-filter.new-products .owl-theme .owl-nav button:focus {
    outline: transparent;
  }
  .product-filter.new-products .owl-theme .owl-nav button span {
    font-size: 20px;
  }
  .product-filter.new-products .owl-theme .owl-nav button span:focus {
    outline-color: transparent;
  }
  .product-filter.new-products .owl-theme .owl-nav [class*=owl-]:hover {
    background-color: transparent;
    color: inherit;
  }
  .product-filter.new-products .owl-theme .owl-item .item .product-box .product-details {
    padding: 25px 0;
  }
  .product-filter.new-products .owl-theme .owl-item .item .product-box + .product-box {
    margin-top: 15px;
  }
  
  .select2-drpdwn-product .form-control {
    border: none;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    margin-bottom: 10px;
    height: 36px;
  }
  
  .feature-products form .form-group {
    position: relative;
  }
  .feature-products form .form-group input {
    margin-bottom: 15px;
    border: none;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    height: 50px;
    padding-left: 30px;
  }
  .feature-products form .form-group i {
    position: absolute;
    top: 17px;
    right: 30px;
    color: #898989;
  }
  
  .product-box {
    border-radius: 10px;
    overflow: hidden;
  }
  .product-box .product-details {
    padding: 15px 25px;
  }
  .product-box .product-details p {
    margin-bottom: 0;
    opacity: 0.8;
  }
  .product-box .product-details h4 {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 20px;
    font-family: roboto;
  }
  .product-box .product-details h6 {
    color: #2b2b2b;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .product-box .product-details span {
    color: #898989;
  }
  .product-box .product-details .rating i {
    font-size: 16px;
    letter-spacing: 3px;
    color: #ffa800;
  }
  .product-box .modal .modal-header .product-box .product-details {
    padding: 25px 0;
  }
  .product-box .modal .modal-header .product-box .product-details h6 {
    text-transform: capitalize;
  }
  .product-box .modal .modal-header .product-box .product-details .product-price {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .product-box .modal .modal-header .product-box .product-details .product-view {
    padding: 20px 0;
    border-top: 1px dotted #59667a;
    border-bottom: 1px dotted #59667a;
  }
  .product-box .modal .modal-header .product-box .product-details .product-size {
    margin: 20px 0;
  }
  .product-box .modal .modal-header .product-box .product-details .product-size ul li {
    display: inline-block;
  }
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset {
    margin-bottom: 20px;
  }
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group {
    width: 35%;
  }
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn {
    padding: 5px 12px;
  }
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn-primary {
    background-color: #59667a !important;
    border: 1px solid #59667a !important;
  }
  .product-box .modal .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .product-box .product-img {
    position: relative;
  }
  .product-box .product-img .product-hover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    border-radius: 100%;
    transform: scale(0);
    transition: all 0.3s ease;
  }
  .product-box .product-img .product-hover ul li {
    display: inline-block;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
    padding: 10px 12px;
    background-color: #fff;
    font-size: 18px;
    border-radius: 10px;
    height: 45px;
    width: 45px;
    margin: 0 3px;
    cursor: pointer;
  }
  .product-box .product-img .product-hover ul li i {
    color: #59667a;
    font-size: 18px;
  }
  .product-box .product-img .product-hover ul li .btn {
    padding: 0;
  }
  .product-box .product-img .product-hover ul li:hover {
    background-color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .product-box .product-img .product-hover ul li:hover .btn,
  .product-box .product-img .product-hover ul li:hover i {
    color: #fff;
  }
  .product-box:hover .product-hover {
    opacity: 1;
    border-radius: 0%;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  
  .product-page-main {
    padding: 30px;
  }
  .product-page-main .owl-item .item {
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .product-page-main .owl-item.current .item {
    border: 1px solid var(--theme-default);
  }
  .product-page-main .product-slider {
    margin-bottom: 20px;
  }
  .product-page-main .product-color {
    margin-top: 10px;
  }
  
  .product-page-details h4 {
    color: #2b2b2b;
    text-transform: uppercase;
    font-size: 18px;
  }
  .product-page-details span {
    padding-left: 15px;
  }
  
  .br-theme-fontawesome-stars .br-widget a,
  .br-theme-fontawesome-stars .br-widget a.br-selected:after,
  .br-theme-fontawesome-stars .br-widget a.br-active:after {
    color: #ffa800 !important;
    font: normal normal normal 14px/1 FontAwesome;
  }
  
  .product-price {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    color: var(--theme-default);
  }
  .product-price del {
    color: rgba(44, 50, 63, 0.4);
    padding-left: 10px;
    font-size: 80%;
  }
  
  .product-color li {
    display: inline-block;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    margin: 0 2px;
  }
  
  .product-page-main p {
    font-size: 15px;
  }
  .product-page-main .nav-link {
    text-transform: uppercase;
    font-weight: 500;
  }
  
  /**====== Product  Ends ======**/
  .payment-opt li {
    display: inline-block;
  }
  .payment-opt li img {
    height: 20px;
    margin: 15px 15px 0 0;
  }
  
  /**=====================
      33. Ecommerce  CSS End
  ==========================**/
  @media screen and (max-width: 1660px) and (min-width: 1200px) {
    .xl-cs-65 {
      max-width: 65%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
      flex: 0 0 65%;
    }
    .xl-cs-35 {
      max-width: 35%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
      order: -1;
    }
    .owl-carousel .owl-item img {
      max-width: 420px;
      margin: 0 auto;
    }
  }
  /**=====================
      13.  Email Application  CSS Start
  ==========================**/
  .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 0;
  }
  .email-wrap .row .col-xl-6 {
    padding-left: 0;
  }
  .email-wrap .dropdown .dropdown-toggle {
    background-color: rgba(115, 102, 255, 0.08);
    color: #7366ff;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100px;
    text-align: left;
  }
  .email-wrap .dropdown .dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "\f107";
    border: none;
    font-family: "FontAwesome";
    font-size: 18px;
    margin: 0;
  }
  .email-wrap .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  .email-wrap .dropdown .dropdown-menu .dropdown-item {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid #f5f5f5;
    background: #fff;
    line-height: 19px;
  }
  .email-wrap .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #fff;
  }
  .email-wrap .email-app-sidebar .media img {
    border: 2px solid #ecf3fa;
  }
  .email-wrap .email-app-sidebar .main-menu > li {
    width: 100%;
    text-align: left;
  }
  .email-wrap .email-app-sidebar .main-menu > li a {
    line-height: 39px;
    text-transform: uppercase;
    border-radius: 3px;
  }
  .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: 10px;
  }
  .email-wrap .email-app-sidebar .main-menu > li a.active > a {
    color: #000;
  }
  .email-wrap .email-app-sidebar .main-menu > li a:hover {
    background-color: #f5f6f9;
    text-decoration: none;
  }
  .email-wrap .email-app-sidebar ul li a {
    display: flex;
    padding-left: 20px;
    align-items: center;
  }
  .email-wrap .email-app-sidebar ul li a > .title {
    width: 100%;
    color: #2c323f;
  }
  .email-wrap .email-app-sidebar ul li a > .badge {
    align-self: center;
    color: #898989;
  }
  .email-wrap .email-left-aside {
    float: left;
    width: 100%;
  }
  .email-wrap .email-left-aside .card-body {
    padding: 20px;
  }
  .email-wrap .email-right-aside .email-body .row .col-xl-4 {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .email-wrap .email-right-aside .email-body .pr-0 {
    padding-right: 0;
  }
  .email-wrap .email-right-aside .email-body .pl-0 {
    padding-left: 0;
  }
  .email-wrap .email-right-aside .email-body .inbox {
    overflow: auto;
  }
  .email-wrap .email-right-aside .email-body .inbox .media {
    padding: 20px;
  }
  .email-wrap .email-right-aside .email-body .inbox .media.active {
    background-color: #fefefe;
  }
  .email-wrap .email-right-aside .radius-left {
    border-radius: 8px;
    height: 100%;
  }
  .email-wrap .btn-mail {
    border-radius: 2px;
    text-align: left;
    padding: 13px 15px;
    margin: 20px 0 20px;
    letter-spacing: 1px;
    line-height: 12px !important;
  }
  .email-wrap p {
    margin-bottom: 0;
    color: #898989;
  }
  .email-wrap .media-body {
    margin-top: 5px;
  }
  .email-wrap .media-body h6 {
    margin-bottom: 2px;
    color: #242934;
    margin-top: 3px;
  }
  .email-wrap .media-body h6 small {
    font-size: 12px;
  }
  .email-wrap .media-body h6 small span {
    color: #777777;
  }
  .email-wrap .media-body p {
    color: rgba(43, 43, 43, 0.54);
    font-size: 12px;
  }
  .email-wrap .email-top {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 49px;
  }
  .email-wrap .email-top i {
    cursor: pointer;
  }
  .email-wrap .email-top h5 {
    margin-bottom: 0;
    padding: 12px 0;
  }
  .email-wrap .email-top .d-flex {
    padding: 13px 0;
  }
  .email-wrap .email-wrapper {
    padding: 20px;
  }
  .email-wrap .email-wrapper hr {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .email-wrap .email-wrapper .d-inline-block {
    width: 100%;
  }
  .email-wrap .email-wrapper h6 {
    margin-bottom: 11px;
    float: left;
  }
  .email-wrap .email-wrapper .right-download {
    float: right;
  }
  .email-wrap .email-wrapper p + p {
    margin-bottom: 20px;
  }
  .email-wrap .email-wrapper h5 {
    margin-bottom: 20px;
  }
  .email-wrap .email-content .email-top .user-emailid:after {
    content: "";
    position: relative;
    height: 20px;
    width: 1px;
    right: 0;
    top: 3px;
    z-index: 2;
    border: 1px solid #efefef;
    float: right;
    padding: 0;
    margin: 0 20px;
  }
  .email-wrap .email-body .attachment ul li img {
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  .email-wrap .email-body .attachment .list-inline .list-inline-item {
    padding-right: 13px;
  }
  .email-wrap .email-body .email-compose .cke_contents.cke_reset {
    max-height: 178px;
    width: 100%;
    border: 1px solid #ecf3fa;
  }
  .email-wrap .email-body .email-compose .email-top h4 {
    padding: 11px 0;
  }
  .email-wrap .email-body .email-compose .compose-border .btn-middle {
    padding-top: 5px;
  }
  .email-wrap .actions li {
    display: inline-block;
    margin-right: 25px;
  }
  
  /**=====================
      13.  Email Application  CSS Ends
  ==========================**/
  /**=====================
       09. Error-page  CSS Start
  ==========================**/
  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
  }
  .error-wrapper.maintenance-bg {
    // background-image: url(../images/other-images/maintenance-bg.jpg);
    background-color: rgba(255, 255, 255, 0.6);
    background-blend-mode: overlay;
  }
  .error-wrapper .maintenance-icons li i {
    color: #59667a;
    font-size: 50px;
    animation: rotate-effect 40s infinite linear reverse;
  }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 100px;
    margin-left: 110px;
    margin-top: -25px;
    animation: rotate-effect 40s linear infinite reverse;
  }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    animation: rotate-effect 30s infinite linear;
    font-size: 150px;
    margin-top: -85px;
    margin-right: 120px;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 70px;
  }
  .error-wrapper .maintenance-heading .headline {
    font-size: 99px;
    font-weight: 900;
    letter-spacing: 10px;
    color: var(--theme-default);
    z-index: 2;
    position: relative;
    margin-top: -70px;
  }
  .error-wrapper .error-heading {
    margin-top: 115px;
  }
  .error-wrapper .error-heading .headline {
    font-size: 285px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-top: -130px;
  }
  .error-wrapper .sub-content {
    font-size: 18px;
    color: #2b2b2b;
    letter-spacing: 1px;
    font-weight: normal;
    line-height: 35px;
    z-index: 3;
    position: relative;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .error-wrapper .btn {
    margin-top: 40px;
  }
  
  .error {
    margin: 70px 0 100px;
    color: #000;
  }
  .error .error-title {
    font-size: 9.5rem;
    color: #fff;
    text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
  }
  .error .line-bottom {
    width: 10%;
    height: 5px;
    background-color: #f8d62b;
    border: none;
  }
  .error .c-white {
    color: #fff;
  }
  .error .error-btn {
    text-align: center;
  }
  .error .padd {
    padding: 10px 30px 10px 30px;
  }
  .error .btn-back-home {
    background-color: #f4f4f4;
    color: #000;
  }
  .error .btn-back-home:hover {
    background-color: #f8d62b;
    color: #fff;
    box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  }
  .error .btn-adnc-serc {
    background-color: #2c323f;
    color: #fff;
  }
  .error .btn-adnc-serc:hover {
    background-color: #f8d62b;
    color: #fff;
    box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  }
  
  /**=====================
       09. Error-page CSS Ends
  ==========================**/
  /**=====================
       71. Faq CSS start
  ==========================**/
  .knowledgebase-bg {
    height: 500px;
    border-radius: 15px;
    margin-bottom: 30px;
  }
  
  .knowledgebase-search {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 5%;
  }
  .knowledgebase-search .form-inline {
    background-color: #fff;
    width: 500px;
    padding: 12px 50px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    position: relative;
  }
  .knowledgebase-search .form-inline ::placeholder {
    color: #cccccc;
  }
  .knowledgebase-search .form-inline svg {
    position: absolute;
    left: 20px;
    width: 20px;
    top: 17px;
    stroke: #cccccc;
  }
  
  .faq-widgets {
    align-items: center;
  }
  .faq-widgets .media-body h5 {
    font-weight: 600;
  }
  .faq-widgets .media-body p {
    padding-right: 20px;
  }
  .faq-widgets svg {
    width: 50px;
    height: 50px;
    opacity: 0.5;
  }
  
  .header-faq {
    margin-bottom: 30px;
  }
  .header-faq h5 {
    font-weight: 600;
  }
  
  .features-faq.card .card-body,
  .features-faq.card .card-footer {
    padding: 20px 25px;
  }
  .features-faq .faq-image img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
  }
  .features-faq .card-body h6 {
    font-weight: 500;
  }
  
  .add-project.table td {
    vertical-align: middle;
  }
  .add-project .text-inherit {
    color: black;
  }
  
  .faq-accordion {
    margin-bottom: 30px;
  }
  .faq-accordion .card .btn-link {
    display: flex;
  }
  .faq-accordion .card .btn-link svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .faq-accordion .faq-header {
    position: relative;
    padding: 30px !important;
  }
  .faq-accordion .faq-header svg {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .faq-accordion .faq-header span {
    margin-top: 3px;
  }
  .faq-accordion .faq-body {
    padding: 20px !important;
  }
  .faq-accordion .card-mb-faq {
    margin-bottom: 30px;
  }
  
  .faq-title {
    margin: 30px 0;
  }
  .faq-title h6 {
    color: #999999;
    font-weight: 600;
  }
  
  .faq-form {
    position: relative;
  }
  .faq-form .search-icon {
    position: absolute;
    right: 12px;
    top: 9px;
    width: 20px;
    height: 20px;
  }
  
  .navigation-btn {
    margin-bottom: 30px;
  }
  .navigation-btn a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navigation-btn a svg {
    width: 15px;
    height: 15px;
  }
  
  .navigation-option ul li {
    position: relative;
    padding: 15px 15px 15px 0;
  }
  .navigation-option ul li:hover {
    background-color: #fdfeff;
  }
  .navigation-option ul li a {
    padding-left: 40px;
    color: #2b2b2b;
  }
  .navigation-option ul li a svg {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 17px;
    left: 15px;
  }
  
  .updates-faq {
    width: 40px;
    height: 40px;
    border: 2px solid var(--theme-default);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
  }
  .updates-faq svg {
    width: 18px;
    height: 18px;
  }
  
  .updates-bottom-time p {
    margin-bottom: 2px;
  }
  .updates-bottom-time p + p {
    color: #999999;
  }
  
  .updates-faq-main {
    margin-bottom: 30px;
  }
  .updates-faq-main a {
    font-weight: 500;
  }
  .updates-faq-main:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (max-width: 1199px) {
    .faq-accordion {
      margin-bottom: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    .faq-accordion .faq-header {
      padding: 20px !important;
    }
    .faq-accordion .faq-header svg {
      right: 20px;
      top: 20px;
    }
    .navigation-option ul li {
      padding: 10px 10px 10px 0;
    }
    .navigation-option ul li a svg {
      top: 12px;
    }
    .navigation-btn,
  .updates-faq-main {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .xs-mt-search {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 575px) {
    .xs-mt-search {
      margin-top: 0px;
    }
    .faq-widgets svg {
      width: 30px;
      height: 30px;
    }
    .faq-title {
      margin: 15px 0;
    }
    .header-faq {
      margin-bottom: 15px;
      margin-top: 10px;
    }
    .faq-accordion {
      margin-bottom: 0px;
    }
    .faq-accordion .faq-header {
      padding: 15px !important;
    }
    .faq-accordion .faq-header .pull-right {
      float: right;
    }
    .faq-accordion .faq-header svg {
      right: 15px;
      top: 15px;
    }
    .navigation-btn,
  .updates-faq-main {
      margin-bottom: 15px;
    }
  }
  /**=====================
       71. Faq CSS end
  ==========================**/
  /**=====================
      File Manager
  ==========================**/
  .file-sidebar .card .card-body,
  .file-sidebar .card .card-header,
  .file-content .card .card-body,
  .file-content .card .card-header {
    padding: 20px !important;
  }
  
  .file-sidebar ul li + li {
    margin-top: 8px;
  }
  .file-sidebar .btn {
    display: flex;
    align-items: center;
  }
  .file-sidebar .btn.btn-light:hover {
    color: var(--theme-default) !important;
  }
  .file-sidebar .btn.btn-light:hover svg {
    stroke: var(--theme-default);
  }
  .file-sidebar .btn svg {
    width: 15px;
    vertical-align: middle;
    margin-right: 8px;
  }
  .file-sidebar .pricing-plan {
    border: 1px solid #ecf3fa;
    border-radius: 5px;
    margin-top: 10px;
    padding: 15px;
    position: relative;
    overflow: hidden;
  }
  .file-sidebar .pricing-plan h6 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;
    color: #898989;
  }
  .file-sidebar .pricing-plan h5 {
    font-weight: 800;
  }
  .file-sidebar .pricing-plan p {
    margin-bottom: 5px;
    color: #898989;
  }
  .file-sidebar .pricing-plan .btn {
    display: inline-block;
  }
  .file-sidebar .pricing-plan .bg-img {
    position: absolute;
    top: 40px;
    opacity: 0.1;
    transform: rotate(-45deg);
    right: -40px;
  }
  
  .file-manager > h6 {
    opacity: 0.6;
    font-weight: 400 !important;
    font-size: 15px;
    margin-bottom: 12px;
  }
  .file-manager .files .file-box:nth-child(1) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 100000ms;
  }
  .file-manager .files .file-box:nth-child(2) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 200000ms;
  }
  .file-manager .files .file-box:nth-child(3) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 300000ms;
  }
  .file-manager .files .file-box:nth-child(4) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 400000ms;
  }
  .file-manager .files .file-box:nth-child(5) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 500000ms;
  }
  .file-manager .files .file-box:nth-child(6) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 600000ms;
  }
  .file-manager .files .file-box:nth-child(7) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 700000ms;
  }
  .file-manager .files .file-box:nth-child(8) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 800000ms;
  }
  .file-manager .files .file-box:nth-child(9) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 900000ms;
  }
  .file-manager .files .file-box:nth-child(10) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1000000ms;
  }
  .file-manager .files .file-box:nth-child(11) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1100000ms;
  }
  .file-manager .files .file-box:nth-child(12) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1200000ms;
  }
  .file-manager .files .file-box:nth-child(13) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1300000ms;
  }
  .file-manager .files .file-box:nth-child(14) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1400000ms;
  }
  .file-manager .files .file-box:nth-child(15) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1500000ms;
  }
  .file-manager .files h6 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .file-manager p {
    opacity: 0.9;
    font-size: 12px;
  }
  
  .files h6,
  .folder h6 {
    opacity: 0.9;
    font-weight: 500 !important;
    font-size: 14px;
  }
  
  .file-content .ellips {
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 0.7;
  }
  .file-content .form-inline {
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 0 20px;
  }
  .file-content .form-inline i {
    padding-right: 10px;
    color: #898989;
    line-height: 3;
  }
  .file-content .form-inline input::-webkit-input-placeholder {
    color: #898989;
  }
  .file-content .form-inline input:focus {
    outline: none !important;
  }
  .file-content .search-form input {
    padding: 5px 10px 5px 70px;
    border-radius: 5px;
  }
  .file-content .search-form .form-group:before {
    left: 82px;
    top: 37px;
  }
  .file-content .search-form .form-group:after {
    top: 39px;
    left: 53px;
  }
  .file-content .btn svg {
    height: 15px;
    margin-right: 2px;
    vertical-align: middle;
  }
  .file-content h4 {
    font-weight: 500;
  }
  .file-content .folder .folder-box {
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 15px;
    background-color: #f6f7fb;
    width: calc(25% - 15px);
    display: inline-block;
  }
  .file-content .folder .folder-box:nth-child(1) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 100000ms;
  }
  .file-content .folder .folder-box:nth-child(2) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 200000ms;
  }
  .file-content .folder .folder-box:nth-child(3) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 300000ms;
  }
  .file-content .folder .folder-box:nth-child(4) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 400000ms;
  }
  .file-content .folder .folder-box:nth-child(5) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 500000ms;
  }
  .file-content .folder .folder-box:nth-child(6) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 600000ms;
  }
  .file-content .folder .folder-box:nth-child(7) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 700000ms;
  }
  .file-content .folder .folder-box:nth-child(8) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 800000ms;
  }
  .file-content .folder .folder-box:nth-child(9) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 900000ms;
  }
  .file-content .folder .folder-box:nth-child(10) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1000000ms;
  }
  .file-content .folder .folder-box:nth-child(11) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1100000ms;
  }
  .file-content .folder .folder-box:nth-child(12) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1200000ms;
  }
  .file-content .folder .folder-box:nth-child(13) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1300000ms;
  }
  .file-content .folder .folder-box:nth-child(14) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1400000ms;
  }
  .file-content .folder .folder-box:nth-child(15) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1500000ms;
  }
  .file-content .files .file-box {
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 15px;
    background-color: #f6f7fb;
    width: calc(25% - 15px);
    display: inline-block;
    position: relative;
  }
  .file-content .files .file-box .file-top {
    height: 100px;
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 5px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 1440px) {
    .file-content .folder .folder-box .media {
      display: block;
    }
    .file-content .folder .folder-box .media .media-body {
      margin-left: 0 !important;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 1366px) {
    .file-content .files {
      margin-bottom: -10px;
    }
    .file-content .files .file-box {
      width: calc(50% - 15px);
      margin-bottom: 10px;
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 768px) {
    .file-content .folder {
      margin-bottom: -10px;
    }
    .file-content .folder .folder-box {
      width: calc(50% - 15px);
      margin-bottom: 10px;
      margin-right: 8px;
    }
    .file-content .media {
      display: block;
      text-align: center;
    }
    .file-content .media .media-body {
      margin-top: 20px;
      text-align: center !important;
    }
  }
  @media screen and (max-width: 575px) {
    .file-content .folder .folder-box,
  .file-content .files .file-box {
      width: 100%;
    }
  }
  /**=====================
       File Manager
  ==========================**/
  /**=====================
       45. Gallery CSS Start
  ==========================**/
  .profile-img-style img, .scroll-bar-wrap img {
    width: 100%;
  }
  
  .gallery {
    margin-bottom: -30px;
  }
  .gallery > a {
    margin-bottom: 30px;
  }
  .gallery > a:before {
    content: "\edee";
    font-family: IcoFont;
    position: absolute;
    height: calc(100% - 10px);
    left: 20px;
    width: calc(100% - 40px);
    background-color: rgba(255, 255, 255, 0.27);
    top: 5px;
    transform: scale(0);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #000;
  }
  .gallery > a:hover:before {
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .gallery > a .img-thumbnail {
    border-radius: 0;
  }
  
  .gallery-with-description {
    margin-bottom: -30px;
  }
  .gallery-with-description a > div {
    padding: 10px;
    border: 1px solid #e4e6ec;
    margin-bottom: 30px;
    border-radius: 0 0 5px 5px;
  }
  .gallery-with-description a:hover {
    text-decoration: none !important;
  }
  .gallery-with-description h4 {
    color: #2c323f;
    margin-top: 15px;
    font-size: 18px;
  }
  .gallery-with-description p {
    color: #a3a3a3;
  }
  
  .lg-backdrop {
    background-color: #fff;
  }
  
  .lg-outer .lg-actions .lg-next, .lg-outer .lg-actions .lg-prev {
    background-color: rgb(255, 255, 255);
    border: 1px solid #efefef;
  }
  .lg-outer .lg-actions .lg-next:hover, .lg-outer .lg-actions .lg-prev:hover {
    color: #2c323f;
  }
  .lg-outer .lg-toolbar {
    background-color: rgba(255, 255, 255, 0.45);
    border-bottom: 1px solid #2c323f;
  }
  .lg-outer .lg-toolbar .lg-icon:hover {
    color: #2c323f;
  }
  .lg-outer #lg-counter {
    color: #2c323f;
  }
  .lg-outer .lg-sub-html {
    background-color: rgba(255, 255, 255, 0.45);
  }
  .lg-outer .lg-sub-html h4 {
    font-size: inherit;
    color: #2c323f;
  }
  .lg-outer .lg-sub-html p {
    color: #2c323f;
  }
  .lg-outer .lg-thumb-outer {
    background-color: #ecf3fa;
  }
  .lg-outer .lg-thumb-item {
    padding: 4px;
    border: 1px solid #efefef;
    border-radius: 0;
  }
  .lg-outer .lg-thumb-item .active, .lg-outer .lg-thumb-item:hover {
    border-color: #000;
  }
  
  .gallery a:before, .gallery a:after {
    content: none;
  }
  .gallery .img-hover > div {
    overflow: hidden;
  }
  .gallery .hover-1 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-1 img:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  .gallery .hover-2 img {
    width: 100%;
    height: auto;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    transform: translateX(0px);
  }
  .gallery .hover-2:hover img {
    transform: translateX(40px) scale(1.2);
  }
  .gallery .hover-3 img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-3:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .gallery .hover-4 img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-4:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .gallery .hover-5 img {
    margin-left: 30px;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-5:hover img {
    margin-left: 0;
  }
  .gallery .hover-6 img {
    -webkit-transform: rotate(15deg) scale(1.4);
    transform: rotate(15deg) scale(1.4);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-6:hover img {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
  }
  .gallery .hover-7 img {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-7:hover img {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
  .gallery .hover-8 img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-8:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  .gallery .hover-9 img {
    -webkit-filter: sepia(100%);
    filter: sepia(100%);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-9:hover img {
    -webkit-filter: sepia(0);
    filter: sepia(0);
  }
  .gallery .hover-10 img {
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-10:hover img {
    -webkit-filter: grayscale(100%) blur(3px);
    filter: grayscale(100%) blur(3px);
  }
  .gallery .hover-11 img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .gallery .hover-11:hover img {
    opacity: 0.5;
  }
  @-webkit-keyframes flash {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flash {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  .gallery .hover-13:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
  }
  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes shine {
    100% {
      left: 125%;
    }
  }
  .gallery .hover-14 div {
    position: relative;
  }
  .gallery .hover-14 div:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }
  .gallery .hover-14 div:hover:before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s;
  }
  .gallery .hover-15 div {
    position: relative;
  }
  .gallery .hover-15 div:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .gallery .hover-15 div:hover:before {
    -webkit-animation: circle 0.75s;
    animation: circle 0.75s;
  }
  
  @-webkit-keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }
  @keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }
  * {
    box-sizing: border-box;
  }
  *:after, *:before {
    box-sizing: border-box;
  }
  
  .grid {
    position: relative;
  }
  
  /* clear fix */
  .grid:after {
    content: "";
    display: block;
    clear: both;
  }
  
  /* ---- .grid-item ---- */
  .grid-item img {
    max-width: 100%;
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  
  .gallery-with-description .grid-item img {
    border: 0;
    padding: 0;
  }
  
  /**=====================
       45. Gallery CSS Ends
  ==========================**/
  /**=====================
       74. internationalization css start
  ==========================**/
  .hdg_main {
    position: relative;
  }
  
  .main .langChoice {
    position: absolute;
    right: 40px;
    top: 31px;
    padding: 10px 12px;
    background-color: transparent;
  }
  
  .dropdown .dropdown-toggle {
    background-color: rgba(115, 102, 255, 0.08);
    color: #7366ff;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100px;
    text-align: left;
  }
  .dropdown .dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "\f107";
    border: none;
    font-family: "FontAwesome";
    font-size: 18px;
    margin: 0;
  }
  .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  .dropdown .dropdown-menu .dropdown-item {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid #efefef;
    background: #fff;
  }
  .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #fff;
  }
  
  @media (max-width: 991px) {
    .main .langChoice {
      right: 20px;
      top: 11px;
      padding: 8px;
    }
  }
  @media only screen and (max-width: 575px) {
    .main .langChoice {
      top: 14px;
      padding: 4px 7px;
    }
    .language-xs {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 360px) {
    .language-header {
      padding-bottom: 60px !important;
    }
    .main .langChoice {
      left: 0;
      margin: 0 auto;
      top: 50px;
      width: 30%;
      right: 0;
    }
  }
  /**=====================
       74. internationalization CSS end
  ==========================**/
  /**=====================
       72. Job CSS start
  ==========================**/
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 30px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .location-checkbox span {
    color: #999999;
    padding-left: 33px;
  }
  
  .job-accordion .card {
    margin-bottom: 30px;
  }
  .job-accordion .btn-block {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .job-search p {
    margin-top: 30px;
  }
  .job-search .media .media-body h6 {
    margin-bottom: 3px;
  }
  .job-search .media .media-body h6 span {
    font-size: 13px;
    color: #999999;
    font-weight: normal;
  }
  .job-search .media .media-body h6 span.badge {
    color: #fff;
  }
  .job-search .media .media-body p {
    margin-top: 0;
    color: #999999;
  }
  .job-search .job-description {
    margin-top: 30px;
  }
  .job-search .job-description h6 {
    font-weight: 600;
  }
  .job-search .job-description p {
    margin-top: 0;
    margin-bottom: 6px;
  }
  .job-search .job-description ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  .job-search .job-description ul li {
    margin-bottom: 10px;
  }
  .job-search .job-description ul li:last-child {
    margin-bottom: 0;
  }
  .job-search .job-description .theme-form {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
    margin-top: 22px;
  }
  .job-search .job-description .theme-form .row div[class^=col-] .job-select2 ~ .select2-container {
    margin-top: 0 !important;
  }
  .job-search .job-description .theme-form .row div[class^=col-] .input-group .datepicker-here {
    font-size: 14px;
    border: 1px solid #f4f4f4;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  
  .select2-container .select2-selection--single {
    border-radius: 0.25rem !important;
    border-color: #f6f7fb;
    height: 40px !important;
    padding: 5px;
  }
  
  .job-pagination {
    margin-bottom: 30px;
  }
  .job-pagination nav ul {
    justify-content: center;
  }
  
  @media only screen and (max-width: 1366px) {
    .xl-mt-job {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 991px) {
    .filter-cards-view {
      padding: 20px !important;
    }
    .filter-cards-view .job-filter:first-child {
      margin-bottom: 20px;
    }
    .filter-cards-view .checkbox-animated {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .job-accordion .animate-chk {
      padding: 20px !important;
    }
    .job-search p {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .filter-cards-view {
      padding: 15px !important;
    }
    .filter-cards-view .job-filter:first-child {
      margin-bottom: 15px;
    }
    .filter-cards-view .checkbox-animated {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .job-accordion .animate-chk {
      padding: 15px !important;
    }
    .job-search p {
      margin-top: 15px;
    }
    .job-search .media {
      display: block;
      text-align: center;
    }
    .job-search .media .media-body h6 .pull-right {
      float: none;
      margin-top: 3px;
    }
    .job-search .media .media-body h6 a {
      display: block;
    }
    .job-search .media .media-body p span + span {
      display: block;
    }
    .job-search .media img {
      margin-bottom: 15px;
    }
    .job-search .media .m-r-20 {
      margin-right: 0;
    }
    .job-search .job-description {
      margin-top: 15px;
    }
    .job-search .job-description .theme-form {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
      margin-top: 2px;
    }
    .job-search .job-description .theme-form .xs-mt-period {
      margin-top: 15px;
    }
    .job-pagination {
      margin-bottom: 15px;
    }
    .xl-mt-job {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 360px) {
    .job-search .job-description .btn {
      padding: 6px 12px;
    }
  }
  /**=====================
       72. Job CSS end
  ==========================**/
  /**=====================
       31. JS datagrid CSS Start
  ==========================**/
  .jsgrid .jsgrid-grid-body .jsgrid-cell {
    padding: 0.75rem;
  }
  .jsgrid .jsgrid-pager-container {
    text-align: right;
  }
  .jsgrid .jsgrid-pager {
    padding: 0;
    margin-top: 20px;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
    margin-right: -5px;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button:first-child {
    border-radius: 5px 0 0 5px;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button {
    margin-left: -5px;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button:last-child {
    border-radius: 0 5px 5px 0;
  }
  .jsgrid .jsgrid-pager [class*=jsgrid-pager] {
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    border: 1px solid #f4f4f4;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-page a {
    color: #2c323f;
  }
  .jsgrid .jsgrid-pager .jsgrid-pager-current-page {
    color: #2c323f;
  }
  .jsgrid .jsgrid-selected-row > .jsgrid-cell {
    background: #f4f4f4;
    border-color: #fafafa;
  }
  .jsgrid .jsgrid-header-row > .jsgrid-header-cell {
    padding: 0.75rem;
    font-weight: 500;
  }
  
  /**=====================
       31. JS datagrid CSS Ends
  ==========================**/
  /**=====================
     61. base_inputs CSS start
  ==========================**/
  .jkanban-container .btn {
    margin-bottom: 10px;
  }
  .jkanban-container .note a {
    display: contents;
  }
  
  .kanban-board-header {
    height: auto;
    width: auto;
    line-height: unset;
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
  }
  .kanban-board-header.bg-info ~ .kanban-drag {
    background-color: rgba(169, 39, 249, 0.3);
  }
  .kanban-board-header.bg-warning ~ .kanban-drag {
    background-color: rgba(248, 214, 43, 0.3);
  }
  .kanban-board-header.bg-success ~ .kanban-drag {
    background-color: rgba(81, 187, 37, 0.3);
  }
  
  .kanban-container {
    display: contents;
  }
  
  .kanban-board {
    margin-bottom: 30px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 8px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    overflow: hidden;
    width: calc(33.3333333333% - 30px) !important;
  }
  .kanban-board .kanban-drag {
    background: #f8f8f8;
  }
  
  .kanban-item {
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }
  .kanban-item p {
    opacity: 0.7;
  }
  .kanban-item:last-child {
    margin-bottom: 0;
  }
  .kanban-item .list {
    display: inline-block;
    opacity: 0.4;
  }
  .kanban-item .list li {
    display: inline-block;
    font-size: 12px;
  }
  .kanban-item .list li i {
    margin-right: 10px;
  }
  .kanban-item .kanban-box {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    display: block;
    color: inherit;
    background-color: #fff;
  }
  .kanban-item .kanban-box:hover {
    color: inherit;
  }
  .kanban-item .kanban-box + .kanban-box {
    margin-top: 20px;
  }
  .kanban-item .kanban-box .badge {
    font-weight: 400;
  }
  .kanban-item .kanban-box h6 {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 500;
  }
  .kanban-item .kanban-box .dropdown {
    display: inline-block;
    right: 10px;
    position: absolute;
    top: 20px;
  }
  .kanban-item .kanban-box .dropdown .dropdown-item {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
    text-align: left;
  }
  .kanban-item .kanban-box .dropdown svg {
    height: 14px;
  }
  .kanban-item .kanban-box .d-flex {
    justify-content: space-between;
  }
  .kanban-item .kanban-box .dropdown-toggle:after {
    content: none;
  }
  .kanban-item .kanban-box .customers ul li + li {
    margin-left: -10px;
  }
  .kanban-item .kanban-box .date {
    font-size: 11px;
    opacity: 0.5;
  }
  
  footer {
    margin: 0;
    padding: 0;
  }
  
  @media only screen and (max-width: 1199px) {
    .jkanban-container .btn {
      margin-bottom: 10px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
      padding: 10px 15px;
    }
    .kanban-container .kanban-board {
      width: 320px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
  @media only screen and (max-width: 480px) {
    .jkanban-container .card-body {
      padding: 20px 0 !important;
    }
    .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
      padding: 10px 15px;
    }
    .kanban-container .kanban-board {
      width: 270px !important;
    }
  }
  /**=====================
      61. base_inputs CSS ends
  ==========================**/
  /**=====================
       73. knowledgebase CSS start
  ==========================**/
  .browse .browse-articles.browse-bottom {
    margin-top: 30px;
  }
  .browse .browse-articles span {
    vertical-align: middle;
    position: relative;
  }
  .browse .browse-articles span:nth-child(2) {
    padding-left: 20px;
  }
  .browse .browse-articles span svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    vertical-align: text-top;
    position: absolute;
    left: 0;
    top: 3px;
  }
  .browse .browse-articles h6 {
    padding-bottom: 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #fdfeff;
    padding-left: 25px;
  }
  .browse .browse-articles h6 span svg {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    top: 0;
    left: -30px;
  }
  .browse .browse-articles ul li {
    padding: 8px;
    border-radius: 8px;
  }
  .browse .browse-articles ul li a {
    color: #2b2b2b;
  }
  .browse .browse-articles ul li:hover {
    background-color: #fdfeff;
  }
  
  @media only screen and (max-width: 1366px) {
    .browse div[class^=col-]:nth-child(3) {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    .browse div[class^=col-]:nth-child(3) {
      margin-top: 20px;
    }
    .browse .browse-articles.browse-bottom {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .browse div[class^=col-]:nth-child(2) {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .browse div[class^=col-]:nth-child(2), .browse div[class^=col-]:nth-child(3) {
      margin-top: 15px;
    }
    .browse .browse-articles.browse-bottom {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 360px) {
    .browse .browse-articles ul li a span:nth-child(2) {
      width: 158px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
  /**=====================
       73. knowledgebase CSS end
  ==========================**/
  /**=====================
       38. Landing CSS Start
  ==========================**/
  /* MESH LOADER */
  @keyframes mesh {
    0% {
      transform-origin: 50% -100%;
      transform: rotate(0);
    }
    50% {
      transform-origin: 50% -100%;
      transform: rotate(360deg);
    }
    50.1% {
      transform-origin: 50% 200%;
      transform: rotate(0deg);
    }
    100% {
      transform-origin: 50% 200%;
      transform: rotate(360deg);
    }
  }
  .mesh-loader {
    overflow: hidden;
    height: inherit;
    width: inherit;
  }
  .mesh-loader .circle {
    position: absolute;
    background: #7366ff;
    border-radius: 50%;
    margin: -3px;
    animation: mesh 3s ease-in-out infinite -1.5s;
    width: 6px;
    height: 6px;
  }
  .mesh-loader > div .circle:last-child {
    animation-delay: 0s;
  }
  .mesh-loader > div {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .mesh-loader > div:last-child {
    transform: rotate(90deg);
  }
  
  @-webkit-keyframes animloader14 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes animloader14 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .loader-1 {
    width: 76px;
    height: 76px;
    display: inline-block;
    position: relative;
  }
  .loader-1::after, .loader-1::before {
    content: "";
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: #80a5ff;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader14 2s linear infinite;
  }
  .loader-1::after {
    animation-delay: 1s;
  }
  @media (max-width: 1199px) {
    .loader-1 {
      width: 60px;
      height: 60px;
    }
    .loader-1::after, .loader-1::before {
      width: 60px;
      height: 60px;
    }
  }
  @media (max-width: 575px) {
    .loader-1 {
      width: 40px;
      height: 40px;
    }
    .loader-1::after, .loader-1::before {
      width: 40px;
      height: 40px;
    }
  }
  
  .landing-page {
    color: #000248;
  }
  .landing-page .accordion-item {
    border: none;
  }
  .landing-page .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
  .landing-page .customization-accordion .accordion-collapse {
    border: none;
  }
  .landing-page .customization-accordion .accordion-button {
    font-weight: 500;
    position: relative;
    border: none;
    padding: 10px 10px 10px 30px;
    color: #000248;
  }
  .landing-page .customization-accordion .accordion-button::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-left: 0;
    // background-image: url(../images/landing/icon/plus.svg);
    background-position: center;
    background-size: 16px;
  }
  .landing-page .customization-accordion .accordion-button:focus {
    box-shadow: none;
  }
  .landing-page .customization-accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  .landing-page .customization-accordion .accordion-button:not(.collapsed)::after {
    // background-image: url(../images/landing/icon/minus.svg);
  }
  .landing-page .customization-accordion .accordion-body {
    padding-top: 0;
    padding-bottom: 10px;
    text-align: left;
    padding-left: 30px;
  }
  
  .animate-slider .slick-slide img {
    width: 100%;
  }
  
  .f-light {
    color: #52526C;
    opacity: 0.8;
  }
  
  .vector-image {
    position: absolute;
  }
  @media (max-width: 991px) {
    .vector-image {
      display: none;
    }
  }
  
  .line-gif {
    left: 20%;
    top: 0;
    z-index: 1;
  }
  .line-gif img {
    width: 140px;
    height: 140px;
  }
  @media (max-width: 1399px) {
    .line-gif img {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 991px) {
    .line-gif img {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 575px) {
    .line-gif img {
      width: 60px;
      height: 60px;
    }
  }
  @media (max-width: 1460px) {
    .line-gif {
      left: 14%;
    }
  }
  @media (max-width: 1399px) {
    .line-gif {
      left: 21%;
    }
  }
  @media (max-width: 575px) {
    .line-gif {
      left: 7%;
      top: -30px;
    }
  }
  
  .wavy-line-gif {
    right: 0;
    top: 3%;
  }
  @media (max-width: 991px) {
    .wavy-line-gif {
      top: 1%;
    }
  }
  @media (max-width: 767px) {
    .wavy-line-gif {
      top: 40px;
    }
  }
  @media (max-width: 575px) {
    .wavy-line-gif {
      top: 10px;
    }
  }
  .wavy-line-gif img {
    width: 200px;
  }
  @media (max-width: 1199px) {
    .wavy-line-gif img {
      width: 150px;
    }
  }
  @media (max-width: 767px) {
    .wavy-line-gif img {
      width: 120px;
    }
  }
  @media (max-width: 575px) {
    .wavy-line-gif img {
      width: 90px;
    }
  }
  
  .round-gif {
    left: 5%;
    top: 20%;
  }
  @media (max-width: 1660px) {
    .round-gif {
      left: 2%;
    }
  }
  @media (max-width: 1399px) {
    .round-gif {
      left: 0%;
      top: 15%;
    }
  }
  @media (max-width: 1199px) {
    .round-gif {
      top: 20%;
    }
  }
  @media (max-width: 991px) {
    .round-gif {
      top: 28%;
    }
  }
  @media (max-width: 767px) {
    .round-gif {
      top: -2%;
    }
  }
  .round-gif img {
    width: 147px;
    height: 147px;
  }
  @media (max-width: 1660px) {
    .round-gif img {
      width: 127px;
      height: 127px;
    }
  }
  @media (max-width: 1199px) {
    .round-gif img {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 991px) {
    .round-gif img {
      width: 70px;
      height: 70px;
    }
  }
  
  .wavy-gif {
    right: 17%;
    top: 23%;
  }
  .wavy-gif svg {
    width: 80px;
    transform: rotate(90deg);
  }
  @media (max-width: 1199px) {
    .wavy-gif svg {
      width: 60px;
    }
  }
  @media (max-width: 575px) {
    .wavy-gif svg {
      width: 30px;
    }
  }
  .wavy-gif path {
    stroke: #f8912b;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: dash 1.5s linear infinite;
  }
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  @media (max-width: 1399px) {
    .wavy-gif {
      right: 3%;
      top: 15%;
    }
  }
  @media (max-width: 991px) {
    .wavy-gif {
      right: 1%;
      top: 10%;
    }
  }
  @media (max-width: 767px) {
    .wavy-gif {
      top: 8%;
    }
  }
  .wavy-gif img {
    transform: rotate(90deg);
    width: 120px;
  }
  @media (max-width: 1399px) {
    .wavy-gif img {
      width: 80px;
    }
  }
  @media (max-width: 767px) {
    .wavy-gif img {
      width: 60px;
    }
  }
  @media (max-width: 575px) {
    .wavy-gif img {
      width: 40px;
    }
  }
  
  .app-section .wavy-gif {
    right: unset;
    left: 20%;
    top: 7%;
  }
  @media (max-width: 1660px) {
    .app-section .wavy-gif {
      left: 8%;
    }
  }
  @media (max-width: 1199px) {
    .app-section .wavy-gif {
      left: 3%;
      top: 3%;
    }
  }
  @media (max-width: 767px) {
    .app-section .wavy-gif {
      top: 30px;
    }
  }
  .app-section .wavy-gif svg {
    width: 50px;
  }
  @media (max-width: 767px) {
    .app-section .wavy-gif svg {
      width: 40px;
    }
  }
  
  .loader-gif {
    right: 5%;
    top: 25%;
  }
  @media (max-width: 1199px) {
    .loader-gif {
      top: 32%;
    }
  }
  @media (max-width: 991px) {
    .loader-gif {
      right: 15px;
    }
  }
  @media (max-width: 575px) {
    .loader-gif {
      top: 28%;
    }
  }
  
  .loader-line-gif {
    right: 15%;
    top: 12%;
  }
  @media (max-width: 1660px) {
    .loader-line-gif {
      right: 5%;
    }
  }
  @media (max-width: 1399px) {
    .loader-line-gif {
      top: 10%;
      right: 0;
    }
  }
  @media (max-width: 1199px) {
    .loader-line-gif {
      top: 9%;
    }
  }
  @media (max-width: 991px) {
    .loader-line-gif {
      top: 0;
    }
  }
  .loader-line-gif img {
    width: 130px;
  }
  @media (max-width: 575px) {
    .loader-line-gif img {
      width: 80px;
    }
  }
  
  .premium-wrap {
    margin-top: -28px;
  }
  .premium-wrap .vector-image {
    top: -50px;
    left: -30px;
  }
  @media (max-width: 1399px) {
    .premium-wrap .vector-image {
      left: 75px;
    }
  }
  @media (max-width: 1199px) {
    .premium-wrap .vector-image {
      left: -15px;
    }
  }
  .premium-wrap .vector-image img {
    height: 400px;
  }
  @media (max-width: 1399px) {
    .premium-wrap .vector-image img {
      height: 220px;
    }
  }
  
  .framework-section .wavy-gif {
    top: unset;
    bottom: 0;
    right: 20%;
  }
  @media (max-width: 1399px) {
    .framework-section .wavy-gif {
      bottom: -30px;
      right: 0;
    }
  }
  @media (max-width: 575px) {
    .framework-section .wavy-gif {
      bottom: 0;
    }
  }
  
  .components-section .flower-gif {
    left: unset;
    right: 5%;
    top: 20%;
  }
  @media (max-width: 991px) {
    .components-section .flower-gif {
      right: 10%;
      top: 9%;
    }
  }
  
  .feature-section .vector-image {
    top: 50px;
    right: -170px;
  }
  @media (max-width: 1600px) {
    .feature-section .vector-image {
      top: 0px;
      right: -20px;
    }
  }
  @media (max-width: 1399px) {
    .feature-section .vector-image {
      top: 10px;
      right: -10px;
    }
  }
  @media (max-width: 1199px) {
    .feature-section .vector-image {
      right: -12px;
    }
  }
  .feature-section .vector-image img {
    height: 370px;
  }
  @media (max-width: 1600px) {
    .feature-section .vector-image img {
      height: 300px;
    }
  }
  @media (max-width: 1399px) {
    .feature-section .vector-image img {
      height: 280px;
    }
  }
  .feature-section .round-gif {
    top: unset;
    bottom: 12%;
  }
  @media (max-width: 1660px) {
    .feature-section .round-gif {
      left: 0;
    }
  }
  @media (max-width: 1460px) {
    .feature-section .round-gif {
      bottom: unset;
      top: 6%;
    }
  }
  @media (max-width: 991px) {
    .feature-section .round-gif {
      top: -3%;
    }
  }
  @media (max-width: 575px) {
    .feature-section .round-gif {
      top: -2%;
    }
  }
  .feature-section .round-gif img {
    width: 105px;
    height: 105px;
  }
  @media (max-width: 1460px) {
    .feature-section .round-gif img {
      width: 90px;
      height: 90px;
    }
  }
  @media (max-width: 575px) {
    .feature-section .round-gif img {
      width: 70px;
      height: 70px;
    }
  }
  
  .document-section a {
    display: block;
    color: #2b2b2b;
  }
  .document-section .document-box {
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
    border-radius: 5px;
    padding: 20px;
  }
  @media (max-width: 767px) {
    .document-section .document-box {
      gap: 14px;
    }
  }
  @media (max-width: 575px) {
    .document-section .document-box {
      gap: 10px;
      padding: 15px;
    }
  }
  .document-section .document-box .doc-icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .document-section .document-box .doc-icon img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  .document-section .document-box h5 {
    margin-bottom: 0;
  }
  @media (max-width: 479px) {
    .document-section .row > div {
      width: 100%;
    }
  }
  .document-section .bg-icon1 {
    background: #F0E9FB;
  }
  .document-section .bg-icon2 {
    background: rgba(222, 77, 89, 0.1);
  }
  .document-section .bg-icon3 {
    background: rgba(77, 186, 135, 0.15);
  }
  .document-section .bg-icon4 {
    background: rgba(103, 190, 253, 0.12);
  }
  .document-section .bg-icon5 {
    background: rgba(255, 227, 225, 0.59);
  }
  .document-section .bg-icon6 {
    background: rgba(65, 184, 131, 0.15);
  }
  .document-section .bg-icon7 {
    background: rgba(158, 149, 255, 0.22);
  }
  .document-section .bg-icon8 {
    background: rgba(238, 67, 35, 0.12);
  }
  .document-section .bg-icon9 {
    background: rgba(154, 214, 183, 0.22);
  }
  .document-section .bg-icon10 {
    background: rgba(255, 153, 33, 0.14);
  }
  
  .rating-title {
    font-size: calc(14px + 6 * (100vw - 300px) / 1620);
    font-weight: normal;
  }
  
  .layout h5 {
    margin-bottom: 10px;
    opacity: 0.8;
    letter-spacing: 1.2px;
  }
  .layout .btn {
    padding: 0.15rem 0.5rem;
  }
  
  .common-card {
    background: #FCFCFD;
    border: 2px solid #fff;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    border-radius: 10px;
  }
  
  .testimonial-box {
    position: relative;
    padding: 20px 25px;
    text-align: left;
    margin-bottom: 33px;
  }
  .testimonial-box .rating-content {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  .testimonial-box h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .testimonial-box .text-end span {
    font-size: 13px;
  }
  .testimonial-box .customer-img {
    width: 60px;
    height: 60px;
    background: #FFFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
  }
  .testimonial-box p {
    margin-bottom: 0;
  }
  
  .about-section .slick-slide {
    padding: 0 14px;
  }
  
  .testimonial-slider .slick-dots li {
    width: auto;
    height: auto;
  }
  .testimonial-slider .slick-dots li button {
    background: rgba(82, 82, 108, 0.2);
    border-radius: 20px;
    height: 5px;
    width: 11px;
    padding: 0;
    transition: all 0.5s;
  }
  .testimonial-slider .slick-dots li button::before {
    display: none;
  }
  .testimonial-slider .slick-dots li.slick-active button {
    background: #7366ff;
    border-radius: 20px;
    width: 20px;
    transition: all 0.5s;
  }
  
  .customer-box {
    position: relative;
    background-color: #f7f7f7;
    text-align: center;
    padding: 40px 30px;
    border-radius: 25px;
    box-shadow: 30px 30px 33px rgba(119, 119, 119, 0.25);
  }
  @media (max-width: 1399px) {
    .customer-box {
      padding: 40px 35px;
    }
  }
  @media (max-width: 1199px) {
    .customer-box {
      padding: 30px 25px;
    }
  }
  @media (max-width: 991px) {
    .customer-box {
      padding: 17px 13px;
    }
  }
  @media (max-width: 575px) {
    .customer-box {
      box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
    }
  }
  .customer-box img {
    height: 70px;
    width: auto;
  }
  @media (max-width: 991px) {
    .customer-box img {
      height: 50px;
    }
  }
  
  .customer-wrapper {
    position: relative;
    padding: 12px;
  }
  @media (max-width: 767px) {
    .customer-wrapper {
      padding: 0;
    }
  }
  .customer-wrapper .outline-box {
    position: absolute;
    bottom: -6px;
    left: -5px;
  }
  @media (max-width: 1399px) {
    .customer-wrapper .outline-box {
      bottom: -1px;
      left: 0px;
    }
  }
  @media (max-width: 1199px) {
    .customer-wrapper .outline-box {
      bottom: -1px;
      left: -2px;
      width: 120%;
    }
  }
  @media (max-width: 767px) {
    .customer-wrapper .outline-box {
      display: none;
    }
  }
  
  .customer-wrap {
    margin: 0 -28px;
  }
  @media (max-width: 1199px) {
    .customer-wrap {
      margin: 0 -15px;
    }
  }
  @media (max-width: 991px) {
    .customer-wrap {
      margin: 0 -10px;
    }
  }
  @media (max-width: 767px) {
    .customer-wrap {
      margin: -12px;
    }
  }
  .customer-wrap > div {
    padding: 0 28px;
  }
  @media (max-width: 1199px) {
    .customer-wrap > div {
      padding: 0 15px;
    }
  }
  @media (max-width: 991px) {
    .customer-wrap > div {
      padding: 0 10px;
    }
  }
  @media (max-width: 767px) {
    .customer-wrap > div {
      padding: 12px;
    }
  }
  @media (max-width: 1199px) {
    .customer-wrap > div:last-child .outline-box {
      width: 100%;
    }
  }
  
  .frame-tab {
    background: rgba(244, 245, 248, 0.5);
    border-radius: 40px;
    border: none;
    gap: 10px;
    display: inline-flex;
    padding: 5px;
    margin-bottom: 50px;
  }
  .frame-tab .nav-item img {
    width: 21px;
    height: 21px;
  }
  .frame-tab .nav-item .nav-link {
    font-size: 18px;
    padding: 16px 40px;
    border-radius: 40px;
    background: transparent;
    border: none;
    color: rgba(0, 2, 72, 0.5);
    font-weight: 500;
  }
  @media (max-width: 991px) {
    .frame-tab .nav-item .nav-link {
      font-size: 16px;
      padding: 10px 25px;
    }
  }
  .frame-tab .nav-item .nav-link img {
    margin-right: 10px;
    filter: grayscale(1);
  }
  .frame-tab .nav-item .nav-link.active {
    color: #000248;
    background: #fff;
    box-shadow: 0px 7px 15px rgba(46, 35, 94, 0.06);
  }
  .frame-tab .nav-item .nav-link.active img {
    filter: unset;
  }
  @media (max-width: 575.98px) {
    .frame-tab.nav {
      display: inline-flex;
      background-color: transparent;
    }
  }
  
  .framework-box {
    position: relative;
    display: flex;
    align-items: center;
    background: #FCFCFD;
    border: 2px solid #fff;
    box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
    border-radius: 50px 16px 16px 50px;
    padding: 18px 16px;
    gap: 16px;
    margin-left: 30px;
    transition: all 0.5s;
  }
  .framework-box::after {
    position: absolute;
    content: "";
    width: 40px;
    height: calc(100% + 4px);
    border-right: 1px dashed rgba(0, 2, 72, 0.2);
    right: -1px;
    border-top: 1px dashed rgba(0, 2, 72, 0.2);
    border-bottom: 1px dashed rgba(0, 2, 72, 0.2);
    border-radius: 10px;
    top: -1px;
    bottom: 0;
  }
  .framework-box .frame-icon {
    position: relative;
    min-width: 55px;
    height: 55px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -48px;
  }
  @media (max-width: 575px) {
    .framework-box .frame-icon {
      min-width: 52px;
      height: 52px;
    }
  }
  .framework-box .frame-icon::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 3px 4px 5px rgba(46, 35, 94, 0.11), inset 3px 0px 1px rgba(204, 204, 215, 0.5);
    border-radius: 100%;
  }
  .framework-box .frame-icon img {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 575px) {
    .framework-box .frame-icon img {
      width: 25px;
      height: 25px;
    }
  }
  .framework-box .frame-details {
    position: relative;
    text-align: left;
  }
  .framework-box .frame-details p {
    opacity: 0.7;
    margin-bottom: 0;
  }
  .framework-box .frame-details::before, .framework-box .frame-details::after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #FEFEFF;
    border: 1px solid rgba(0, 2, 72, 0.2);
    border-radius: 100%;
    right: 17px;
  }
  .framework-box .frame-details::before {
    top: -23px;
  }
  .framework-box .frame-details::after {
    bottom: -24px;
  }
  @media (min-width: 576px) {
    .framework-box:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }
  }
  .framework-box:hover .frame-icon::before {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .customization-wrap .landing-title p {
    width: 100%;
  }
  
  .sub-title {
    font-size: calc(18px + 8 * (100vw - 300px) / 1620);
    font-weight: 700;
    font-family: "Caveat", cursive;
    color: #FFB905;
  }
  
  .rotate-title {
    color: #FF5A82;
  }
  
  .landing-home {
    scroll-behavior: smooth;
    overflow: hidden;
    position: relative;
    background-position: bottom;
    background-size: cover;
    // background-image: url(../images/landing/home-bg.jpg);
    background-color: #1b1c21;
  }
  .landing-home .sub-title {
    font-size: calc(18px + 12 * (100vw - 300px) / 1620);
  }
  .landing-home .content h1 {
    color: #fff;
    line-height: 1.2;
    font-size: calc(25px + 35 * (100vw - 300px) / 1620);
    text-transform: uppercase;
  }
  .landing-home .content h1 span {
    background: linear-gradient(90deg, #FFB905 20.35%, #6AEBAD 50%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-home .content p {
    font-size: calc(14px + 4 * (100vw - 300px) / 1620);
    font-weight: 500;
    font-family: roboto;
    color: #fff;
    opacity: 0.8;
    width: 64%;
    margin: 0 auto;
  }
  @media (max-width: 1366px) {
    .landing-home .content p {
      width: 90%;
    }
  }
  @media (max-width: 767px) {
    .landing-home .content p {
      width: 100%;
    }
  }
  .landing-home .navbar-brand img {
    height: 45px;
  }
  .landing-home .btn-grp {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    .landing-home .btn-grp {
      gap: 12px;
    }
  }
  @media (max-width: 575px) {
    .landing-home .btn-grp {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  .landing-home .btn-grp img {
    height: 30px;
  }
  @media (max-width: 767px) {
    .landing-home .btn-grp img {
      height: 22px;
    }
  }
  @media (max-width: 575px) {
    .landing-home .btn-grp img {
      height: 20px;
    }
  }
  .landing-home .screen1 {
    margin-top: -50px;
  }
  @media (max-width: 1660px) {
    .landing-home .screen1 {
      margin-top: -30px;
    }
  }
  .landing-home .content {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  @media (max-width: 1366px) {
    .landing-home .content {
      margin-top: 170px;
    }
  }
  @media (max-width: 1199px) {
    .landing-home .content {
      margin-top: 140px;
    }
  }
  @media (max-width: 575px) {
    .landing-home .content {
      margin-top: 100px;
    }
  }
  .landing-home .content-title {
    position: relative;
    display: inline-block;
  }
  .landing-home .content-title .arrow-decore {
    position: absolute;
    left: -203px;
    top: -25px;
  }
  @media (max-width: 1660px) {
    .landing-home .content-title .arrow-decore {
      left: -166px;
      width: 150px;
    }
  }
  @media (max-width: 991px) {
    .landing-home .content-title .arrow-decore {
      left: -125px;
      width: 110px;
      top: -14px;
    }
  }
  @media (max-width: 575px) {
    .landing-home .content-title .arrow-decore {
      left: -61px;
      width: 60px;
      top: -4px;
    }
  }
  .landing-home .sticky-header header .nav-padding {
    padding: 30px 50px;
  }
  
  .build-section {
    position: relative;
  }
  .build-section .landing-title {
    display: flex;
    align-items: center;
  }
  .build-section .landing-title h2 {
    width: 62%;
  }
  @media (max-width: 1660px) {
    .build-section .landing-title h2 {
      width: 58%;
    }
  }
  @media (max-width: 1366px) {
    .build-section .landing-title h2 {
      width: 59%;
    }
  }
  @media (max-width: 1199px) {
    .build-section .landing-title h2 {
      width: 63%;
    }
  }
  @media (max-width: 991px) {
    .build-section .landing-title h2 {
      width: 100%;
    }
  }
  .build-section .landing-title .title-img {
    margin-bottom: -14px;
  }
  @media (max-width: 991px) {
    .build-section .landing-title .title-img {
      width: 120px;
    }
  }
  @media (max-width: 767px) {
    .build-section .landing-title .title-img {
      width: 80px;
    }
  }
  .build-section .rotate-title {
    width: 14%;
    transform: rotate(13.97deg);
    text-align: center;
    margin-left: -20px;
  }
  @media (max-width: 1199px) {
    .build-section .rotate-title {
      width: 17%;
    }
  }
  @media (max-width: 991px) {
    .build-section .rotate-title {
      width: 30%;
    }
  }
  @media (max-width: 767px) {
    .build-section .rotate-title {
      width: 42%;
    }
  }
  .build-section .build-image img {
    width: calc(100% + 210px);
  }
  @media (max-width: 991px) {
    .build-section .build-image img {
      width: calc(100% + 100px);
    }
  }
  @media (max-width: 767px) {
    .build-section .build-image img {
      width: 100%;
    }
  }
  
  @keyframes animationtop {
    0% {
      transform: translate(-10px, -5px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-10px, -5px);
    }
  }
  @keyframes animationleft {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(20px, 0);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .navbar {
    justify-content: flex-start;
  }
  .navbar .navbar-toggler {
    margin-left: auto;
  }
  
  .navbar-nav {
    align-items: center;
  }
  .navbar-nav .nav-item {
    position: relative;
  }
  .navbar-nav .nav-item .nav-link {
    font-size: 18px;
    letter-spacing: 1px;
    padding: 12px 20px;
    transition: all 0.3s ease;
  }
  .navbar-nav .nav-item .nav-link:hover, .navbar-nav .nav-item .nav-link.active {
    opacity: 1;
    transition: all 0.3s ease;
  }
  
  .landing-page .navbar-expand-xl .navbar-collapse {
    background-color: transparent;
  }
  .landing-page .page-body-wrapper {
    min-height: 100vh;
    background: #eaf4ff;
    overflow: hidden;
  }
  .landing-page .buy-btn {
    font-size: 16px;
    background: linear-gradient(90deg, #7366FF -18.33%, #563EE0 62.78%);
    border-radius: 5px;
    padding: 15px 54px;
    margin-left: 10px;
  }
  @media (max-width: 991px) {
    .landing-page .buy-btn {
      padding: 12px 35px;
    }
  }
  @media (max-width: 767px) {
    .landing-page .buy-btn {
      padding: 9px 20px;
      font-size: 14px;
    }
  }
  .landing-page .buy-btn a {
    color: #fff;
    padding: 0;
  }
  .landing-page .navbar-nav .nav-item .nav-link {
    letter-spacing: 1.5px;
    color: #fff;
  }
  .landing-page .sticky-header header {
    background: transparent;
    box-shadow: none;
    z-index: 100;
    left: 0;
    height: auto;
  }
  .landing-page .sticky-header header.sticky {
    margin-top: 0;
    background: #1d1f29;
  }
  .landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    padding: 15px 12px;
  }
  
  .navbar-expand-xl .navbar-brand {
    padding-left: 18px;
  }
  .navbar-expand-xl .navbar-collapse {
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: #fff;
  }
  
  .section-space {
    position: relative;
    padding: 80px 0;
    text-align: center;
  }
  .section-space p {
    font-size: 15px;
    line-height: 1.5;
  }
  
  .landing-title {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    .landing-title {
      margin-bottom: 20px;
    }
  }
  .landing-title h2 {
    font-size: calc(25px + 27 * (100vw - 300px) / 1620);
  }
  .landing-title .gradient-1 {
    background: linear-gradient(90.78deg, #7366ff 49.33%, #f73164 82.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-2 {
    background: linear-gradient(90deg, #FF6A5B 4.44%, #1E52B9 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-3 {
    background: linear-gradient(90deg, #7B5DFE 11.24%, #FFAB2E 50%), linear-gradient(0deg, #FF6A5B, #FF6A5B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-4 {
    background: linear-gradient(90deg, #22AC16 65.06%, #1C97BE 92.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-5 {
    background: linear-gradient(90deg, #6A2FE7 2%, #46B7D0 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-6 {
    background: linear-gradient(271deg, #F1305F 30%, #0066FF 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-7 {
    background: linear-gradient(90deg, #FF7A00 20%, #D72695 89.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-8 {
    background: linear-gradient(257.2deg, #FF5E51 21.09%, #FFAB6F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-9 {
    background: linear-gradient(90deg, rgba(255, 57, 57, 0.88) 2%, #24D34B 103.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-10 {
    background: linear-gradient(90deg, #4A86FB 50%, #FF5823 68.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-11 {
    background: linear-gradient(90deg, #FF1717 22.23%, #0A96E6 68.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-12 {
    background: linear-gradient(90deg, #89D32B 5.6%, #FF7A00 36.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title .gradient-13 {
    background: linear-gradient(90deg, #FF6056 31.63%, #7F74FE 67.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .landing-title p {
    font-size: calc(14px + 4 * (100vw - 300px) / 1620);
    width: 65%;
    margin: 0 auto;
    color: #52526C;
    opacity: 0.8;
  }
  @media (max-width: 1199px) {
    .landing-title p {
      width: 80%;
    }
  }
  @media (max-width: 991px) {
    .landing-title p {
      width: 100%;
    }
  }
  
  .bottom-section-grad {
    position: relative;
  }
  .bottom-section-grad::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #EDF7FF -3.32%, rgba(227, 242, 255, 0) 144.14%);
    opacity: 0.7;
    height: 100%;
    width: 100%;
  }
  
  .faq-section .accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .faq-section .accordion .accordion-item {
    background: #FCFCFD;
    border: 2px solid #fff;
    box-shadow: 0px 4px 10px rgba(46, 35, 94, 0.07);
    border-radius: 10px;
    padding: 22px 30px;
  }
  @media (max-width: 767px) {
    .faq-section .accordion .accordion-item {
      padding: 15px 20px;
    }
  }
  .faq-section .accordion-button, .faq-section .accordion-body {
    padding: 0;
  }
  .faq-section .accordion-button:not(.collapsed) {
    color: #7366ff;
    background: transparent;
  }
  .faq-section .accordion-button:focus {
    box-shadow: unset;
    border: none;
  }
  .faq-section .accordion-button::after {
    font-family: "IcoFont";
    content: "\eb25";
    font-size: 24px;
    background: unset;
    width: auto;
    height: auto;
    margin-bottom: -4px;
  }
  .faq-section .accordion-body {
    padding-top: 14px;
    margin-top: 14px;
    border-top: 1px solid rgba(82, 82, 108, 0.5);
    text-align: left;
  }
  .faq-section .vector-image {
    left: -160px;
    top: 30px;
  }
  @media (max-width: 1600px) {
    .faq-section .vector-image {
      left: 60px;
      top: -30px;
    }
  }
  @media (max-width: 1199px) {
    .faq-section .vector-image {
      left: 0px;
      top: -30px;
    }
  }
  .faq-section .vector-image img {
    height: 400px;
  }
  @media (max-width: 1600px) {
    .faq-section .vector-image img {
      height: 270px;
    }
  }
  
  .pricing-section .landing-title p {
    width: 100%;
  }
  .pricing-section .loader-gif {
    top: -5%;
  }
  @media (max-width: 1600px) {
    .pricing-section .loader-gif {
      top: -11%;
      right: 2%;
    }
  }
  @media (max-width: 991px) {
    .pricing-section .loader-gif {
      top: -2%;
      right: 2%;
    }
  }
  .pricing-section .loader-gif .loader-1 {
    width: 56px;
    height: 56px;
  }
  .pricing-section .loader-gif .loader-1::after, .pricing-section .loader-gif .loader-1::before {
    width: 56px;
    height: 56px;
  }
  @media (max-width: 575px) {
    .pricing-section .loader-gif .loader-1 {
      width: 40px;
      height: 40px;
    }
    .pricing-section .loader-gif .loader-1::after, .pricing-section .loader-gif .loader-1::before {
      width: 40px;
      height: 40px;
    }
  }
  .pricing-section .loader-line-gif {
    right: unset;
    top: unset;
    left: 22%;
    bottom: 15%;
  }
  @media (max-width: 1600px) {
    .pricing-section .loader-line-gif {
      left: 12%;
    }
  }
  @media (max-width: 1600px) {
    .pricing-section .loader-line-gif {
      left: 16%;
    }
  }
  @media (max-width: 991px) {
    .pricing-section .loader-line-gif {
      left: 10px;
      bottom: -20px;
    }
    .pricing-section .loader-line-gif img {
      width: 90px;
    }
  }
  
  .pricing-box {
    background: rgba(243, 245, 245, 0.5);
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 30px 20px;
  }
  .pricing-box img {
    width: 23px;
    height: 22px;
  }
  @media (max-width: 767px) {
    .pricing-box {
      padding: 20px 15px;
    }
  }
  .pricing-box p {
    color: #52526C;
  }
  .pricing-box h4 {
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    .pricing-box h4 {
      margin-bottom: 15px;
    }
  }
  .pricing-box p {
    line-height: 1.5;
  }
  .pricing-box .purchase-details {
    background: #FCFCFD;
    border: 2px solid #fff;
    box-shadow: 0px 4px 20px rgba(46, 35, 94, 0.05);
    border-radius: 10px;
    padding: 24px 40px;
    margin-top: 20px;
  }
  @media (max-width: 1199px) {
    .pricing-box .purchase-details {
      padding: 24px 20px;
    }
  }
  .pricing-box .purchase-details h2 {
    margin-bottom: 14px;
  }
  .pricing-box .purchase-details .btn-lg {
    font-weight: 500;
    padding: 12px 20px;
    width: 100%;
    min-width: auto;
  }
  @media (max-width: 1199px) {
    .pricing-box .purchase-details .btn-lg {
      padding: 12px 10px;
      font-size: 15px;
    }
  }
  
  .license-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  @media (max-width: 767px) {
    .license-list {
      gap: 10px;
    }
  }
  .license-list li {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
  }
  @media (max-width: 991px) {
    .license-list li {
      gap: 5px;
    }
  }
  @media (max-width: 575px) {
    .license-list li img {
      width: 18px;
      height: 18px;
    }
  }
  .license-list li h6 {
    font-weight: normal;
    margin-bottom: 0;
  }
  
  .feature-box {
    padding: 30px 20px;
    text-align: left;
    position: relative;
  }
  @media (max-width: 575px) {
    .feature-box {
      padding: 20px;
    }
  }
  .feature-box::after {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    bottom: 0;
    left: 0;
    background: #F94646;
    border-radius: 0 0 10px 10px;
    transition: width 0.5s;
  }
  .feature-box:hover::after {
    width: 100%;
    transition: width 0.5s;
  }
  .feature-box .feature-icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 25px;
  }
  .feature-box.bg-feature-1 .feature-icon {
    background: #FBECEC;
  }
  .feature-box.bg-feature-2 .feature-icon {
    background: #F0E9FB;
  }
  .feature-box.bg-feature-2::after {
    background: #6610F2;
  }
  .feature-box.bg-feature-3 .feature-icon {
    background: rgba(41, 156, 252, 0.1);
  }
  .feature-box.bg-feature-3::after {
    background: #43BCFF;
  }
  .feature-box.bg-feature-4 .feature-icon {
    background: rgba(96, 177, 88, 0.15);
  }
  .feature-box.bg-feature-4::after {
    background: #60B158;
  }
  .feature-box.bg-feature-5 .feature-icon {
    background: rgba(255, 150, 39, 0.1);
  }
  .feature-box.bg-feature-5::after {
    background: #FF9414;
  }
  .feature-box.bg-feature-6 .feature-icon {
    background: rgba(222, 77, 89, 0.1);
  }
  .feature-box.bg-feature-6::after {
    background: #FC6773;
  }
  .feature-box.bg-feature-7 .feature-icon {
    background: rgba(255, 132, 62, 0.1);
  }
  .feature-box.bg-feature-7::after {
    background: #FF843E;
  }
  .feature-box.bg-feature-8 .feature-icon {
    background: rgba(221, 84, 255, 0.1);
  }
  .feature-box.bg-feature-8::after {
    background: #FF81FF;
  }
  
  .benefit-box {
    position: relative;
    background: rgba(241, 242, 242, 0.5);
    padding: 40px 10px;
    box-shadow: 17px 17px 20px rgba(186, 186, 187, 0.3);
    border-radius: 30px;
  }
  @media (max-width: 991px) {
    .benefit-box {
      padding: 30px 10px;
    }
  }
  @media (max-width: 575px) {
    .benefit-box {
      padding: 20px 10px;
    }
  }
  .benefit-box svg {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 991px) {
    .benefit-box svg {
      width: 30px;
      height: 30px;
    }
  }
  .benefit-box::after {
    position: absolute;
    content: "";
    width: 54px;
    height: 8px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0 0;
  }
  @media (max-width: 991px) {
    .benefit-box h2 {
      font-size: 25px;
    }
  }
  .benefit-box h6 {
    font-weight: 400;
    color: #52526C;
    opacity: 0.7;
  }
  .benefit-box.pink h2 {
    color: #FF3692;
  }
  .benefit-box.pink::after {
    background: #FF3692;
  }
  .benefit-box.purple h2 {
    color: #7238FF;
  }
  .benefit-box.purple::after {
    background: #7238FF;
  }
  .benefit-box.red h2 {
    color: #FF3556;
  }
  .benefit-box.red::after {
    background: #FF3556;
  }
  .benefit-box.warning h2 {
    color: #FFAA05;
  }
  .benefit-box.warning::after {
    background: #FFAA05;
  }
  
  .rating-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rating-title .decore-1 {
    margin-top: -35px;
    animation: animationtop 5s infinite;
  }
  
  @keyframes arrowsvg {
    0% {
      transform: scale(0);
      transform-origin: left;
    }
    100% {
      transform: scale(1);
      transform-origin: left;
    }
  }
  .title-svg {
    animation: arrowsvg 5s infinite;
  }
  
  .ratings li i {
    color: #FFAA05;
  }
  
  .components-section {
    background: linear-gradient(179deg, rgba(247, 248, 249, 0) 8.83%, #F7F8F9 50%);
  }
  .components-section .vector-image {
    left: 30px;
    top: 110px;
  }
  @media (max-width: 1660px) {
    .components-section .vector-image {
      left: 50px;
      top: 80px;
    }
  }
  @media (max-width: 1399px) {
    .components-section .vector-image {
      left: 30px;
    }
  }
  @media (max-width: 1199px) {
    .components-section .vector-image {
      left: -16px;
    }
  }
  .components-section .vector-image img {
    height: 280px;
  }
  @media (max-width: 1660px) {
    .components-section .vector-image img {
      height: 240px;
    }
  }
  @media (max-width: 1399px) {
    .components-section .vector-image img {
      height: 220px;
    }
  }
  @media (max-width: 1199px) {
    .components-section .vector-image img {
      height: 190px;
    }
  }
  
  .component_responsive {
    flex-wrap: initial;
  }
  
  .component-hover-effect {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #FCFCFD;
    border: 2px solid #fff;
    box-shadow: 0px 4px 10px rgba(46, 35, 94, 0.07);
    border-radius: 5px;
    padding: 20px;
    justify-content: center;
    min-height: 78px;
  }
  @media (max-width: 767px) {
    .component-hover-effect {
      padding: 13px;
      min-height: 68px;
    }
  }
  .component-hover-effect svg {
    width: 26px;
    height: 26px;
  }
  
  .ptb50 {
    padding: 80px 0;
  }
  @media (max-width: 991.98px) {
    .ptb50 {
      padding: 60px 0;
    }
  }
  @media (max-width: 575.98px) {
    .ptb50 {
      padding: 50px 0;
    }
  }
  
  .purchase-btn {
    background: linear-gradient(90deg, #7366FF -18.33%, #563EE0 62.78%);
    padding: 12px 20px;
    min-width: 200px;
    border: none;
  }
  @media (max-width: 991px) {
    .purchase-btn {
      min-width: 160px;
    }
  }
  @media (max-width: 767px) {
    .purchase-btn {
      min-width: 140px;
      padding: 10px 17px;
      font-size: 16px;
    }
  }
  
  .footer-bg {
    position: relative;
    text-align: center;
    // background: url(../images/landing/footer.jpg);
    margin-left: 0;
    color: #fff;
  }
  @media (max-width: 575px) {
    .footer-bg {
      background-position: center;
    }
  }
  
  @keyframes move_wave {
    0% {
      transform: translateX(0) translateZ(0) scaleY(1);
    }
    50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.55);
    }
    100% {
      transform: translateX(-50%) translateZ(0) scaleY(1);
    }
  }
  footer h2 {
    font-size: calc(24px + 46 * (100vw - 300px) / 1620);
  }
  footer .landing-title p {
    color: #fff;
    opacity: 0.7;
  }
  footer .license-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: calc(15px + 15 * (100vw - 300px) / 1620);
    gap: calc(15px + 15 * (100vw - 300px) / 1620);
  }
  @media (max-width: 991px) {
    footer .license-list {
      gap: 20px;
    }
  }
  @media (max-width: 767px) {
    footer .license-list {
      gap: 15px;
    }
  }
  @media (max-width: 575px) {
    footer .license-list {
      flex-wrap: wrap;
    }
  }
  footer .license-list h6 {
    color: #fff;
  }
  footer .feature-content {
    margin-bottom: calc(25px + 35 * (100vw - 300px) / 1620);
  }
  footer .feature-content ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(25px + 15 * (100vw - 300px) / 1620);
  }
  @media (max-width: 767px) {
    footer .feature-content ul {
      gap: 15px;
    }
  }
  @media (max-width: 575px) {
    footer .feature-content ul {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  footer .feature-content ul li h4 {
    max-width: 205px;
    margin: 0 auto;
  }
  @media (max-width: 1199px) {
    footer .feature-content ul li h4 {
      max-width: 200px;
    }
  }
  @media (max-width: 991px) {
    footer .feature-content ul li h4 {
      max-width: 140px;
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    footer .feature-content ul li h4 {
      max-width: 116px;
      font-size: 16px;
    }
  }
  @media (max-width: 575px) {
    footer .feature-content ul li h4 {
      max-width: 105px;
      font-size: 14px;
    }
  }
  footer .feature-content ul li img {
    margin-top: -40px;
  }
  @media (max-width: 991px) {
    footer .feature-content ul li img {
      width: 220px;
    }
  }
  @media (max-width: 767px) {
    footer .feature-content ul li img {
      width: 160px;
      margin-top: -25px;
    }
  }
  @media (max-width: 575px) {
    footer .feature-content ul li img {
      width: 140px;
    }
  }
  footer .sub-footer {
    padding: 20px 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  @media (max-width: 575px) {
    footer .sub-footer {
      padding: 12px 0px;
    }
  }
  footer .sub-footer h6 {
    font-weight: normal;
  }
  footer .sub-footer ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  footer .sub-footer ul li a {
    color: #fff;
  }
  footer .sub-footer ul li a:hover {
    color: #7366ff;
  }
  footer .sub-footer ul li i {
    font-size: 20px;
  }
  
  .page-wrapper .bg-Widget {
    background: linear-gradient(180deg, #F7F8F9 69%, rgba(247, 248, 249, 0) 91.83%);
  }
  .page-wrapper .bg-Widget:before {
    top: 220px !important;
    transform: rotate(-40deg);
  }
  .page-wrapper .bg-Widget:after {
    top: unset !important;
    bottom: 0 !important;
  }
  .page-wrapper .bg-Widget .cuba-demo-content p {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper .bg-Widget .cuba-demo-content .couting h2 {
    -webkit-text-fill-color: #fff !important;
  }
  
  .btn-md:hover {
    animation: 0.3s pulse 3;
  }
  
  .tap-top {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 5;
    color: #fff;
    background: var(--theme-default);
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px var(--theme-default);
    opacity: 0.5;
    transition: all 0.3s ease;
  }
  .tap-top:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }
  .tap-top svg {
    width: 20px;
  }
  
  @keyframes animationFramesOne {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
      transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
      transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
      transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }
  .page-wrapper {
    overflow: hidden;
  }
  .page-wrapper .section-space {
    padding: 80px 0;
  }
  .page-wrapper .customer-support {
    padding-top: 21px !important;
  }
  .page-wrapper .cuba-demo-content {
    text-align: center;
    position: relative;
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    .page-wrapper .cuba-demo-content {
      margin-bottom: 30px;
    }
  }
  .page-wrapper .cuba-demo-content img {
    margin: 0 auto;
    width: 70px;
    animation: rotated 50s infinite linear;
  }
  .page-wrapper .cuba-demo-section {
    position: relative;
  }
  .page-wrapper .cuba-demo-section .img-effect {
    -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
    border-radius: 15px;
    padding: 20px;
    position: relative;
  }
  .page-wrapper .cuba-demo-section .img-effect .hover-link {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(10deg, rgba(44, 50, 63, 0.8) 0%, transparent 80%);
    border-radius: 15px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
    opacity: 0;
    transition: all 0.3s ease;
    left: 0;
    top: 0;
  }
  .page-wrapper .cuba-demo-section .img-effect:hover .hover-link {
    opacity: 1;
    z-index: 1;
    transition: all 0.3s ease;
  }
  .page-wrapper .cuba-demo-section .img-effect img {
    width: 100%;
    transition: all 0.3s ease;
  }
  .page-wrapper .cuba-demo-section .img-effect:hover {
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
    transition: all 0.3s ease;
  }
  .page-wrapper .cuba-demo-section .img-effect:hover img {
    transform: scale(1.01);
    transition: all 0.3s ease;
  }
  .page-wrapper .cuba-demo-section .cuba-demo-content .couting {
    margin-bottom: -10px;
    margin-top: calc(-15px + -17 * (100vw - 300px) / 1620);
  }
  .page-wrapper .cuba-demo-section .cuba-demo-content .couting h2 {
    color: var(--theme-default);
    font-weight: 500;
    font-size: calc(100px + 150 * (100vw - 300px) / 1620);
    background: linear-gradient(180deg, #5456AA 0%, rgba(0, 2, 72, 0) 88.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    line-height: 1;
    opacity: 0.1;
  }
  
  .fluid-space {
    padding: 0 calc(10px + 70 * (100vw - 300px) / 1620);
  }
  @media (max-width: 1399px) {
    .fluid-space {
      padding: 0 30px;
    }
  }
  @media (max-width: 575px) {
    .fluid-space {
      padding: 0 12px;
    }
  }
  
  ul.dot-group {
    display: flex;
    gap: 6px;
    padding: 15px 10px 0;
  }
  ul.dot-group li {
    width: 8px;
    height: 8px;
    background: #E8E8EC;
    border-radius: 100%;
  }
  
  .demo-imgs {
    margin: -15px;
  }
  .demo-imgs .demo-content .cuba-demo-img {
    box-shadow: 0px 9px 40px rgba(46, 35, 94, 0.15);
    border-radius: 5px;
    position: relative;
  }
  .demo-imgs .demo-content .cuba-demo-img .hover-link {
    padding: 20px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 0px 0px 5px 5px;
  }
  @media (max-width: 1640px) {
    .demo-imgs .demo-content .cuba-demo-img .hover-link {
      padding: 20px 12px;
      gap: 10px;
    }
  }
  @media (max-width: 1366px) {
    .demo-imgs .demo-content .cuba-demo-img .hover-link {
      padding: 20px;
    }
  }
  .demo-imgs .demo-content .cuba-demo-img .hover-link .link-btn {
    cursor: not-allowed;
  }
  .demo-imgs .demo-content .cuba-demo-img .hover-link a {
    color: #fff;
    display: inline-block;
    border-radius: 14px;
    background: #f8f8f8;
    transition: all 0.3s ease;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 7px 7px 5px rgba(192, 202, 216, 0.22);
    position: relative;
  }
  @media (max-width: 1640px) {
    .demo-imgs .demo-content .cuba-demo-img .hover-link a {
      width: 55px;
      height: 55px;
    }
  }
  .demo-imgs .demo-content .cuba-demo-img .hover-link a img {
    margin-bottom: -2px;
  }
  .demo-imgs .demo-content .cuba-demo-img .hover-link .coming-soon {
    opacity: 0.4;
    pointer-events: none;
  }
  .demo-imgs .demo-content .cuba-demo-img .overflow-hidden {
    padding: 10px;
  }
  .demo-imgs .demo-content .cuba-demo-img .overflow-hidden img {
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .demo-imgs .demo-content .cuba-demo-img:hover .overflow-hidden img {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  .demo-imgs .demo-content .title-wrapper {
    padding: 0 10px 0;
  }
  .demo-imgs .demo-content .title-wrapper .theme-name {
    position: relative;
    font-size: calc(15px + 5 * (100vw - 300px) / 1620);
    text-transform: capitalize;
  }
  .demo-imgs .demo-content .title-wrapper .theme-name span {
    color: #52526C;
  }
  @media (max-width: 1670px) {
    .demo-imgs > div {
      padding: 15px;
      margin: 0;
    }
  }
  
  .app-box {
    padding: 18px 12px 12px;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 5px;
  }
  .app-box h6 {
    color: #52526C;
    margin: 0 12px 0 0;
    position: relative;
  }
  .app-box h6 .app-title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
  .app-box h6 .line {
    width: 1px;
    background: #52526C;
    height: 18px;
    position: absolute;
    top: -30px;
    left: 50%;
  }
  .app-box .app-image {
    flex: auto;
  }
  .app-box .app-image .img-effect {
    box-shadow: 0px 3px 20px rgba(46, 35, 94, 0.1);
    border-radius: 5px;
  }
  .app-box .app-image a {
    display: block;
  }
  .app-box .app-image a img {
    width: 100%;
  }
  .app-box ul.dot-group {
    padding: 0 0 13px;
  }
  
  .app-bg-1 {
    background: #F9F7F7;
  }
  
  .app-bg-2 {
    background: #F3F5F5;
  }
  
  .app-bg-3 {
    background: #EFF5F8;
  }
  
  .app-bg-4 {
    background: #F0F5F7;
  }
  
  .app-bg-5 {
    background: #F5F4F3;
  }
  
  .app-bg-6 {
    background: #F8F8FA;
  }
  
  .text-marqee {
    height: 230px;
  }
  @media (max-width: 1199px) {
    .text-marqee {
      height: 166px;
    }
  }
  @media (max-width: 991px) {
    .text-marqee {
      height: 130px;
    }
  }
  @media (max-width: 767px) {
    .text-marqee {
      height: 95px;
    }
  }
  @media (max-width: 575px) {
    .text-marqee {
      height: 65px;
    }
  }
  
  .decoration li {
    position: absolute;
  }
  
  .flower-gif {
    top: -40px;
    left: 19%;
  }
  @media (max-width: 991px) {
    .flower-gif {
      top: 0;
      left: 15%;
    }
  }
  @media (max-width: 767px) {
    .flower-gif {
      left: 8%;
    }
  }
  @media (max-width: 575px) {
    .flower-gif {
      display: none;
    }
  }
  
  .big-title {
    font-weight: 700;
    font-size: 200px;
    line-height: 229px;
    letter-spacing: -0.045em;
    color: rgba(234, 235, 241, 0.5);
    margin-bottom: 0;
  }
  @media (max-width: 1199px) {
    .big-title {
      font-size: 140px;
      line-height: 170px;
    }
  }
  @media (max-width: 991px) {
    .big-title {
      font-size: 110px;
      line-height: 140px;
    }
  }
  @media (max-width: 767px) {
    .big-title {
      font-size: 80px;
      line-height: 100px;
    }
  }
  @media (max-width: 575px) {
    .big-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  .navabr_btn-set {
    position: relative;
  }
  .navabr_btn-set:focus {
    outline: 0;
  }
  .navabr_btn-set span {
    display: block;
    background-color: #fff;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  .navabr_btn-set:not(.custom_nav) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
  }
  .navabr_btn-set:not(.custom_nav) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  .navabr_btn-set:not(.custom_nav) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
  }
  
  .navbar-b {
    transition: all 0.5s ease-in-out;
    background-color: transparent;
    padding-top: 1.563rem;
    padding-bottom: 1.563rem;
  }
  
  .fixed-top {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    left: 0;
    z-index: 1030;
  }
  
  .navbar-toggler span {
    display: block;
    background-color: transparent;
    height: 3px;
    width: 25px;
    z-index: 112;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  .navbar-toggler span:nth-child(1) {
    transition: transform 0.35s ease-in-out;
  }
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  
  .navbar_nav_modify li {
    text-align: center;
  }
  
  .sticky-header header {
    position: fixed;
    width: 100%;
    z-index: 6;
    text-align: center;
    line-height: normal;
    height: 80px;
    background: var(--theme-default);
    color: #fff;
    box-shadow: 0 0 5px 1px rgba(5, 56, 111, 0.1);
    transition: all 0.4s ease;
  }
  .sticky-header header .nav-padding {
    padding: 14px;
  }
  .sticky-header header.sticky {
    line-height: normal;
    background: var(--theme-default);
    text-align: left;
  }
  .sticky-header header.sticky .nav-padding {
    padding: 7px 14px;
  }
  
  .landing-home .sticky .navbar-brand img {
    max-height: 35px;
  }
  
  .title-padding {
    padding: 130px 0;
  }
  
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-4px);
    }
  }
  @media (max-width: 1440px) {
    .navbar-nav .nav-item .nav-link {
      font-size: 16px;
      padding: 12px 7px;
    }
  }
  /* ========= responsive scss for 1200 screen ========= */
  @media only screen and (max-width: 1199px) {
    .sticky-header header.sticky .nav-padding {
      padding: 15px 25px;
    }
    .navbar-toggler span {
      background-color: #fff;
    }
    .landing-home .screen1 {
      left: 100px;
      margin-top: 0;
    }
    .sticky-header .navbar-nav .nav-item .nav-link {
      color: #000;
      padding: 10px 12px !important;
      font-size: 15px;
    }
    .sticky-header .navbar-nav li {
      display: block;
      width: 100%;
    }
    .landing-page .navbar-expand-xl .navbar-collapse {
      background-color: white;
      margin-top: 20px;
      position: absolute;
      width: calc(100% - 30px);
      left: 15px;
      top: 70px;
    }
  }
  @media only screen and (max-width: 1199px) and (max-width: 575px) {
    .landing-page .navbar-expand-xl .navbar-collapse {
      top: 40px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .landing-page .navbar-expand-xl .navbar-collapse .navbar-nav {
      padding: 20px 30px 30px 30px;
    }
  }
  @media only screen and (max-width: 1199px) {
    header.sticky .navbar-expand-xl .navbar-collapse {
      top: 50px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .landing-main .sticky .navbar-expand-xl .navbar-brand {
      top: 8px;
    }
    .landing-main .sticky .navbar-expand-xl .navabr_btn-set {
      top: 16px;
    }
    .landing-main .navbar-toggler span {
      background-color: #fff;
    }
    .landing-main .navbar_nav_modify {
      width: 100vw;
      left: 0;
      position: fixed;
      height: 100vh;
      background-color: #293240;
      top: 0;
      padding: 120px 0px;
    }
    .landing-main h4 {
      font-size: 20px;
    }
    .landing-main .navbar-expand-xl .navabr_btn-set {
      position: fixed;
      top: 24px;
      z-index: 1;
      right: 20px;
    }
    .landing-main .navbar-expand-xl .navbar-brand {
      padding-left: 5px;
      z-index: 1;
      position: fixed;
      top: 14px;
    }
    .landing-main .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item a {
      padding: 28px 60px;
      width: 16px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .landing-main .navbar-expand-lg .navbar-brand {
      padding-left: 5px;
    }
    .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
      padding: 9px;
    }
  }
  /* ========= responsive scss for 992 screen ========= */
  @media only screen and (max-width: 991px) {
    .landing-home .navbar-brand img {
      height: 35px;
    }
    .frameworks-section > .container {
      max-width: 100%;
    }
    .landing-main .navbar-expand-lg .navbar-collapse {
      position: absolute;
      top: 90px;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 30px);
      border-radius: 5px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      padding: 15px;
    }
    .landing-main .section-space {
      padding: 60px 0;
    }
    .landing-main .section-space p {
      font-size: 16px;
    }
    .landing-main .navbar-expand-lg .navbar-toggler {
      background-color: #fff;
    }
    .landing-main .navbar-expand-lg .navbar-toggler .navbar-toggler-icon i {
      margin-top: 5px;
      color: var(--theme-default);
    }
    .landing-main .navbar-expand-lg .navbar-collapse {
      background-color: #fff;
      z-index: 111;
    }
    .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
      color: #000;
    }
    .landing-main .footer-bg h2 {
      font-size: 28px;
    }
  }
  /* ========= responsive scss for 768 screen ========= */
  @media only screen and (max-width: 767px) {
    .landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
      font-size: 14px;
      padding: 10px 12px;
    }
  }
  /* ========= responsive scss for 575 screen ========= */
  @media only screen and (max-width: 575px) {
    .landing-home .sticky-header header .nav-padding {
      padding: 15px 30px;
    }
    .page-wrapper .cuba-demo-content {
      margin-bottom: 20px;
    }
    .page-wrapper .section-space {
      padding: 40px 0;
    }
    .landing-main h4 {
      font-size: 17px;
    }
    .landing-main .footer-bg h2 {
      font-size: 21px;
    }
  }
  @media only screen and (max-width: 480px) {
    .sticky-header header.sticky {
      height: 56px;
    }
    .landing-home .sticky-header header .nav-padding {
      padding: 15px;
    }
    .landing-home .navbar-toggler {
      border: none;
      background-color: transparent;
      padding: 0;
    }
    .landing-home .navbar-brand img {
      height: 25px;
    }
  }
  @media only screen and (max-width: 380px) {
    .component-col-set {
      width: 100%;
    }
  }
  /**=====================
       38. Landing CSS Ends
  ==========================**/
  /**=====================
       76. Language CSS start
  ==========================**/
  .current_lang {
    cursor: pointer;
    text-transform: uppercase;
    overflow: hidden;
  }
  
  .lang.selected {
    display: none;
  }
  .lang span.lang-txt {
    display: inline-flex;
    margin-left: 8px;
    font-weight: 500;
  }
  .lang span span {
    color: #999;
    font-weight: 400;
    margin-left: 5px;
  }
  
  /*more lang*/
  .more_lang {
    transform: translateY(-20px);
    opacity: 0;
    cursor: pointer;
    display: none;
    transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
  }
  
  .translate_wrapper.active .more_lang {
    display: block;
    position: absolute;
    background-color: #fff;
    top: 47px;
    right: 0;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  }
  .translate_wrapper.active .more_lang:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    content: "";
    top: -7px;
    position: absolute;
    right: 20px;
    z-index: 2;
  }
  .translate_wrapper.active .more_lang:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #bdc2c5;
    content: "";
    top: -7px;
    position: absolute;
    right: 20px;
    z-index: 1;
  }
  
  .more_lang.active {
    opacity: 1;
    transform: translateY(0px);
  }
  .more_lang .lang {
    padding: 10px 15px;
    display: flex;
  }
  .more_lang .lang:hover {
    background: var(--theme-default);
    color: #fff;
  }
  .more_lang .lang:hover span {
    color: #fff;
  }
  
  .translate_wrapper.active .more_lang .lang {
    border-bottom: 1px solid #eaeaea;
  }
  
  /**=====================
       76. Language CSS end
  ==========================**/
  /**=====================
       75. Learning CSS start
  ==========================**/
  .learning-header {
    margin-bottom: 10px;
  }
  
  .categories {
    padding: 30px 0;
  }
  .categories .learning-header {
    padding-left: 30px;
  }
  .categories ul li {
    padding: 10px 30px;
  }
  .categories ul li a {
    color: #2b2b2b;
  }
  .categories ul li:hover {
    background-color: #f4f4f4;
  }
  
  .upcoming-course .media + .media {
    margin-top: 30px;
  }
  
  .job-accordion {
    margin-bottom: 0;
  }
  
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .learning-comment {
      margin-left: -14px !important;
      float: left !important;
    }
    .blog-box .blog-details .blog-social li:first-child {
      padding-right: 30px;
    }
    .blog-box .blog-details .blog-social li + li {
      padding-left: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    .categories {
      padding: 20px 0;
    }
    .categories .learning-header {
      padding-left: 20px;
    }
    .categories ul li {
      padding: 10px 20px;
    }
    .upcoming-course .media + .media {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .categories {
      padding: 15px 0;
    }
    .categories .learning-header {
      padding-left: 15px;
    }
    .categories ul li {
      padding: 10px 15px;
    }
    .upcoming-course .media + .media {
      margin-top: 15px;
    }
  }
  /**=====================
       75. Learning CSS end
  ==========================**/
  /**=====================
      25. Authentication CSS Start
  ==========================**/
  .login-card {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;    
    background: url("/assets/images/bg-login.jpg");
    background-position: center;
    padding: 30px 12px;
  }
  .login-card .logo {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }
  .login-card .btn-showcase .btn {
    line-height: 1;
    padding: 10px 15px;
    margin: 0;
  }
  .login-card .btn-showcase .btn + .btn {
    margin-left: 5px;
  }
  .login-card .btn-showcase .btn svg {
    height: 16px;
    vertical-align: bottom;
  }
  .login-card .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: #fff;
  }
  .login-card .login-main .theme-form h4 {
    margin-bottom: 5px;
  }
  .login-card .login-main .theme-form label {
    font-size: 15px;
    letter-spacing: 0.4px;
  }
  .login-card .login-main .theme-form .checkbox label::before {
    background-color: #f9f9fa;
    border: 1px solid #dfdfdf;
  }
  .login-card .login-main .theme-form .or {
    position: relative;
  }
  .login-card .login-main .theme-form .or:before {
    content: "";
    position: absolute;
    width: 65%;
    height: 2px;
    background-color: #f3f3ff;
    top: 9px;
    z-index: 0;
    right: 0;
  }
  // .login-card .login-main .theme-form input {
  //   background-color: #f3f3ff;
  //   transition: all 0.3s ease;
  // }
  // .login-card .login-main .theme-form input::-webkit-input-placeholder {
  //   color: #999999;
  // }
  // .login-card .login-main .theme-form input:hover, .login-card .login-main .theme-form input:focus {
  //   border: 1px solid #b9c1cc;
  //   box-shadow: none !important;
  //   transition: all 0.3s ease;
  // }
  .login-card .login-main .theme-form p {
    margin-bottom: 25px;
    font-size: 14px;
    color: #898989;
  }
  .login-card .login-main .theme-form .form-group {
    margin-bottom: 10px;
    position: relative;
  }
  .login-card .login-main .theme-form .link {
    position: absolute;
    top: 10px;
    right: 0;
  }
  
  .show-hide {
    position: absolute;
    top: 52px;
    right: 20px;
    transform: translateY(-50%);
  }
  .show-hide span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-default);
  }
  .show-hide span.show:before {
    content: "show";
  }
  .show-hide span:before {
    content: "hide";
  }
  
  .needs-validation .invalid-feedback {
    color: #898989;
  }
  .needs-validation .show-hide {
    right: 30px;
  }
  .needs-validation .invalid-tooltip {
    top: 10px;
    right: 10px;
  }
  
  /**=====================
      25. Authentication CSS Ends
  ==========================**/
  /**=====================
     19. Mega option CSS Start
  ==========================**/
  .mega-inline {
    margin-bottom: -30px;
    display: flex;
  }
  .mega-inline .card {
    border: 1px solid #f3f3f3;
    box-shadow: 0 0 10px 0 rgba(68, 102, 242, 0.05);
  }
  .mega-inline .mega-title-badge {
    display: table;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .mega-inline .media .radio {
    margin-left: 3px;
  }
  
  .mega-vertical {
    display: flex;
    flex-wrap: wrap;
  }
  .mega-vertical .card {
    box-shadow: 0 0 10px 0 rgba(68, 102, 242, 0.05);
  }
  .mega-vertical .mega-title-badge {
    display: table;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .mega-vertical .media .radio {
    margin-left: 3px;
  }
  
  .mega-title {
    font-weight: 600;
  }
  
  .mega-horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  .mega-horizontal .card {
    border: 1px solid #f3f3f3;
    box-shadow: 0 0 10px 0 rgba(68, 102, 242, 0.05);
  }
  .mega-horizontal .mega-title-badge {
    display: table;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .mega-horizontal .media .radio {
    margin-left: 3px;
  }
  
  .plain-style .card {
    box-shadow: none;
    border: none !important;
  }
  
  .border-style .card {
    box-shadow: none;
    border: 1px solid #ecf3fa !important;
  }
  
  .offer-style .card {
    box-shadow: none;
    border: 1px dashed #ecf3fa !important;
  }
  
  .rating-star-wrapper {
    color: #797979;
  }
  
  /**=====================
       19. Mega option CSS Ends
  ==========================**/
  /**=====================
       76. Order History CSS start
  ==========================**/
  .prooduct-details-box {
    margin-bottom: 30px;
  }
  .prooduct-details-box .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 16px;
    cursor: pointer;
  }
  .prooduct-details-box .media {
    border: 1px solid #d7e2e9;
    border-radius: 10px;
    padding: 15px 0;
    width: 100%;
    position: relative;
  }
  .prooduct-details-box .rating {
    color: #ffaf45;
    letter-spacing: 3px;
  }
  .prooduct-details-box .product-name a {
    color: gray;
  }
  .prooduct-details-box .btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  
  .order-history .input-group {
    text-align: right;
    max-width: 300px;
  }
  .order-history table th,
  .order-history table td {
    text-align: center;
  }
  .order-history table thead tr th {
    vertical-align: middle;
    border-top: none;
    background-color: #f8f8f8;
    border: 1px solid #ecf3fa;
    padding: 1.25rem;
    font-size: 16px;
  }
  .order-history table .qty-box {
    width: 120px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
  }
  .order-history table .qty-box input {
    width: 44px;
    max-width: 44px;
    background-color: transparent;
    border: none !important;
  }
  .order-history table .qty-box .input-group {
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #ecf3fa;
    overflow: hidden;
  }
  .order-history table .qty-box .btn {
    background-color: transparent;
    border: none;
  }
  .order-history table .qty-box .btn.bootstrap-touchspin-down {
    border-right: 1px solid;
  }
  .order-history table .qty-box .btn.bootstrap-touchspin-up {
    border-left: 1px solid;
  }
  .order-history table tbody tr td {
    vertical-align: middle;
  }
  .order-history table tbody tr td .product-name a {
    font-size: 15px;
    color: #898989;
  }
  .order-history table tbody tr td .product-name .order-process {
    font-size: 13px;
    padding-left: 15px;
  }
  .order-history table tbody tr td .product-name .order-process .order-process-circle {
    position: relative;
  }
  .order-history table tbody tr td .product-name .order-process .order-process-circle:before {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #898989;
    left: -15px;
    top: 4px;
  }
  .order-history table tbody tr td .product-name .order-process .order-process-circle.shipped-order:before {
    background-color: #51bb25;
  }
  .order-history table tbody tr td .product-name .order-process .order-process-circle.cancel-order:before {
    background-color: #dc3545;
  }
  
  @media only screen and (max-width: 1199px) {
    .order-history table {
      width: 900px;
      overflow: auto;
    }
  }
  /**=====================
       76. Order History CSS end
  ==========================**/
  /**=====================
       32. pagination CSS Start
  ==========================**/
  /*test Nav bar color */
  .pagination-theme .page-item .page-link {
    color: var(--theme-default) !important;
  }
  .pagination-theme .page-item.active .page-link {
    color: #fff !important;
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default);
  }
  
  .pagination-primary .page-item .page-link {
    color: var(--theme-default) !important;
  }
  .pagination-primary .page-item.active .page-link {
    color: #fff !important;
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default);
  }
  
  .pagination-secondary .page-item .page-link {
    color: #f73164 !important;
  }
  .pagination-secondary .page-item.active .page-link {
    color: #fff !important;
    background-color: #f73164 !important;
    border-color: #f73164;
  }
  
  .pagination-success .page-item .page-link {
    color: #51bb25 !important;
  }
  .pagination-success .page-item.active .page-link {
    color: #fff !important;
    background-color: #51bb25 !important;
    border-color: #51bb25;
  }
  
  .pagination-danger .page-item .page-link {
    color: #dc3545 !important;
  }
  .pagination-danger .page-item.active .page-link {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545;
  }
  
  .pagination-info .page-item .page-link {
    color: #a927f9 !important;
  }
  .pagination-info .page-item.active .page-link {
    color: #fff !important;
    background-color: #a927f9 !important;
    border-color: #a927f9;
  }
  
  .pagination-dark .page-item .page-link {
    color: #2c323f !important;
  }
  .pagination-dark .page-item.active .page-link {
    color: #fff !important;
    background-color: #2c323f !important;
    border-color: #2c323f;
  }
  
  .pagination-warning .page-item .page-link {
    color: #f8d62b !important;
  }
  .pagination-warning .page-item.active .page-link {
    color: #fff !important;
    background-color: #f8d62b !important;
    border-color: #f8d62b;
  }
  
  /**=====================
       32. pagination CSS Ends
  ==========================**/
  /**=====================
       34. Pricing CSS Start
  ==========================**/
  .pricing-simple {
    box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
  }
  .pricing-simple .card-body {
    border-bottom: none;
  }
  .pricing-simple .card-body h3 {
    position: relative;
    margin-bottom: 38px;
  }
  .pricing-simple .card-body h3:before {
    position: absolute;
    content: "";
    width: 80px;
    height: 1px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -10px;
    background-color: #999999;
  }
  .pricing-simple .card-body h1 {
    font-size: 30px;
    line-height: 0.9;
    margin-bottom: 30px;
    font-weight: 400;
  }
  
  .pricingtable {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
    border-radius: 10px;
    box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 100%;
  }
  .pricingtable:before, .pricingtable:after {
    content: "";
    background-color: var(--theme-default);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    right: -213px;
    z-index: -1;
    opacity: 0.1;
  }
  
  .pricingtable:after {
    background-color: var(--theme-default);
    height: 300px;
    width: 300px;
    top: auto;
    bottom: -210px;
    right: auto;
    left: -160px;
    opacity: 0.1;
  }
  .pricingtable .title {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .pricingtable .price-value {
    color: #fff;
    background-color: var(--theme-default);
    width: 130px;
    height: 130px;
    padding: 45px 0 0;
    margin: 0 auto 25px;
    border-radius: 50%;
  }
  .pricingtable .price-value .duration {
    margin: 0 0 0 -5px;
  }
  .pricingtable .price-value .amount {
    font-size: 58px;
    font-weight: 700;
    line-height: 45px;
    display: inline-block;
  }
  .pricingtable .price-value .currency {
    vertical-align: top;
  }
  .pricingtable .pricing-content {
    margin: 0 0 25px;
    display: inline-block;
  }
  .pricingtable .pricing-content li {
    font-weight: 500;
    text-align: left;
    margin: 0 0 20px;
    position: relative;
    padding-left: 30px;
  }
  .pricingtable .pricing-content li:last-child {
    margin: 0;
  }
  .pricingtable .pricing-content li:before {
    content: "\f00c";
    color: var(--theme-default);
    font-family: FontAwesome;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
  }
  
  @media (max-width: 1470px) {
    .pricing-block > div {
      flex: 50%;
    }
    .pricing-block > div:nth-child(n+3) {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    .pricing-simple .card-body h1 {
      margin-bottom: 20px;
    }
    .pricing-simple .card-body h3 {
      margin-bottom: 28px;
    }
    .pricing-block > div:nth-child(n+2) {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .pricing-simple .card-body h1 {
      margin-bottom: 15px;
    }
    .pricing-block > div {
      flex: 100%;
    }
    .pricingtable {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  /**=====================
      34. Pricing CSS Ends
  ==========================**/
  /**=====================
       23. Progress CSS Start
  ==========================**/
  .progress-showcase .progress {
    margin-bottom: 10px;
  }
  .progress-showcase .progress:last-child {
    margin-bottom: 0;
  }
  
  .sm-progress-bar {
    height: 5px;
  }
  
  .lg-progress-bar {
    height: 20px;
  }
  
  .progress-animate {
    overflow: inherit;
    background-color: transparent;
    margin-bottom: 2px;
  }
  .progress-animate span {
    position: absolute;
    bottom: 25px;
    right: -10px;
    font-weight: 600;
    font-size: 14px;
  }
  
  @keyframes heartbit {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    25% {
      transform: scale(0.1);
      opacity: 0.1;
    }
    50% {
      transform: scale(0.5);
      opacity: 0.3;
    }
    75% {
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .progress-gradient-primary {
    background-image: linear-gradient(to right, transparent, var(--theme-default));
    position: relative;
  }
  .progress-gradient-primary:hover {
    background-size: 100%;
  }
  .progress-gradient-primary::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: var(--theme-default);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(var(--theme-default), 0.36);
  }
  .progress-gradient-primary .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid var(--theme-default);
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-secondary {
    background-image: linear-gradient(to right, transparent, #f73164);
    position: relative;
  }
  .progress-gradient-secondary:hover {
    background-size: 100%;
  }
  .progress-gradient-secondary::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #f73164;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(247, 49, 100, 0.36);
  }
  .progress-gradient-secondary .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #f73164;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-success {
    background-image: linear-gradient(to right, transparent, #51bb25);
    position: relative;
  }
  .progress-gradient-success:hover {
    background-size: 100%;
  }
  .progress-gradient-success::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #51bb25;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(81, 187, 37, 0.36);
  }
  .progress-gradient-success .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #51bb25;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-danger {
    background-image: linear-gradient(to right, transparent, #dc3545);
    position: relative;
  }
  .progress-gradient-danger:hover {
    background-size: 100%;
  }
  .progress-gradient-danger::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #dc3545;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(220, 53, 69, 0.36);
  }
  .progress-gradient-danger .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #dc3545;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-info {
    background-image: linear-gradient(to right, transparent, #a927f9);
    position: relative;
  }
  .progress-gradient-info:hover {
    background-size: 100%;
  }
  .progress-gradient-info::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #a927f9;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(169, 39, 249, 0.36);
  }
  .progress-gradient-info .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #a927f9;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-light {
    background-image: linear-gradient(to right, transparent, #f4f4f4);
    position: relative;
  }
  .progress-gradient-light:hover {
    background-size: 100%;
  }
  .progress-gradient-light::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #f4f4f4;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(244, 244, 244, 0.36);
  }
  .progress-gradient-light .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #f4f4f4;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-dark {
    background-image: linear-gradient(to right, transparent, #2c323f);
    position: relative;
  }
  .progress-gradient-dark:hover {
    background-size: 100%;
  }
  .progress-gradient-dark::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #2c323f;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(44, 50, 63, 0.36);
  }
  .progress-gradient-dark .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #2c323f;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  .progress-gradient-warning {
    background-image: linear-gradient(to right, transparent, #f8d62b);
    position: relative;
  }
  .progress-gradient-warning:hover {
    background-size: 100%;
  }
  .progress-gradient-warning::after {
    position: absolute;
    content: "";
    top: -3px;
    right: -1px;
    background-color: #f8d62b;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 17px 4px rgba(248, 214, 43, 0.36);
  }
  .progress-gradient-warning .animate-circle {
    position: absolute;
    top: -11px;
    right: -8px;
    height: 25px;
    width: 25px;
    border: 5px solid #f8d62b;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  /**=====================
       23. Progress CSS Ends
  ==========================**/
  .project-list .row {
    margin: 15px;
  }
  .project-list button:focus {
    outline: none !important;
  }
  .project-list .theme-form .form-group {
    margin-bottom: 15px;
  }
  .project-list .border-tab.nav-tabs .nav-item .nav-link {
    border: 1px solid transparent;
    padding: 5px 30px 5px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .project-list .btn {
    margin-right: 5px;
    vertical-align: -12px;
    float: right;
  }
  .project-list .btn svg {
    vertical-align: middle;
    height: 16px;
  }
  .project-list ul {
    margin-bottom: 0 !important;
    border-bottom: 0;
  }
  .project-list ul li svg {
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .tab-content .row > div.col-xl-4:nth-child(1) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 100000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(2) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 200000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(3) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 300000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(4) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 400000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(5) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 500000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(6) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 600000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(7) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 700000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(8) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 800000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(9) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 900000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(10) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1000000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(11) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1100000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(12) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1200000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(13) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1300000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(14) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1400000ms;
  }
  .tab-content .row > div.col-xl-4:nth-child(15) {
    animation-fill-mode: both;
    animation: fadeIncustom 0.5s linear 1500000ms;
  }
  
  .project-cards .tab-content {
    margin-bottom: -30px;
  }
  
  .project-box {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 30px;
    background-color: white;
    margin-bottom: 30px;
  }
  .project-box h6 {
    font-weight: 500 !important;
  }
  .project-box .badge {
    position: absolute;
    right: 30px;
    top: 15px;
  }
  .project-box .project-status p {
    margin-bottom: 5px;
    font-weight: 800;
  }
  .project-box .media {
    margin-bottom: 15px;
  }
  .project-box .media .media-body {
    opacity: 0.5;
  }
  .project-box .details div {
    margin-bottom: 5px;
    opacity: 0.7;
  }
  
  .projectdetails .card .card-body {
    padding: 20px;
  }
  .projectdetails .card .card-footer {
    padding: 20px;
  }
  .projectdetails .card select {
    width: 90px;
    height: 28px;
    font-size: 11px;
    right: 20px;
    top: 20px;
  }
  
  .projectmore .details .col-4 {
    margin-bottom: 5px;
  }
  .projectmore .details .col-4:nth-child(even) {
    opacity: 0.5;
  }
  .projectmore h5 {
    font-size: 20px;
    font-weight: 600;
  }
  .projectmore .task-list {
    position: relative;
  }
  .projectmore .task-list ul li {
    margin-bottom: 5px;
  }
  .projectmore .task-list ul + ul {
    position: absolute;
    left: 50%;
    top: 0;
  }
  
  .new-users.activity .gradient-round {
    padding: 13px;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    top: unset !important;
  }
  .new-users.activity .gradient-round::after {
    display: none;
  }
  
  /**=====================
       79. social app css start
  ==========================**/
  .user-profile .hovercard .socialheader {
    // background: url(../images/social-app/social-image.png);
  }
  .user-profile .hovercard .user-image .share-icons {
    position: absolute;
    right: 100px;
    top: -27px;
  }
  .user-profile .hovercard .user-image .share-icons li {
    display: inline-block;
    margin-right: 10px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    border-radius: 100%;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon i {
    color: #fff;
    font-size: 25px;
  }
  .user-profile .hovercard .user-image .share-icons li:last-child {
    margin-right: 0;
  }
  .user-profile .card-footer > div h3 {
    font-size: 16px;
  }
  
  .market-tabs .border-tab.nav-tabs .nav-item .nav-link.active {
    border-bottom: none !important;
  }
  .market-tabs .nav {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  .market-tabs .nav .nav-item {
    width: 100%;
  }
  .market-tabs .nav .nav-item .nav-link {
    height: 81px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 30px;
    text-align: center;
  }
  
  .tabs-scoial {
    border-bottom: none;
    margin-bottom: 20px !important;
    margin-top: 20px;
  }
  .tabs-scoial li a {
    font-weight: 600;
  }
  .tabs-scoial li a svg circle {
    color: #999999;
  }
  
  .social-network span {
    margin-bottom: 30px;
    display: block;
  }
  
  .social-list .media {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .social-list .media:last-child {
    margin-bottom: 0;
  }
  .social-list .media .media-body a {
    color: #999999;
  }
  .social-list .media .media-body span {
    font-weight: 600;
  }
  .social-list .media .media-body:hover a {
    color: var(--theme-default);
  }
  
  .photos {
    margin-bottom: -15px;
  }
  .photos ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 70px;
  }
  .photos ul li:nth-child(4n) {
    margin-right: 0;
  }
  
  .new-users-social {
    margin-bottom: 30px;
  }
  .new-users-social svg {
    cursor: pointer;
  }
  .new-users-social .media img {
    width: 58px;
  }
  .new-users-social .media .media-body {
    margin-top: 10px;
  }
  .new-users-social svg circle {
    color: #999999;
  }
  
  .like-content span i {
    font-size: 14px;
  }
  
  .timeline-content p {
    margin-top: 30px;
    color: #999999;
  }
  .timeline-content .comment-number i {
    color: #999999;
    margin-right: 20px;
  }
  .timeline-content .comments-box {
    margin-top: 30px;
  }
  .timeline-content .comments-box .input-group {
    margin-top: 6px;
  }
  .timeline-content .comments-box .input-group .input-txt-bx {
    border: none;
  }
  .timeline-content .comments-box .input-group .input-txt-bx::placeholder {
    color: #999999;
  }
  .timeline-content .comments-box .input-group .input-group-append .btn i {
    font-size: 20px;
    display: flex;
    color: #999999;
  }
  
  .social-chat {
    margin-top: 30px;
  }
  .social-chat span span {
    font-weight: normal;
    color: #999999;
    font-size: 12px;
  }
  .social-chat .media-body {
    padding: 30px;
    border: 1px solid #f4f4f4;
    position: relative;
  }
  .social-chat .media-body p {
    margin-top: 5px;
  }
  .social-chat .media-body:before {
    content: "";
    position: absolute;
    top: 19px;
    right: 100%;
    border: 7px solid transparent;
    border-right: 7px solid #59667a;
  }
  .social-chat .media-body:after {
    content: "";
    position: absolute;
    top: 19px;
    right: 100%;
    border: 7px solid transparent;
    border-right: 7px solid #fff;
  }
  .social-chat .your-msg,
  .social-chat .other-msg {
    margin-bottom: 30px;
  }
  .social-chat .other-msg {
    margin-left: 40px;
  }
  
  .socialprofile span {
    color: #999999;
  }
  .socialprofile .social-btngroup {
    margin: 30px 0;
  }
  .socialprofile .likes-profile h5 span {
    color: #2b2b2b;
  }
  .socialprofile .social-group {
    margin-top: 30px;
  }
  .socialprofile .social-group ul li:nth-child(4) {
    margin-left: -22px;
  }
  
  .social-status form .form-group .form-control-social {
    border: 1px solid #f4f4f4;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .social-status .media {
    position: relative;
    margin-bottom: 30px;
  }
  .social-status .media:last-child {
    margin-bottom: 0;
  }
  .social-status .media .social-status {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #fff;
    bottom: 0;
    left: 35px;
    border-radius: 100%;
  }
  .social-status .media .social-online {
    border: 3px solid #51bb25;
  }
  .social-status .media .social-busy {
    border: 3px solid #f8d62b;
  }
  .social-status .media .social-offline {
    border: 3px solid #59667a;
  }
  .social-status .media .media-body p {
    color: #999999;
    margin-bottom: 5px;
  }
  .social-status .media .media-body span + span {
    color: #999999;
  }
  .social-status .media .media-body .light-span {
    color: #999999;
  }
  
  .social-header h5 span:first-child {
    line-height: 1.48;
  }
  .social-header h5 span svg circle {
    color: #999999;
  }
  
  .details-about + .details-about {
    margin-top: 30px;
  }
  .details-about .your-details p {
    color: #999999;
  }
  
  .avatar-showcase .friend-pic {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .avatar-showcase .pepole-knows ul li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .avatar-showcase .pepole-knows ul li:last-child {
    margin-right: 0;
  }
  .avatar-showcase .pepole-knows ul li .add-friend h6 {
    margin-top: 3px;
  }
  
  .activity-log .my-activity + .my-activity {
    margin-top: 30px;
  }
  .activity-log .my-activity p {
    margin-bottom: 5px;
  }
  .activity-log .my-activity p span svg {
    width: 15px;
    height: 15px;
  }
  
  /**=====================
       79. social app CSS end
  ==========================**/
  /**=====================
       87. task app CSS Start
  ==========================**/
  .modal-open .datepickers-container {
    z-index: 1075;
  }
  
  .date-details {
    align-items: center;
  }
  .date-details > div + div {
    margin-left: 10px;
  }
  
  .taskadd table tr td {
    border-top: none;
  }
  .taskadd table tr td:first-child {
    padding-left: 30px;
  }
  .taskadd table tr td:last-child {
    padding-right: 30px;
  }
  .taskadd table tr td svg {
    width: 18px;
    height: 18px;
    color: #59667a;
  }
  .taskadd table tr:first-child td {
    padding-top: 30px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 30px;
  }
  
  @media only screen and (max-width: 1660px) {
    .taskadd table tr td:first-child {
      min-width: 160px;
    }
    .taskadd table tr td:nth-child(3) {
      min-width: 70px;
    }
  }
  @media only screen and (max-width: 991px) {
    .date-details {
      display: block !important;
      margin-bottom: -10px;
    }
    .date-details .d-inline-block {
      margin-bottom: 10px;
    }
    .taskadd table tr td:first-child {
      padding-left: 20px;
    }
    .taskadd table tr:first-child td {
      padding-top: 20px;
    }
    .taskadd table tr:last-child td {
      padding-bottom: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .taskadd table tr td:nth-child(2) {
      min-width: 745px;
    }
  }
  @media only screen and (max-width: 575px) {
    .taskadd table tr td:first-child {
      padding-left: 15px;
    }
    .taskadd table tr:first-child td {
      padding-top: 15px;
    }
    .taskadd table tr:last-child td {
      padding-bottom: 15px;
    }
  }
  /**=====================
     87. task app CSS Ends
  ==========================**/
  /**=====================
       42. Timeline CSS Start
  ==========================**/
  .activity .media {
    margin-bottom: 30px;
  }
  .activity .media:last-child {
    margin-bottom: 0;
  }
  .activity .media .gradient-round {
    position: relative;
    width: 53px;
    height: 53px;
    background-image: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
    border-radius: 100%;
    top: -18px;
  }
  .activity .media .gradient-round svg {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 18px;
    right: 0;
    margin: 0 auto;
  }
  .activity .media .gradient-round svg path,
  .activity .media .gradient-round svg line,
  .activity .media .gradient-round svg circle {
    color: #fff;
  }
  .activity .media .gradient-round.gradient-line-1:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 62px;
    bottom: -77px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .activity .media .gradient-round.small-line:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 19px;
    bottom: -34px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .activity .media .gradient-round.medium-line:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 53px;
    bottom: -68px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .activity .media .media-body h6 {
    font-weight: 600;
    margin-bottom: 30px;
  }
  .activity .media .media-body h6 span {
    font-weight: normal;
    color: #999999;
  }
  .activity .media .media-body p {
    width: 80%;
  }
  
  .timeline-small .media {
    margin-bottom: 30px;
  }
  .timeline-small .media:last-child {
    margin-bottom: 0;
  }
  .timeline-small .media .timeline-round {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 100%;
    border-radius: 15px;
    top: -18px;
  }
  .timeline-small .media .timeline-round svg {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 18px;
    right: 0;
    margin: 0 auto;
  }
  .timeline-small .media .timeline-round svg path,
  .timeline-small .media .timeline-round svg line,
  .timeline-small .media .timeline-round svg circle {
    color: #fff;
  }
  .timeline-small .media .timeline-round.timeline-line-1:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 62px;
    bottom: -88px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .timeline-small .media .timeline-round.small-line:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 19px;
    bottom: -34px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .timeline-small .media .timeline-round.medium-line:after {
    position: absolute;
    content: "";
    background-color: #e9e9e9;
    width: 2px;
    height: 53px;
    bottom: -68px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .timeline-small .media .media-body h6 {
    margin-bottom: 30px;
  }
  .timeline-small .media .media-body h6 span {
    font-weight: normal;
    color: #999999;
  }
  .timeline-small .media .media-body p {
    width: 80%;
  }
  
  .cd-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
  }
  
  .cd-container::after {
    content: "";
    display: table;
    clear: both;
  }
  
  @media only screen and (min-width: 1170px) {
    #cd-timeline::before {
      left: 50% !important;
      margin-left: -2px;
    }
    .cd-timeline-block {
      margin: 4em 0;
    }
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #f4f4f4;
    }
    .cd-timeline-img {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
    }
    .cd-timeline-img > i {
      font-size: 18px;
      top: 50%;
      left: 50%;
      padding: 3px;
    }
    .cssanimations .cd-timeline-img.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
      visibility: visible;
      animation: cd-bounce-1 0.6s;
    }
    .cssanimations .cd-timeline-content.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
      visibility: visible;
      animation: cd-bounce-2 0.6s;
    }
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
      animation: cd-bounce-2-inverse 0.6s;
    }
    .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%;
    }
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 16px;
    }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #fff;
    }
  }
  #cd-timeline {
    position: relative;
    padding: 2em 0;
  }
  
  #cd-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    font-size: 1.5rem;
    height: 100%;
    width: 2px;
    background: #f4f4f4;
    left: -15px;
  }
  
  .cd-timeline-block {
    position: relative;
    margin: 30px 0;
  }
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    border-radius: 4px;
  }
  .cd-timeline-img i {
    display: block;
    position: relative;
    left: 45%;
    top: 44%;
    margin-left: -12px;
    margin-top: -12px;
    font-size: 27px;
    color: #fff;
  }
  
  @-webkit-keyframes cd-bounce-1 {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @-moz-keyframes cd-bounce-1 {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    60% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .cd-timeline-content {
    position: relative;
    background: #fdfeff;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  }
  .cd-timeline-content p {
    padding-top: 9px;
  }
  .cd-timeline-content h4 {
    margin-bottom: 0;
  }
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-timeline-content audio {
    width: 100%;
  }
  .cd-timeline-content .cd-date {
    font-size: 13px;
    display: inline-block;
    float: left;
    padding: 0.8em 0 0;
    opacity: 0.7;
  }
  
  .cd-timeline-content::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid #f4f4f4;
  }
  
  @media only screen and (min-width: 768px) {
    .cd-timeline-content .cd-date {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
  @-webkit-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @-moz-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    60% {
      opacity: 1;
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-webkit-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @-moz-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    60% {
      opacity: 1;
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @media only screen and (max-width: 1169px) {
    .cd-timeline-block .cd-timeline-img {
      left: -2px;
      height: 30px;
      width: 30px;
      margin-left: -30px;
      top: 14px;
      z-index: 7;
    }
    .cd-timeline-block .cd-timeline-img > i {
      font-size: 15px;
      top: 16px;
      left: 17px;
      padding: 3px;
    }
    .landing-main #cd-timeline::before {
      left: -39px;
    }
    .landing-main .cd-timeline-block .cd-timeline-img {
      left: -34px;
      height: 50px;
      width: 50px;
      top: 1px;
      z-index: 8;
    }
    .landing-main .cd-timeline-block .cd-timeline-img > i {
      font-size: 23px;
      top: 21px;
      left: 7px;
      padding: 3px;
    }
  }
  @media only screen and (max-width: 576px) {
    .cd-timeline-content {
      padding: 15px;
    }
    .cd-timeline-content h4 {
      font-size: 18px;
    }
  }
  /**=====================
       42. Timeline  CSS Ends
  ==========================**/
  /**=====================
      43. Timeline 2 CSS Start
  ==========================**/
  .timeliny {
    position: relative;
    display: block;
    padding: 10rem 0;
    width: 100%;
    border-top: 1px solid #fcfcfc;
    border-bottom: 1px solid #fcfcfc;
    text-rendering: optimizeLegibility;
    color: #f4f4f4;
    opacity: 0;
    overflow: hidden;
    cursor: move;
  }
  .timeliny.loaded {
    opacity: 1;
  }
  .timeliny::before {
    content: "";
    position: absolute;
    background-color: #898989;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
  }
  .timeliny .timeliny-wrapper {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 1024px;
  }
  .timeliny .timeliny-dot {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 1px solid #898989;
    background-color: #fff;
  }
  .timeliny .timeliny-dot::before {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    content: attr(data-year);
    display: block;
    position: absolute;
    top: -5rem;
    left: 1.25rem;
    color: #2c323f;
    font-family: Rubik, sans-serif;
    font-size: 22px;
  }
  .timeliny .timeliny-dot::after {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: attr(data-text);
    display: block;
    position: absolute;
    width: 256px;
    top: 5.6rem;
    left: 1.25rem;
    color: #f4f4f4;
    opacity: 0;
    font-size: 0.9em;
  }
  @media (min-width: 768px) {
    .timeliny .timeliny-dot::after {
      width: 341.3333333333px;
    }
  }
  .timeliny .timeliny-timeline {
    position: absolute;
    top: 50%;
    left: 0;
    display: table;
    width: 100%;
    z-index: 5;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock {
    display: table-cell;
    position: relative;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot {
    border-color: var(--theme-default);
    background-color: var(--theme-default);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.33);
  }
  .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
    color: #2c323f;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
    color: #2c323f;
    opacity: 1;
    background-color: white;
    border: 1px solid #efefef;
    padding: 10px;
    border-radius: 5px;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock.inactive .timeliny-dot {
    cursor: move;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock.inactive .timeliny-dot::before {
    color: #f4f4f4;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover {
    border-color: #2c323f;
    background-color: #2c323f;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover::before {
    color: #2c323f;
  }
  .timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
    color: #2c323f;
    opacity: 1;
    background-color: #fff;
    border: 1px solid #efefef;
    padding: 10px;
    border-radius: 5px;
  }
  .timeliny .timeliny-vertical-line {
    position: absolute;
    display: block;
    z-index: 1;
    left: 16%;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--theme-default);
  }
  @media (min-width: 768px) {
    .timeliny .timeliny-vertical-line {
      left: 32%;
    }
  }
  .timeliny .timeliny-vertical-line::before {
    content: "";
    position: absolute;
    top: 0;
    left: -7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--theme-default);
  }
  .timeliny .timeliny-vertical-line::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--theme-default);
  }
  
  /**=====================
       43. Timeline 2 CSS Ends
  ==========================**/
  /**=====================
       22. User profile css start
  ==========================**/
  .user-profile .ttl-info h6 {
    font-size: 12px;
    color: #59667a;
  }
  .user-profile .border-right {
    border-right: 1px solid #f4f4f4;
  }
  .user-profile hr {
    margin: 30px 0;
  }
  .user-profile .hovercard .cardheader {
    // background: url(../images/other-images/bg-profile.png);
    background-size: cover;
    background-position: 10%;
    height: 470px;
  }
  .user-profile .hovercard .user-image {
    position: relative;
    height: 0;
  }
  .user-profile .hovercard .user-image .avatar {
    margin-top: -80px;
  }
  .user-profile .hovercard .user-image .avatar img {
    width: 100px;
    height: 100px;
    max-width: 155px;
    max-height: 155px;
    border-radius: 50%;
    border: 7px solid #fff;
  }
  .user-profile .hovercard .user-image .icon-wrapper {
    position: absolute;
    bottom: -20px;
    left: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto;
    font-size: 17px;
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
  }
  .user-profile .hovercard .info {
    padding: 30px;
  }
  .user-profile .hovercard .info .title {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1;
    color: #2c323f;
    vertical-align: middle;
  }
  .user-profile .hovercard .info .title a {
    color: #242934;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .user-profile .hovercard .info .desc {
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    color: #59667a;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  .user-profile .follow {
    margin-top: 20px;
  }
  .user-profile .follow .follow-num {
    font-size: 20px;
    color: #242934;
    font-family: Rubik, sans-serif;
    font-weight: 500;
  }
  .user-profile .follow span {
    color: #59667a;
  }
  .user-profile .profile-img-style {
    padding: 30px;
  }
  .user-profile .profile-img-style .img-container {
    margin-top: 30px;
  }
  .user-profile .profile-img-style .user-name {
    font-size: 14px;
    color: #242934;
    margin: 0;
    font-weight: 600;
  }
  .user-profile .profile-img-style p {
    line-height: 30px;
    font-size: 14px;
    color: #898989;
    margin-bottom: 0;
  }
  .user-profile .profile-img-style .media img {
    width: 50px;
  }
  .user-profile .like-comment a {
    color: #898989;
  }
  .user-profile .like-comment a:hover {
    color: #dc3545;
  }
  .user-profile .like-comment span {
    font-family: Rubik, sans-serif;
  }
  .user-profile .social-media a {
    padding: 0 15px;
    color: #59667a;
    font-size: 24px;
    cursor: pointer;
  }
  .user-profile .social-media a:hover {
    color: var(--theme-default);
  }
  
  .edit-profile .profile-title .media {
    align-items: center;
    margin-bottom: 30px;
  }
  .edit-profile .profile-title .media .media-body {
    margin-left: 25px;
  }
  
  /**=====================
       22. User profile css Ends
  ==========================**/
  /**=====================
      83. Wishlist CSS start
  ==========================**/
  .wishlist table tr td svg {
    color: #898989;
    cursor: pointer;
  }
  
  /**=====================
      83. Wishlist CSS Ends
  ==========================**/
  /* ---------------------
      *** themes ***
  -----------------------*/
  /**=====================
  55. theme layout CSS Start
  ==========================**/
  body.dark-only {
    color: rgba(255, 255, 255, 0.6);
    background-color: #1d1e26;
  }
  body.dark-only .activity-media .media .media-body span {
    color: #98a6ad;
  }
  body.dark-only .activity-media .media + .media {
    border-top: 1px solid #374558;
  }
  body.dark-only .static-top-widget h6 {
    color: #98a6ad;
  }
  body.dark-only .widget-joins .widget-card {
    border: 1px solid #374558;
  }
  body.dark-only .custom-profile .card-social .social-link {
    background-color: #1d1e26;
    border: 1px solid #374558;
  }
  body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: #2c2c2c;
    color: #fff;
  }
  body.dark-only .card {
    background-color: #2c2c2c;
  }
  body.dark-only .ecommerce-widget {
    border: 1px solid #374558 !important;
  }
  body.dark-only .btn-light {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .form-select {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .add-project .text-inherit {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .knowledgebase-search {
    color: #2b2b2b;
  }
  body.dark-only .knowledgebase-search .form-control-plaintext {
    color: #2b2b2b;
  }
  body.dark-only .btn-close {
    filter: brightness(0.8) invert(1);
  }
  body.dark-only .prooduct-details-box .media {
    border: 1px solid #374558;
  }
  body.dark-only .product-price del {
    color: #98a6ad;
  }
  body.dark-only .ProfileCard {
    border: 1px solid #374558 !important;
  }
  body.dark-only .form-control-plaintext {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .form-select {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .box-layout {
    background-color: #1d1e26;
  }
  body.dark-only .box-layout .page-wrapper .page-header .header-wrapper, body.dark-only .box-layout.page-wrapper .page-header .header-wrapper {
    border: 1px solid #1d1e26;
  }
  body.dark-only .form-check-input {
    background-color: #1d1e26;
  }
  body.dark-only .switch-state:before {
    background-color: #2c2c2c;
  }
  body.dark-only .daterangepicker:before {
    border-bottom-color: #2c2c2c;
  }
  body.dark-only .daterangepicker .ranges li {
    background-color: #1d1e26;
    border-color: #2c2c2c;
    color: #898989;
  }
  body.dark-only .daterangepicker .daterangepicker_input .form-control {
    background-color: #1d1e26;
    border-color: #2c2c2c !important;
  }
  body.dark-only .daterangepicker .calendar-time select {
    border-color: #2c2c2c;
    background-color: #1d1e26;
  }
  body.dark-only .daterangepicker.dropdown-menu {
    background-color: #2c2c2c;
    -webkit-box-shadow: 0 0 14px #1d1e26;
    box-shadow: 0 0 14px #1d1e26;
  }
  body.dark-only .daterangepicker .calendar-table {
    background-color: #2c2c2c;
    border-color: #2c2c2c;
  }
  body.dark-only .daterangepicker.ltr td.in-range, body.dark-only .daterangepicker.ltr td.off {
    background-color: #1d1e26;
    color: #fff;
  }
  body.dark-only .tooltip.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: #323846;
  }
  body.dark-only .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: #323846;
    border-top-color: transparent;
  }
  body.dark-only .tooltip.bs-tooltip-start .tooltip-arrow:before {
    border-left-color: #323846;
    border-top-color: transparent;
  }
  body.dark-only .tooltip.bs-tooltip-end .tooltip-arrow:before {
    border-right-color: #323846;
    border-top-color: transparent;
  }
  body.dark-only .tooltip .tooltip-inner {
    background-color: #323846;
    color: #fff;
  }
  body.dark-only .tooltip .tooltip-arrow:before {
    border-top-color: #323846;
  }
  body.dark-only .page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6:first-child h6 {
    color: rgba(255, 255, 255, 0.5);
  }
  body.dark-only .page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
    background-color: #1d1e26 !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
    background-color: #1d1e26;
    border: 1px solid #374558;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:last-child {
    border-top: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
    background-color: #1d1e26 !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
    stroke: #fff;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group .btn {
    color: rgba(255, 255, 255, 0.4);
    border-color: #2c2c2c !important;
    background-color: #2c2c2c !important;
  }
  body.dark-only .qty-box input {
    color: rgba(255, 255, 255, 0.5);
  }
  body.dark-only .customizer-contain {
    color: #1d1e26;
  }
  body.dark-only .customizer-contain .customizer-body .main-layout .box-layout {
    background-color: #fff;
  }
  body.dark-only .login-card .btn-showcase .btn {
    background-color: #2c2c2c !important;
    color: #fff;
    border-color: #2c2c2c !important;
  }
  body.dark-only .login-card .login-main {
    box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
  }
  body.dark-only .login-card .login-main .theme-form input {
    background-color: #2c2c2c !important;
  }
  body.dark-only .login-card .login-main .theme-form .or:before {
    background-color: #2c2c2c;
  }
  body.dark-only .login-card .login-main .theme-form .checkbox label::before {
    background-color: #2c2c2c !important;
    border-color: #2c2c2c !important;
  }
  body.dark-only .H_ui ~ div {
    background-color: #1d1e26 !important;
  }
  body.dark-only .swal-icon--success:after,
  body.dark-only .swal-icon--success:before {
    background: #2c2c2c;
  }
  body.dark-only .swal-icon--success__hide-corners {
    background-color: #2c2c2c;
  }
  body.dark-only .note-editor.note-frame .note-status-output {
    border-top: 1px solid #2c2c2c;
  }
  body.dark-only .note-editor.note-frame .note-statusbar {
    border-top: 1px solid #2c2c2c;
  }
  body.dark-only .note-editor.note-frame .note-statusbar .note-resizebar {
    background-color: #2c2c2c;
  }
  body.dark-only .light-font {
    color: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .page-link {
    background-color: #1d1e26;
    border: 1px solid #374558;
  }
  body.dark-only .b-r-light {
    border-right: 1px solid #374558 !important;
  }
  body.dark-only .history-details .media {
    border-top: 1px solid #374558;
  }
  body.dark-only .modal-header {
    border-bottom: 1px solid #374558;
  }
  body.dark-only #right-history {
    background-color: #2c2c2c;
    box-shadow: 0 0 9px #1d1e26;
  }
  body.dark-only #right-history h6 span a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .ProfileCard:hover {
    color: rgba(255, 255, 255, 0.6);
    background-color: #2c2c2c;
  }
  body.dark-only .translate_wrapper.active .more_lang:before {
    border-bottom: 7px solid #2c2c2c;
  }
  body.dark-only .translate_wrapper.active .more_lang .lang {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .toggle-sidebar svg {
    stroke: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-title {
    background-color: #2c2c2c;
    border-bottom: 1px solid #374558;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 40px;
  }
  body.dark-only .page-wrapper .sidebar-main-title p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active svg {
    stroke: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    background-color: rgba(115, 102, 255, 0.2);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    stroke: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
    background: #2c2c2c;
    border-top: 1px solid #374558;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    background: #2c2c2c;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active, body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
    background: #2c2c2c;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    background: #2c2c2c;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
  body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    background: #2c2c2c;
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    background-color: rgba(115, 102, 255, 0.2);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    stroke: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper.material-type .page-header .header-wrapper {
    border-radius: 15px 15px 0 0;
  }
  body.dark-only .page-wrapper.material-icon .page-header .header-wrapper {
    background-color: transparent;
  }
  body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
    color: #fff;
  }
  body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
    color: #fff;
  }
  body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
    border: 1px solid #1d1e26;
    background: #1d1e26;
    color: #fff;
  }
  body.dark-only .page-wrapper.material-icon .page-header .header-logo-wrapper {
    background-color: transparent;
  }
  body.dark-only .page-wrapper.material-icon .page-body-wrapper .page-body {
    background-color: transparent;
  }
  body.dark-only .page-wrapper.advance-layout {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper {
    background: transparent;
  }
  body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper .header-logo-wrapper {
    background: transparent;
  }
  body.dark-only .page-wrapper.advance-layout .page-body-wrapper {
    background: transparent;
  }
  body.dark-only .page-wrapper.advance-layout .page-body-wrapper .page-body {
    background: transparent;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .search-full {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .search-full input {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  body.dark-only .left-header .mega-menu-container {
    background-color: #2c2c2c;
    border-top: 1px solid #2c2c2c;
  }
  body.dark-only .left-header .mega-menu-container .mega-box + .mega-box {
    border-left: 1px solid #374558;
  }
  body.dark-only .left-header .mega-menu div > div a {
    color: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .left-header .level-menu .header-level-menu {
    background-color: #2c2c2c;
  }
  body.dark-only .left-header .level-menu .header-level-menu > li .header-level-sub-menu {
    background-color: #2c2c2c;
  }
  body.dark-only .left-header .level-menu .header-level-menu > li a {
    color: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .left-header .level-menu .header-level-menu > li svg {
    stroke: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .kanban-board-header {
    background-color: #1d1e26 !important;
    border-bottom: 1px solid #2c2c2c !important;
  }
  body.dark-only .kanban-board .kanban-drag {
    background: #1d1e26 !important;
  }
  body.dark-only .kanban-container .kanban-item .kanban-box {
    border: 1px solid rgba(115, 102, 255, 0.15);
    background-color: #1d1e26;
  }
  body.dark-only .project-box {
    border: 1px solid rgba(115, 102, 255, 0.15);
    background-color: #1d1e26;
  }
  body.dark-only .file-content .form-inline {
    border: 1px solid #1d1e26;
  }
  body.dark-only .file-content .files .file-box {
    border: 1px solid rgba(115, 102, 255, 0.15);
    background-color: #1d1e26;
  }
  body.dark-only .file-content .files .file-box .file-top {
    background-color: #2c2c2c;
    border: 1px solid rgba(115, 102, 255, 0.15);
  }
  body.dark-only .file-content .folder .folder-box {
    border: 1px solid rgba(115, 102, 255, 0.15);
    background-color: #1d1e26;
  }
  body.dark-only .file-sidebar .pricing-plan {
    border: 1px solid rgba(115, 102, 255, 0.15) !important;
  }
  body.dark-only .file-sidebar .btn-light {
    color: rgba(255, 255, 255, 0.6);
    background-color: #1d1e26 !important;
    border: 1px solid rgba(115, 102, 255, 0.15) !important;
  }
  body.dark-only #lnb {
    border-right: 1px solid #374558;
  }
  body.dark-only .lnb-new-schedule,
  body.dark-only .lnb-calendars > div {
    border-bottom: 1px solid #374558;
  }
  body.dark-only #menu .sidebar-list .btn-default {
    color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only .tui-full-calendar-timegrid-hour {
    background-color: #2c2c2c;
    color: #fff !important;
  }
  body.dark-only .tui-full-calendar-timegrid-gridline {
    border-bottom: 1px solid #374558 !important;
  }
  body.dark-only .tui-full-calendar-time-date,
  body.dark-only .tui-full-calendar-weekday-grid-line,
  body.dark-only .tui-full-calendar-left,
  body.dark-only .tui-full-calendar-timegrid-timezone {
    border-right-color: #374558 !important;
  }
  body.dark-only .tui-full-calendar-popup {
    color: #2b2b2b;
  }
  body.dark-only #menu .btn-default {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only #menu .btn-default:hover {
    color: #2b2b2b;
  }
  body.dark-only #menu .dropdown-menu {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .tui-full-calendar-dayname-container,
  body.dark-only .tui-full-calendar-splitter {
    border-top-color: #374558 !important;
    border-bottom-color: #374558 !important;
  }
  body.dark-only span.tui-full-calendar-dayname-date-area {
    color: #fff !important;
  }
  body.dark-only .tui-full-calendar-layout {
    background-color: #2c2c2c !important;
  }
  body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span {
    background-color: var(--theme-default);
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot {
    background: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .blockquote {
    border-left: 4px solid #374558;
  }
  body.dark-only .figure.text-end blockquote {
    border-right-color: #374558;
  }
  body.dark-only .contacts-tabs .nav-pills {
    border-right: 1px solid #374558;
  }
  body.dark-only .contacts-tabs .nav-pills .nav-link + .nav-link {
    border-top: 1px solid #374558;
  }
  body.dark-only .list-persons .profile-mail .media .media-body ul li + li {
    border-left: 2px solid #374558;
  }
  body.dark-only .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
    background-color: #2c2c2c;
  }
  body.dark-only .apexcharts-gridline {
    stroke: #1d1e26;
  }
  body.dark-only .apexcharts-tooltip-title,
  body.dark-only .apexcharts-tooltip-series-group,
  body.dark-only .apexcharts-tooltip.light,
  body.dark-only .apexcharts-menu-item {
    color: #000;
  }
  body.dark-only .small-donut ~ svg path:nth-child(even),
  body.dark-only .small-donut svg path:nth-child(even),
  body.dark-only .donut-peity2 ~ svg path:nth-child(even),
  body.dark-only .donut-peity2 svg path:nth-child(even),
  body.dark-only .pie-peity ~ svg path:nth-child(even),
  body.dark-only .pie-peity svg path:nth-child(even) {
    fill: #1d1e26;
  }
  body.dark-only .earning-card.card .card-body .left_side_earning p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .earning-card.card .card-body .earning-content {
    border-right: 1px solid #374558;
  }
  body.dark-only .earning-card.card .card-body .inner-top-left ul li,
  body.dark-only .earning-card.card .card-body .inner-top-right ul li {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .earning-card.card .card-body .inner-top-left ul li.active,
  body.dark-only .earning-card.card .card-body .inner-top-right ul li.active {
    color: var(--theme-default);
  }
  body.dark-only .earning-card.card .card-body .media .media-body p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .earning-card.card .card-body .border-top {
    border-top: 1px solid #374558 !important;
  }
  body.dark-only .chart_data_left .card-body .chart-main .media,
  body.dark-only .chart_data_right .card-body .chart-main .media {
    border-right: 1px solid #374558;
  }
  body.dark-only .chart_data_left .card-body .chart-main .media.border-none,
  body.dark-only .chart_data_right .card-body .chart-main .media.border-none {
    border-right: none;
  }
  body.dark-only .chart_data_left .card-body .media .right-chart-content span,
  body.dark-only .chart_data_right .card-body .media .right-chart-content span {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .chart_data_right .card-body .knob-block input {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .third-news-update .news-update,
  body.dark-only .news .news-update,
  body.dark-only .appointment-sec .news-update {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .third-news-update .news-update span,
  body.dark-only .news .news-update span,
  body.dark-only .appointment-sec .news-update span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .third-news-update .news-update h6,
  body.dark-only .news .news-update h6,
  body.dark-only .appointment-sec .news-update h6 {
    margin-bottom: 2px;
    line-height: 24px;
  }
  body.dark-only .third-news-update .appointment .radar-chart .apexcharts-datalabel,
  body.dark-only .news .appointment .radar-chart .apexcharts-datalabel,
  body.dark-only .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .chat-default .card-body.chat-box .input-group .form-control {
    background-color: #2c2c2c !important;
    border: none !important;
    border-left: 1px solid var(--theme-default) !important;
  }
  body.dark-only .chat-default .card-body.chat-box .input-group .form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .appointment-table table tr td.img-content-box .font-roboto {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .notification .card .media .media-body p span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .notification .card .media .media-body span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .datepicker {
    background-color: #323846;
  }
  body.dark-only .datepicker .datepicker--cell.-current- {
    color: #7366ff;
  }
  body.dark-only .cal-date-widget .datepicker {
    background-color: #2c2c2c;
  }
  body.dark-only .cal-date-widget .datepicker .datepicker--day-name, body.dark-only .cal-date-widget .datepicker .datepicker--nav, body.dark-only .cal-date-widget .datepicker .datepicker--nav-title i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .cal-date-widget .datepicker .datepicker--cell-day {
    color: #fff;
  }
  body.dark-only .cal-date-widget .datepicker .datepicker--cell.-current- {
    color: #7366ff;
  }
  body.dark-only .default-datepicker .datepicker-inline .datepicker {
    background: #2c2c2c;
  }
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name.-selected-,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day.-selected-,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name.-selected-,
  body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-selected- {
    color: #fff;
  }
  body.dark-only .dash-chart .media .media-body p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .dash-chart .media .media-body p .font-roboto {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .our-product table tr td p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .activity-timeline .media .activity-line {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only .activity-timeline .media .media-body p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .total-users .goal-status ul:last-child li {
    border-top-color: #374558;
  }
  body.dark-only .best-seller-table table tbody tr td .label {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .best-seller-table table tbody tr td p {
    color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only [class*=activity-dot-] {
    background-color: #2c2c2c;
  }
  body.dark-only .page-header .header-wrapper .nav-right .chat-dropdown li:last-child,
  body.dark-only .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
    border-top: 1px solid #374558 !important;
  }
  body.dark-only .alert-primary {
    background-color: rgba(115, 102, 255, 0.8) !important;
    border-color: rgba(115, 102, 255, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-primary .progress {
    height: 5px;
    background-color: #6e61ff;
    border-radius: 0;
  }
  body.dark-only .alert-primary .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-primary.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-primary.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-primary.outline .btn-close,
  body.dark-only .alert-primary.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-secondary {
    background-color: rgba(247, 49, 100, 0.8) !important;
    border-color: rgba(247, 49, 100, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-secondary .progress {
    height: 5px;
    background-color: #f72c60;
    border-radius: 0;
  }
  body.dark-only .alert-secondary .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-secondary.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-secondary.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-secondary.outline .btn-close,
  body.dark-only .alert-secondary.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-success {
    background-color: rgba(81, 187, 37, 0.8) !important;
    border-color: rgba(81, 187, 37, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-success .progress {
    height: 5px;
    background-color: #4fb724;
    border-radius: 0;
  }
  body.dark-only .alert-success .progress-bar {
    background-color: #ebfae5;
  }
  body.dark-only .alert-success.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-success.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-success.outline .btn-close,
  body.dark-only .alert-success.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-danger {
    background-color: rgba(220, 53, 69, 0.8) !important;
    border-color: rgba(220, 53, 69, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-danger .progress {
    height: 5px;
    background-color: #db3141;
    border-radius: 0;
  }
  body.dark-only .alert-danger .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-danger.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-danger.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-danger.outline .btn-close,
  body.dark-only .alert-danger.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-warning {
    background-color: rgba(248, 214, 43, 0.8) !important;
    border-color: rgba(248, 214, 43, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-warning .progress {
    height: 5px;
    background-color: #f8d526;
    border-radius: 0;
  }
  body.dark-only .alert-warning .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-warning.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-warning.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-warning.outline .btn-close,
  body.dark-only .alert-warning.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-info {
    background-color: rgba(169, 39, 249, 0.8) !important;
    border-color: rgba(169, 39, 249, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-info .progress {
    height: 5px;
    background-color: #a722f9;
    border-radius: 0;
  }
  body.dark-only .alert-info .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-info.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-info.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-info.outline .btn-close,
  body.dark-only .alert-info.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-light {
    background-color: rgba(244, 244, 244, 0.8) !important;
    border-color: rgba(244, 244, 244, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-light .progress {
    height: 5px;
    background-color: #f1f1f1;
    border-radius: 0;
  }
  body.dark-only .alert-light .progress-bar {
    background-color: white;
  }
  body.dark-only .alert-light.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-light.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-light.outline .btn-close,
  body.dark-only .alert-light.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .alert-dark {
    background-color: rgba(44, 50, 63, 0.8) !important;
    border-color: rgba(44, 50, 63, 0.9) !important;
    color: #fff;
  }
  body.dark-only .alert-dark .progress {
    height: 5px;
    background-color: #2a303c;
    border-radius: 0;
  }
  body.dark-only .alert-dark .progress-bar {
    background-color: #a8b0c2;
  }
  body.dark-only .alert-dark.inverse {
    background-color: transparent !important;
  }
  body.dark-only .alert-dark.inverse .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .alert-dark.outline .btn-close,
  body.dark-only .alert-dark.outline-2x .btn-close {
    filter: brightness(1) invert(1);
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close {
    filter: unset;
  }
  body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
    color: #2b2b2b !important;
  }
  body.dark-only .options > div {
    border: 1px solid #374558;
  }
  body.dark-only .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    background-color: #1d1e26;
  }
  body.dark-only .pricing-simple {
    box-shadow: 1px 1px 2px 1px #1d1e26 !important;
  }
  body.dark-only .pricing-block {
    box-shadow: 0 0 10px 5px rgba(115, 102, 255, 0.05) !important;
  }
  body.dark-only .search-page ul.search-info li + li {
    border-left: 1px solid #1d1e26;
  }
  body.dark-only .browse .browse-articles h6 {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .job-search .job-description .theme-form .row div[class^=col-] .input-group .datepicker-here {
    border: 1px solid #1d1e26;
  }
  body.dark-only .calendar-wrap .fc-unthemed td,
  body.dark-only .calendar-wrap .fc-unthemed th {
    border-color: #1d1e26;
  }
  body.dark-only .mega-inline .card,
  body.dark-only .mega-horizontal .card,
  body.dark-only .mega-vertical .card {
    box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
  }
  body.dark-only .mega-inline.plain-style .card, body.dark-only .mega-inline.border-style .card, body.dark-only .mega-inline.offer-style .card,
  body.dark-only .mega-horizontal.plain-style .card,
  body.dark-only .mega-horizontal.border-style .card,
  body.dark-only .mega-horizontal.offer-style .card,
  body.dark-only .mega-vertical.plain-style .card,
  body.dark-only .mega-vertical.border-style .card,
  body.dark-only .mega-vertical.offer-style .card {
    box-shadow: none !important;
  }
  body.dark-only .mega-inline.border-style .card,
  body.dark-only .mega-horizontal.border-style .card,
  body.dark-only .mega-vertical.border-style .card {
    border: 1px solid #374558 !important;
  }
  body.dark-only .mega-inline.offer-style .card,
  body.dark-only .mega-horizontal.offer-style .card,
  body.dark-only .mega-vertical.offer-style .card {
    border: 1px dashed #374558 !important;
  }
  body.dark-only .setting-dot .setting-bg {
    background-color: var(--theme-secondary);
  }
  body.dark-only .bookmark.pull-right {
    border: none;
  }
  body.dark-only .popover {
    background-color: #1d1e26;
  }
  body.dark-only .popover.bs-popover-bottom .popover-arrow:after {
    border-bottom-color: #1d1e26;
    border-right-color: transparent;
  }
  body.dark-only .popover.bs-popover-top .popover-arrow:after {
    border-right-color: transparent;
    border-top-color: #1d1e26;
  }
  body.dark-only .popover.bs-popover-start .popover-arrow:after {
    border-left-color: #1d1e26;
  }
  body.dark-only .popover .popover-header {
    background-color: #2c2c2c;
  }
  body.dark-only .popover .popover-arrow:after {
    border-right-color: #1d1e26;
  }
  body.dark-only .popover .popover-body {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .popover code {
    background-color: unset;
  }
  body.dark-only .apex-chart-container .apexcharts-legend .apexcharts-legend-series span {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
    fill: #fff;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
    fill: #fff;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-yaxis-label {
    fill: #fff;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-xaxis-label {
    fill: #fff;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-label {
    fill: #fff;
  }
  body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-value {
    fill: #fff;
  }
  body.dark-only .Typeahead-menu {
    background-color: #1d1e26;
  }
  body.dark-only .ecommerce-widget {
    border: 1px solid #1d1e26;
  }
  body.dark-only .bookmark ul li .search-form .form-control-search input {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .bookmark ul li .search-form .form-control-search:before {
    background: #1d1e26;
  }
  body.dark-only .cart .qty-box .input-group .btn {
    background-color: #2e3648 !important;
    border-color: #374558 !important;
  }
  body.dark-only .checkout .checkout-details {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    padding: 40px;
  }
  body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
    border: 2px solid #2c2c2c;
  }
  body.dark-only .order-box .title-box {
    color: #bfc2c6;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .order-box .qty {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .order-box .qty li {
    color: #bfc2c6;
  }
  body.dark-only .order-box .qty li span {
    color: #bfc2c6;
  }
  body.dark-only .order-box .sub-total li {
    color: #bfc2c6;
  }
  body.dark-only .order-box .total li {
    color: #bfc2c6;
  }
  body.dark-only .shopping-checkout-option .checkbox_animated:after {
    border: 2px solid #2c2c2c;
  }
  body.dark-only .animate-chk .radio_animated:after {
    border: 2px solid #1d1e26;
  }
  body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(41, 50, 64, 0.52);
  }
  body.dark-only .product-box .product-img .product-hover ul li .btn {
    color: #98a6ad;
  }
  body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
    color: #fff;
  }
  body.dark-only .radio_animated:after,
  body.dark-only .checkbox_animated:after {
    background: #1d1e26;
    border: 2px solid #1d1e26;
  }
  body.dark-only .slider-product {
    border-top: 1px solid #1d1e26;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .square-product-setting .icon-grid {
    background-color: #2c2c2c;
  }
  body.dark-only .square-product-setting .icon-grid svg {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .active-order-table table thead tr th,
  body.dark-only .market-table table thead tr th {
    border-top: none !important;
  }
  body.dark-only .active-order-table table tbody tr td,
  body.dark-only .market-table table tbody tr td {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .active-order-table table tbody tr:last-child td,
  body.dark-only .market-table table tbody tr:last-child td {
    border-bottom: none;
  }
  body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .flot-chart-container .legend .table tbody {
    background-color: #2c2c2c;
    color: #fff;
  }
  body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
    padding-left: 5px;
  }
  body.dark-only .google-visualization-tooltip text {
    fill: rgba(0, 0, 0, 0.7) !important;
  }
  body.dark-only .nav-dark .nav-link,
  body.dark-only .nav-pills.nav-dark .nav-link {
    color: #fff;
  }
  body.dark-only .loader-wrapper {
    background-color: #1d1e26;
  }
  body.dark-only .loader-wrapper .loader {
    background-color: #1d1e26 !important;
  }
  body.dark-only .page-wrapper {
    /* Main Header start */
    /* Main Header ends */
  }
  body.dark-only .page-wrapper .page-header .translate_wrapper.active .more_lang {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .translate_wrapper.active .lang {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header form {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper li i {
    color: #f4f4f4;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li svg {
    stroke: #fff;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right.right-header > ul > li .profile-media .media-body p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .media .media-body .txt-dark {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div {
    background-color: #1d1e26;
    box-shadow: 0 0 14px #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div li {
    border-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:before {
    border-bottom: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:after {
    border-bottom: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div .bg-light {
    background-color: #282e38 !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg path,
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg line,
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg circle {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg line,
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg path,
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg circle {
    color: var(--theme-default) !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .media .media-body .txt-dark {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div {
    background-color: #1d1e26;
    box-shadow: 0 0 2px 2px #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:before {
    border-bottom: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:after {
    border-bottom: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li {
    border-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content .bookmark-icon {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown li {
    color: #fff;
    border-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-header .header-logo-wrapper {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-dark {
    display: none;
  }
  body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-light {
    display: block;
  }
  body.dark-only .page-wrapper .page-body-wrapper {
    /* body start*/
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .header-small {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .statistics p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .media-body p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .professor-block p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .media-body p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .professor-block p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .logs-element span + span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-block .progress-title span + span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .ecommerce-icons div span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .new-users .media .media-body p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .recent-notification .media .media-body p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .media .media-body span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .progress-change span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td {
    border-top: none !important;
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr:last-child td {
    border-bottom: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .notifiaction-media .media .media-body .circle-left {
    border: 5px solid #334154;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner:last-child {
    border-bottom: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table table tbody tr td {
    border-top: none !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .number-widgets .media .media-body h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.gradient-line-1:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.small-line:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.medium-line:after {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .media-body h6 span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card.custom-card .card-header {
    border-bottom: none !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .custom-card .card-header img {
    background-color: #000;
    opacity: 0.8;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 small {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb li {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb-item.active {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body {
    background-color: #1d1e26;
    /* body end*/
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card:not(.email-body) {
    border: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) {
    background-color: #2c2c2c;
    box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > rect {
    fill: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > g text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > rect {
    fill: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header {
    background-color: transparent;
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header > span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h2,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h3,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h4,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h5,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i.fa-cog {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark a {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline-2x,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #animation-box .animate-widget p {
    color: #98a6ad !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .grid-item img {
    background-color: #2c2c2c;
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .line {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) thead,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tbody,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tfoot,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tr,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) th {
    border-color: #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead th {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead .border-bottom-primary th {
    border-bottom: 1px solid var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger td {
    color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-primary,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-secondary,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-success,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-info,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-warning,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-danger {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-light {
    color: #000;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .thead-light th {
    color: #000;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary td {
    border-bottom: 1px solid var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*=bg-] th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*=bg-] td {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd) {
    --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover td {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-double,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dotted,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dashed {
    border-left-color: #374558;
    border-right-color: #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered {
    border-color: #374558 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered th {
    border-color: #374558 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-inverse tbody tr:nth-of-type(odd):hover td {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical tr,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical td {
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody td {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-footer {
    background-color: #2c2c2c;
    border-top: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch .switch-state {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch input:checked + .switch-state {
    background-color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bg-white {
    background-color: #fff !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-l-light {
    border-left: 1px solid #1d1e26 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-grid {
    stroke: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-label {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) hr {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .text-muted {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .calender-widget .cal-date {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .contact-form .theme-form .form-icon {
    background-color: #2c2c2c;
    border: 1px solid #374558;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-dark,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light-2x {
    color: #fff !important;
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light:hover {
    color: #000 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
    border-right: 1px solid #1d1e26 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder .flot-text {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder svg text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart svg g rect:first-child {
    fill: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart rect:nth-child(6) {
    fill: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#line-chart rect, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart1 rect, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart2 rect {
    fill: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > rect {
    fill: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > g > g text {
    fill: rgb(177, 177, 178);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .radial-bar:after {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num .color-bottom {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light {
    border-right: 1px solid #374558 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-labels .ct-label,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-labels .ct-label,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-labels .ct-label,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-labels .ct-label {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-grid,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-grid,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-grid,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-grid {
    stroke: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #browser-uses-chart svg text,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #website-visiter-chart svg text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .clipboaard-container .form-control {
    background-color: #1d1e26;
    color: #98a6ad;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .img-thumbnail {
    background-color: #1d1e26;
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper button {
    color: #000;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-danger,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-success,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-primary {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length select {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_info,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_processing,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter input[type=search] {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr:hover > .sorting_1 {
    background-color: #1c212b;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd {
    background-color: #1f232b;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #1c212b;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #22262f;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead td {
    border-bottom: 2px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable input,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable select {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody tr {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
    border: 1px solid #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .table-striped tbody tr:nth-of-type(odd) {
    --bs-table-accent-bg: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.current, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    border-color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    color: #98a6ad !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled :active {
    color: #98a6ad !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody td {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even:hover > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
    background-color: #191e27;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.hover tbody tr:hover,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover {
    background-color: #1c212b;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td {
    border-top: 1px solid #1d1e26;
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th:first-child,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td:first-child {
    border-left: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_2,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_3,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_2,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_3 {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #example-style-3_wrapper #example-style-3 tfoot {
    border-top: 2px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.disabled .page-link {
    background-color: #2c2c2c;
    border-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
    color: rgba(255, 255, 255, 0.6);
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item:hover .page-link {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.active .page-link {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .icon {
    color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-container-small {
    background-color: #2c2c2c;
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td span,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link.active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:focus, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-selected,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-active,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-selected,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-active,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-selected,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-active,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-selected,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-active,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a {
    border: 2px solid #1d1e26;
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid var(--theme-default);
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-selected:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-active:after,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-active:after {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .scroll-demo {
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form input {
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form .form-group:before {
    background: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content::before {
    border-left: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    border-right-color: #1d1e26;
    border-left: transparent;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .breadcrumb.bg-white {
    background-color: #2c2c2c !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table th {
    border-top: none !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris-daily svg,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris svg,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #browser-uses-chart svg,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #website-visiter-chart svg {
    opacity: 0.5;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-header-1,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-2-header {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .show > .nav-link {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link.active {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link.active {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .drag-box fieldset {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .help-block {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body #viewhtml .render {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
    border-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-column .drag-bx {
    border: 1px dotted #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .wizard-4 ul.anchor li a.disabled {
    color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description h4 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description a > div {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-header {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell {
    background: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input {
    background-color: #1d1e26;
    border-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input:focus,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input:focus,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input:focus,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input:focus {
    outline: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row select,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row select {
    background-color: #1d1e26;
    border-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-row > .jsgrid-cell {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-alt-row > .jsgrid-cell {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-cell,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-body {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-selected-row > .jsgrid-cell {
    background-color: #1c212b;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager [class*=jsgrid-pager] {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager .jsgrid-pager-page a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-pager-current-page {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .sub-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon .separator {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according li .text-muted {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon.default-according.style-1 li button:hover {
    color: var(--theme-default) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover a {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div {
    background-color: #1d1e26;
    box-shadow: 0 0 14px 0 #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li p {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg path,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg line,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polyline,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polygon,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg rect {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polygon,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg rect {
    color: var(--theme-default) !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .onhover-dropdown:hover .onhover-show-div:before {
    border-bottom: 7px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .btn-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
    border: 1px solid #374558;
    border-top: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .border {
    border: 1px solid #374558 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-date {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li {
    color: #98a6ad;
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:first-child,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:first-child {
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:last-child,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:last-child {
    border-right: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top p,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media h6 span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media img {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li:first-child {
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box hr {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover td,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover th {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li {
    border: 1px dotted #374558;
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
    padding: 10px;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li:hover {
    background: #000;
    box-shadow: 0 0 3px #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li em {
    display: none;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .box {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .button-preview h2 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap pre.well {
    background-color: #2c2c2c !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li + li {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity ul.dates li {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris svg text,
  body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris-daily svg text {
    fill: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-profile img {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .profile-details h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-social li a {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div + div {
    border-left: 1px solid #323846;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .form-control {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .checkbox label::before,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .radio label::before {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a {
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent;
    border-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-header {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-divider {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a:hover, body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a.active {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview .dz-details {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body {
    column-rule: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .media img {
    border: 2px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a:hover {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar ul li a > .title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .row .col-xl-4 {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .inbox .media.active {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-top {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap p {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-content .email-top .user-emailid:after {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu {
    background-color: #1d1e26;
    border: 1px solid #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a {
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    border-top-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div i {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div:hover {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .media-body > span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .details {
    border-left: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before, body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after {
    background-color: #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .redial-social-widget i {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card .media .social-font {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-light {
    border-bottom: 1px solid #374558 !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-dark {
    border-right: 1px solid #fff !important;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial i {
    color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-showcase span {
    background-color: #2c2c2c;
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-align .row {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-html-output {
    background-color: #1d1e26;
    color: #98a6ad;
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-grid {
    background-color: #2c2c2c;
    color: #98a6ad !important;
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-mainControls .ge-addRowGroup .ge-row-icon .column {
    border-left: 3px solid #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-code,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-screen {
    color: #98a6ad;
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .row {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .column {
    border: 1px solid #1d1e26;
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details input {
    background-color: #1d1e26;
    border-color: #1d1e26;
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details .btn-group a {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-content-type-ckeditor {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .search-page .info-block {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-primary h5,
  body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-secondary h5 {
    color: #fff;
  }
  body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.timeline-line-1:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.small-line:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.medium-line:after {
    background-color: #374558;
  }
  body.dark-only .page-wrapper .page-body-wrapper .footer {
    border-top: 1px solid #1d1e26;
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .page-body-wrapper .footer p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .custom-select,
  body.dark-only .page-wrapper .page-body-wrapper .custom-file-label {
    background: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .page-body-wrapper .footer-fix {
    background-color: #2c2c2c;
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .note textarea {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .dt-button-info {
    background-color: #2c2c2c;
    border: 1px solid #98a6ad;
  }
  body.dark-only .page-wrapper .dt-button-info h2 {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .chat-box .about .name {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .chat-box .chat-menu {
    border-left: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .icon-wrapper {
    background-color: #2c2c2c;
    box-shadow: 1px 1px 3px 1px #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .avatar img {
    border: 5px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .social-media a {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow span {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow .follow-num {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .chat-box .status {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .chat-box .status p {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header img {
    box-shadow: 1px 1px 4px 1px #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .message {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message {
    background-color: #2c2c2c;
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message .input-group .form-control {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li a {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper pre {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .scorlled {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .input-group-air {
    box-shadow: 0 3px 20px 0 #1d1e26;
  }
  body.dark-only .page-wrapper .input-group-text {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .input-group-text i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .input-group-solid .input-group-text,
  body.dark-only .page-wrapper .input-group-solid .form-control {
    background: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .semilight-bg-color,
  body.dark-only .page-wrapper .header-bg-color {
    background-color: #fff;
  }
  body.dark-only .page-wrapper .list-group-item {
    background-color: #2c2c2c;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  body.dark-only .page-wrapper .list-group-item.active {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default);
    color: #fff;
  }
  body.dark-only .page-wrapper .list-group-item:hover, body.dark-only .page-wrapper .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
  }
  body.dark-only .page-wrapper .list-group-item-action:hover:not(.active), body.dark-only .page-wrapper .list-group-item-action:focus {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .list-group-flush .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
  body.dark-only .page-wrapper .list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
  }
  body.dark-only .page-wrapper .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
  }
  body.dark-only .page-wrapper .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
  }
  body.dark-only .page-wrapper .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }
  body.dark-only .page-wrapper .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }
  body.dark-only .page-wrapper .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }
  body.dark-only .page-wrapper .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }
  body.dark-only .page-wrapper .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
  }
  body.dark-only .page-wrapper .auth-bg {
    background-blend-mode: overlay;
    background-color: rgba(54, 64, 74, 0.9);
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box .card {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group input {
    color: rgba(255, 255, 255, 0.6);
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group label {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label::before {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .auth-bg .authentication-box h3,
  body.dark-only .page-wrapper .auth-bg .authentication-box h4,
  body.dark-only .page-wrapper .auth-bg .authentication-box h6 {
    color: #fff;
  }
  body.dark-only .page-wrapper .auth-bg-video {
    background-blend-mode: overlay;
    background-color: rgba(54, 64, 74, 0.7);
  }
  body.dark-only .page-wrapper .auth-bg-video .authentication-box h4,
  body.dark-only .page-wrapper .auth-bg-video .authentication-box h3 {
    color: #fff;
  }
  body.dark-only .page-wrapper .auth-bg-video .authentication-box h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .auth-bg-video .authentication-box .card {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type=text],
  body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type=password] {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .checkbox label::before {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
    color: #1d1e26;
  }
  body.dark-only .page-wrapper .error-wrapper .maintenance-icons li:nth-child(3) i {
    color: #1d1e26;
  }
  body.dark-only .page-wrapper .modal-content {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .modal-content .modal-header {
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .modal-content .modal-header .close {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .modal-content .modal-footer {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .comingsoon {
    background-blend-mode: overlay;
    background-color: rgba(54, 64, 74, 0.9);
  }
  body.dark-only .page-wrapper .comingsoon .comingsoon-inner h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown {
    border-top: 1px solid #1d1e26;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown .title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .comingsoon .comingsoon-inner .coming-soon-bottom-link a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .theme-form .login-divider {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .theme-form .login-divider:before {
    background: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .authentication-main {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .card {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .reset-password-link {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group label {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group .form-control {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border-color: #1d1e26;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .opt-box {
    background-color: #1d1e26;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h4 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h6 {
    color: #98a6ad;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h3 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type=text],
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type=password] {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .checkbox label::before {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .vertical-menu-main {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .mega-menu .title {
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .page-wrapper .mega-menu .list-unstyled div a:hover {
    color: var(--theme-default);
  }
  body.dark-only .page-wrapper .default-according .card {
    background-color: #2c2c2c;
  }
  body.dark-only .page-wrapper .default-according .card .btn-link {
    background-color: #2c2c2c;
    border: 1px solid #374558;
    border-bottom: none;
    color: #fff;
  }
  body.dark-only .page-wrapper .default-according .card .text-muted {
    color: #98a6ad !important;
  }
  body.dark-only .page-wrapper .default-according .bg-primary .btn-link {
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
  }
  body.dark-only .page-wrapper .default-according .bg-secondary .btn-link {
    background-color: var(--theme-secondary);
    border: 1px solid var(--theme-secondary);
  }
  body.dark-only .page-wrapper .collapse .card-body {
    background-color: #2c2c2c;
  }
  @media screen and (max-width: 1660px) {
    body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
      border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .total-time h2 {
      color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .caller-img img {
      opacity: 0.7;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .call-content > div {
      background-color: rgba(0, 0, 0, 0.75);
      background-blend-mode: overlay;
    }
  }
  @media only screen and (max-width: 1199.98px) {
    body.dark-only .page-wrapper .left-header .mega-menu-container {
      border-left: 1px solid #374558;
    }
    body.dark-only .page-wrapper .left-header .mega-menu-container .mega-box + .mega-box {
      border-left: none;
    }
  }
  @media only screen and (max-width: 1199px) {
    body.dark-only .page-wrapper .mobile-title.d-none {
      border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .chat-menu {
      border-top: 1px solid #1d1e26;
      background-color: #2c2c2c;
    }
    body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
      color: #2c323f;
    }
  }
  @media only screen and (max-width: 575.98px) {
    body.dark-only .page-wrapper .user-profile .hovercard .info .user-designation {
      border-top: 1px solid #1d1e26;
      border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .widget-joins .border-after-xs:after {
      background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form .form-control-plaintext {
      background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, body.dark-only .page-wrapper .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
      border-right: 1px solid #374558;
    }
  }
  body.dark-only .lg-backdrop {
    background-color: #1d1e26;
  }
  body.dark-only .lg-outer .lg-thumb-outer {
    background-color: #2c2c2c;
  }
  body.dark-only .drag {
    background-color: #2c2c2c;
  }
  body.dark-only .drag form {
    background: #2c2c2c;
    box-shadow: 0 0 30px #1d1e26;
    border: 1px dotted #1d1e26;
  }
  body.dark-only .drag form input {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .drag form .help-block {
    text-transform: capitalize;
    color: #98a6ad;
  }
  body.dark-only .draggable input {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .draggable p {
    color: #98a6ad;
  }
  body.dark-only .draggable select {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .draggable .radio label::before,
  body.dark-only .draggable .checkbox label::before {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .select2-container--default .select2-selection--multiple,
  body.dark-only .select2-container--default .select2-selection--single {
    background-color: #1d1e26;
    border: 1px solid #1d1e26 !important;
  }
  body.dark-only .select2-container--default .select2-search--inline .select2-search__field {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #2c2c2c !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .select2-dropdown {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only .select2-drpdwn .form-control-primary {
    border-color: var(--theme-default) !important;
    color: var(--theme-default) !important;
  }
  body.dark-only .select2-drpdwn .form-control-secondary {
    border-color: var(--theme-secondary) !important;
    color: var(--theme-secondary) !important;
  }
  body.dark-only .select2-drpdwn .form-control-success {
    border-color: #51bb25 !important;
    color: #51bb25 !important;
  }
  body.dark-only .select2-drpdwn .form-control-info {
    border-color: #a927f9 !important;
    color: #a927f9 !important;
  }
  body.dark-only .select2-drpdwn .form-control-warning {
    border-color: #f8d62b !important;
    color: #f8d62b !important;
  }
  body.dark-only .select2-drpdwn .form-control-danger {
    border-color: #dc3545 !important;
    color: #dc3545 !important;
  }
  body.dark-only .select2-drpdwn .form-control-inverse {
    border-color: #fff !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-primary-fill {
    background-color: var(--theme-default) !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-secondary-fill {
    background-color: var(--theme-secondary) !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-success-fill {
    background-color: #51bb25 !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-info-fill {
    background-color: #a927f9 !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-warning-fill {
    background-color: #f8d62b !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-danger-fill {
    background-color: #dc3545 !important;
    color: #fff !important;
  }
  body.dark-only .select2-drpdwn .form-control-inverse-fill {
    background-color: #fff !important;
    color: #000 !important;
  }
  body.dark-only .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #1d1e26;
  }
  body.dark-only .theme-form .form-group select.form-control:not([size]):not([multiple]) {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border-color: #1d1e26;
  }
  body.dark-only .theme-form .form-group input[type=text],
  body.dark-only .theme-form .form-group input[type=email],
  body.dark-only .theme-form .form-group input[type=search],
  body.dark-only .theme-form .form-group input[type=password],
  body.dark-only .theme-form .form-group input[type=number],
  body.dark-only .theme-form .form-group input[type=tel],
  body.dark-only .theme-form .form-group input[type=date],
  body.dark-only .theme-form .form-group input[type=datetime-local],
  body.dark-only .theme-form .form-group input[type=time],
  body.dark-only .theme-form .form-group input[type=datetime-local],
  body.dark-only .theme-form .form-group input[type=month],
  body.dark-only .theme-form .form-group input[type=week],
  body.dark-only .theme-form .form-group input[type=url],
  body.dark-only .theme-form .form-group input[type=file],
  body.dark-only .theme-form .form-group select {
    border-color: #1d1e26;
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .theme-form .form-group input[type=text]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=email]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=search]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=password]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=number]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=tel]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=date]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=time]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=month]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=week]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=url]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group input[type=file]::-webkit-input-placeholder,
  body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
    color: #98a6ad;
  }
  body.dark-only .theme-form .form-group .form-control {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .theme-form .form-group textarea {
    border-color: #1d1e26;
  }
  body.dark-only .theme-form .form-divider {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .theme-form .form-divider::before {
    background: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .CodeMirror {
    background: #2c2c2c;
    border: 1px solid #98a6ad;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .CodeMirror .CodeMirror-code pre {
    background: #2c2c2c;
  }
  body.dark-only .editor-toolbar {
    border-top: 1px solid #98a6ad;
    border-left: 1px solid #98a6ad;
    border-right: 1px solid #98a6ad;
  }
  body.dark-only .editor-toolbar a {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  body.dark-only .editor-toolbar a:hover, body.dark-only .editor-toolbar a.active {
    background: #1d1e26;
  }
  body.dark-only .editor-toolbar i.separator {
    border-left: 1px solid #98a6ad;
    border-right: 1px solid #98a6ad;
  }
  body.dark-only .editor-toolbar.fullscreen::before {
    background: linear-gradient(to right, #1d1e26 0, rgba(0, 0, 0, 0) 100%);
  }
  body.dark-only .editor-toolbar.fullscreen::after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, #1d1e26 100%);
  }
  body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background: #1d1e26;
  }
  body.dark-only .editor-preview {
    background-color: #2c2c2c;
  }
  body.dark-only .editor-toolbar.fullscreen,
  body.dark-only .editor-preview-side {
    background: #2c2c2c;
    border: 1px solid #98a6ad;
  }
  body.dark-only .u-step {
    background: #1d1e26;
  }
  body.dark-only .u-step.active, body.dark-only .u-step.current {
    background: var(--theme-default);
    color: #fff;
  }
  body.dark-only .u-step-title,
  body.dark-only .u-pearl-title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .u-step-number {
    background-color: #2c2c2c;
  }
  body.dark-only .u-pearl:before {
    background-color: #1d1e26;
  }
  body.dark-only .u-pearl-number,
  body.dark-only .u-pearl-icon {
    background: #1d1e26;
    border: 2px solid #1d1e26;
  }
  body.dark-only .u-pearl.disabled .u-pearl-icon,
  body.dark-only .u-pearl.disabled .u-pearl-number {
    background: #1d1e26;
    border: 2px solid #1d1e26;
  }
  body.dark-only .u-pearl.disabled:after {
    background-color: #334053;
  }
  body.dark-only .u-pearl.error:after {
    background-color: #1d1e26;
  }
  body.dark-only .note-editor.note-frame {
    border-color: #1d1e26;
  }
  body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .swal-modal {
    background-color: #2c2c2c;
  }
  body.dark-only .swal-modal .swal-title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .swal-modal .swal-text {
    color: #98a6ad;
  }
  body.dark-only .swal-modal .swal-content__input {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .nav-tabs {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .nav-tabs .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .nav-tabs .nav-link.active {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
    border-color: #374558 #374558 #2c2c2c;
  }
  body.dark-only .nav-tabs .nav-link:hover, body.dark-only .nav-tabs .nav-link:focus {
    border-color: #374558 #374558 #323846;
  }
  body.dark-only .nav-tabs .nav-item.show .nav-link {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
    border-color: #1d1e26 #1d1e26 #2c2c2c;
  }
  body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active, body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus, body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
    border-color: #2c2c2c #1d1e26 #1d1e26;
  }
  body.dark-only .border-tab.nav-left .nav-link,
  body.dark-only .border-tab.nav-right .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .border-tab.nav-left .nav-link.active,
  body.dark-only .border-tab.nav-right .nav-link.active {
    color: var(--theme-default);
  }
  body.dark-only .border-tab.nav-left .show > .nav-link,
  body.dark-only .border-tab.nav-right .show > .nav-link {
    color: var(--theme-default);
  }
  body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active, body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus, body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
    border-left-color: var(--theme-secondary);
    color: var(--theme-secondary) !important;
  }
  body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
    border-left-color: var(--theme-secondary);
    color: var(--theme-secondary) !important;
  }
  body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
    color: var(--theme-secondary) !important;
    border-left-color: var(--theme-secondary);
  }
  body.dark-only .border-tab.nav-left.nav-success .nav-link.active, body.dark-only .border-tab.nav-left.nav-success .nav-link:focus, body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
    color: #51bb25 !important;
  }
  body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
    border-left-color: #51bb25;
    color: #51bb25 !important;
  }
  body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
    color: #51bb25 !important;
    border-left-color: #51bb25;
  }
  body.dark-only .border-tab.nav-right.nav-info .nav-link.active, body.dark-only .border-tab.nav-right.nav-info .nav-link:focus, body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
    color: #a927f9 !important;
  }
  body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
    border-left-color: #a927f9;
    color: #a927f9 !important;
  }
  body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
    color: #a927f9 !important;
    border-left-color: #a927f9;
  }
  body.dark-only .border-tab.nav-secondary .nav-link.active, body.dark-only .border-tab.nav-secondary .nav-link:focus, body.dark-only .border-tab.nav-secondary .nav-link:hover {
    color: var(--theme-secondary) !important;
  }
  body.dark-only .border-tab.nav-secondary .nav-item.show {
    color: var(--theme-secondary) !important;
  }
  body.dark-only .border-tab.nav-success .nav-link.active, body.dark-only .border-tab.nav-success .nav-link:focus, body.dark-only .border-tab.nav-success .nav-link:hover {
    color: #51bb25 !important;
  }
  body.dark-only .border-tab.nav-success .nav-item.show {
    color: #51bb25 !important;
  }
  body.dark-only .dropdown-divider {
    border-top: 1px solid #1d1e26;
  }
  body.dark-only .icon-hover-bottom {
    background-color: #2c2c2c;
    box-shadow: 0px 0px 1px 1px #1d1e26;
  }
  body.dark-only .icon-hover-bottom .icon-title {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .icon-hover-bottom span {
    color: #98a6ad;
  }
  body.dark-only .icon-hover-bottom .form-group input {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #1d1e26;
  }
  body.dark-only .icon-hover-bottom .icon-first i {
    color: #98a6ad;
  }
  body.dark-only code {
    background-color: #1d1e26;
    border-radius: 2px;
  }
  body.dark-only #cd-timeline::before {
    background: #1d1e26;
  }
  body.dark-only .timeliny {
    border-top: 1px solid #1d1e26;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .timeliny::before {
    background-color: #fff;
  }
  body.dark-only .timeliny .timeliny-dot {
    background-color: #2c2c2c;
    border: 1px solid #fff;
  }
  body.dark-only .timeliny .timeliny-dot::before {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
    border: none;
  }
  body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover {
    background-color: #fff;
  }
  body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover::before {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
    background: #2c2c2c;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-label {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
    color: #fff;
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover:not(.active) {
    background: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .task-label {
    color: var(--theme-default);
  }
  body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover {
    border: 1px solid #51bb25 !important;
  }
  body.dark-only .todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .user-profile .ttl-info h6 {
    color: #98a6ad;
  }
  body.dark-only .user-profile .ttl-info span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .user-profile .hovercard .info .title a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .user-profile .hovercard .user-image .avatar img {
    border: 10px solid #2c2c2c;
  }
  body.dark-only .user-profile .hovercard .user-image .icon-wrapper {
    background-color: #2c2c2c;
  }
  body.dark-only .user-profile .hovercard .tabs-scoial {
    border-bottom: none !important;
  }
  body.dark-only .user-profile .follow .follow-num {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .user-profile .profile-img-style .user-name {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .user-profile .profile-img-style p {
    color: #98a6ad;
  }
  body.dark-only .introjs-helperLayer {
    background-color: rgba(54, 64, 74, 0.9);
    border: rgba(255, 255, 255, 0.5);
  }
  body.dark-only .jstree-default .jstree-node,
  body.dark-only .jstree-default .jstree-icon {
    color: #455869 !important;
  }
  body.dark-only .jstree-default .jstree-anchor {
    color: #98a6ad;
  }
  body.dark-only .jstree-default .jstree-clicked {
    color: #fff;
    background-color: transparent;
  }
  body.dark-only .jstree-default .jstree-hovered {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
  }
  body.dark-only span.twitter-typeahead .tt-menu {
    background-color: #1d1e26;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only span.twitter-typeahead .tt-suggestion {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only span.twitter-typeahead .tt-suggestion:hover, body.dark-only span.twitter-typeahead .tt-suggestion:focus {
    background-color: #2c2c2c;
  }
  body.dark-only .typography small {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .code-box-copy pre {
    background-color: #1d1e26;
  }
  body.dark-only .code-box-copy pre code {
    background-color: #1d1e26;
  }
  body.dark-only .code-box-copy pre[class*=language-] {
    border: 1px solid #1d1e26;
  }
  body.dark-only .code-box-copy .code-box-copy__btn {
    background-color: #1d1e26;
    border: 1px solid #1d1e26;
    color: #fff;
  }
  body.dark-only .code-box-copy code[class*=language-],
  body.dark-only .code-box-copy pre[class*=language-] {
    text-shadow: 0px 1px #000;
  }
  body.dark-only .code-box-copy code[class*=language-] ::selection,
  body.dark-only .code-box-copy pre[class*=language-] ::selection {
    text-shadow: none;
    background: #2c2c2c;
  }
  body.dark-only table.fixedHeader-floating {
    background-color: #1d1e26;
  }
  body.dark-only .note textarea {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .dt-button-info {
    background-color: #2c2c2c;
    border: 1px solid #1d1e26;
  }
  body.dark-only .dt-button-info h2 {
    background-color: #2c2c2c;
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only pre {
    background-color: #1d1e26;
  }
  body.dark-only #keytable_wrapper .dataTables_paginate,
  body.dark-only #column-selector_wrapper .dataTables_paginate,
  body.dark-only #auto-fill_wrapper .dataTables_paginate,
  body.dark-only #custom-button_wrapper .dataTables_paginate,
  body.dark-only #class-button_wrapper .dataTables_paginate,
  body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
  body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
  body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
  body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
  body.dark-only #state-saving_wrapper .dataTables_paginate,
  body.dark-only #real-time_wrapper .dataTables_paginate,
  body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
  body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
  body.dark-only #export-button_wrapper .dataTables_paginate,
  body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
  body.dark-only #cust-json_wrapper .dataTables_paginate,
  body.dark-only #basic-key-table_wrapper .dataTables_paginate,
  body.dark-only #focus-cell_wrapper .dataTables_paginate,
  body.dark-only #responsive_wrapper .dataTables_paginate,
  body.dark-only #new-cons_wrapper .dataTables_paginate,
  body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
  body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
  body.dark-only #full-row_wrapper .dataTables_paginate,
  body.dark-only #rest-column_wrapper .dataTables_paginate {
    border: none;
  }
  body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
  body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
    background-color: #1d1e26;
  }
  body.dark-only .error-wrapper {
    background-color: rgba(54, 64, 74, 0.8);
  }
  body.dark-only .error-wrapper .sub-content {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .b-light {
    border: 1px solid #1d1e26 !important;
  }
  body.dark-only .modal-content {
    background-color: #2c2c2c;
  }
  body.dark-only .modal-content .modal-header {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .modal-content .modal-header .close {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  body.dark-only .modal-content .ui-front .form-control {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid #374558;
  }
  body.dark-only .stepwizard .stepwizard-row:before {
    background-color: #374558;
  }
  body.dark-only .modal .theme-close {
    background-color: #2c2c2c !important;
    color: rgba(255, 255, 255, 0.2);
  }
  body.dark-only .token.atrule,
  body.dark-only .token.attr-value,
  body.dark-only .token.keyword {
    color: #a927f9;
  }
  body.dark-only .token.boolean, body.dark-only .token.constant, body.dark-only .token.deleted, body.dark-only .token.number, body.dark-only .token.property, body.dark-only .token.symbol, body.dark-only .token.tag {
    color: #dc3545;
  }
  body.dark-only .loader-box.card-loader {
    background-color: #2c2c2c;
  }
  body.dark-only .my-gallery.gallery-with-description img {
    border: 1px solid #1d1e26 !important;
    border-bottom: none !important;
  }
  body.dark-only .alert-primary.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-secondary.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-success.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-danger.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-warning.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-info.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-light.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-dark.inverse {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .alert-theme span + span + span {
    border-left: 5px solid var(--theme-default);
    background-color: #1d1e26;
    border-radius: 4px;
  }
  body.dark-only .alert-theme i {
    color: #fff;
    padding: 20px;
    margin-right: 20px !important;
  }
  body.dark-only .user-card .user-deatils h6 {
    color: #98a6ad;
  }
  body.dark-only .user-card .card-footer > div:first-child {
    border-right: 1px solid #1d1e26;
  }
  body.dark-only .user-card .card-footer .user-footer h6 {
    color: #98a6ad;
  }
  body.dark-only .user-card .card-footer .user-footer svg path,
  body.dark-only .user-card .card-footer .user-footer svg rect {
    color: #98a6ad;
  }
  body.dark-only .order-history table .qty-box button {
    background-color: #1d1e26 !important;
    border: none !important;
  }
  body.dark-only .order-history table .qty-box .input-group {
    border-color: #374558;
  }
  body.dark-only .order-history table thead tr th {
    background-color: #1d1e26;
  }
  body.dark-only .order-history table tr td,
  body.dark-only .order-history table tr th {
    border-top: none !important;
  }
  body.dark-only .order-history .title-orders {
    background-color: #1d1e26;
  }
  body.dark-only .navigation-option ul li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .navigation-option ul li:hover {
    background-color: #1d1e26;
  }
  body.dark-only .product-box .product-details h6 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .product-box .product-details span {
    color: #98a6ad;
  }
  body.dark-only .product-box .product-img .product-hover ul li {
    background-color: #2c2c2c;
  }
  body.dark-only .product-box .product-img .product-hover ul li:hover {
    background-color: #000;
    color: #fff;
  }
  body.dark-only .browse .browse-articles ul li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .browse .browse-articles ul li:hover {
    background-color: #1d1e26;
  }
  body.dark-only .radio-primary input[type=radio] + label::before {
    border-color: var(--theme-default) !important;
  }
  body.dark-only .radio-primary input[type=radio] + label::after {
    background-color: var(--theme-default);
  }
  body.dark-only .radio-primary input[type=radio]:checked + label::before {
    border-color: var(--theme-default) !important;
  }
  body.dark-only .radio-primary input[type=radio]:checked + label::after {
    background-color: var(--theme-default);
  }
  body.dark-only .radio-secondary input[type=radio] + label::before {
    border-color: #f73164 !important;
  }
  body.dark-only .radio-secondary input[type=radio] + label::after {
    background-color: #f73164;
  }
  body.dark-only .radio-secondary input[type=radio]:checked + label::before {
    border-color: #f73164 !important;
  }
  body.dark-only .radio-secondary input[type=radio]:checked + label::after {
    background-color: #f73164;
  }
  body.dark-only .radio-success input[type=radio] + label::before {
    border-color: #51bb25 !important;
  }
  body.dark-only .radio-success input[type=radio] + label::after {
    background-color: #51bb25;
  }
  body.dark-only .radio-success input[type=radio]:checked + label::before {
    border-color: #51bb25 !important;
  }
  body.dark-only .radio-success input[type=radio]:checked + label::after {
    background-color: #51bb25;
  }
  body.dark-only .radio-danger input[type=radio] + label::before {
    border-color: #dc3545 !important;
  }
  body.dark-only .radio-danger input[type=radio] + label::after {
    background-color: #dc3545;
  }
  body.dark-only .radio-danger input[type=radio]:checked + label::before {
    border-color: #dc3545 !important;
  }
  body.dark-only .radio-danger input[type=radio]:checked + label::after {
    background-color: #dc3545;
  }
  body.dark-only .radio-info input[type=radio] + label::before {
    border-color: #a927f9 !important;
  }
  body.dark-only .radio-info input[type=radio] + label::after {
    background-color: #a927f9;
  }
  body.dark-only .radio-info input[type=radio]:checked + label::before {
    border-color: #a927f9 !important;
  }
  body.dark-only .radio-info input[type=radio]:checked + label::after {
    background-color: #a927f9;
  }
  body.dark-only .radio-light input[type=radio] + label::before {
    border-color: #f4f4f4 !important;
  }
  body.dark-only .radio-light input[type=radio] + label::after {
    background-color: #f4f4f4;
  }
  body.dark-only .radio-light input[type=radio]:checked + label::before {
    border-color: #f4f4f4 !important;
  }
  body.dark-only .radio-light input[type=radio]:checked + label::after {
    background-color: #f4f4f4;
  }
  body.dark-only .radio-dark input[type=radio] + label::before {
    border-color: #2c323f !important;
  }
  body.dark-only .radio-dark input[type=radio] + label::after {
    background-color: #2c323f;
  }
  body.dark-only .radio-dark input[type=radio]:checked + label::before {
    border-color: #2c323f !important;
  }
  body.dark-only .radio-dark input[type=radio]:checked + label::after {
    background-color: #2c323f;
  }
  body.dark-only .radio-warning input[type=radio] + label::before {
    border-color: #f8d62b !important;
  }
  body.dark-only .radio-warning input[type=radio] + label::after {
    background-color: #f8d62b;
  }
  body.dark-only .radio-warning input[type=radio]:checked + label::before {
    border-color: #f8d62b !important;
  }
  body.dark-only .radio-warning input[type=radio]:checked + label::after {
    background-color: #f8d62b;
  }
  body.dark-only .checkbox-primary label::before {
    border-color: var(--theme-default) !important;
  }
  body.dark-only .checkbox-secondary label::before {
    border-color: #f73164 !important;
  }
  body.dark-only .checkbox-success label::before {
    border-color: #51bb25 !important;
  }
  body.dark-only .checkbox-danger label::before {
    border-color: #dc3545 !important;
  }
  body.dark-only .checkbox-info label::before {
    border-color: #a927f9 !important;
  }
  body.dark-only .checkbox-light label::before {
    border-color: #f4f4f4 !important;
  }
  body.dark-only .checkbox-dark label::before {
    border-color: #2c323f !important;
  }
  body.dark-only .checkbox-warning label::before {
    border-color: #f8d62b !important;
  }
  body.dark-only .checkbox-solid-primary label:before {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-primary input[type=checkbox]:checked + label::before {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-primary input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-secondary label:before {
    background-color: #f73164 !important;
    border-color: #f73164 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-secondary input[type=checkbox]:checked + label::before {
    background-color: #f73164 !important;
    border-color: #f73164 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-secondary input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-success label:before {
    background-color: #51bb25 !important;
    border-color: #51bb25 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-success input[type=checkbox]:checked + label::before {
    background-color: #51bb25 !important;
    border-color: #51bb25 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-success input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-danger label:before {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-danger input[type=checkbox]:checked + label::before {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-danger input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-info label:before {
    background-color: #a927f9 !important;
    border-color: #a927f9 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-info input[type=checkbox]:checked + label::before {
    background-color: #a927f9 !important;
    border-color: #a927f9 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-info input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-light label:before {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-light input[type=checkbox]:checked + label::before {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-light input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-dark label:before {
    background-color: #2c323f !important;
    border-color: #2c323f !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-dark input[type=checkbox]:checked + label::before {
    background-color: #2c323f !important;
    border-color: #2c323f !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-dark input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-warning label:before {
    background-color: #f8d62b !important;
    border-color: #f8d62b !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-warning input[type=checkbox]:checked + label::before {
    background-color: #f8d62b !important;
    border-color: #f8d62b !important;
    color: #fff !important;
  }
  body.dark-only .checkbox-solid-warning input[type=checkbox]:checked + label::after {
    color: #fff !important;
  }
  body.dark-only .bootstrap-datetimepicker-widget ul li {
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #1d1e26;
  }
  body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
  body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
  body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover, body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
  body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
  body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
    background-color: #2c2c2c;
  }
  body.dark-only .btn-transparent {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only #cal-basic .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after,
  body.dark-only #cal-basic .fc-toolbar .fc-right .fc-next-button .fc-icon-right-single-arrow:after {
    color: #98a6ad;
  }
  body.dark-only #cal-basic .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after,
  body.dark-only #cal-basic .fc-toolbar .fc-right .fc-prev-button .fc-icon-left-single-arrow:after {
    color: #98a6ad;
  }
  body.dark-only #cal-basic .fc-toolbar .fc-left .fc-button-group .fc-month-button {
    color: #fff !important;
  }
  body.dark-only .fc-button-group .fc-basicWeek-button,
  body.dark-only .fc-button-group .fc-basicDay-button,
  body.dark-only .fc-button-group .fc-agendaWeek-button,
  body.dark-only .fc-button-group .fc-agendaDay-button {
    color: #000 !important;
  }
  body.dark-only .fc-button-group .fc-month-button {
    color: #fff !important;
  }
  body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-month-button,
  body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .btn-light {
    color: #000 !important;
  }
  body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button {
    color: #fff !important;
  }
  body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button:hover {
    color: #000 !important;
  }
  body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
  body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .btn-light,
  body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
  body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
  body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
  body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .btn-light {
    color: #000 !important;
  }
  body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button,
  body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button,
  body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button {
    color: #fff !important;
  }
  body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
  body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
  body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover {
    color: #000 !important;
  }
  body.dark-only .categories ul li a {
    color: #98a6ad;
  }
  body.dark-only .categories ul li:hover {
    background-color: #1d1e26;
  }
  body.dark-only .socialprofile .likes-profile h5 span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .socialprofile span {
    color: #98a6ad;
  }
  body.dark-only .social-status .media .media-body span + span {
    color: #98a6ad;
  }
  body.dark-only .social-status .media .media-body p,
  body.dark-only .social-status .media .media-body .light-span {
    color: #98a6ad;
  }
  body.dark-only .filter-cards-view p,
  body.dark-only .timeline-content p {
    color: #98a6ad;
  }
  body.dark-only .filter-cards-view .comment-number i,
  body.dark-only .timeline-content .comment-number i {
    color: #98a6ad;
  }
  body.dark-only .filter-cards-view .comments-box .input-group .form-control,
  body.dark-only .timeline-content .comments-box .input-group .form-control {
    border: none !important;
  }
  body.dark-only .filter-cards-view .comments-box .input-group .input-group-append,
  body.dark-only .timeline-content .comments-box .input-group .input-group-append {
    background-color: #1d1e26;
  }
  body.dark-only .social-chat .media-body {
    border: 1px solid #1d1e26;
  }
  body.dark-only .social-chat .media-body:after {
    border-right: 7px solid #2c2c2c;
  }
  body.dark-only .social-chat span span {
    color: #98a6ad;
  }
  body.dark-only .details-about .your-details p {
    color: #98a6ad;
  }
  body.dark-only .activity-log .my-activity p {
    color: #98a6ad;
  }
  body.dark-only .bookmark ul li a {
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 1366px) {
    body.dark-only .earning-card.card .card-body .earning-content {
      border-bottom: 1px solid #374558;
    }
  }
  @media only screen and (max-width: 991.98px) {
    body.dark-only .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
      border-right: 1px solid #374558;
    }
    body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg, body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
      stroke: rgba(255, 255, 255, 0.6);
    }
  }
  @media only screen and (max-width: 767.98px) {
    body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg, body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
      stroke: #fff;
    }
    body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link, body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu .nav-link {
      border-left: 1px solid #374558;
    }
    body.dark-only .page-wrapper.material-icon .page-header {
      background-color: #2c2c2c;
    }
    body.dark-only .chart_data_left .card-body .chart-main > div:first-child {
      border-right: 1px solid #374558;
    }
    body.dark-only .chart_data_left .card-body .chart-main > div:nth-child(3) {
      border-right: 1px solid #374558;
    }
  }
  body.dark-only .apexcharts-tooltip.light {
    border-color: #374558;
    background-color: #1d1e26;
  }
  body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
    border-color: #374558;
  }
  body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-label, body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-value {
    color: #fff;
  }
  body.dark-sidebar .toggle-sidebar svg {
    stroke: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper .sidebar-main-title p {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    background-color: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    background-color: rgba(115, 102, 255, 0.2);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    stroke: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
    background: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
    background-color: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    background: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active, body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
    color: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
    background: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    background: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
  body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
    background-color: #2c2c2c;
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    background: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
    border-bottom: 1px solid #374558;
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    background-color: #2c2c2c;
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
    border-bottom: 1px solid #374558;
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    background-color: rgba(115, 102, 255, 0.2);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
    color: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    stroke: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
    color: rgba(255, 255, 255, 0.6);
  }
  body .for-dark {
    display: none;
  }
  body[class*=dark-] .for-dark {
    display: block;
  }
  body[class*=dark-] .for-light {
    display: none;
  }
  
  /**=====================
  55. theme layout CSS ends
  ==========================**/
  /**=====================
     55. theme layout CSS Start
  ==========================**/
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=border-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=border-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
    border-bottom: 1px solid rgba(115, 102, 255, 0.1);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+1):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+2):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+3):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+4):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+5):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+6):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+7):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+8):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+9):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+10):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+11):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+12):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+13):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+14):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+15):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+16):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+17):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+18):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+19):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+20):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+21):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+22):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+23):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+24):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+25):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+26):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+27):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+28):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+29):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+30):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+31):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+32):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+33):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+34):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+35):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+36):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+37):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+38):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+39):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+40):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+41):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+42):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+43):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+44):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+45):hover a:hover {
    background-color: rgba(247, 49, 100, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+46):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+47):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+48):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+49):hover a:hover {
    background-color: rgba(244, 244, 244, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50) svg {
    color: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+50):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+51):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52) svg {
    color: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+52):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53):hover a svg {
    stroke: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+53):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54):hover a svg {
    stroke: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+54):hover a:hover {
    background-color: rgba(115, 102, 255, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55) svg {
    color: #fae475 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+55):hover a:hover {
    background-color: rgba(248, 214, 43, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56) svg {
    color: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56):hover a svg {
    stroke: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+56):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57):hover a svg {
    stroke: #79dc50 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+57):hover a:hover {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58) svg {
    color: #b9b3ff !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58):hover a svg {
    stroke: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+58):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59) svg {
    color: #c771fb !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59):hover a svg {
    stroke: white !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+59):hover a:hover {
    background-color: rgba(169, 39, 249, 0.15) !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60) svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60) svg {
    color: #e77681 !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60):hover a svg, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60):hover a svg {
    stroke: #fa7b9b !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60):hover a:hover, .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout=iconcolor-sidebar] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n+60):hover a:hover {
    background-color: rgba(81, 187, 37, 0.15) !important;
  }
  
  /**=====================
     55. theme layout CSS ends
  ==========================**/
  /**=====================
     59. Customizer  CSS Start
  ==========================**/
  .customizer-links {
    position: fixed;
    right: 0px;
    top: 50%;
    z-index: 3;
    transform: translate(0, -50%);
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
  }
  .customizer-links > .nav {
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
  }
  .customizer-links.open {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  .customizer-links .nav-link {
    padding: 0;
    position: relative;
  }
  .customizer-links .nav-link:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(115, 102, 255, 0.1);
    border-radius: 5px;
    transform: scale(0);
    transition: all 0.3s ease;
  }
  .customizer-links .nav-link:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: 12px;
    border-width: 5px 0 5px 5px;
    border-left-color: #e8e6ff;
    opacity: 0;
    transform: scale(0);
    color: #fff;
    left: -10px;
    z-index: 2;
    transition: all 0.3s ease;
  }
  .customizer-links .nav-link:hover i {
    color: var(--theme-default);
  }
  .customizer-links .nav-link:hover:before, .customizer-links .nav-link:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .customizer-links .nav-link:hover span {
    opacity: 1;
    right: 48px;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .customizer-links .nav-link + .nav-link {
    margin-bottom: 5px;
  }
  .customizer-links .nav-link span {
    position: absolute;
    right: -60px;
    width: max-content;
    text-align: right;
    padding: 7px 10px;
    display: block;
    top: 0;
    border-radius: 5px;
    transform: scale(0);
    transition: all 0.3s ease;
    opacity: 0;
    color: var(--theme-default);
    background-color: #e8e6ff;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
  }
  .customizer-links .nav-link i {
    font-size: 17px;
    padding: 8px 10px;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    color: gray;
    border-radius: 5px;
  }
  
  .customizer-contain {
    direction: ltr;
    position: fixed;
    height: 100vh;
    top: 82px;
    width: 333px;
    right: -335px;
    background-color: #fff;
    z-index: 8;
    transition: all 0.3s ease;
  }
  .customizer-contain.open {
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  }
  .customizer-contain li {
    position: relative;
  }
  .customizer-contain li.active {
    box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
  }
  .customizer-contain h6 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 15px;
    padding-top: 15px;
    margin-bottom: 10px;
  }
  .customizer-contain .customizer-header {
    padding: 15px 25px;
    border-bottom: 1px solid #f4f4f4;
  }
  .customizer-contain .customizer-header .icon-close {
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    font-weight: 900;
    right: 24px;
    color: var(--theme-default);
  }
  .customizer-contain .customizer-header h5 {
    font-weight: 700;
    text-transform: uppercase;
  }
  .customizer-contain .customizer-body {
    padding: 15px 25px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  .customizer-contain .customizer-body .fade:not(.show) {
    display: none;
  }
  .customizer-contain .customizer-body .unlimited-color-layout {
    display: flex;
    align-items: center;
  }
  .customizer-contain .customizer-body .unlimited-color-layout .btn {
    font-size: 15px;
    padding: 4px 20px;
  }
  .customizer-contain .customizer-body .unlimited-color-layout input {
    border: 1px solid #cccccc;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 5px;
  }
  .customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
    height: 70px;
    width: 80px;
    border: 1px solid #f6f7fb;
    border-radius: 5px;
  }
  .customizer-contain .customizer-body .tab-pane > h6:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  .customizer-contain .customizer-body li {
    cursor: pointer;
    opacity: 0.9;
  }
  .customizer-contain .customizer-body li.active {
    opacity: 1;
  }
  .customizer-contain .customizer-body .color-layout {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 3px;
    border: 1px solid #b8b8b8;
    padding: 3px;
  }
  .customizer-contain .customizer-body .color-layout > div {
    background-color: #7366ff;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .customizer-contain .customizer-body .color-layout[data-attr=color-2] div {
    background-color: #4831D4;
  }
  .customizer-contain .customizer-body .color-layout[data-attr=color-3] div {
    background-color: #d64dcf;
  }
  .customizer-contain .customizer-body .color-layout[data-attr=color-4] div {
    background-color: #4c2fbf;
  }
  .customizer-contain .customizer-body .color-layout[data-attr=color-5] div {
    background-color: #7c4dff;
  }
  .customizer-contain .customizer-body .color-layout[data-attr=color-6] div {
    background-color: #3949ab;
  }
  .customizer-contain .customizer-body .dark > li {
    background-color: #2f3c4e;
  }
  .customizer-contain .customizer-body .main-layout > li {
    height: 65px;
    width: 85px;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    display: inline-block;
  }
  .customizer-contain .customizer-body .main-layout > li + li {
    margin-left: 7px;
  }
  .customizer-contain .customizer-body .main-layout .box-layout .body .badge {
    left: -14px;
  }
  .customizer-contain .customizer-body .sidebar-type > li,
  .customizer-contain .customizer-body .sidebar-setting > li {
    height: 70px;
    width: 85px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    display: inline-block;
  }
  .customizer-contain .customizer-body .sidebar-type > li + li,
  .customizer-contain .customizer-body .sidebar-setting > li + li {
    margin-left: 7px;
  }
  .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-dark, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.sidebar {
    width: 100%;
    height: 10px;
    display: block;
  }
  .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-light, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.body {
    height: calc(100% - 10px);
    width: 100%;
  }
  .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar].body-compact .body ul li.body {
    width: calc(100% - 25px);
  }
  .customizer-contain .customizer-body .layout-types {
    margin-bottom: -30px;
  }
  .customizer-contain .customizer-body .layout-types > li {
    display: block;
    width: unset;
    height: unset;
    margin-bottom: 30px;
  }
  .customizer-contain .customizer-body .layout-types > li .layout-img h6 {
    padding-top: 0;
    text-transform: inherit;
    text-align: center;
  }
  .customizer-contain .customizer-body .layout-types .box-layout {
    background: unset;
  }
  
  .layout-grid:not(.customizer-color) li {
    padding: 5px;
  }
  .layout-grid:not(.customizer-color) li > .header {
    height: 9px;
    border-radius: 3px;
    position: relative;
  }
  .layout-grid:not(.customizer-color) li > .header ul {
    position: absolute;
    top: -8px;
    left: 5px;
  }
  .layout-grid:not(.customizer-color) li > .header ul > li {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    top: calc(50% - 3px);
    background-color: var(--theme-default);
    margin-right: 2px;
    padding: 0;
  }
  .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
    background-color: var(--theme-secondary);
  }
  .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
    background-color: #51bb25;
  }
  .layout-grid:not(.customizer-color) li > .body {
    background-color: #f4f4f4;
    height: calc(100% - 16px);
    border-radius: 3px;
    position: relative;
    margin-top: 5px;
    text-align: center;
    line-height: 3.1;
  }
  .layout-grid:not(.customizer-color) li > .body ul {
    height: 100%;
    background-color: #fff;
  }
  .layout-grid:not(.customizer-color) li > .body ul .sidebar {
    width: 18px;
    height: 100%;
    border-radius: 3px;
    display: inline-block;
    margin-right: 3px;
    padding: 0;
  }
  .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
    width: 12px;
  }
  .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
    width: calc(100% - 19px);
  }
  .layout-grid:not(.customizer-color) li > .body ul .body {
    width: calc(100% - 25px);
    padding: 0;
    margin: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }
  .layout-grid:not(.customizer-color) li > .body ul .body .badge {
    position: absolute;
  }
  
  /**=====================
       59.Customizer CSS Ends
  ==========================**/
  .jsgrid-filter-row select, .jsgrid-filter-row input {
    padding: 6px 10px;
    border: 1px solid #f2f4ff;
    background: #fff;
  }
  
  .popover {
    background-color: #fff;
    border: none;
    -webkit-box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
    box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
  }
  .popover .popover-header {
    background-color: #eae8ff;
    color: #7366ff;
    border-bottom: none;
  }
  .popover .popover-body {
    color: rgba(43, 43, 43, 0.7);
  }
  
  .tooltip.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: #eae8ff;
  }
  .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: #eae8ff;
    border-top-color: transparent;
  }
  .tooltip.bs-tooltip-start .tooltip-arrow:before {
    border-left-color: #eae8ff;
    border-top-color: transparent;
  }
  .tooltip.bs-tooltip-end .tooltip-arrow:before {
    border-right-color: #eae8ff;
    border-top-color: transparent;
  }
  .tooltip .tooltip-inner {
    background-color: #eae8ff;
    color: #7366ff;
  }
  .tooltip .tooltip-arrow:before {
    border-top-color: #eae8ff;
  }
  
  .dropdown-basic .dropdown .dropdown-content a {
    padding: 6px 16px;
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    border-top: 1px solid #efefef;
    background: #fff;
  }
  .dropdown-basic .dropdown .dropdown-content a:hover {
    background-color: #fff;
  }
  .dropdown-basic .dropdown .dropdown-content .dropdown-header {
    padding: 8px 16px;
    font-weight: 400;
    color: #2c323f;
    font-size: 13px;
  }
  .dropdown-basic .dropup .dropup-content {
    top: auto;
  }
  
  .default-according .card .card-header {
    padding: 0;
    border: none;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .default-according .card .card-header.bg-primary .btn, .default-according .card .card-header.bg-secondary .btn {
    border-color: transparent;
  }
  .default-according .card .card-header i {
    position: initial;
    font-size: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 5px;
  }
  .default-according .card .card-header h5 {
    margin-top: 0;
  }
  .default-according .card .card-header .btn-link {
    padding: 12px 20px;
    width: 100%;
    text-align: left;
    letter-spacing: 0.7px;
    font-family: Roboto;
    border: 1px solid #efefef;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .default-according .card .card-body {
    padding: 15px 20px;
    line-height: 22px;
    font-size: 13px;
    border-color: #efefef;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #2c323f;
  }
  
  .nav-tabs {
    border-bottom-color: #efefef;
  }
  .nav-tabs .nav-bottom .nav-item .nav-link.active {
    border-color: #efefef;
  }
  
  .nav-tabs .nav-item.show .nav-link {
    font-weight: 500;
  }
  .nav-tabs .nav-link {
    color: #2c323f;
  }
  .nav-tabs .nav-link.active {
    font-weight: 500;
  }
  .nav-tabs.nav-bottom .nav-item .nav-link.active {
    border-color: #ecf3fa #ecf3fa #fff;
  }
  
  .dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  .dropdown-menu .dropdown-item {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid #efefef;
    background: #fff;
  }
  .dropdown-menu .dropdown-item:hover {
    background-color: #fff;
  }
  .dropdown-menu .dropdown-divider {
    margin: 0;
    border-top: 1px solid #cccccc;
  }
  
  .border-tab .nav-tabs .nav-item .nav-link.active {
    border-bottom: 2px solid #7366ff;
  }
  .border-tab .nav-tabs .nav-item .nav-link.show {
    border-bottom: 2px solid #7366ff;
  }
  .border-tab .nav-tabs .nav-item .nav-link:focus {
    border-bottom: 2px solid #7366ff;
  }
  
  .tabbed-card .nav-tabs .nav-item .nav-link {
    top: 17px;
  }
  
  .timeliny .timeliny-dot::before {
    font-size: 18px;
  }
  
  .form-builder-header-1, .form-builder-2-header {
    background-color: #f7f6ff;
  }
  .form-builder-header-1 .nav-primary .nav-link, .form-builder-2-header .nav-primary .nav-link {
    color: #2c323f;
    font-weight: 400;
  }
  .form-builder-header-1 .nav-primary .nav-link.active, .form-builder-2-header .nav-primary .nav-link.active {
    color: #fff;
  }
  .form-builder-header-1 h6, .form-builder-2-header h6 {
    margin-bottom: 0;
    font-weight: 400;
    line-height: 36px;
  }
  
  .component .input-group .btn {
    line-height: 32px;
    text-transform: capitalize;
  }
  
  .form-builder .drag-box fieldset {
    margin-top: 0;
  }
  
  .login-card .login-main .theme-form label {
    font-size: 14px;
  }
  .login-card .login-main .theme-form .show-hide {
    top: 50%;
  }
  
  .faq-accordion .card .btn-link svg {
    margin-left: 20px;
  }
  
  .job-filter .faq-form .form-control {
    font-size: 14px;
  }
  .job-filter .faq-form .search-icon {
    width: 16px;
    height: 16px;
  }
  
  /**=====================
       47. Footer CSS Start
  ==========================**/
  .footer {
    background-color: #fff;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    padding: 15px;
    bottom: 0;
    left: 0;
    margin-left: 280px;
    transition: 0.5s;
  }
  .footer.no-sidebar {
    margin-left: 0px;
  }
  .footer.footer-dark {
    background-color: #242934;
  }
  .footer.footer-dark p {
    color: #fff;
  }
  
  .footer-fix {
    width: calc(100% - 280px);
    position: fixed;
  }
  
  /**=====================
       47. Footer CSS Ends
  ==========================**/
  /**=====================
        08. GRID CSS Start
  ==========================**/
  .grid-showcase {
    margin-bottom: -10px;
  }
  .grid-showcase span {
    display: block;
    border: 1px solid #f4f4f4;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #fff;
  }
  
  .grid-align {
    margin-bottom: -30px;
  }
  .grid-align .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid rgba(115, 102, 255, 0.02);
    margin-bottom: 30px;
    background-color: rgba(115, 102, 255, 0.02);
  }
  
  /**=====================
        08. GRID CSS Ends
  ==========================**/
  /*======= Page Header css Start ======= */
  /*======= Page Header css ends  ======= */
  /**======Main Header css Start ======**/
  .offcanvas .page-wrapper .page-body-wrapper .page-body,
  .offcanvas-bookmark .page-wrapper .page-body-wrapper .page-body {
    position: relative;
    filter: blur(5px) grayscale(100%);
  }
  
  .offcanvas {
    visibility: visible;
    background: unset;
    z-index: 2;
  }
  
  .toggle-sidebar {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  .toggle-sidebar svg {
    width: 20px;
    height: 20px;
  }
  .toggle-sidebar:before {
    content: "";
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: rgba(115, 102, 255, 0.1);
    border-radius: 100%;
    left: -11px;
    z-index: -2;
    top: -8px;
    transform: scale(0);
    transition: all 0.3s ease;
  }
  .toggle-sidebar:hover:before {
    height: 40px;
    width: 40px;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .toggle-sidebar:hover > svg {
    color: var(--theme-default) !important;
    stroke: var(--theme-default) !important;
  }
  
  .onhover-dropdown {
    cursor: pointer;
    position: relative;
  }
  .onhover-show-div {
    top: 80px;
    position: absolute;
    z-index: 8;
    background-color: #fff;
    transition: all linear 0.3s;
  }
  .onhover-show-div li a svg {
    margin-top: 0 !important;
  }
  .onhover-show-div li a svg path,
  .onhover-show-div li a svg line {
    color: #2b2b2b !important;
  }
  
  /**======Main Header css Ends ======**/
  /*=======Mega menu css start=======*/
  .left-header .level-menu .nav-link {
    display: inline-block;
    border-radius: 5px;
    background-color: rgba(247, 49, 100, 0.15);
    transition: all 0.3s ease;
    color: var(--theme-secondary);
    margin-left: 10px;
  }
  .left-header .level-menu .nav-link.active {
    background-color: var(--theme-secondary);
    color: #fff;
    transition: all 0.3s ease;
  }
  .left-header .level-menu .nav-link.active svg {
    stroke: #fff;
  }
  .left-header .level-menu .nav-link svg {
    height: 18px;
    margin-right: 5px;
    vertical-align: middle;
    stroke: var(--theme-secondary);
  }
  .left-header .level-menu .header-level-menu {
    position: absolute;
    top: 59px;
    background-color: #fff;
    border-radius: 5px;
    width: 180px;
    height: 0;
    padding: 0.7rem 0.5rem;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    min-width: 180px;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .left-header .level-menu .header-level-menu.show {
    opacity: 1;
    visibility: visible;
    height: 225px;
  }
  .left-header .level-menu .header-level-menu li {
    padding: 0.5rem 1rem;
    width: 100%;
    transition: all 0.3s ease;
  }
  .left-header .level-menu .header-level-menu li:hover {
    background-color: rgba(115, 102, 255, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .left-header .level-menu .header-level-menu li:hover > a > span {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .level-menu .header-level-menu li:hover > a > svg {
    stroke: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .level-menu .header-level-menu > li {
    position: relative;
    width: 100%;
  }
  .left-header .level-menu .header-level-menu > li:hover .header-level-sub-menu {
    opacity: 1;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    animation: fadeInLeft 300ms ease-in-out;
  }
  .left-header .level-menu .header-level-menu > li a {
    color: #2c323f;
  }
  .left-header .level-menu .header-level-menu > li svg {
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
    stroke: #2c323f;
  }
  .left-header .level-menu .header-level-menu > li span {
    vertical-align: middle;
  }
  .left-header .level-menu .header-level-menu > li > .header-level-sub-menu {
    position: absolute;
    padding: 0.7rem 0.5rem;
    left: 173px;
    z-index: 9;
    background-color: #fff;
    width: 170px;
    border-radius: 5px;
    top: 0;
    opacity: 0;
    animation: fadeInLeft 300ms ease-in-out;
  }
  .left-header .mega-menu .nav-link {
    display: inline-block;
    border-radius: 5px;
    color: #7366ff;
    background-color: rgba(115, 102, 255, 0.15);
    transition: all 0.3s ease;
  }
  .left-header .mega-menu .nav-link.active {
    transition: all 0.3s ease;
    color: #fff;
    background-color: var(--theme-default);
  }
  .left-header .mega-menu .nav-link .according-menu {
    display: none;
  }
  .left-header .mega-menu .nav-link svg {
    height: 18px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .left-header .mega-menu-container {
    min-width: 1100px;
    position: absolute;
    width: calc(100vw - 800px);
    height: 0;
    border-radius: 0px;
    background-color: #fff;
    top: 59px;
    left: -30px;
    border-top: 1px solid #efefef;
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 20px;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    opacity: 0;
    visibility: hidden;
    display: block !important;
    transition: all 0.2s ease-in-out;
  }
  .left-header .mega-menu-container.show {
    opacity: 1;
    visibility: visible;
    height: 300px;
    right: 0;
  }
  .left-header .mega-menu-container .link-section > div h6 {
    margin-top: 20px;
  }
  .left-header .mega-menu-container .link-section li {
    width: 100%;
    padding: 7px 0 7px 15px;
  }
  .left-header .mega-menu-container .link-section li a {
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .link-section li:hover a {
    color: var(--theme-default);
    letter-spacing: 1.5px;
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box + .mega-box {
    padding-left: 30px;
    border-left: 1px solid #efefef;
  }
  .left-header .mega-menu-container .mega-box .doted li {
    position: relative;
  }
  .left-header .mega-menu-container .mega-box .doted li:hover:before {
    background-color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box .doted li:before {
    content: "";
    position: absolute;
    top: 15px;
    height: 5px;
    width: 5px;
    background-color: #999999;
    left: 0;
    border-radius: 100%;
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box .dashed-links li {
    position: relative;
  }
  .left-header .mega-menu-container .mega-box .dashed-links li:hover:before {
    background-color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box .dashed-links li:before {
    content: "";
    position: absolute;
    top: 16px;
    height: 2px;
    width: 6px;
    background-color: #999999;
    left: 0;
  }
  .left-header .mega-menu-container .mega-box .icon li {
    position: relative;
  }
  .left-header .mega-menu-container .mega-box .icon li:hover:before {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box .icon li:before {
    content: "\f101";
    font-family: fontawesome;
    position: absolute;
    top: 7px;
    left: 0px;
  }
  .left-header .mega-menu-container .mega-box .svg-icon ~ div h6 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .left-header .mega-menu-container .mega-box .svg-icon li {
    padding-left: 0px;
  }
  .left-header .mega-menu-container .mega-box .svg-icon li:hover:before {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .left-header .mega-menu-container .mega-box .svg-icon li a svg {
    width: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .left-header .mega-menu-container .submenu-title {
    margin-top: 20px;
    margin-left: 20px;
  }
  .left-header .mega-menu-container .according-menu {
    display: none;
  }
  .left-header .horizontal-menu li {
    display: inline-block;
  }
  
  .mega-menu .default-according .card .btn-link {
    font-weight: 500;
    color: gray;
  }
  .mega-menu .default-according .card .card-body {
    font-size: 12px;
  }
  .mega-menu .onhover-show-div {
    width: 1500px;
  }
  .mega-menu .card-body {
    padding: 20px;
  }
  .mega-menu div > div a {
    margin-bottom: 0px;
    display: inline-block;
    color: rgba(43, 43, 43, 0.54);
  }
  .mega-menu .list-unstyled div a {
    padding: 8px 35px 8px 0;
    transition: 0.5s;
  }
  .mega-menu .list-unstyled div a:hover {
    padding: 8px 35px 8px 10px;
    transition: 0.5s;
  }
  
  /*=======Mega menu css end=======*/
  /**=====================
      05. Header CSS Ends
  ==========================**/
  /**=====================
       36. NAV CSS Start
  ==========================**/
  #accordionoc #collapseicon, #accordionoc #collapseicon1 {
    padding-top: 0px;
  }
  #accordionoc li button {
    display: flex;
    align-items: center;
  }
  
  .navs-icon.default-according.style-1 li button {
    position: relative;
  }
  .navs-icon.default-according.style-1 li button:hover {
    color: var(--theme-default) !important;
  }
  .navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
    right: 2px;
    top: 6px;
  }
  .navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
    right: 2px;
    top: 6px;
  }
  
  .navs-icon {
    padding: 30px;
  }
  .navs-icon li {
    padding: 9px 0;
  }
  .navs-icon li a {
    color: #2b2b2b;
  }
  .navs-icon li a:hover {
    color: var(--theme-default);
  }
  .navs-icon li ul {
    padding-top: 9px;
  }
  
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before, .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    right: 77px;
    left: unset;
  }
  
  .icon-lists {
    font-family: Rubik, sans-serif;
  }
  
  .pl-navs-inline {
    padding-left: 30px !important;
  }
  
  .inline-nav li {
    line-height: 1.3;
  }
  
  /**=====================
       36. NAV CSS Ends
  ==========================**/
  /**=====================
       51. Search CSS Start
  ==========================**/
  .search-page .theme-form input {
    border: 1px solid var(--theme-default);
    padding-left: 20px;
    border-radius: 10px 0 0 10px;
    flex: 1 1 auto;
  }
  .search-page .theme-form input::placeholder {
    color: #7366ff;
  }
  .search-page .theme-form input:focus {
    outline: none;
  }
  .search-page .theme-form .input-group .btn {
    line-height: 32px;
    font-size: 16px;
  }
  .search-page .theme-form .input-group .form-control-plaintext {
    background: transparent;
  }
  .search-page h6 {
    line-height: 1.4;
  }
  .search-page .nav-link {
    font-weight: 500;
    text-transform: uppercase;
  }
  .search-page .border-tab.nav-tabs .material-border {
    border-bottom-width: 1px;
  }
  .search-page .border-tab.nav-tabs .nav-item .nav-link {
    font-size: 15px;
    padding: 10px 18px;
  }
  .search-page .media .media-body {
    overflow: hidden;
  }
  .search-page .info-block {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #f4f4f4;
  }
  .search-page .info-block a {
    color: #656565;
    margin-bottom: 3px;
    display: block;
  }
  .search-page .info-block h6 {
    color: #7366ff;
  }
  .search-page .info-block + .info-block {
    margin-top: 20px;
  }
  .search-page .border-tab.nav-tabs {
    align-items: left;
    justify-content: end;
  }
  .search-page .border-tab.nav-tabs .nav-item {
    width: auto;
  }
  .search-page .search-links h6 {
    margin-bottom: 10px;
  }
  .search-page p {
    text-transform: lowercase;
    margin-bottom: 0;
    color: #a5a2a2;
  }
  .search-page ul.search-info li {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    color: #586082;
  }
  .search-page ul.search-info li + li {
    border-left: 1px solid #a8b0c2;
    padding-left: 8px;
    margin-left: 8px;
  }
  .search-page ul.search-info li i {
    color: #f8d62b;
  }
  .search-page ul.search-info li i:not(:last-child) {
    margin-right: 4px;
  }
  .search-page #video-links .embed-responsive + .embed-responsive {
    margin-top: 30px;
  }
  
  .search-list {
    margin-bottom: 30px;
    width: auto;
    display: inline-flex;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #7366ff;
    border: none;
  }
  .search-list .nav-item {
    background-color: rgba(115, 102, 255, 0.1);
  }
  .search-list .nav-item .nav-link {
    border: none;
    padding: 0.7rem 1.5rem;
    opacity: 0.5;
  }
  .search-list .nav-item .nav-link.active {
    background-color: rgba(115, 102, 255, 0.1);
    color: var(--theme-default);
    opacity: 1;
  }
  .search-list .nav-item.show, .search-list .nav-item.active {
    background-color: rgba(115, 102, 255, 0.1);
  }
  
  /**=====================
      51. Search CSS Ends
  ==========================**/
  /**=====================
       21. Select 2 CSS Start
  ==========================**/
  .select2 {
    max-width: 100%;
  }
  
  .add-post #cke_text-box {
    border: 1px solid #efefef;
  }
  .add-post form .m-checkbox-inline label {
    margin-bottom: 0;
  }
  .add-post form .form-control {
    border: 1px solid #efefef;
  }
  .add-post form .col-form-label {
    padding-bottom: 0;
    padding-top: 0;
  }
  .add-post form .col-form-label .select2-container--default {
    margin-top: 10px;
    max-width: 100%;
  }
  .add-post form .col-form-label .select2-container--default .selection .select2-selection .select2-search__field {
    padding: 2px;
  }
  .add-post form .col-form-label .select2-container--default .select2-selection--multiple {
    border: 1px solid #efefef;
    max-width: 100%;
  }
  .add-post form .col-form-label .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    margin-top: 6px !important;
  }
  .add-post .dropzone {
    margin-bottom: 30px;
  }
  
  .select2-container--open .select2-dropdown {
    z-index: 7;
  }
  
  .select2-container {
    width: 100% !important;
  }
  
  .select2-drpdwn .select2-selection {
    border-radius: 0 !important;
    border-color: #f4f4f4 !important;
    height: 40px !important;
    padding: 5px;
  }
  .select2-drpdwn .form-control {
    border-radius: 5px;
  }
  .select2-drpdwn .form-control-primary {
    border-color: var(--theme-default);
    color: var(--theme-default);
  }
  .select2-drpdwn .form-control-secondary {
    border-color: var(--theme-secondary);
    color: var(--theme-secondary);
  }
  .select2-drpdwn .form-control-success {
    border-color: #51bb25;
    color: #51bb25;
  }
  .select2-drpdwn .form-control-info {
    border-color: #a927f9;
    color: #a927f9;
  }
  .select2-drpdwn .form-control-warning {
    border-color: #f8d62b;
    color: #f8d62b;
  }
  .select2-drpdwn .form-control-danger {
    border-color: #dc3545;
    color: #dc3545;
  }
  .select2-drpdwn .form-control-inverse {
    border-color: #2c323f;
    color: #2c323f;
  }
  .select2-drpdwn .form-control-primary-fill {
    background-color: var(--theme-default);
    color: #fff;
  }
  .select2-drpdwn .form-control-secondary-fill {
    background-color: var(--theme-secondary);
    color: #fff;
  }
  .select2-drpdwn .form-control-success-fill {
    background-color: #51bb25;
    color: #fff;
  }
  .select2-drpdwn .form-control-info-fill {
    background-color: #a927f9;
    color: #fff;
  }
  .select2-drpdwn .form-control-warning-fill {
    background-color: #f8d62b;
    color: #fff;
  }
  .select2-drpdwn .form-control-danger-fill {
    background-color: #dc3545;
    color: #fff;
  }
  .select2-drpdwn .form-control-inverse-fill {
    background-color: #2c323f;
    color: #fff;
  }
  
  .selection .select2-selection {
    border-radius: 5px !important;
  }
  .selection .select2-selection .select2-search__field {
    margin-top: 0;
    padding: 3px;
  }
  
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    padding: 2px 6px !important;
    margin-top: 0 !important;
    background-color: var(--theme-default) !important;
    border-color: #4433ff !important;
    color: #fff;
    margin-right: 8px !important;
  }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff !important;
    float: right;
    margin-left: 0.5rem;
  }
  .select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #e0e0e0 !important;
    color: #424242 !important;
  }
  .select2-container--classic .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: var(--theme-default) !important;
    color: #fff !important;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px !important;
    right: 10px !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 2px 6px !important;
    margin-top: 0 !important;
    background-color: var(--theme-default) !important;
    border-color: #4433ff !important;
    color: #fff;
    margin-right: 8px !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff !important;
    float: right;
    margin-left: 0.5rem;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #e0e0e0 !important;
    color: #424242 !important;
  }
  .select2-container--default .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: var(--theme-default) !important;
    color: #fff !important;
  }
  
  /**=====================
       21. Select 2 CSS Ends
  ==========================**/
  /**=====================
      78. Sidebar CSS Start
  ==========================**/
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
    position: absolute;
    content: "";
    top: 48%;
    width: 6px;
    border-top: 2px solid #595c60;
  }
  
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
    position: absolute;
    content: "";
    top: 49%;
    width: 5px;
    height: 1px;
    border-radius: 100%;
    background-color: #59667a;
  }
  
  .onhover-show-div {
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
  }
  
  .onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
  }
  .onhover-dropdown:hover .onhover-show-div:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  .onhover-dropdown:hover .onhover-show-div:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #d7e2e9;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
  
  .page-wrapper {
    position: relative;
  }
  .page-wrapper .page-header {
    max-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 8;
    transition: 0.5s;
    background-color: #fff;
  }
  .page-wrapper .page-header .header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .search-full {
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 1;
    height: 0;
    width: 0;
    transform: scale(0);
    transition: all 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .search-full.open {
    height: 100%;
    width: 100%;
    animation: zoomIn 0.5s ease-in-out;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .search-full input {
    line-height: 65px;
    padding-left: 60px;
    width: 100% !important;
  }
  .page-wrapper .page-header .header-wrapper .search-full input:focus {
    outline: none !important;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    position: absolute;
    font-size: 15px;
    right: 30px;
    top: 30px;
    color: #898989;
    cursor: pointer;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
    position: absolute;
    left: 30px;
    top: 27px;
    content: "\f002";
    font-size: 16px;
    color: #898989;
    font-family: FontAwesome;
  }
  .page-wrapper .page-header .header-wrapper .nav-right {
    justify-content: flex-end;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li svg {
    vertical-align: middle;
    width: 20px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li svg path {
    color: #242934;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode {
    cursor: pointer;
    width: 20px;
    text-align: center;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i {
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i.fa-lightbulb-o {
    font-weight: 500;
    font-size: 22px;
    transition: all 0.3s ease;
    animation: zoomIn 300ms ease-in-out;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .header-search {
    cursor: pointer;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
    margin-left: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body span {
    font-weight: 500;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
    font-size: 12px;
    line-height: 1;
    color: rgba(43, 43, 43, 0.7);
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 6px 10px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li .badge {
    position: absolute;
    right: -5px;
    top: -6px;
    padding: 2px 4px;
    font-size: 11px;
    font-weight: 700;
    font-family: work-Sans, sans-serif;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul {
    left: inherit;
    right: -10px;
    width: 130px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:before, .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:after {
    left: inherit;
    right: 10px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li {
    display: block;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a {
    font-size: 14px;
    color: #2c323f;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a i {
    margin-left: 10px;
    font-size: 13px;
    color: #2c323f;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul .flag-icon {
    font-size: 16px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip {
    width: unset !important;
    box-shadow: unset !important;
    background-color: transparent !important;
    overflow: initial;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::after, .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::before {
    display: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content {
    text-align: center;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content .bookmark-icon {
    width: 52px;
    height: 52px;
    background-color: #f9f9f9;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card {
    width: 300px;
    height: 260px;
    background-color: transparent;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner {
    position: relative;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front,
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
    position: absolute;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    width: 300px;
    height: 260px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button,
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
    background-color: transparent;
    color: #7366ff;
    border: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button:focus,
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button:focus {
    outline: transparent;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
    background-color: #fff;
    transition: 0.3s;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
    stroke: #2c323f;
    transition: all 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg:hover {
    stroke: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
    background-color: #fff;
    transform: rotateY(180deg);
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:first-child {
    margin-top: 20px;
    height: 140px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
    width: 100%;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
    border-radius: 25px;
    padding: 6px 12px;
    width: 100%;
    border: 1px solid #ddd;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input:focus {
    outline: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped {
    transform: rotateY(180deg);
  }
  .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped .front {
    opacity: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: 330px;
    top: 50px;
    right: 0;
    left: unset;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div .dropdown-title {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid rgba(145, 142, 153, 0.3);
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul {
    padding: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li {
    padding: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li p {
    font-size: 14px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li a {
    text-decoration: underline;
    // text-transform: uppercase;
    letter-spacing: 0.3px;
    font-family: Roboto, sans-serif;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li + li {
    margin-top: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li:last-child {
    padding-bottom: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .media {
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .media img {
    width: 40px;
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .media .status-circle {
    left: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .media .media-body > span {
    letter-spacing: 0.8px;
    padding-right: 10px;
    display: inline-block;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .media .media-body p {
    margin-bottom: 8px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:before, .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-box {
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-box svg {
    animation: tada 1.5s ease infinite;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-box {
    position: relative;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-box svg {
    animation: swing 1.5s ease infinite;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown ul {
    padding: 0 0 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li {
    margin: 0 15px;
    padding: 10px 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .media {
    align-items: center;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .media .media-body {
    margin-right: 20px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li + li {
    margin-top: 0;
    border-top: 1px solid rgba(145, 142, 153, 0.1);
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li:last-child {
    padding: 20px 15px;
    border-top: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown p {
    line-height: 1.3;
    margin-top: 3px;
    opacity: 0.6;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
    position: absolute;
    right: 0;
    top: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle a {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle svg {
    width: 14px;
    stroke: #fff;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box {
    margin: 5px 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group {
    width: 80px;
    background-color: transparent;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group input {
    background-color: transparent;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group input, .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group .btn {
    font-size: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group .btn {
    padding: 0px 7px;
    background-color: #F7F6FF !important;
    border-color: #F7F6FF !important;
    border-radius: 100%;
    font-size: 14px;
    color: #2b2b2b;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total {
    margin: 0;
    padding: 15px;
    background-color: #f9f9f9;
    border: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h6 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h6 span {
    font-size: 14px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .view-checkout {
    text-decoration: unset;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    top: 52px;
    width: 300px;
    right: -20px !important;
    left: unset;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li {
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li:last-child {
    text-align: center;
    background-color: transparent;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p span {
    font-size: 11px;
    vertical-align: middle;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:before, .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:after {
    right: 28px !important;
    left: unset !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li h6, .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li p {
    font-family: Roboto, sans-serif;
    letter-spacing: 0.5px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li p {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    color: #8F8F8F;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li .badge {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    padding: 3px 6px;
    position: relative;
    right: unset;
    top: unset;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown ul li + li {
    margin-top: 0;
    border-top: 1px solid rgba(145, 142, 153, 0.1);
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    width: 160px;
    top: 40px;
    left: -12px;
    padding: 0 10px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
    padding: 10px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
    text-decoration: unset;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a span {
    color: var(--theme-default);
    transition: color 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg {
    stroke: var(--theme-default);
    transition: stroke 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg path {
    stroke: var(--theme-default);
    transition: stroke 0.3s ease;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li + li {
    margin-top: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
    padding-top: 10px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
    color: #2c323f;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
    width: 16px;
    vertical-align: bottom;
    margin-right: 10px;
    stroke: #2c323f;
  }
  .page-wrapper .page-body-wrapper {
    background-color: #f8f8f8;
  }
  .page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 136px);
    margin-top: 136px;
    padding: 0 15px 0 15px;
    position: relative;
    transition: 0.5s;
  }
  .page-wrapper .page-body-wrapper .page-body:before {
    animation: fadeIn 0.5s ease-in-out;
  }
  .page-wrapper .page-body-wrapper .page-title {
    padding: 30px 30px 15px 30px;
    margin: 0px -27px 0px;
    background-color: #f8f8f8;
    // border-bottom: 1px solid #D9D9E1;
    // box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.07);
  }
  .page-wrapper .page-body-wrapper .page-title > .row {
    margin: 0;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6 {
    padding: 0;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
    display: flex;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child h3 {
    padding-right: 20px;
  }
  .page-wrapper .page-body-wrapper .page-title .row {
    align-items: center;
  }
  .page-wrapper .page-body-wrapper .page-title .row h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .page-wrapper .page-body-wrapper .page-title .row h3 small {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: capitalize;
    color: rgba(43, 43, 43, 0.54);
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-end;
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    content: "/";
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a {
    color: var(--theme-default);
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
    width: 16px;
    height: 16px;
    color: #2c323f;
    vertical-align: text-top;
  }
  .page-wrapper.horizontal-wrapper [data-simplebar] {
    position: relative;
  }
  .page-wrapper.horizontal-wrapper .footer {
    margin-left: 0;
  }
  .page-wrapper.horizontal-wrapper .header-logo-wrapper {
    width: 150px;
  }
  .page-wrapper.horizontal-wrapper .header-logo-wrapper .toggle-sidebar {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .left-header {
    padding-right: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper li {
    display: inline-block;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
    height: 54px;
    line-height: inherit;
    top: 81px;
    background: #fff;
    box-shadow: 0px 9px 24px rgba(89, 102, 122, 0.05);
    overflow-x: hidden;
    z-index: 1;
    overflow-y: visible;
    position: fixed;
    width: 100vw;
    border-top: 1px solid #efefef;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .badge {
    position: absolute;
    top: 2px;
    right: 0px;
    z-index: 1;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper,
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-icon-wrapper {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
    text-align: left;
    position: relative;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
    position: absolute;
    top: 0px;
    padding: 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow.disabled,
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow.disabled {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow {
    left: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
    right: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    direction: ltr;
    text-align: left;
    display: -webkit-box !important;
    padding: 0 5px;
    transition: all 0.5s ease;
    white-space: nowrap;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a.active .according-menu i:before {
    content: "-";
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a .according-menu {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active:after {
    background-color: var(--theme-default);
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover > a > span {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover a {
    background-color: rgba(115, 102, 255, 0.0015);
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover a svg {
    fill: rgba(115, 102, 255, 0.001);
    stroke: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
    display: none !important;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
    display: block;
    color: #2b2b2b;
    line-height: 23px;
    text-decoration: none;
    text-transform: capitalize;
    padding: 5px 15px;
    margin: 0 3px;
    letter-spacing: 0.04em;
    position: relative;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active {
    background-color: #dad6ff;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active svg,
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active span {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a .badge {
    position: absolute;
    top: -5px;
    margin-left: 1px;
    right: 10px;
    padding: 0.2em 0.7em;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a svg {
    width: 16px;
    vertical-align: bottom;
    margin-right: 10px;
    stroke-width: 2.5px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    position: fixed;
    z-index: 4;
    box-shadow: 0 0 1px 0 #898989;
    padding: 15px 0px 15px 0px;
    background: white;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li {
    border: 0;
    float: none;
    position: relative;
    display: block;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    background: transparent;
    padding: 4px 30px;
    display: block;
    transition: all 0.5s ease;
    line-height: 1.9;
    color: rgba(43, 43, 43, 0.6);
    letter-spacing: 0.06em;
    font-weight: 500;
    font-family: Roboto;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
    left: 20px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
    color: var(--theme-default);
    letter-spacing: 1.5px;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover:after {
    background-color: var(--theme-default);
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    position: fixed;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 4;
    box-shadow: 0 0 1px 0 #898989;
    padding: 30px;
    background: white;
    width: 95%;
    border-radius: 4px;
    display: block !important;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
    width: 20%;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    text-transform: uppercase;
    font-size: 14px;
    margin-left: -13px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content {
    box-shadow: none;
    background-color: transparent;
    position: relative;
    display: block;
    padding: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li {
    border: 0;
    float: none;
    position: relative;
    display: block;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
    border: 0 !important;
    background: transparent;
    color: #2b2b2b;
    border-radius: 0 !important;
    text-transform: capitalize;
    padding: 3px 0;
    font-size: 13px;
    display: block;
    letter-spacing: 0.07em;
    line-height: 1.9;
    position: relative;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover {
    color: var(--theme-default);
    letter-spacing: 1.5px;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover:after {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:after {
    position: absolute;
    content: "";
    left: -12px;
    top: 50%;
    width: 5px;
    height: 1px;
    background-color: #59667a;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover > a:after {
    left: 0;
    right: 0;
    opacity: 1;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu {
    position: unset;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper.overlay-white {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav i {
    font-size: 24px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    margin-top: 135px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main-title {
    display: none !important;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper.scorlled .sidebar-wrapper {
    display: none;
    transition: all 0.3s ease;
  }
  .page-wrapper.horizontal-wrapper .page-header {
    width: 100%;
    box-shadow: none;
  }
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .form-control-plaintext {
    width: auto !important;
  }
  .page-wrapper.horizontal-wrapper.enterprice-type footer {
    margin-left: 0;
  }
  .page-wrapper.material-icon .left-header .level-menu .nav-link, .page-wrapper.material-icon .left-header .mega-menu .nav-link {
    border-radius: 10px;
  }
  .page-wrapper.material-icon .card {
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
  }
  .page-wrapper .sidebar-main-title > div {
    background-color: rgba(115, 102, 255, 0.06);
    padding: 15px 17px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-wrapper .sidebar-main-title h6 {
    color: var(--theme-default);
    margin-bottom: 5px;
    letter-spacing: 0.4px;
  }
  .page-wrapper .sidebar-main-title p {
    color: #2c323f;
    font-size: 11px;
    margin-bottom: 0;
    text-transform: capitalize;
    line-height: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-wrapper.compact-wrapper .page-header {
    margin-left: 250px;
    width: calc(100% - 250px);
  }

  .page-wrapper.compact-wrapper .page-header.no-sidebar {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .page-wrapper.compact-wrapper .page-header.close_icon {
    margin-left: 90px;
    width: calc(100% - 90px);
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    position: fixed;
    top: 0;
    z-index: 9;
    height: auto;
    line-height: inherit;
    background: #fff;
    width: 250px;
    text-align: left;
    transition: 0.3s;
    box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
    padding: 27px 30px;
    box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
    width: auto;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .badge {
    opacity: 0;
    width: 0;
    padding: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    max-width: 86px;
    margin: 0;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
    padding: 12px 14px;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main-title {
    display: none !important;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
    text-align: right;
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
    width: 280px;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .badge {
    opacity: 1;
    width: auto;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    max-width: unset;
    margin: initial;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
    padding: 12px 15px;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a span {
    display: inline-block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main-title {
    opacity: 1;
    display: block !important;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-icon-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .mobile-sidebar {
    transform: translate(183px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
    margin-left: 100px;
    transition: 0.5s;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer-fix {
    width: calc(100% - 90px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: calc(100vh - 118px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link::before {
    right: 20px;
    left: unset;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a span {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .according-menu {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 115px);
    left: -300px;
    z-index: 99;
    transition: color 1s ease;
    overflow: auto;
    color: rgba(0, 0, 0, 0);
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
    font-weight: 500;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    letter-spacing: 0.7px;
    font-family: Roboto;
    color: #2c323f;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.link-nav .according-menu {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li:last-child {
    border-bottom: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links.opennav {
    left: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn {
    display: block;
    width: 100%;
    padding: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn .mobile-back {
    padding: 20px;
    color: #242934;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    display: block;
    width: 100%;
    padding: 0 20px;
    position: relative;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title {
    padding-top: 15px;
    padding-bottom: 13px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-list .badge {
    position: absolute;
    right: 50px;
    top: 14.5px;
    padding: 0.45em 0.8em 0.33em;
    z-index: 1;
    font-size: 10px;
    letter-spacing: 0.7px;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    border-radius: 10px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-list:hover > a:hover {
    
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover svg {
    fill: rgba(115, 102, 255, 0.001);
    stroke: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover span {
    color: var(--theme-accent);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover .according-menu i {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
    padding: 12px 15px;
    position: relative;
    color: #222222;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
    width: 18px;
    margin-right: 10px;
    vertical-align: bottom;
    float: none;
    color: #2c323f;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow {
    right: 20px;
    position: absolute;
    top: 10px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow i {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow:before {
    display: none;
  }
  // .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
  //   display: none !important;
  // }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    width: 100%;
    padding: 0;
    position: relative !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
    padding-left: 40px !important;
    font-weight: 400;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu .submenu-title .according-menu {
    top: 7px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu.opensubmenu {
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover > a {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover > a:after {
    border-top: 2px solid var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
    display: none;
    position: relative !important;
    right: 0;
    width: 100%;
    padding: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu.opensubchild {
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu li a {
    padding: 6px 45px;
    padding-left: 56px !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu li a:after {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    padding: 7px 25px;
    font-size: 14px;
    color: rgba(43, 43, 43, 0.6);
    display: block;
    position: relative;
    letter-spacing: 0.06em;
    font-weight: 500;
    font-family: Roboto;
    transition: all 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
    left: 20px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
    margin-left: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu .sidebar-title.active ~ .mega-menu-container {
    display: block !important;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    padding: 0;
    position: relative;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container.opensubmenu {
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
    width: 100%;
    padding: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section:hover h5 {
    color: var(--theme-default) !important;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section:hover h5:after {
    border-top: 2px solid var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title {
    position: relative;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    margin-bottom: 0;
    line-height: 1.9;
    padding: 6px 25px 6px 45px;
    font-size: 14px;
    position: relative;
    color: rgba(43, 43, 43, 0.6);
    display: block;
    letter-spacing: 0.06em;
    font-weight: 500;
    font-family: Roboto;
    cursor: pointer;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
    left: 25px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title .according-menu {
    top: 8px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    margin-bottom: 0;
    line-height: 1.9;
    padding: 6px 25px 6px 45px;
    font-size: 14px;
    position: relative;
    color: rgba(43, 43, 43, 0.6);
    display: block;
    letter-spacing: 0.06em;
    font-weight: 500;
    font-family: Roboto;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a:after {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a.active {
    color: var(--theme-default);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
    line-height: 1.9;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
    margin-left: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
    transition: all 0.5s ease;
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    transition: all 0.5s ease;
    position: relative;
    margin-bottom: 10px;
    background-color: #dad6ff;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active .according-menu i {
    color: var(--theme-default);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
    // color: var(--theme-secondary2);
    color: white;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
    flex-basis: unset;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
    position: absolute;
    right: 15px;
    top: 13px;
    display: block;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
    border: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    font-size: 18px;
    padding-left: 5px;
    text-transform: uppercase;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 130px);
    margin-top: 60px;
    margin-bottom: 20px;
    margin-left: 250px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body.no-sidebar {
    margin-left: 0px;
  }
  .page-wrapper.compact-wrapper.dark-sidebar .toggle-sidebar svg, .page-wrapper.compact-wrapper.color-sidebar .toggle-sidebar svg {
    stroke: var(--theme-default);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
    background-color: #1f2229;
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
    background-color: rgb(40, 132, 255, 0.5);
    border-radius: 20px;
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
    color: var(--theme-default);
    stroke: var(--theme-default);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: var(--theme-default);
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-light, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-light {
    display: none;
  }
  .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-dark, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-dark {
    display: block;
  }
  .page-wrapper.compact-wrapper.dark-sidebar .according-menu i, .page-wrapper.compact-wrapper.color-sidebar .according-menu i {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.dark-sidebar .sidebar-main-title p, .page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title p {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
    background-color: #2f3c4e;
  }
  .page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title p {
    display: none;
  }
  .page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title h6 {
    margin-bottom: 0;
  }
  .page-wrapper.compact-sidebar ~ .bg-overlay1 {
    transition: 0.8s;
  }
  .page-wrapper.compact-sidebar ~ .bg-overlay1.active {
    z-index: 8;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
  }
  .page-wrapper.compact-sidebar .toggle-sidebar {
    position: relative;
    right: unset;
    top: unset;
    margin-right: 15px;
  }
  .page-wrapper.compact-sidebar .sidebar-main-title {
    display: none;
  }
  .page-wrapper.compact-sidebar .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-sidebar .logo-icon-wrapper {
    display: block;
    text-align: center;
    padding: 24px 30px;
    box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
  }
  .page-wrapper.compact-sidebar .page-header {
    margin-left: 150px;
    width: calc(100% - 150px);
  }
  .page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-sidebar .page-header.close_icon {
    margin-left: 0;
    width: calc(100% - 0px);
  }
  .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    margin-top: 80px;
    margin-left: 150px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper .footer {
    margin-left: 150px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
    position: fixed;
    top: 0;
    z-index: 9;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    height: auto;
    line-height: inherit;
    background: #fff;
    width: 150px;
    text-align: left;
    transition: 0.3s;
    box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
    display: none;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    margin: 30px 0;
    height: calc(100vh - 113px);
    overflow: auto;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
    color: #2c323f;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    letter-spacing: 0.7px;
    font-family: Roboto;
    text-transform: capitalize;
    color: #2c323f;
    font-size: 13px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active {
    background: rgba(115, 102, 255, 0.1);
    color: #7366ff;
    border-radius: 20px;
    margin: 0 10px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active span {
    color: #7366ff;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
    animation: fadeInLeft 300ms ease-in-out;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #f3f3f3;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    position: fixed;
    top: 0px;
    height: calc(100vh - 0px);
    overflow: auto;
    left: 150px;
    width: 230px;
    background-color: #fff;
    box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
    transition: all 0.5s ease-in-out;
    display: none;
    padding-top: 20px;
    z-index: -1;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
    padding: 15px 15px 15px 40px;
    display: block;
    position: relative;
    border-bottom: 1px solid #f1f1f1;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
    display: none;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
    position: absolute;
    right: 20px;
    top: 16px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a.active,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a.active {
    background-color: rgba(115, 102, 255, 0.1);
    color: #7366ff;
    transition: all 0.3s ease;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li .submenu-content li a.active,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li .submenu-content li a.active {
    background-color: transparent;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
    position: absolute;
    content: "";
    left: 20px;
    top: 48%;
    width: 6px;
    border-top: 2px solid #595c60;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
    text-align: left;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 15px 15px 40px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
    position: absolute;
    content: "";
    left: 20px;
    top: 48%;
    width: 6px;
    border-top: 2px solid #595c60;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list {
    position: relative;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
    position: absolute;
    left: 53%;
    top: 7px;
    font-weight: 500;
    font-size: 9px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
    text-align: center;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
    display: block;
    margin: 0 auto 6px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
    display: block;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link .according-menu {
    display: none;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
    text-align: center;
    padding: 0;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
    display: block;
    margin: 0 auto 6px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
    position: absolute;
    right: 20px;
    top: 13px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translateX(-150px);
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
    margin-left: 0px;
  }
  .page-wrapper.compact-small .toggle-sidebar {
    position: relative;
    right: unset;
    top: unset;
    margin-right: 15px;
  }
  .page-wrapper.compact-small .sidebar-main-title,
  .page-wrapper.compact-small .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-small .logo-icon-wrapper {
    display: block;
    text-align: center;
    padding: 27px 30px;
    box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
  }
  .page-wrapper.compact-small .page-header {
    margin-left: 90px;
    width: calc(100% - 90px);
  }
  .page-wrapper.compact-small .page-header .header-wrapper .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-small .page-header.close_icon {
    margin-left: 0;
    width: calc(100% - 0px);
  }
  .page-wrapper.compact-small .page-body-wrapper .page-body {
    margin-left: 90px;
  }
  .page-wrapper.compact-small .page-body-wrapper .footer {
    margin-left: 90px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
    width: 90px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
    display: block;
    padding: 15px 0;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 90px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
    padding: 10px 15px 10px 40px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
    display: none;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
    top: 11px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
    display: none;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
    display: block;
    margin: 0 auto;
    width: 20px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
    display: none;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
    margin: 0 auto;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu a > span {
    display: none;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translateX(-150px);
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
    margin-left: 0px;
  }
  .page-wrapper.only-body {
    background-color: #f2f0fa;
  }
  .page-wrapper.only-body .page-body-wrapper {
    background-color: #f2f0fa;
  }
  .page-wrapper.only-body .page-header {
    position: relative;
  }
  .page-wrapper.only-body .page-header .nav-menus .profile-nav .media-body > span, .page-wrapper.only-body .page-header .nav-menus .profile-nav .media-body > p {
    color: #fff !important;
  }
  .page-wrapper.only-body .page-header .nav-menus > li > svg {
    stroke: #fff;
  }
  .page-wrapper.only-body .page-header .nav-menus > li > a > svg {
    stroke: #fff;
  }
  .page-wrapper.only-body .page-header .nav-menus > li > div .lang-txt, .page-wrapper.only-body .page-header .nav-menus > li > span .lang-txt {
    color: #fff;
  }
  .page-wrapper.only-body .page-header .nav-menus > li > div i, .page-wrapper.only-body .page-header .nav-menus > li > span i {
    color: #fff;
  }
  .page-wrapper.only-body .page-header .nav-menus > li > div svg, .page-wrapper.only-body .page-header .nav-menus > li > span svg {
    stroke: #fff;
  }
  .page-wrapper.only-body .page-header .left-header,
  .page-wrapper.only-body .page-header .header-logo-wrapper {
    display: none;
  }
  .page-wrapper.only-body .page-header .header-wrapper {
    padding: 0 !important;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    position: absolute;
    right: 60px;
    top: 58px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .search-full {
    top: 37px;
    width: calc(100% - 50px);
    left: 25px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
    background-color: #fff;
    border-radius: 15px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .search-full .Typeahead-menu {
    top: 78px;
    width: calc(100% - 30px);
    margin: 0 auto;
    right: 0;
  }
  .page-wrapper.only-body .page-body-wrapper .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb li {
    color: #fff;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb-item a svg {
    color: #fff;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title .row h3 {
    color: #fff;
  }
  .page-wrapper.only-body .sidebar-wrapper,
  .page-wrapper.only-body footer {
    display: none;
  }
  .page-wrapper.only-body .page-body-wrapper .page-body {
    margin-top: 0px;
    min-height: calc(100vh - 0px);
  }
  .page-wrapper.only-body .page-body-wrapper .page-title {
    background: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
    padding-left: 30px;
    padding-right: 30px;
    margin: 30px 0;
    border-radius: 15px;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb {
    margin-top: 5px;
    justify-content: flex-start;
  }
  .page-wrapper.modern-type .ecommerce-widget {
    border: 1px solid #dad6ff !important;
  }
  .page-wrapper.modern-type .theme-form input[type=text], .page-wrapper.modern-type .theme-form input[type=email], .page-wrapper.modern-type .theme-form input[type=search], .page-wrapper.modern-type .theme-form input[type=password], .page-wrapper.modern-type .theme-form input[type=number], .page-wrapper.modern-type .theme-form input[type=tel], .page-wrapper.modern-type .theme-form input[type=date], .page-wrapper.modern-type .theme-form input[type=datetime-local], .page-wrapper.modern-type .theme-form input[type=time], .page-wrapper.modern-type .theme-form input[type=datetime-local], .page-wrapper.modern-type .theme-form input[type=month], .page-wrapper.modern-type .theme-form input[type=week], .page-wrapper.modern-type .theme-form input[type=url], .page-wrapper.modern-type .theme-form input[type=file], .page-wrapper.modern-type .theme-form select, .page-wrapper.modern-type textarea {
    background-color: #dad6ff;
  }
  .page-wrapper.modern-type .file-sidebar .pricing-plan {
    border: 1px solid #dad6ff !important;
  }
  .page-wrapper.modern-type .alert-primary {
    color: #7366ff;
  }
  .page-wrapper.modern-type .project-box {
    background-color: #dad6ff;
  }
  .page-wrapper.modern-type .nav-tabs .nav-link.active {
    background-color: transparent;
  }
  .page-wrapper.modern-type .activity-timeline .media .activity-line {
    background-color: #dad6ff;
  }
  .page-wrapper.modern-type .simplebar-mask {
    top: 10px;
  }
  .page-wrapper.modern-type .chart_data_left .card-body .chart-main > div:last-child .media, .page-wrapper.modern-type .chart_data_right .card-body .chart-main > div:last-child .media {
    border-right: none !important;
  }
  .page-wrapper.modern-type .page-header {
    margin-left: 0px;
    width: calc(100% - 0px);
  }
  .page-wrapper.modern-type .page-header.close_icon {
    margin-left: 0px;
    width: calc(100% - 0px);
  }
  .page-wrapper.modern-type .page-header .header-wrapper .header-logo-wrapper {
    display: flex;
    align-items: center;
  }
  .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
    display: block;
    margin-right: 30px;
  }
  .page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
    display: block;
    position: relative;
    top: unset;
    right: unset;
    margin-right: 15px;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
    margin: 30px !important;
    top: 80px;
    bottom: 0;
    border-radius: 15px;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
    display: none;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 160px);
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
    display: none;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
    margin-left: 0px;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
    margin-left: 0px;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
    display: none;
  }
  .page-wrapper.modern-type .page-body-wrapper .page-body {
    margin-left: 310px;
  }
  .page-wrapper.modern-type .page-body-wrapper .page-body .container-fluid {
    max-width: 1450px;
  }
  .page-wrapper.modern-type .page-body-wrapper .footer {
    margin-left: 390px;
    margin-right: 70px;
    border-radius: 10px;
  }
  .page-wrapper.material-type {
    position: relative;
  }
  .page-wrapper.material-type::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
  }
  .page-wrapper.material-type .card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  }
  .page-wrapper.material-type .page-header {
    position: relative;
    max-width: 1560px;
    margin: 50px auto 0;
    border-radius: 15px 15px 0 0;
  }
  .page-wrapper.material-type .page-body-wrapper {
    // background-image: url(../images/other-images/boxbg.jpg);
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
    position: relative;
    max-width: 1560px;
    margin: 0 auto;
    top: unset;
    height: unset;
  }
  .page-wrapper.material-type .page-body-wrapper .page-body {
    margin: 0 auto;
    max-width: 1560px;
    background-color: #f8f8f8;
    box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
    border-radius: 0 0 15px 15px;
  }
  .page-wrapper.material-type .footer {
    margin-top: 40px;
  }
  .page-wrapper.advance-layout {
    background-blend-mode: overlay;
    background-color: rgba(248, 248, 248, 0.7);
  }
  .page-wrapper.advance-layout .card {
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ecf3fa;
  }
  .page-wrapper.advance-layout .page-header {
    position: relative;
    background-color: #fff;
    margin: 0 auto;
  }
  .page-wrapper.advance-layout .page-body-wrapper {
    background: transparent;
  }
  .page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
    position: relative !important;
    top: unset !important;
    height: unset;
    box-shadow: none;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }
  .page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper > div {
    overflow-x: hidden;
  }
  .page-wrapper.advance-layout .page-body-wrapper .page-body {
    margin-top: 0 !important;
  }
  
  @keyframes pagebody {
    from {
      opacity: 0;
      transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
    }
    50% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1480px) {
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
      right: -18%;
      width: 91%;
    }
  }
  @media screen and (max-width: 1460px) {
    .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
      right: -54%;
      width: 75%;
    }
    .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 1366px) {
    .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
      right: -49%;
      width: 70%;
    }
    .profile-dropdown.onhover-show-div {
      right: 0;
      left: unset !important;
    }
    .profile-dropdown.onhover-show-div:before, .profile-dropdown.onhover-show-div:after {
      right: 10px !important;
      left: unset !important;
    }
  }
  @media screen and (max-width: 1199px) {
    .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
      padding: 6px;
    }
    .left-header .mega-menu-container .submenu-title {
      margin: 20px 20px 0 20px;
    }
    .mobile-title.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;
    }
    .mobile-title.d-none h5 {
      color: var(--theme-default);
    }
    .mobile-title.d-none svg {
      stroke: var(--theme-default) !important;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
      right: -35%;
      width: 56%;
    }
  }
  @media screen and (min-width: 992px) {
    .page-header .header-wrapper .nav-right .notification-dropdown {
      top: 46px;
    }
    .responsive-btn {
      display: none;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-mask {
      top: 0;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
      width: 200px;
      opacity: 0;
      visibility: hidden;
      border-radius: 5px;
      transition: all 0.5s ease;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a .sub-arrow {
      position: absolute;
      right: 20px;
      top: 5px;
      color: #59667a;
      font-weight: 100;
      transition: all 0.5s ease;
      font-size: 15px;
      top: 5px;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
      display: none;
      border-radius: 4px;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover .nav-sub-childmenu {
      display: block;
      position: absolute;
      left: 200px;
      background-color: #fff;
      top: -10px;
      padding: 15px 0px 15px 0px;
      box-shadow: 0 0 1px 0 #898989;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover .sub-arrow {
      right: 16px;
      transition: all 0.5s ease;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.hoverd .sidebar-submenu {
      opacity: 1;
      visibility: visible;
      margin-top: 8px;
      transition: all 0.5s ease;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.hoverd .mega-menu-container {
      opacity: 1;
      visibility: visible;
      margin-top: 20px;
      transition: all 0.5s ease;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
      display: none;
    }
    .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
      display: none !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .page-wrapper.material-icon {
      position: relative;
    }
    .page-wrapper.material-icon::before {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 240px;
      background: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
    }
    .page-wrapper.material-icon .toggle-sidebar {
      color: rgba(255, 255, 255, 0.8);
    }
    .page-wrapper.material-icon .toggle-sidebar:hover > svg {
      color: #fff !important;
      stroke: #fff !important;
    }
    .page-wrapper.material-icon .left-header .mega-menu .nav-link,
  .page-wrapper.material-icon .left-header .level-menu .nav-link {
      background-color: rgba(255, 255, 255, 0.09);
      color: rgba(255, 255, 255, 0.7);
    }
    .page-wrapper.material-icon .left-header .mega-menu .nav-link svg,
  .page-wrapper.material-icon .left-header .level-menu .nav-link svg {
      stroke: rgba(255, 255, 255, 0.7);
    }
    .page-wrapper.material-icon .page-header {
      position: relative;
      background: transparent;
      padding-left: 90px;
      max-width: 1660px;
      margin: 0 auto;
      width: calc(100% - 0px);
      box-shadow: none;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li {
      color: #fff;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .notification-box svg,
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .cart-box svg {
      stroke: #fff;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > a > svg {
      stroke: #fff;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > svg {
      stroke: #fff;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
      color: #2b2b2b;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
      color: #2b2b2b;
    }
    .page-wrapper.material-icon .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
      color: rgba(255, 255, 255, 0.7);
    }
    .page-wrapper.material-icon .page-header.close_icon {
      padding-left: 0;
      margin: 0 auto !important;
    }
    .page-wrapper.material-icon .page-body-wrapper .page-body {
      margin-top: 0 !important;
      padding-left: 90px;
      max-width: 1660px;
      margin: 0 auto;
    }
    .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
      padding-left: 15px;
      margin: 0 auto !important;
    }
    .page-wrapper.material-icon .page-body-wrapper .page-title {
      color: #fff;
      background-color: transparent;
      border-bottom: none;
      box-shadow: none;
    }
    .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
      color: #fff;
    }
    .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item.active {
      color: rgba(255, 255, 255, 0.6);
    }
    .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  @media screen and (max-width: 767px) {
    .sidebar-wrapper .menu .brand-logo img {
      width: 120px;
    }
  }
  @media screen and (max-width: 576px) {
    .toggle-nav i {
      font-size: 20px;
    }
  }
  /**=====================
      78. Sidebar CSS Ends
  ==========================**/
  /**=====================
      60. RTL CSS Start
  ==========================**/
  html[dir=rtl] ul {
    padding-right: 0;
  }
  html[dir=rtl] .f-right {
    float: left;
  }
  html[dir=rtl] .form-check-inline {
    margin-right: unset;
    margin-left: 1rem;
  }
  html[dir=rtl] .color-box button + button {
    margin-left: unset;
    margin-right: 5px;
  }
  html[dir=rtl] .knowledgebase-search .form-inline svg {
    left: unset;
    right: 20px;
  }
  html[dir=rtl] .switch-showcase .form-check .form-check-input {
    float: left;
  }
  html[dir=rtl] .pill-input-group span:last-child {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top-left-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important;
  }
  html[dir=rtl] .pill-input-group span:first-child {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .toggle-sidebar {
    right: unset;
    left: 30px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    padding: 0;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .badge {
    left: 50px !important;
    right: unset !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon {
    margin-right: 0;
    margin-left: unset;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper {
    text-align: left;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper img {
    margin-left: 8px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body,
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    margin-left: 0;
    margin-right: 100px;
    transition: 0.5s;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
    margin-left: 0;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
    display: none !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .according-menu {
    display: none;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
    margin-left: 13px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover {
    transform: translate(0px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
    display: block !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
    float: none;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .according-menu {
    display: block;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu:after {
    right: 30px;
    left: unset;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
    padding-right: 45px !important;
    padding-left: unset !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a:after {
    right: 26px;
    left: unset;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: unset;
    margin-right: 280px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
    margin-right: unset;
    margin-left: 15px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
    right: unset;
    left: 20px;
  }
  html[dir=rtl] .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    right: unset;
    left: 30px;
  }
  html[dir=rtl] .date-details > div + div {
    margin-left: unset;
    margin-right: 10px;
  }
  html[dir=rtl] .contact-editform.ps-0 {
    padding-left: 20px !important;
  }
  html[dir=rtl] .contact-options li .btn-category {
    padding-left: unset !important;
    padding-right: 40px;
  }
  html[dir=rtl] .list-persons .profile-mail .email-general ul {
    padding-right: unset;
    padding-left: 20px;
  }
  html[dir=rtl] .list-persons .profile-mail .email-general ul li > span {
    float: left;
  }
  html[dir=rtl] .list-persons .profile-mail .media .media-body ul li + li {
    padding-left: unset;
    padding-right: 10px;
    margin-left: unset;
    margin-right: 5px;
    border-left: unset;
    border-right: 1px solid #f4f4f4;
  }
  html[dir=rtl] .file-content .form-inline i {
    padding-right: unset;
    padding-left: 10px;
  }
  html[dir=rtl] .file-sidebar .btn svg {
    margin-right: unset;
    margin-left: 8px;
  }
  html[dir=rtl] .form-select {
    background-position: left 0.75rem center;
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  }
  html[dir=rtl] .customers ul li + li {
    margin-left: unset;
    margin-right: -10%;
  }
  html[dir=rtl] .project-list .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 0 5px 30px;
  }
  html[dir=rtl] .project-box .badge {
    right: unset;
    left: 30px;
  }
  html[dir=rtl] .kanban-board {
    float: right;
  }
  html[dir=rtl] .kanban-item .kanban-box .customers ul li + li {
    margin-left: unset;
    margin-right: -10px;
  }
  html[dir=rtl] .kanban-item .list li i {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .page-wrapper.horizontal-wrapper .header-logo-wrapper {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper footer {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-type .page-body-wrapper .page-body {
    margin-right: auto;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:first-child {
    margin-right: 0;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
    margin-right: 15px;
    margin-left: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .toggle-sidebar {
    margin-left: 2px;
    margin-right: unset;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    left: -20px !important;
    right: unset !important;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full input {
    padding-left: unset;
    padding-right: 60px;
    text-align: right;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
    left: unset;
    right: 30px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    right: unset;
    left: 30px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .toggle-sidebar {
    margin-left: 15px;
    margin-right: unset;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
    margin-left: unset;
    margin-right: 150px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-header.close_icon {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    margin-left: unset;
    margin-right: 150px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .footer {
    margin-left: unset;
    margin-right: 150px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
    animation: fadeInRight 1000ms ease-in-out;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 150px;
    left: unset;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
    padding: 15px 40px 15px 15px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
    right: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
    left: unset;
    right: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
    padding: 15px 40px 15px 15px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
    left: unset;
    right: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
    left: unset;
    right: 53%;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
    text-align: center;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
    text-align: center;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
    right: unset;
    left: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translateX(150px);
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
    margin-right: 0px;
  }
  html[dir=rtl] .page-wrapper.compact-small .page-header {
    margin-right: 90px;
  }
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 90px;
  }
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .footer,
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .page-body {
    margin-right: 90px;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-header {
    padding-left: unset;
    padding-right: 90px;
    margin: 0 auto;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-header.close_icon {
    padding-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
    padding-left: 15px;
    padding-right: 90px;
    margin: 0 auto;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
    padding-right: 15px;
    margin: 0 auto !important;
  }
  html[dir=rtl] .apexcharts-canvas {
    direction: ltr;
  }
  html[dir=rtl] .email-wrap .btn-mail {
    text-align: right;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar ul li a {
    padding-left: unset;
    padding-right: 20px;
  }
  html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
    text-align: center;
  }
  html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
    text-align: right;
  }
  html[dir=rtl] .bookmark-wrap .details-bookmark .row > div + div {
    padding-left: 15px !important;
  }
  html[dir=rtl] .earning-card.card .card-body .chart-left {
    padding: 40px 40px 40px 0;
  }
  html[dir=rtl] .earning-card.card .card-body .inner-top-left ul li, html[dir=rtl] .earning-card.card .card-body .inner-top-right ul li {
    margin-left: unset;
    margin-right: 35px;
  }
  html[dir=rtl] .earning-card.card .card-body .inner-top-left ul li:first-child, html[dir=rtl] .earning-card.card .card-body .inner-top-right ul li:first-child {
    margin-right: 0;
  }
  html[dir=rtl] .earning-card.card .card-body .inner-top-right {
    padding-left: 15px;
  }
  html[dir=rtl] .earning-card.card .card-body .media .media-left {
    margin-right: unset;
    margin-left: 15px;
  }
  html[dir=rtl] .earning-card.card .card-body .earning-content {
    border-right: unset;
    border-left: 1px solid #ecf3fa;
  }
  html[dir=rtl] .chart_data_left .card-body .chart-main .media, html[dir=rtl] .chart_data_right .card-body .chart-main .media {
    border-right: unset;
    border-left: 1px solid #ecf3fa;
  }
  html[dir=rtl] .chart_data_left .card-body .chart-main .media .media-body .right-chart-content, html[dir=rtl] .chart_data_right .card-body .chart-main .media .media-body .right-chart-content {
    margin-left: unset;
    margin-right: 30px;
  }
  html[dir=rtl] .chart_data_left .card-body .chart-main .media.border-none, html[dir=rtl] .chart_data_right .card-body .chart-main .media.border-none {
    border-left: none;
  }
  html[dir=rtl] .chart_data_right .card-body .media .right-chart-content h4 .new-box {
    margin-left: unset;
    margin-right: 10px;
  }
  html[dir=rtl] .appointment-table table tr td .status-circle {
    left: unset;
    right: 35px;
  }
  html[dir=rtl] .notification .card .media .media-body h6 .dot-notification {
    float: left;
  }
  html[dir=rtl] .notification .card .media .media-body p .badge {
    margin-left: unset;
    margin-right: 10px;
  }
  html[dir=rtl] .notification .card .media .media-body .inner-img:first-child {
    margin-right: unset;
    margin-left: 20px;
  }
  html[dir=rtl] .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat {
    margin-left: unset;
    margin-right: 15px;
  }
  html[dir=rtl] .chat-default .card-body.chat-box .left-side-chat .media-body .main-chat .message-main span {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 30px;
  }
  html[dir=rtl] .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0;
  }
  html[dir=rtl] .chat-default .card-body.chat-box .input-group .form-control {
    border-left: unset;
    border-right: 1px solid var(--theme-default);
  }
  html[dir=rtl] .chat-default .card-body.chat-box .input-group .send-msg svg {
    transform: rotate(-135deg);
    margin-left: 5px;
  }
  html[dir=rtl] .dash-chart .media .media-body p .font-roboto {
    float: right;
  }
  html[dir=rtl] .progress-gradient-primary {
    background-image: linear-gradient(to right, var(--theme-default), transparent);
  }
  html[dir=rtl] .progress-gradient-primary::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-primary .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-secondary {
    background-image: linear-gradient(to right, #f73164, transparent);
  }
  html[dir=rtl] .progress-gradient-secondary::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-secondary .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-success {
    background-image: linear-gradient(to right, #51bb25, transparent);
  }
  html[dir=rtl] .progress-gradient-success::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-success .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-danger {
    background-image: linear-gradient(to right, #dc3545, transparent);
  }
  html[dir=rtl] .progress-gradient-danger::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-danger .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-info {
    background-image: linear-gradient(to right, #a927f9, transparent);
  }
  html[dir=rtl] .progress-gradient-info::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-info .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-light {
    background-image: linear-gradient(to right, #f4f4f4, transparent);
  }
  html[dir=rtl] .progress-gradient-light::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-light .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-dark {
    background-image: linear-gradient(to right, #2c323f, transparent);
  }
  html[dir=rtl] .progress-gradient-dark::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-dark .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-gradient-warning {
    background-image: linear-gradient(to right, #f8d62b, transparent);
  }
  html[dir=rtl] .progress-gradient-warning::after {
    right: unset;
    left: -1px;
  }
  html[dir=rtl] .progress-gradient-warning .animate-circle {
    right: unset;
    left: -8px;
  }
  html[dir=rtl] .progress-animate span {
    left: -10px;
    right: unset;
  }
  html[dir=rtl] .our-product table tr td:first-child, html[dir=rtl] .best-seller-table table tr td:first-child {
    padding-left: 20px;
    padding-right: 0;
  }
  html[dir=rtl] .our-product table tr td:last-child, html[dir=rtl] .best-seller-table table tr td:last-child {
    padding-right: 20px;
    padding-left: 0;
    text-align: left;
  }
  html[dir=rtl] .best-seller-table table tbody tr td .align-middle .status-circle {
    left: unset;
    right: 32px;
  }
  html[dir=rtl] .best-seller-table table tbody tr td .fa-check-circle {
    left: unset;
    right: 30px;
  }
  html[dir=rtl] .activity-timeline .media .media-body {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .activity-timeline .media .activity-line {
    left: unset;
    right: 46px;
  }
  html[dir=rtl] .offer-box .offer-slider .carousel-control-prev, html[dir=rtl] .offer-box .offer-slider .carousel-control-next {
    right: unset;
    left: 0;
  }
  html[dir=rtl] .total-users .goal-status .progress {
    background-image: linear-gradient(to right, transparent 15%, rgba(169, 39, 249, 0.1) 25%, transparent);
  }
  html[dir=rtl] .total-users .goal-status .progress-gradient-primary {
    background-image: linear-gradient(to right, var(--theme-default) 25%, rgba(169, 39, 249, 0.1), transparent);
  }
  html[dir=rtl] .cal-date-widget .cal-info .pe-3 {
    padding-right: 1rem !important;
  }
  html[dir=rtl] .me-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  html[dir=rtl] .pe-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important;
  }
  html[dir=rtl] .me-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  html[dir=rtl] .navs-dropdown .onhover-show-div {
    right: 0;
  }
  html[dir=rtl] .default-according.style-1 button {
    text-align: right;
  }
  html[dir=rtl] .default-according.style-1 button:before {
    left: 20px;
    right: unset;
  }
  html[dir=rtl] .default-according.style-1 button i {
    right: 18px;
    left: unset;
  }
  html[dir=rtl] .default-according .card .card-header i {
    right: 18px;
    left: unset;
  }
  html[dir=rtl] .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }
  html[dir=rtl] .starter-main .card-body ul {
    padding-right: 30px;
    padding-left: unset;
  }
  html[dir=rtl] .theme-tab .tab-title li a svg {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .spent .spent-graph .project-budget {
    margin-left: 30px;
    margin-right: unset;
  }
  html[dir=rtl] .support-table table tbody tr td:first-child {
    padding-right: 0;
    padding-left: unset;
  }
  html[dir=rtl] .widget-joins .row .pe-0 {
    padding-right: 15px !important;
  }
  html[dir=rtl] .widget-joins .row .ps-0 {
    padding-left: 15px !important;
  }
  html[dir=rtl] .order-box .sub-total .shipping-class .shopping-checkout-option, html[dir=rtl] .order-box .sub-total .shipping-class span, html[dir=rtl] .order-box .sub-total li .shopping-checkout-option, html[dir=rtl] .order-box .sub-total li span, html[dir=rtl] .order-box .total .shipping-class .shopping-checkout-option, html[dir=rtl] .order-box .total .shipping-class span, html[dir=rtl] .order-box .total li .shopping-checkout-option, html[dir=rtl] .order-box .total li span, html[dir=rtl] .order-box .qty .shipping-class .shopping-checkout-option, html[dir=rtl] .order-box .qty .shipping-class span, html[dir=rtl] .order-box .qty li .shopping-checkout-option, html[dir=rtl] .order-box .qty li span {
    float: left;
  }
  html[dir=rtl] .order-box .sub-total li .count, html[dir=rtl] .order-box .total li .count, html[dir=rtl] .order-box .qty li .count {
    float: left;
  }
  html[dir=rtl] .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }
  html[dir=rtl] .cart .qty-box .input-group .btn {
    border-radius: 0 !important;
  }
  html[dir=rtl] .product-qnty fieldset .input-group .input-group-prepend .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .grid-options {
    margin-right: 10px;
    margin-left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-1 {
    right: 12px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-2 {
    right: 18px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-3 {
    right: 36px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-4 {
    right: 42px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-5 {
    right: 48px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-6 {
    right: 66px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-7 {
    right: 72px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-8 {
    right: 78px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-9 {
    right: 84px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-10 {
    right: 103px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-11 {
    right: 109px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-12 {
    right: 115px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-13 {
    right: 121px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-14 {
    right: 127px;
    left: unset;
  }
  html[dir=rtl] .grid-options ul li a .line-grid-15 {
    right: 133px;
    left: unset;
  }
  html[dir=rtl] .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-right: calc(25% + 9px);
    margin-left: unset;
  }
  html[dir=rtl] .product-filter.new-products .owl-theme .owl-nav {
    left: 0;
    right: unset;
  }
  html[dir=rtl] .product-filter.new-products .owl-theme .owl-item .item .product-box .product-details.text-start {
    text-align: left !important;
  }
  html[dir=rtl] .product-sidebar .filter-section .card .card-header h6 .pull-right i {
    left: auto;
    right: unset;
  }
  html[dir=rtl] .prooduct-details-box .btn {
    right: unset;
    left: 15px;
  }
  html[dir=rtl] .prooduct-details-box .close {
    right: unset;
    left: 10px;
  }
  html[dir=rtl] .collection-filter-block svg {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .feature-products form .form-group i {
    left: 30px;
    right: unset;
  }
  html[dir=rtl] .product-wrapper-grid.list-view .product-box .product-details {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    margin-left: unset;
  }
  html[dir=rtl] .bookmark ul li + li {
    margin-left: unset;
    margin-right: 10px;
  }
  html[dir=rtl] .bookmark ul li .search-form .form-control-search {
    left: -50px;
    right: unset;
  }
  html[dir=rtl] .bookmark ul li .search-form .form-control-search:before {
    right: 50px;
    left: unset;
  }
  html[dir=rtl] .bookmark ul li .search-form .form-control-search:after {
    right: 20px;
    left: unset;
  }
  html[dir=rtl] .me-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  html[dir=rtl] .footer .pull-right {
    float: none;
  }
  html[dir=rtl] .checkbox_animated, html[dir=rtl] .radio_animated {
    margin: 0 8px 0 16px;
  }
  html[dir=rtl] .button.remove {
    left: -10px;
    right: unset;
  }
  html[dir=rtl] .code-box-copy__btn {
    left: 11px;
    right: unset;
  }
  html[dir=rtl] ul.nav-menus {
    border-bottom: none;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
    text-align: right;
  }
  html[dir=rtl] .badge + .badge {
    margin-right: 5px;
    margin-left: unset;
  }
  html[dir=rtl] .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }
  html[dir=rtl] .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }
  html[dir=rtl] .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .text-start {
    text-align: right !important;
  }
  html[dir=rtl] .m-r-10 {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .pe-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }
  html[dir=rtl] .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }
  html[dir=rtl] .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .ps-3 {
    padding-right: 1rem !important;
  }
  html[dir=rtl] .b-r-light {
    border-left: 1px solid #f4f4f4 !important;
    border-right: unset !important;
  }
  html[dir=rtl] .float-end {
    float: left !important;
  }
  html[dir=rtl] .float-start {
    float: right !important;
  }
  html[dir=rtl] .text-end {
    text-align: left !important;
  }
  html[dir=rtl] .border-right {
    border-left: 1px solid #dee2e6 !important;
    border-right: unset !important;
  }
  html[dir=rtl] .pe-0 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  html[dir=rtl] .ps-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }
  html[dir=rtl] .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }
  html[dir=rtl] .owl-carousel {
    direction: ltr;
  }
  html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle), html[dir=rtl] .btn-group > :not(:last-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .btn-group > .btn:nth-child(2) {
    border-radius: 0 !important;
  }
  html[dir=rtl] .btn-group .btn-group > :not(:first-child) > .btn {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .btn-group .btn + .btn, html[dir=rtl] .btn-group .btn + .btn-group {
    margin-right: -1px;
    border-right: none;
  }
  html[dir=rtl] .btn-group .btn-group + .btn, html[dir=rtl] .btn-group .btn-group + .btn-group {
    margin-right: -1px;
  }
  html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn, html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn-group {
    margin-right: -1px;
  }
  html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn, html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn-group {
    margin-right: -1px;
  }
  html[dir=rtl] .me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }
  html[dir=rtl] .me-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  html[dir=rtl] .ms-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }
  html[dir=rtl] .b-l-light {
    border-right: 1px solid #f4f4f4 !important;
    border-left: unset !important;
  }
  html[dir=rtl] .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }
  html[dir=rtl] .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }
  html[dir=rtl] .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  html[dir=rtl] .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }
  html[dir=rtl] .alert {
    text-align: right;
  }
  html[dir=rtl] .pull-right {
    float: left;
  }
  html[dir=rtl] .form-inline .form-group {
    margin-left: 15px;
    margin-right: 0;
  }
  html[dir=rtl] .card {
    text-align: right;
  }
  html[dir=rtl] .card .card-header h5:not(.mb-0) {
    float: right;
  }
  html[dir=rtl] .card .card-header span {
    clear: both;
  }
  html[dir=rtl] .card .card-header .card-header-right {
    left: 35px;
    right: unset;
  }
  html[dir=rtl] .card .card-header .card-header-right .card-option {
    text-align: left;
  }
  html[dir=rtl] .card .card-header .card-header-right .card-option.list-unstyled {
    padding-right: 0;
    padding-left: unset;
  }
  html[dir=rtl] .card .card-header .pull-left {
    float: right;
  }
  html[dir=rtl] .card.full-card {
    left: 0;
    right: unset;
  }
  html[dir=rtl] .card .card-body .alert svg {
    margin-left: 4px;
    margin-right: unset;
  }
  html[dir=rtl] .card .card-body .value-left {
    margin-left: 30px;
    margin-right: unset;
  }
  html[dir=rtl] .card .card-body .square {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .modal-header .btn-close {
    margin: -0.5rem auto -0.5rem -0.5rem;
  }
  html[dir=rtl] .modal-footer > :not(:first-child) {
    margin-right: 0.5rem;
    margin-left: 0;
  }
  html[dir=rtl] .form-inline .form-group .col-form-label {
    margin-left: 5px;
    margin-right: 0;
  }
  html[dir=rtl] .breadcrumb-item + .breadcrumb-item {
    padding-right: 0.5rem;
    padding-left: unset;
  }
  html[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
    padding-left: 0.5rem;
    padding-right: unset;
    float: right;
  }
  html[dir=rtl] code[class*=language-], html[dir=rtl] pre[class*=language-] {
    text-align: right;
  }
  html[dir=rtl] .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }
  html[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  html[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  html[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
    margin-right: -1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .was-validated .form-control:invalid,
  html[dir=rtl] .form-control.is-invalid,
  html[dir=rtl] .was-validated .form-control:valid,
  html[dir=rtl] .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }
  html[dir=rtl] .input-group > .form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .input-group > .input-group-prepend > .btn, html[dir=rtl] .input-group > .input-group-prepend > .input-group-text, html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn, html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text, html[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), html[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .input-group > .input-group-append > .btn, html[dir=rtl] .input-group > .input-group-append > .input-group-text, html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn, html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text, html[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child), html[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .border-tab.nav-left .nav-link {
    text-align: left;
  }
  html[dir=rtl] .socialprofile .social-group ul li:nth-child(4) {
    margin-right: -22px;
  }
  html[dir=rtl] .social-status form .form-group .form-control-social {
    padding-right: 15px;
    padding-left: unset;
  }
  html[dir=rtl] .social-status .media .social-status {
    right: 35px;
    left: unset;
  }
  html[dir=rtl] .social-chat .other-msg {
    margin-right: 40px;
    margin-left: unset;
  }
  html[dir=rtl] .social-chat .media-body:before {
    left: 100%;
    right: unset;
    border-left: 7px solid var(-semi-dark);
    border-right: unset;
  }
  html[dir=rtl] .social-chat .media-body:after {
    left: 100%;
    right: unset;
    border-left: 7px solid #fff;
    border-right: unset;
  }
  html[dir=rtl] .timeline-content .comment-number i {
    margin-left: 20px;
    margin-right: unset;
  }
  html[dir=rtl] .photos ul li {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .avatar-showcase .friend-pic {
    margin-left: 8px;
    margin-right: unset;
  }
  html[dir=rtl] .avatar-showcase .pepole-knows ul li {
    margin-left: 21px;
    margin-right: unset;
  }
  html[dir=rtl] .avatar-showcase .pepole-knows ul li:last-child {
    margin-left: 0;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 100px;
    right: unset;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons li:last-child {
    margin-left: 0;
  }
  html[dir=rtl] .faq-form .search-icon {
    left: 12px;
    right: unset;
  }
  html[dir=rtl] .faq-widgets .media-body p {
    padding-left: 20px;
    padding-right: unset;
  }
  html[dir=rtl] .header-faq, html[dir=rtl] .faq-title {
    text-align: right;
  }
  html[dir=rtl] .faq-accordion .faq-header svg {
    left: 30px;
    right: unset;
  }
  html[dir=rtl] .faq-accordion .card .btn-link svg {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .navigation-option ul li {
    padding: 15px 0 15px 15px;
  }
  html[dir=rtl] .navigation-option ul li a {
    padding-right: 40px;
    padding-left: unset;
  }
  html[dir=rtl] .navigation-option ul li a svg {
    right: 15px;
    left: unset;
  }
  html[dir=rtl] .updates-faq {
    margin-right: unset;
    margin-left: 30px;
  }
  html[dir=rtl] .browse .browse-articles span svg {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .categories .learning-header {
    padding-right: 30px;
    padding-left: unset;
  }
  html[dir=rtl] .main .langChoice {
    left: 30px;
    right: unset;
  }
  html[dir=rtl] .pricing-block svg {
    direction: ltr;
  }
  html[dir=rtl] p.data-attributes svg {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .avatars .avatar {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .avatars .avatar:last-child {
    margin-left: 0;
  }
  html[dir=rtl] .avatars .avatar .status {
    left: 4px;
    right: unset;
  }
  html[dir=rtl] .customers.avatar-group {
    margin-left: 30px;
    margin-right: unset;
  }
  html[dir=rtl] .card .card-header h5 {
    float: none !important;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-small {
    right: 10px;
    left: unset;
  }
  html[dir=rtl] .widget-joins .widget-card h6 {
    margin-left: unset;
    margin-right: 15px;
  }
  html[dir=rtl] .widget-joins .widget-card .icon-bg {
    right: unset;
    left: 20px;
  }
  html[dir=rtl] .weather-widget-two .widget-list .media .media-body {
    margin-left: unset;
    margin-right: 30px;
  }
  html[dir=rtl] .activity-media .media .media-body {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .custom-profile .card-social li + li {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .social-widget-card .media .media-body {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .mobile-clock-widget .bg-svg {
    left: unset;
    right: -25px;
  }
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
    left: 30px;
    right: unset;
  }
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks .btn-label {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
    text-align: left;
  }
  html[dir=rtl] .todo .notification-popup {
    left: 10px;
    right: unset;
  }
  html[dir=rtl] .todo-options .badges-todo h6 {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .todo-options .badges-todo h6:last-child {
    margin-left: 0;
  }
  html[dir=rtl] .todo-list-btns .dropdown-basic .btn-group .form-group .checkbox {
    padding-right: 14px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-left: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn {
    margin-right: -6px;
    margin-left: unset;
  }
  html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn .btn {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  html[dir=rtl] .search-todo .dropdown-basic .btn-group {
    margin-left: 18px;
    margin-right: unset;
  }
  html[dir=rtl] .sticky-note .note {
    float: right;
  }
  html[dir=rtl] .mega-menu .list-unstyled div a {
    padding: 8px 0 8px 35px;
  }
  html[dir=rtl] .mega-menu .list-unstyled div a:hover {
    padding: 8px 10px 8px 35px;
  }
  html[dir=rtl] #testimonial .owl-stage-outer .owl-stage .owl-item .slide--item .media .me-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .needs-validation .input-group > .form-control:not(:last-child), html[dir=rtl] .needs-validation .input-group > .custom-select:not(:last-child), html[dir=rtl] .form-row .input-group > .form-control:not(:last-child), html[dir=rtl] .form-row .input-group > .custom-select:not(:last-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .form-check {
    padding-right: 1.25rem;
    padding-left: unset;
  }
  html[dir=rtl] .form-check .form-check-input {
    float: right;
    margin-left: unset;
    margin-right: -1.5em;
  }
  html[dir=rtl] .mega-menu .default-according .card .btn-link {
    text-align: right;
  }
  html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=true]:before, html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=false]:before {
    right: unset;
    left: 20px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-6 {
    padding-right: 0;
    padding-left: 15px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-left: 0;
    padding-right: 15px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
    text-align: right;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar ul {
    padding-right: 0;
  }
  html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
    float: left;
  }
  html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
    padding-right: unset !important;
  }
  html[dir=rtl] .email-wrap .email-right-aside .radius-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 4px;
  }
  html[dir=rtl] #renderRange {
    float: left;
    padding-left: unset;
    padding-right: 12px;
  }
  html[dir=rtl] .event-calendar {
    direction: ltr;
  }
  html[dir=rtl] .calendar-wrap .fc {
    direction: rtl;
    text-align: right;
  }
  html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-left {
    float: right;
  }
  html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-right {
    float: left;
  }
  html[dir=rtl] .calendar-wrap .fc .fc-toolbar > * > * {
    margin-left: 0;
  }
  html[dir=rtl] .calendar-wrap .fc-events-container {
    text-align: right;
  }
  html[dir=rtl] .calendar-wrap .fc-scroller {
    margin-right: unset;
    margin-left: -17px;
  }
  html[dir=rtl] .fc-agenda-view .fc-day-grid .fc-row {
    margin-right: 0 !important;
  }
  html[dir=rtl] .fc-unthemed .fc-row {
    margin-right: 0 !important;
  }
  html[dir=rtl] .btn-group-showcase .btn-group {
    margin-left: 20px;
    margin-right: unset;
  }
  html[dir=rtl] .btn-showcase .btn {
    margin-right: unset;
    margin-left: 18px;
  }
  html[dir=rtl] .btn-square.dropdown-toggle {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  html[dir=rtl].button-builder ul.the-icons li {
    float: right;
  }
  html[dir=rtl].button-builder .color-slelector {
    float: right;
  }
  html[dir=rtl].button-builder .custom-button-color .form-control {
    border-radius: 0 5px 5px 0;
  }
  html[dir=rtl].button-builder .custom-button-color .form-control.pull-left {
    float: right;
  }
  html[dir=rtl].button-builder .hint-label {
    float: right;
    padding: 4px 20px 0 0;
  }
  html[dir=rtl] #customer-review .owl-item img {
    order: 2;
  }
  html[dir=rtl] .options > div {
    margin: 0 0 8px 8px;
  }
  html[dir=rtl] .modal-dialog .modal-content .modal-body p a {
    margin-left: 0;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .text-lg-left {
    text-align: right !important;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn,
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn-right,
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .checkbox-radius {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group #prependedcheckbox {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group #appendedcheckbox {
    border-right-color: #efefef;
  }
  html[dir=rtl] .drag-box fieldset .component .form-group .input-group #buttondropdown {
    margin-right: unset;
    margin-left: -1px;
  }
  html[dir=rtl] .drag form {
    text-align: right;
  }
  html[dir=rtl] .drag form input {
    text-align: right;
  }
  html[dir=rtl] .drag form label {
    text-align: right !important;
  }
  html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn,
  html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn-right,
  html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .checkbox-radius {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .drag form .form-group .input-group #buttondropdown {
    margin-right: unset;
    margin-left: -1px;
  }
  html[dir=rtl] .draggable label {
    text-align: right;
  }
  html[dir=rtl] .form-builder-column .tab-content .tab-pane .theme-form .ui-draggable .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group #buttondropdown, html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group #buttondropdown {
    margin-right: unset;
    margin-left: 15px;
  }
  html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group .input-group-btn button, html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group .input-group-btn button {
    border-radius: 4px !important;
  }
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .text-lg-left {
    text-align: right !important;
  }
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn,
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn-right,
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .checkbox-radius {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group #prependedcheckbox {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group #appendedcheckbox {
    border-right-color: #efefef;
  }
  html[dir=rtl] .form-builder .component .form-group .input-group #buttondropdown {
    margin-right: unset;
    margin-left: -1px;
  }
  html[dir=rtl] .form-builder .component .form-group .pl-md-radios {
    padding-right: 37px;
    padding-left: unset;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper {
    box-shadow: 9px 0 20px rgba(89, 102, 122, 0.1) !important;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper a {
    transform: translate(-64px);
  }
  html[dir=rtl] .search-page .nav i {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .product-stts {
    display: inline-block;
  }
  html[dir=rtl] .product-stts .ms-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .search-form input {
    padding: 10px 70px 10px 10px;
    text-align: right;
  }
  html[dir=rtl] .icon-hover-bottom .icon-title {
    text-align: right;
  }
  html[dir=rtl] .icon-hover-bottom .icon-popup .icon-last .form-inline .form-group .btn {
    margin-right: 15px;
    margin-left: unset;
  }
  html[dir=rtl] .icon-lists div i {
    margin: 0 10px 0 15px;
  }
  html[dir=rtl] .icon-lists ul {
    padding-right: 0;
  }
  html[dir=rtl] ul.icon-lists {
    padding-right: 30px;
  }
  html[dir=rtl] .floated-customizer-btn.third-floated-btn {
    left: 35px;
    right: unset;
  }
  html[dir=rtl] .floated-customizer-btn > span {
    margin-right: 10px;
  }
  html[dir=rtl] .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;
  }
  html[dir=rtl] .floated-customizer-panel.active {
    left: 35px;
    right: unset;
  }
  html[dir=rtl] .floated-customizer-panel .close-customizer-btn {
    left: 30px;
    right: unset;
  }
  html[dir=rtl] .floated-customizer-panel ul {
    padding-right: 0;
  }
  html[dir=rtl] .form-builder-2-header > div nav {
    float: left;
  }
  html[dir=rtl] .radio label {
    padding-left: 0;
    padding-right: 15px;
  }
  html[dir=rtl] .radio label::before {
    right: 0;
    margin-right: -20px;
    left: unset;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h3 {
    text-align: right;
    padding-left: 20px;
    padding-right: unset !important;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h6 {
    text-align: right;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
    margin-right: 280px;
    margin-left: unset;
    width: calc(100% - 280px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header.close_icon {
    margin-right: 100px;
    margin-left: unset;
    width: calc(100% - 100px);
  }
  html[dir=rtl] .page-wrapper.modern-type .page-header {
    margin-right: 0;
    width: calc(100% - 0px);
  }
  html[dir=rtl] .page-wrapper.modern-type .page-header.close_icon {
    margin-right: 0;
    width: calc(100% - 0px);
  }
  html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
    margin-right: unset;
    margin-left: 30px;
  }
  html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
    margin-right: unset;
    margin-left: 15px;
  }
  html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .page-body {
    margin-right: 310px;
  }
  html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .footer {
    margin-left: unset;
    margin-right: 310px;
  }
  html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
    margin-right: 0px;
  }
  html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
    margin-right: 0;
  }
  html[dir=rtl] .left-header .mega-menu .nav-link svg,
  html[dir=rtl] .left-header .level-menu .nav-link svg {
    margin-right: unset;
    margin-left: 5px;
  }
  html[dir=rtl] .left-header .level-menu .nav-link {
    margin-left: unset;
    margin-right: 10px;
  }
  html[dir=rtl] .left-header .level-menu .header-level-menu > li svg {
    margin-right: unset;
    margin-left: 5px;
  }
  html[dir=rtl] .left-header .level-menu .header-level-menu > li > .header-level-sub-menu {
    left: unset;
    right: 173px;
  }
  html[dir=rtl] .left-header .mega-menu-container {
    left: unset;
    right: -30px;
  }
  html[dir=rtl] .left-header .mega-menu-container .mega-box + .mega-box {
    padding-left: unset;
    padding-right: 30px;
    border-left: unset;
    border-right: 1px solid #efefef;
  }
  html[dir=rtl] .page-header .header-wrapper .left-header {
    text-align: right;
  }
  html[dir=rtl] .page-header .header-wrapper .left-header .mega-menu-container .mega-box .link-section li {
    padding: 7px 15px 7px 0;
  }
  html[dir=rtl] .page-header .header-wrapper .left-header .mega-menu-container .mega-box .link-section li:before {
    right: 0px;
    left: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .left-header .mega-menu-container .mega-box .svg-icon li {
    padding-right: 0;
  }
  html[dir=rtl] .page-header .header-wrapper .left-header .mega-menu-container .mega-box .svg-icon li a svg {
    margin-right: unset;
    margin-left: 5px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right {
    text-align: right;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right .onhover-show-div {
    right: unset;
    left: 0;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right .chat-dropdown li .media .status-circle {
    left: unset;
    right: 0px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
    right: unset;
    left: 0;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li {
    margin-right: unset;
    margin-left: 5px;
    text-align: right;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .lang-txt {
    margin-left: 0;
    margin-right: 8px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:before {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .dot {
    left: 17px;
    right: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:before {
    right: 10px;
    left: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:after {
    right: 10px;
    left: unset;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:last-child {
    padding-left: 0;
    padding-right: 20px;
    border-right: none;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
    padding-right: 20px;
    padding-left: 0;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li i.ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right .profile-dropdown li svg {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .page-header .header-wrapper .mobile-sidebar {
    padding-left: 20px;
    padding-right: unset;
  }
  html[dir=rtl] .page-header.open {
    margin-right: 0;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div {
    right: initial;
    left: -30px;
    padding: 0;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div:before {
    right: inherit !important;
    left: 35px !important;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div:after {
    right: inherit !important;
    left: 35px !important;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
    margin-right: 0 !important;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div li span.badge {
    margin-left: unset !important;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div li span svg {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] ul.notification-dropdown.onhover-show-div li .notification-icon {
    margin-left: 20px;
    margin-right: unset;
  }
  html[dir=rtl] .card-body .alert svg ~ p {
    padding-left: unset;
    padding-right: 20px;
  }
  html[dir=rtl] .alert i {
    margin-left: 5px;
    margin-right: 0;
  }
  html[dir=rtl] .inverse {
    padding: 13px 65px 13px 20px;
  }
  html[dir=rtl] .inverse i {
    left: unset;
    right: 0;
  }
  html[dir=rtl] .alert-primary.inverse:before {
    border-right: 7px solid #7366ff;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-secondary.inverse:before {
    border-right: 7px solid #f73164;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-success.inverse:before {
    border-right: 7px solid #51bb25;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-danger.inverse:before {
    border-right: 7px solid #dc3545;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-warning.inverse:before {
    border-right: 7px solid #f8d62b;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-info.inverse:before {
    border-right: 7px solid #a927f9;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-light.inverse:before {
    border-right: 7px solid #f4f4f4;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-dark.inverse:before {
    border-right: 7px solid #2c323f;
    right: 54px;
    left: unset;
    border-left: unset;
  }
  html[dir=rtl] .alert-dismissible .btn-close {
    left: 0;
    right: unset;
  }
  html[dir=rtl] .alert-dismissible .btn-close span {
    padding-left: 0;
    padding-right: unset;
  }
  html[dir=rtl] .icofont.icofont-truck {
    display: inline-block;
  }
  html[dir=rtl] .blog-box .blog-details {
    padding-left: 20px;
    text-align: right;
  }
  html[dir=rtl] .blog-box .blog-details .blog-social {
    padding-right: 0;
  }
  html[dir=rtl] .blog-box .blog-details .blog-social li {
    padding-left: 40px;
    padding-right: unset;
  }
  html[dir=rtl] .blog-box .blog-details .blog-social li:first-child {
    border-left: 1px solid #777777;
    border-right: unset;
    padding-left: 40px;
    padding-right: unset;
  }
  html[dir=rtl] .blog-box .blog-details .blog-social li + li {
    margin-right: 30px;
    margin-left: unset;
    padding-left: 0;
  }
  html[dir=rtl] .blog-box .blog-details-main .blog-social li {
    border-left: 1px dotted;
    border-right: unset;
  }
  html[dir=rtl] .blog-box .blog-details-main .blog-social li:last-child {
    border-left: none;
  }
  html[dir=rtl] .blog-box .blog-date span {
    font-size: 36px;
    font-weight: 500;
    padding-left: 5px;
  }
  html[dir=rtl] .comment-box .media img {
    margin-left: 45px;
    margin-right: unset;
  }
  html[dir=rtl] .comment-box .media h6 {
    text-align: right;
  }
  html[dir=rtl] .comment-box ul ul {
    margin-right: 135px;
    margin-left: unset;
    padding-right: 0;
  }
  html[dir=rtl] .comment-box .comment-social {
    text-align: left;
  }
  html[dir=rtl] .comment-box .comment-social li {
    padding-left: 0;
    padding-right: 20px;
  }
  html[dir=rtl] .comment-box .comment-social li:first-child {
    border-left: 1px solid #efefef;
    border-right: unset;
    padding-right: 0;
    padding-left: 20px;
  }
  html[dir=rtl] .button-builder-wrap .form-group .btn {
    margin-left: 15px;
    margin-right: 0;
    padding: 9px;
  }
  html[dir=rtl] .button-builder ul.the-icons {
    padding-right: 0 !important;
  }
  html[dir=rtl] .call-chat-body .chat-box .chat-right-aside .chat .chat-history .caller-img.ps-0 {
    padding-left: 15px !important;
  }
  html[dir=rtl] .chat-box .user-image {
    float: right;
    margin-left: 5px;
    margin-right: 0;
  }
  html[dir=rtl] .chat-box .people-list .search i {
    left: 10px;
    right: unset;
  }
  html[dir=rtl] .chat-box .about {
    float: right;
    padding-right: 10px;
    padding-left: unset;
    text-align: right;
  }
  html[dir=rtl] .chat-box .chat-menu {
    border-right: 1px solid #f4f4f4;
    border-left: unset;
  }
  html[dir=rtl] .chat-box .chat-menu .nav {
    padding-right: 0;
  }
  html[dir=rtl] .chat-box .chat-menu.ps-0 {
    padding-left: 15px !important;
  }
  html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .smiley-box {
    margin-left: 0.5rem;
    margin-right: unset;
  }
  html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    border-radius: 5px 0 0 5px;
  }
  html[dir=rtl] .chat-box .chat-right-aside.pe-0 {
    padding-right: 15px !important;
  }
  html[dir=rtl] .chat-box .chat .chat-message .text-box .input-group-append {
    margin-right: -2px;
  }
  html[dir=rtl] .chat-msg-box ul {
    padding-right: 0;
  }
  html[dir=rtl] .chat-left-aside .status-circle {
    left: unset;
    right: 40px;
  }
  html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons {
    padding-left: 0;
    padding-right: unset;
  }
  html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons.float-sm-right {
    float: left !important;
  }
  html[dir=rtl] .chat-right-aside .chat .chat-header img {
    float: right;
  }
  html[dir=rtl] .cke_toolbar, html[dir=rtl] .cke_toolgroup, html[dir=rtl] a.cke_button, html[dir=rtl] .cke_combo_text {
    float: right;
  }
  html[dir=rtl] .cke_reset_all, html[dir=rtl] .cke_reset_all *, html[dir=rtl] .cke_reset_all a, html[dir=rtl] .cke_reset_all textarea {
    text-align: right;
  }
  html[dir=rtl] .cke_combo_text {
    padding-left: unset;
    padding-right: 10px;
  }
  html[dir=rtl] #editor1 #cke_editor1 #cke_1_contents iframe html {
    direction: rtl !important;
  }
  html[dir=rtl] #clockdiv ul {
    padding-right: 0;
  }
  html[dir=rtl] .custom-card .card-footer > div + div {
    border-right: 1px solid #efefef;
    border-left: unset !important;
  }
  html[dir=rtl] .card-social {
    padding-right: 0;
  }
  html[dir=rtl] .card-absolute .card-header {
    right: 15px;
    left: unset;
  }
  html[dir=rtl] .dataTables_wrapper .dataTables_filter input[type=search] {
    margin-right: 10px;
    margin-left: 0;
  }
  html[dir=rtl] .dataTables_wrapper .dataTables_length {
    float: right;
  }
  html[dir=rtl] .dataTables_wrapper .dataTables_filter {
    margin-left: unset;
    margin-right: 15px;
    float: left;
  }
  html[dir=rtl] .dataTables_wrapper .dataTables_info {
    float: right;
  }
  html[dir=rtl] .dataTables_wrapper .advance-5_filter {
    margin-left: 0;
  }
  html[dir=rtl] .dataTables_wrapper .btn-group button {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .dataTables_wrapper .btn-group button:last-child {
    margin-left: 0;
  }
  html[dir=rtl] .dataTables_wrapper table.dataTable tbody td.select-checkbox:after, html[dir=rtl] .dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
    margin-left: 31px;
  }
  html[dir=rtl] div.dataTables_wrapper div.dataTables_paginate {
    margin-right: 15px !important;
    margin-left: 0 !important;
    float: left;
  }
  html[dir=rtl] table.dataTable thead > tr > th {
    padding-left: 30px;
    padding-right: 12px;
  }
  html[dir=rtl] table.dataTable thead > tr > th:before {
    left: 1em !important;
    right: unset;
  }
  html[dir=rtl] table.dataTable thead > tr > th:after {
    left: 0.5em !important;
    right: unset;
  }
  html[dir=rtl] table.dataTable thead .sorting:before, html[dir=rtl] table.dataTable thead .sorting_asc:before, html[dir=rtl] table.dataTable thead .sorting_desc:before, html[dir=rtl] table.dataTable thead .sorting_asc_disabled:before, html[dir=rtl] table.dataTable thead .sorting_desc_disabled:before {
    left: 1em !important;
    right: unset;
  }
  html[dir=rtl] .dataTables_scrollHeadInner {
    padding-right: unset !important;
    padding-left: 0 !important;
  }
  html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
    padding-right: 0;
    padding-left: 0;
  }
  html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
    padding-right: 0;
    padding-left: 0;
  }
  html[dir=rtl] .dt-plugin-buttons button.m-r-10 {
    margin-left: 10px;
    margin-right: 0;
  }
  html[dir=rtl] .datetime-picker .theme-form .form-group label {
    text-align: left !important;
  }
  html[dir=rtl] .datetime-picker .theme-form .form-group .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .datetime-picker .theme-form .form-group .input-group .form-control {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  html[dir=rtl] .dropdown-basic .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  html[dir=rtl] .dropdown-basic .separated-btn .btn {
    border-radius: 0.25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  html[dir=rtl] .dropdown-basic .btn-group {
    margin-right: unset;
    margin-left: 18px;
  }
  html[dir=rtl] .error-wrapper .col-md-8.offset-md-2 {
    margin: 0 auto !important;
  }
  html[dir=rtl] .product-price del {
    padding-left: 20px;
    padding-right: 0;
  }
  html[dir=rtl] .product-box .modal .modal-header .btn-close {
    right: unset;
    left: 15px;
  }
  html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before, html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-left-color: #007bff;
  }
  html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left:before, html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right:before {
    right: 0;
    left: unset;
    border-left: 15px solid #2a3142;
  }
  html[dir=rtl] .product-box .ribbon-bookmark.ribbon-info:before {
    border-left-color: transparent;
  }
  html[dir=rtl] .product-box .ribbon-warning.ribbon-clip:before {
    border-left-color: #ff850d;
    border-right-color: unset;
  }
  html[dir=rtl] .product-box .ribbon-clip {
    right: -14px;
    left: unset;
  }
  html[dir=rtl] .product-box .ribbon-clip:before {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .product-hover ul {
    padding-right: 0;
  }
  html[dir=rtl] .customer-review .media img {
    margin-left: 25px;
    margin-right: 0;
  }
  html[dir=rtl] .nav {
    padding-right: 0;
  }
  html[dir=rtl] .custom-file-label::after {
    left: 0;
    right: unset;
  }
  html[dir=rtl] .wizard-4 ul.anchor {
    float: right;
    padding-right: 0;
    padding-left: 30px;
  }
  html[dir=rtl] .wizard-4 .step-container {
    clear: unset;
  }
  html[dir=rtl] .wizard-4 .form-group {
    text-align: right;
  }
  html[dir=rtl] .wizard-4 .action-bar .btn {
    float: left;
  }
  html[dir=rtl] .f1 .f1-buttons {
    text-align: left;
  }
  html[dir=rtl] .f1 .f1-steps .f1-step {
    float: right;
  }
  html[dir=rtl] .static-top-widget .media-body .icon-bg {
    left: -20px;
    right: unset;
  }
  html[dir=rtl] .widget-joins .media .details {
    border-right: 1px solid #efefef;
    border-left: unset;
  }
  html[dir=rtl] .widget-joins .media .media-body {
    text-align: right;
  }
  html[dir=rtl] .weather-widget-two .bottom-whetherinfo .whether-content {
    text-align: left;
  }
  html[dir=rtl] .jstree-default .jstree-node {
    margin-left: unset;
    margin-right: 24px;
    background-position: -292px -4px;
    background-repeat: repeat-y;
  }
  html[dir=rtl] .img-cropper .btn-group {
    margin-right: 0;
    margin-left: 10px;
  }
  html[dir=rtl] .img-cropper .btn-group button:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  html[dir=rtl] .img-cropper .btn-group button:first-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  html[dir=rtl] .docs-toggles .btn-group .btn {
    border-radius: 0;
  }
  html[dir=rtl] .docs-toggles .btn-group .btn:last-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  html[dir=rtl] .docs-toggles .btn-group .btn:first-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle) > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .jsgrid-button + .jsgrid-button {
    margin-right: 5px;
    margin-left: unset;
  }
  html[dir=rtl] .list-group {
    padding-right: 0;
  }
  html[dir=rtl] .list-group i, html[dir=rtl] .list-group img {
    margin-left: 10px;
    margin-right: 0;
  }
  html[dir=rtl] .authentication-box {
    text-align: right;
  }
  html[dir=rtl] .mega-horizontal .offset-sm-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .navs-icon svg {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .navs-icon .fa {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .nav-list .nav-list-disc {
    text-align: right;
    padding-right: 0;
  }
  html[dir=rtl] .pl-navs-inline {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }
  html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }
  html[dir=rtl] .navs-dropdown .onhover-show-div .navs-icon li {
    text-align: right;
  }
  html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
    left: 2px;
    right: unset;
  }
  html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
    left: 2px;
    right: unset;
  }
  html[dir=rtl] .search-form .form-group:after {
    right: 53px;
    left: unset;
  }
  html[dir=rtl] .search-form .form-group:before {
    right: 82px;
    left: unset;
  }
  html[dir=rtl] .order-history table tbody tr td .product-name .order-process .order-process-circle:before {
    right: -15px;
    left: unset;
  }
  html[dir=rtl] .order-history table th:first-child, html[dir=rtl] .order-history table td:first-child {
    text-align: right;
  }
  html[dir=rtl] .product-page-details span {
    padding-right: 15px;
    padding-left: 0;
  }
  html[dir=rtl] .m-checkbox-inline label {
    margin-left: 20px;
    margin-right: 0;
  }
  html[dir=rtl] .form-label-align-right label {
    text-align: left;
  }
  html[dir=rtl] .br-theme-bars-reversed .br-widget .br-current-rating, html[dir=rtl] .br-theme-bars-movie .br-widget .br-current-rating, html[dir=rtl] .br-theme-bars-1to10 .br-widget .br-current-rating, html[dir=rtl] .br-theme-bars-square .br-widget .br-current-rating {
    float: right;
  }
  html[dir=rtl] .br-theme-bars-reversed .br-widget a, html[dir=rtl] .br-theme-bars-movie .br-widget a, html[dir=rtl] .br-theme-bars-1to10 .br-widget a, html[dir=rtl] .br-theme-bars-square .br-widget a {
    float: right;
  }
  html[dir=rtl] .ribbon-left {
    right: auto;
    left: -2px;
  }
  html[dir=rtl] .ribbon-vertical-left {
    right: auto;
    left: 12px;
  }
  html[dir=rtl] .ribbon-vertical-right {
    right: auto;
    left: 12px;
  }
  html[dir=rtl] .star-ratings ul.search-info {
    padding-right: 0;
  }
  html[dir=rtl] .star-ratings ul.search-info li + li {
    border-right: 1px solid #a5afc6;
    border-left: unset;
    padding-right: 8px;
    padding-left: 0;
    margin-right: 8px;
    margin-left: 0;
  }
  html[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 10px !important;
    right: unset !important;
  }
  html[dir=rtl] .select2-container--default .select2-selection--multiple .select2-selection__choice {
    float: right;
  }
  html[dir=rtl] .selection .select2-selection .select2-search__field {
    text-align: right;
  }
  html[dir=rtl] .select2-results__option {
    text-align: right;
  }
  html[dir=rtl] .editor-statusbar {
    text-align: left;
  }
  html[dir=rtl] .editor-statusbar span {
    margin-right: 1em;
    margin-left: unset;
  }
  html[dir=rtl] .CodeMirror-scroll {
    margin-left: -30px;
    margin-right: unset;
  }
  html[dir=rtl] .primary-color ul, html[dir=rtl] .yellow-color ul {
    padding-right: 0;
  }
  html[dir=rtl] .u-step-desc {
    text-align: right;
  }
  html[dir=rtl] .u-step-number {
    right: 20px;
    left: unset;
  }
  html[dir=rtl] .u-step-number ~ .u-step-desc {
    margin-right: 50px;
  }
  html[dir=rtl] .u-pearl:after {
    left: 0;
    right: unset;
  }
  html[dir=rtl] .u-pearl:before {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .u-step-icon {
    float: right;
    margin-left: 0.5em;
    margin-right: unset;
  }
  html[dir=rtl] .list-icons {
    padding-right: 0;
  }
  html[dir=rtl] .card-header.note-toolbar .note-color .dropdown-toggle {
    padding-right: 5px;
  }
  html[dir=rtl] .card-header.note-toolbar .form-group.draggable {
    text-align: right;
  }
  html[dir=rtl] .card-header.note-toolbar .form-check {
    padding-right: 24px;
    padding-left: unset;
  }
  html[dir=rtl] .card-header.note-toolbar form .custom-control {
    padding-right: 24px;
    padding-left: unset;
  }
  html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::after {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::before {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }
  html[dir=rtl] .tabbed-card ul {
    left: 22px;
    right: unset;
  }
  html[dir=rtl] .checkbox label {
    padding-right: 16px;
    padding-left: unset;
  }
  html[dir=rtl] .checkbox label::before {
    right: 0;
    left: unset;
    margin-right: -16px;
    margin-left: unset;
  }
  html[dir=rtl] .radio label::after {
    right: 5px;
    left: unset;
    margin-right: -20px;
    margin-left: unset;
  }
  html[dir=rtl] .cd-timeline-content::before {
    border-right: 7px solid #f4f4f4;
    border-left-color: transparent;
  }
  html[dir=rtl] .cd-timeline-img i {
    right: 40%;
    left: unset;
    margin-right: -12px;
    margin-left: unset;
  }
  html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: left;
    border-left: 7px solid #eeeeee;
    border-right: unset;
  }
  html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    right: auto;
    left: 122%;
    text-align: left;
  }
  html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    right: auto;
    left: 100%;
    border-left-color: #eeeeee;
    border-right-color: transparent;
  }
  html[dir=rtl] .cd-timeline-content .cd-date {
    right: 122%;
    left: unset;
  }
  html[dir=rtl] .introjs-tooltip {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .introjs-helperNumberLayer {
    right: -16px;
    left: unset;
  }
  html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
    text-align: right;
  }
  html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
    text-align: left;
  }
  html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-skipbutton {
    margin-left: 5px;
    margin-right: unset;
  }
  html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton {
    -webkit-border-radius: 0.2em 0 0 0.2em;
    -moz-border-radius: 0.2em 0 0 0.2em;
    border-radius: 0.2em 0 0 0.2em;
  }
  html[dir=rtl] .list-inline-item:not(:last-child) {
    margin-left: 0.5rem;
    margin-right: unset;
  }
  html[dir=rtl] .like-comment ul.list-inline {
    padding-right: 0;
  }
  html[dir=rtl] .typeahead {
    text-align: right;
  }
  html[dir=rtl] .listing ul {
    padding-right: 0;
  }
  html[dir=rtl] .listing ul .icofont {
    float: right;
  }
  html[dir=rtl] .gallery .hover-5 img {
    margin-right: 30px;
  }
  html[dir=rtl] .gallery .hover-5:hover img {
    margin-right: 0;
  }
  html[dir=rtl] .lg-outer {
    text-align: right;
  }
  html[dir=rtl] .lg-toolbar .lg-icon {
    float: left;
  }
  html[dir=rtl] #lg-counter {
    padding-right: 20px;
    padding-left: unset;
    float: right;
  }
  html[dir=rtl] .chart-widget-top .text-end {
    text-align: left !important;
  }
  html[dir=rtl] .chart-widget-top .num .ms-1 {
    margin-right: 0.25rem !important;
  }
  html[dir=rtl] .bar-chart-widget .earning-details i {
    left: -240px;
  }
  html[dir=rtl] .bar-chart-widget .bottom-content .b-r-light {
    border-left: 1px solid #eeeeee !important;
    border-right: unset !important;
  }
  html[dir=rtl] .bar-chart-widget .bottom-content .num .ms-1 {
    margin-right: 0.25rem !important;
  }
  html[dir=rtl] .chart-vertical-center #myDoughnutGraph, html[dir=rtl] .chart-vertical-center #myPolarGraph {
    width: auto !important;
  }
  html[dir=rtl] .crm-activity ul.dates li + li {
    border-right: 1px solid #ddd;
    padding-right: 10px;
    margin-right: 5px;
    border-left: unset;
    padding-left: unset;
    margin-left: unset;
  }
  html[dir=rtl] div.dt-buttons {
    float: right;
  }
  html[dir=rtl] .dt-button-collection {
    left: -39px !important;
  }
  html[dir=rtl] .dropzone .dz-preview .dz-error-mark,
  html[dir=rtl] .dropzone .dz-preview .dz-success-mark {
    right: 50%;
    margin-right: -27px;
    margin-left: unset;
    left: unset;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper footer {
    margin-right: 280px;
    margin-left: unset;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper footer p i {
    margin-right: 5px;
    margin-left: unset;
  }
  html[dir=rtl] .footer-copyright {
    text-align: right;
  }
  html[dir=rtl] .footer-links {
    text-align: left;
  }
  html[dir=rtl] .page-item:first-child .page-link {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  html[dir=rtl] .page-item:last-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .popover.top > .arrow:after, html[dir=rtl] .popover.bottom > .arrow:after {
    margin-right: -10px;
    margin-left: unset;
  }
  html[dir=rtl] .bootstrap-touchspin > .input-group-append > .btn,
  html[dir=rtl] .bootstrap-touchspin > .input-group-append > .input-group-text {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  html[dir=rtl] .bootstrap-touchspin > .form-control:not(:last-child), html[dir=rtl] .bootstrap-touchspin > .custom-select:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  html[dir=rtl] .typeahead span.twitter-typeahead .tt-suggestion {
    text-align: right;
  }
  html[dir=rtl] .typeahead .league-name {
    text-align: right;
    margin-right: 5px;
  }
  html[dir=rtl] .follow .text-md-right {
    text-align: left !important;
  }
  html[dir=rtl] .jvectormap-zoomin,
  html[dir=rtl] .jvectormap-zoomout,
  html[dir=rtl] .jvectormap-goback {
    right: 10px;
    left: unset;
  }
  html[dir=rtl] .customizer-links {
    right: unset;
    left: 0;
  }
  html[dir=rtl] .customizer-links > .nav {
    padding-right: 10px;
  }
  html[dir=rtl] .customizer-links .nav-link:after {
    right: -10px;
    left: unset;
    border-width: 5px 5px 5px 0;
    border-left-color: unset;
    border-right-color: #e8e6ff;
  }
  html[dir=rtl] .customizer-links .nav-link span {
    right: unset;
    left: -60px;
  }
  html[dir=rtl] .customizer-links .nav-link:hover span {
    left: 48px;
  }
  html[dir=rtl] .customizer-links.open {
    right: unset;
    left: 330px;
    border-radius: 0 8px 8px 0;
  }
  html[dir=rtl] .customizer-contain {
    width: 330px;
    right: unset;
    left: -330px;
  }
  html[dir=rtl] .customizer-contain.open {
    right: unset;
    left: 0px;
  }
  html[dir=rtl] .customizer-contain .customizer-header .icon-close {
    left: unset;
    right: 30px;
  }
  html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header {
    padding-right: 250px;
    padding-left: unset;
  }
  html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header.open {
    padding-right: 0;
  }
  html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: unset;
    margin-right: 230px;
  }
  html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    width: 1280px;
    padding-left: 0;
    left: 50%;
  }
  html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header {
    margin-left: auto;
    margin-right: auto;
    padding-left: unset;
    padding-right: 230px;
  }
  html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header.close_icon {
    padding-right: 100px;
  }
  html[dir=rtl] body.dark-only .earning-card.card .card-body .earning-content {
    border-left: 1px solid #374558;
  }
  html[dir=rtl] body.dark-only .chart_data_left .card-body .chart-main .media,
  html[dir=rtl] body.dark-only .chart_data_right .card-body .chart-main .media {
    border-left: 1px solid #374558;
  }
  html[dir=rtl] body.dark-only .widget-joins .media .details {
    border-left: unset !important;
    border-right: 1px solid #374558;
  }
  html[dir=rtl] body.dark-only .custom-card .card-footer > div + div {
    border-right: 1px solid #374558;
  }
  html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light,
  html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
    border-right: none !important;
    border-left: 1px solid #374558 !important;
  }
  @media screen and (max-width: 1660px) {
    html[dir=rtl] .caller-img {
      left: unset;
      right: 15px;
    }
  }
  @media only screen and (max-width: 1366px) {
    html[dir=rtl] .profile-dropdown.onhover-show-div {
      right: unset !important;
      left: 0 !important;
    }
    html[dir=rtl] .product-wrapper .product-grid .feature-products {
      margin-right: 0;
    }
    html[dir=rtl] .product-grid {
      margin-right: 0;
    }
    html[dir=rtl] .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }
    html[dir=rtl] .product-sidebar {
      transform: translateX(300px) scaleX(0);
    }
    html[dir=rtl] .photos ul li:nth-child(3n) {
      margin-left: 15px;
      margin-right: unset;
    }
    html[dir=rtl] .chat-left-aside .status-circle {
      left: unset;
      right: 22px;
    }
    html[dir=rtl] .earning-card .card-body > .row > div .chart-left {
      padding: 40px;
    }
    html[dir=rtl] .earning-card .card-body > .row > div .chart-left .left-btn {
      text-align: left;
    }
    html[dir=rtl] .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-right: 4px;
    }
    html[dir=rtl] .best-seller-table table tbody tr td .fa-check-circle {
      right: 2px;
    }
    html[dir=rtl] .offer-box .offer-slider .selling-slide .d-flex .left-content {
      padding-left: unset;
      padding-right: 15px;
    }
  }
  @media (min-width: 1200px) {
    html[dir=rtl] .chat-box .pe-xl-0 {
      padding-left: 0 !important;
      padding-right: 15px !important;
    }
    html[dir=rtl] .chat-box .ps-xl-0 {
      padding-right: 0 !important;
      padding-left: 15px !important;
    }
    html[dir=rtl] .pixelstrap ul a:hover .sub-arrow, html[dir=rtl] .pixelstrap ul a:focus .sub-arrow, html[dir=rtl] .pixelstrap ul a:active .sub-arrow, html[dir=rtl] .pixelstrap ul a.highlighted .sub-arrow {
      left: 3px;
      right: unset;
    }
  }
  @media (min-width: 992px) {
    html[dir=rtl] .text-lg-start {
      text-align: right !important;
    }
  }
  @media only screen and (max-width: 1199.98px) {
    html[dir=rtl] .jkanban-container .btn {
      margin-left: unset;
      margin-right: 20px;
    }
    html[dir=rtl] .link-section > div > h6:before {
      right: unset;
      left: 10px;
    }
    html[dir=rtl] .left-header .mega-menu-container {
      left: -300px !important;
      right: unset !important;
    }
    html[dir=rtl] .left-header .mega-menu-container.d-block {
      left: 0 !important;
      right: unset !important;
      animation: fadeInLeft 0.3s ease-in-out;
    }
    html[dir=rtl] .left-header .mega-menu-container .mega-box + .mega-box {
      padding-right: 15px;
      border-right: 0;
    }
    html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li:first-child {
      padding-left: 10px !important;
    }
    html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li .submenu li:first-child {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
      left: 45px;
    }
    html[dir=rtl] .email-wrap .row .col-xl-6 {
      padding-right: 15px;
    }
    html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
      padding-left: 15px;
    }
    html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
      text-align: right;
    }
    html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
      margin-right: unset;
      margin-left: 10px;
    }
    html[dir=rtl] .email-wrap .email-app-sidebar ul {
      padding-right: 0;
    }
    html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
      float: left;
    }
    html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
      padding-right: unset !important;
    }
    html[dir=rtl] .email-wrap .email-right-aside .radius-left {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-radius: 4px;
    }
    html[dir=rtl] .chat-menu-icons {
      text-align: left;
    }
    html[dir=rtl] .chat-box .chat-history .row .col-sm-7 {
      padding-left: 0 !important;
      padding-right: unset !important;
    }
    html[dir=rtl] .chat-menu {
      left: 0;
      right: unset;
    }
    html[dir=rtl] .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(3) {
      text-align: left;
    }
    html[dir=rtl] .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-right: 30px;
    }
    html[dir=rtl] .best-seller-table table tbody tr td .fa-check-circle {
      right: 18px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
      left: 20px;
    }
    html[dir=rtl] .left-header .mega-menu .nav-link svg,
  html[dir=rtl] .left-header .level-menu .nav-link svg {
      margin-left: 0;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
      transform: translate(285px);
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
      transform: translate(285px);
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {
      float: left;
      transform: rotate(180deg);
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
      margin-right: 0;
      border-right: none;
      padding-right: 0;
      border-left: 1px solid #ddd;
      margin-left: 15px;
      padding-left: 15px;
    }
    html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
      margin-right: 0;
    }
    html[dir=rtl] .page-wrapper.material-icon .page-header {
      padding-right: 0;
    }
    html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
      padding-right: 15px;
    }
    html[dir=rtl] .faq-accordion .faq-header svg {
      left: 20px;
    }
    html[dir=rtl] .categories .learning-header {
      padding-right: 20px;
    }
    html[dir=rtl] .page-wrapper .page-header .header-logo-wrapper .logo-wrapper img {
      margin-left: 15px;
      margin-right: unset;
    }
    html[dir=rtl] .page-body-wrapper .page-body, html[dir=rtl] .page-body-wrapper footer {
      margin-right: 0 !important;
    }
    html[dir=rtl] .main .langChoice {
      left: 20px;
    }
    html[dir=rtl] .cd-timeline-img i {
      right: 50% !important;
    }
    html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
      margin-right: 0;
    }
    html[dir=rtl] .page-wrapper .page-body-wrapper .page-body {
      min-height: calc(100vh - 60px);
    }
    html[dir=rtl] .page-wrapper .page-header {
      margin-right: 0;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
      margin-right: 0 !important;
      margin-left: unset !important;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-wrapper.close_icon {
      transform: translate(285px);
    }
    html[dir=rtl] .card-body .value-left {
      margin-left: 20px;
    }
    html[dir=rtl] .bookmark ul li .search-form .form-control-search {
      left: -127px;
    }
    html[dir=rtl] .card .card-header .card-header-right {
      left: 15px;
    }
    html[dir=rtl] .earning-card.card .card-body > .row > div .chart-left {
      padding: 20px;
    }
    html[dir=rtl] .chart_data_left .card-body .chart-main .media .media-body .right-chart-content {
      margin-right: 20px;
    }
    html[dir=rtl] .activity-timeline .media .activity-line {
      right: 26px;
    }
    html[dir=rtl] .offer-box .offer-slider .selling-slide .d-flex .left-content {
      padding-right: 0;
    }
    html[dir=rtl] .offer-box .offer-slider .carousel-control-prev-icon, html[dir=rtl] .offer-box .offer-slider .carousel-control-next-icon {
      right: unset;
      left: 20px;
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    html[dir=rtl] .page-wrapper.compact-wrapper.material-type .page-body-wrapper div.sidebar-wrapper {
      left: unset;
      right: 0;
    }
  }
  @media (min-width: 768px) {
    html[dir=rtl] .float-md-end {
      float: left !important;
    }
    html[dir=rtl] .text-md-end {
      text-align: left !important;
    }
    html[dir=rtl] .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    html[dir=rtl] .text-md-start {
      text-align: right !important;
    }
    html[dir=rtl] .ecommerce-widget .text-md-end {
      text-align: left !important;
    }
    html[dir=rtl] .ecommerce-widget .text-md-end ul {
      text-align: left;
    }
  }
  @media only screen and (max-width: 767.98px) {
    html[dir=rtl] .list-persons .profile-mail .email-general ul {
      padding-left: 0;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .left-header .mega-menu {
      right: 80px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link {
      border: none;
    }
    html[dir=rtl] .left-header .mega-menu .nav-link.active {
      color: #000;
    }
    html[dir=rtl] .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
      margin-right: 0;
    }
    html[dir=rtl] .feature-products .filter-toggle {
      margin-right: 10px;
      margin-left: unset;
    }
    html[dir=rtl] .page-wrapper .nav-right .nav-menus {
      margin-right: unset;
      margin-left: 57px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .nav-right .nav-menus {
      margin-right: unset;
      margin-left: 25px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .left-header .mega-menu, html[dir=rtl] .page-wrapper.compact-sidebar .left-header .mega-menu {
      right: unset;
      left: 30px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link, html[dir=rtl] .page-wrapper.compact-sidebar .left-header .mega-menu .nav-link {
      padding-right: 16px;
      padding-left: 0;
      border: 0;
    }
    html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
      left: 25px !important;
    }
    html[dir=rtl] .ecommerce-widget .text-md-right {
      text-align: left !important;
    }
    html[dir=rtl] .bar-chart-widget .earning-details i {
      left: -147px !important;
    }
    html[dir=rtl] .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row ul li + li {
      margin-left: unset;
      margin-right: 25px;
    }
    html[dir=rtl] .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row .inner-top-right ul li {
      margin-left: unset;
      margin-right: 30px;
    }
  }
  @media (min-width: 576px) {
    html[dir=rtl] .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    html[dir=rtl] .text-sm-end {
      text-align: left !important;
    }
    html[dir=rtl] .float-sm-end {
      float: left !important;
    }
    html[dir=rtl] .me-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }
  @media only screen and (max-width: 575.98px) {
    html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
      left: 15px;
    }
    html[dir=rtl] .list-persons .nav-pills .nav-link .media .media-body {
      text-align: right;
    }
    html[dir=rtl] .mega-menu {
      padding-left: unset;
      padding-right: 15px;
    }
    html[dir=rtl] .left-header .level-menu .nav-link {
      margin: 0;
    }
    html[dir=rtl] .page-wrapper .nav-right .nav-menus {
      margin-left: 45px;
    }
    html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li {
      margin-right: unset;
      margin-left: 1px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, html[dir=rtl] .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
      margin-left: 8px;
      padding-left: 8px;
      margin-right: unset;
      padding-right: unset;
      border-right: unset;
      border-left: 1px solid #ddd;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .left-header .mega-menu, html[dir=rtl] .page-wrapper.compact-sidebar .left-header .mega-menu {
      left: 15px;
    }
    html[dir=rtl] .page-wrapper.compact-wrapper .nav-right .nav-menus {
      margin-left: 30px;
    }
    html[dir=rtl] .translate_wrapper.active .more_lang.active {
      left: unset;
      right: 15px;
    }
    html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
      padding-right: 10px;
    }
    html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul.profile-dropdown, html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul.chat-dropdown, html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul.notification-dropdown {
      left: unset !important;
      right: 15px !important;
    }
    html[dir=rtl] .faq-accordion .faq-header svg {
      left: 15px;
    }
    html[dir=rtl] .categories .learning-header {
      padding-right: 15px;
    }
    html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
      left: 15px !important;
    }
    html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
      margin-left: 0px;
    }
    html[dir=rtl] .main .langChoice {
      left: 15px;
    }
    html[dir=rtl] .page-wrapper .search-form .form-group {
      margin-left: 0;
    }
    html[dir=rtl] .card-body .value-left {
      margin-left: 15px;
    }
    html[dir=rtl] .earning-card .card-body > .row > div .chart-left .left_side_earning:nth-child(3) {
      text-align: right;
    }
    html[dir=rtl] .earning-card .card-body > .row > div .chart-left .left-btn {
      text-align: right;
    }
    html[dir=rtl] .dashboard-sec .earning-card .card-body > .row > div .chart-right > .row ul li + li {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 480px) {
    html[dir=rtl] .custom-profile .card-social li + li {
      margin-right: 6px;
    }
    html[dir=rtl] .left-header .level-menu .header-level-menu > li > .header-level-sub-menu {
      right: 140px;
    }
    html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
      right: unset;
      left: 20px;
    }
  }
  @media only screen and (max-width: 420px) {
    html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
      right: 112px;
    }
    html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
      right: 80px;
    }
  }
  @media only screen and (max-width: 360px) {
    html[dir=rtl] .prooduct-details-box .close {
      left: 0;
    }
    html[dir=rtl] .social-app-profile .hovercard .user-image .avatar {
      margin-top: -47px;
    }
    html[dir=rtl] .social-app-profile .hovercard .user-image .avatar img {
      width: 85px;
      height: 85px;
    }
    html[dir=rtl] .social-chat .other-msg {
      margin-right: 15px;
    }
  }
  @media screen and (max-width: 1460px) {
    html[dir=rtl] .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
      margin-left: 8px;
      margin-right: unset;
    }
  }
  
  /**=====================
      60. RTL CSS Ends
  ==========================**/
  /**=====================
      63. Box-layout CSS start
  ==========================**/
  .box-layout {
    // background-image: url(../images/other-images/boxbg.jpg);
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media only screen and (min-width: 1280px) {
    .box-layout .apache-cotainer-large > div,
  .box-layout .apache-cotainer-large canvas,
  .box-layout #line-visual > div,
  .box-layout #line-visual canvas,
  .box-layout #line-visulH > div,
  .box-layout #line-visulH canvas {
      width: 100% !important;
    }
    .box-layout .left-header .mega-menu-container {
      width: calc(100vw - 870px);
      left: -70px;
      min-width: 1000px;
    }
    .box-layout .page-wrapper.box-layout, .box-layout.page-wrapper.box-layout {
      padding-top: 40px;
      margin-top: 0 !important;
    }
    .box-layout .page-wrapper.compact-wrapper, .box-layout.page-wrapper.compact-wrapper {
      margin-top: 40px;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
      width: 230px;
      top: 40px;
      border-radius: 10px 0 0 10px;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
      width: 1200px;
      left: 47%;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix {
      padding-left: 0;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
      height: calc(100vh - 196px);
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper {
      box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
      margin-top: 10px;
      margin-bottom: 20px;
      height: calc(100vh - 191px);
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
      min-height: calc(100vh - 130px);
      margin-top: 0;
      top: 80px;
      overflow: scroll;
      max-height: calc(100vh - 220px);
      padding-bottom: 80px;
      margin-left: 230px;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #ecf3fa;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
      width: 6px;
    }
    .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
      background-color: rgba(68, 102, 242, 0.15);
    }
    .box-layout .page-wrapper.compact-wrapper .page-header .header-wrapper .left-header, .box-layout.page-wrapper.compact-wrapper .page-header .header-wrapper .left-header {
      display: inline-block;
    }
    .box-layout .page-wrapper.compact-sidebar .page-header, .box-layout.page-wrapper.compact-sidebar .page-header {
      padding-left: 148px;
    }
    .box-layout .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header, .box-layout.page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
      flex: 0 0 30%;
      max-width: 30%;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper {
      margin-top: 40px;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
      top: 40px;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
      display: none;
      transition: 0.5s;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
      height: calc(100vh - 166px);
      margin: 0;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
      left: 470px;
      top: 40px;
      height: calc(100vh - 84px);
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
      animation: unset;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .footer, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .footer {
      margin-left: 0;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
      min-height: calc(100vh - 90px);
      margin-top: 0;
      top: 80px;
      overflow: scroll;
      max-height: calc(100vh - 170px);
      padding-bottom: 80px;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #ecf3fa;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar {
      width: 6px;
    }
    .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
      background-color: rgba(68, 102, 242, 0.15);
    }
    .box-layout .page-wrapper.compact-sidebar.compact-small .page-header, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header {
      padding-left: 90px;
    }
    .box-layout .page-wrapper.compact-sidebar.compact-small .page-header.close_icon, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header.close_icon {
      padding-left: 0;
    }
    .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
      left: 410px;
    }
    .box-layout .page-wrapper.horizontal-wrapper:before, .box-layout.page-wrapper.horizontal-wrapper:before {
      display: none;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper {
      margin-top: 40px;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
      margin-top: 80px;
      min-height: calc(100vh - 310px);
      max-height: calc(100vh - 230px);
      overflow: scroll;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #ecf3fa;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
      width: 6px;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
      background-color: rgba(68, 102, 242, 0.15);
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .footer, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .footer {
      padding-left: 15px;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
      width: 1280px;
      top: 80px;
      margin: 0;
      overflow-x: unset;
      position: relative;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu {
      left: 0%;
      transform: translate(0, 0);
      top: 125px;
      background-color: #fff;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-header, .box-layout.page-wrapper.horizontal-wrapper .page-header {
      padding-left: 0;
    }
    .box-layout .page-wrapper.modern-type .page-header, .box-layout.page-wrapper.modern-type .page-header {
      padding-left: 0px;
    }
    .box-layout .page-wrapper.modern-type .page-header .header-wrapper .left-header, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .left-header {
      flex: 0 0 26%;
      max-width: 26%;
    }
    .box-layout .page-wrapper.modern-type .page-header .header-wrapper .nav-right, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .nav-right {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .page-body, .box-layout.page-wrapper.modern-type .page-body-wrapper .page-body {
      margin-left: 260px;
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper {
      top: 120px;
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
      height: calc(100vh - 311px);
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
      height: calc(100vh - 316px);
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
      width: 1280px;
      left: 50%;
      margin-left: 0;
    }
    .box-layout .page-wrapper.modern-type .page-body-wrapper .footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .footer {
      margin-left: 0;
    }
    .box-layout .page-wrapper .page-header, .box-layout.page-wrapper .page-header {
      max-width: 1280px;
      margin: 0 auto;
      left: 0;
      right: 0;
      transition: 0.5s;
      padding-left: 230px;
      margin-top: 40px;
      border-radius: 10px;
      width: unset;
    }
    .box-layout .page-wrapper .page-header.close_icon, .box-layout.page-wrapper .page-header.close_icon {
      padding-left: 0;
      margin-left: auto;
      width: calc(100% - 0px);
      transition: 0.5s;
    }
    .box-layout .page-wrapper .page-header .header-wrapper, .box-layout.page-wrapper .page-header .header-wrapper {
      border: 1px solid #f8f8f8;
      border-radius: 10px 10px 0 0;
    }
    .box-layout .page-wrapper .page-header .header-wrapper .left-header, .box-layout.page-wrapper .page-header .header-wrapper .left-header {
      flex: 0 0 33%;
      max-width: 33%;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header, .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
      flex: 0 0 24%;
      max-width: 24%;
    }
    .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right, .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
      flex: 0 0 63%;
      max-width: 63%;
    }
    .box-layout .page-wrapper.material-type .page-body-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper {
      margin-top: 0;
    }
    .box-layout .page-wrapper.material-type .page-body-wrapper .page-body, .box-layout.page-wrapper.material-type .page-body-wrapper .page-body {
      margin-top: 0;
    }
    .box-layout .page-wrapper.material-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
      top: 0;
    }
    .box-layout .page-wrapper.material-icon .page-header.close_icon, .box-layout.page-wrapper.material-icon .page-header.close_icon {
      margin-top: 40px !important;
    }
    .box-layout .page-wrapper.material-icon .page-body-wrapper, .box-layout.page-wrapper.material-icon .page-body-wrapper {
      margin-top: 0;
    }
    .box-layout .page-wrapper.material-icon .page-body-wrapper .page-body, .box-layout.page-wrapper.material-icon .page-body-wrapper .page-body {
      top: 0;
      min-height: calc(100vh - 170px);
      padding-bottom: 40px;
    }
    .box-layout .page-wrapper.advance-layout .page-header, .box-layout.page-wrapper.advance-layout .page-header {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    }
    .box-layout .page-wrapper.advance-layout .page-body-wrapper, .box-layout.page-wrapper.advance-layout .page-body-wrapper {
      margin-top: 0;
    }
    .box-layout .page-wrapper .page-body-wrapper, .box-layout.page-wrapper .page-body-wrapper {
      width: 1280px;
      box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
      margin: 0 auto;
      overflow: hidden;
    }
    .box-layout .page-wrapper .page-body-wrapper .learning-comment, .box-layout.page-wrapper .page-body-wrapper .learning-comment {
      margin-left: -14px !important;
      float: left !important;
    }
    .box-layout .page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive, .box-layout.page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive {
      min-width: 1087px;
      overflow: auto;
    }
    .box-layout .page-wrapper .page-body-wrapper .active-order-table, .box-layout.page-wrapper .page-body-wrapper .active-order-table {
      max-width: 443px;
      overflow: auto;
    }
    .box-layout .page-wrapper .page-body-wrapper .active-order-table table tbody tr td p, .box-layout.page-wrapper .page-body-wrapper .active-order-table table tbody tr td p {
      width: 100px;
    }
    .box-layout .page-wrapper .page-body-wrapper .activity .media .gradient-round.gradient-line-1:after, .box-layout.page-wrapper .page-body-wrapper .activity .media .gradient-round.gradient-line-1:after {
      height: 57px;
      bottom: -64px;
    }
    .box-layout .page-wrapper .page-body-wrapper .activity .media .gradient-round.small-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .media .gradient-round.small-line:after {
      height: 36px;
      bottom: -43px;
    }
    .box-layout .page-wrapper .page-body-wrapper .activity .media .gradient-round.medium-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .media .gradient-round.medium-line:after {
      height: 40px;
      bottom: -48px;
    }
    .box-layout .page-wrapper .page-body-wrapper footer, .box-layout.page-wrapper .page-body-wrapper footer {
      width: 1280px;
      margin: 0 auto;
      padding-left: 230px;
      margin-bottom: 40px !important;
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 0 0 10px;
    }
    .box-layout .page-wrapper .page-body-wrapper .blockquote-footer, .box-layout.page-wrapper .page-body-wrapper .blockquote-footer {
      margin-left: 0;
      width: 100%;
    }
    .box-layout .page-wrapper .page-body-wrapper .footer-fix, .box-layout.page-wrapper .page-body-wrapper .footer-fix {
      padding-left: 230px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div {
      z-index: 8;
      background-color: rgba(255, 255, 255, 0.75);
      background-blend-mode: overlay;
      width: 100%;
      padding: 30px;
      left: 15px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button {
      width: 40%;
      font-size: 14px;
      margin: 0 auto;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li {
      border: 1px solid #717171;
      width: 50px;
      height: 50px;
      padding: 8px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img {
      width: 38%;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2 {
      font-size: 28px;
      color: #717171;
    }
    .box-layout .page-wrapper .page-body-wrapper canvas#myLineCharts, .box-layout.page-wrapper .page-body-wrapper canvas#myLineCharts {
      width: 100%;
    }
    .box-layout .page-wrapper .page-body-wrapper .chat-right-aside, .box-layout.page-wrapper .page-body-wrapper .chat-right-aside {
      flex: 0 0 60%;
      max-width: 60%;
      overflow: hidden;
    }
    .box-layout .page-wrapper .page-body-wrapper .caller-img, .box-layout.page-wrapper .page-body-wrapper .caller-img {
      position: absolute;
      width: 100%;
      max-width: 100%;
      left: 15px;
    }
    .box-layout .page-wrapper .page-body-wrapper .caller-img img, .box-layout.page-wrapper .page-body-wrapper .caller-img img {
      opacity: 0.7;
    }
    .box-layout .page-wrapper .page-body-wrapper .browser-widget img, .box-layout.page-wrapper .page-body-wrapper .browser-widget img {
      height: 65px;
    }
    .box-layout .page-wrapper .page-body-wrapper .weather-widget-two .bottom-whetherinfo .whether-content, .box-layout.page-wrapper .page-body-wrapper .weather-widget-two .bottom-whetherinfo .whether-content {
      top: 39px;
    }
    .box-layout .page-wrapper .page-body-wrapper .custom-card .card-header img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-header img {
      margin-top: -73px;
    }
    .box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-profile img {
      height: 130px;
      top: -17px;
    }
    .box-layout .page-wrapper .page-body-wrapper .select2, .box-layout.page-wrapper .page-body-wrapper .select2 {
      width: 901.781px;
    }
    .box-layout .page-wrapper ul.close_icon > li label, .box-layout.page-wrapper ul.close_icon > li label {
      padding: 0;
    }
    .box-layout .page-wrapper ul.close_icon > li label:after, .box-layout.page-wrapper ul.close_icon > li label:after {
      display: none;
    }
    .box-layout .page-wrapper .bookmark ul, .box-layout.page-wrapper .bookmark ul {
      margin-right: -1px;
    }
    .box-layout .page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn, .box-layout.page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn {
      padding-left: 20px;
      padding-right: 20px;
    }
    .box-layout .page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio], .box-layout.page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio] {
      display: none;
    }
    .box-layout .page-wrapper .media.bitcoin-graph, .box-layout.page-wrapper .media.bitcoin-graph {
      display: block;
    }
    .box-layout .page-wrapper .media.bitcoin-graph .top-bitcoin, .box-layout.page-wrapper .media.bitcoin-graph .top-bitcoin {
      display: inline-block;
      vertical-align: middle;
    }
    .box-layout .page-wrapper .media.bitcoin-graph .media-body, .box-layout.page-wrapper .media.bitcoin-graph .media-body {
      margin-top: 30px;
    }
    .box-layout .page-wrapper .media.bitcoin-graph .media-body .bitcoin-content .bitcoin-numbers h6, .box-layout.page-wrapper .media.bitcoin-graph .media-body .bitcoin-content .bitcoin-numbers h6 {
      font-size: 14PX;
    }
    .box-layout .page-wrapper .media.bitcoin-graph .media-body .bitcoin-content.text-right, .box-layout.page-wrapper .media.bitcoin-graph .media-body .bitcoin-content.text-right {
      text-align: center !important;
    }
    .box-layout .page-wrapper .alert.inverse p, .box-layout.page-wrapper .alert.inverse p {
      max-width: 238px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .box-layout .page-wrapper .auth-bg-effect .second-effect, .box-layout.page-wrapper .auth-bg-effect .second-effect {
      left: 55%;
    }
    .box-layout .page-wrapper .auth-bg-video video, .box-layout.page-wrapper .auth-bg-video video {
      min-width: 67%;
      width: 67%;
    }
    .box-layout .page-wrapper .page-builder .ge-canvas.ge-layout-desktop, .box-layout.page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
      margin-top: 40px;
    }
    .box-layout .page-wrapper .page-builder .ge-addRowGroup, .box-layout.page-wrapper .page-builder .ge-addRowGroup {
      margin-bottom: 10px;
    }
    .box-layout .page-wrapper .pricing-wrapper-card, .box-layout.page-wrapper .pricing-wrapper-card {
      padding: 50px 20px;
    }
    .box-layout .page-wrapper .card .blog-box.blog-grid.set-min-height, .box-layout.page-wrapper .card .blog-box.blog-grid.set-min-height {
      min-height: 400px;
    }
    .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily {
      min-height: 430px;
    }
    .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
      min-height: 430px;
    }
    .box-layout .page-wrapper .box-col-12, .box-layout.page-wrapper .box-col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .box-layout .page-wrapper .box-col-6, .box-layout.page-wrapper .box-col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-layout .page-wrapper .box-col-3, .box-layout.page-wrapper .box-col-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-layout .page-wrapper .box-col-7, .box-layout.page-wrapper .box-col-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .box-layout .page-wrapper .box-col-5, .box-layout.page-wrapper .box-col-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .box-layout .page-wrapper .box-col-8, .box-layout.page-wrapper .box-col-8 {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .box-layout .page-wrapper .box-col-4, .box-layout.page-wrapper .box-col-4 {
      flex: 0 0 33.33%;
      max-width: 33%;
    }
    .box-layout .page-wrapper .chat-box .chat-right-aside, .box-layout.page-wrapper .chat-box .chat-right-aside {
      flex: 0 0 100%;
      max-width: calc(100% - 15px);
      overflow: hidden;
    }
    .box-layout .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i, .box-layout.page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
      font-size: 19px;
    }
    .box-layout .page-wrapper .chat-box .toogle-bar, .box-layout.page-wrapper .chat-box .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
    .box-layout .page-wrapper .chat-menu, .box-layout.page-wrapper .chat-menu {
      right: 0;
      border-top: 1px solid #ddd;
      opacity: 0;
      transform: translateY(-30px);
      visibility: hidden;
      top: 81px;
      position: absolute;
      z-index: 9;
      background-color: #fff;
      transition: all linear 0.3s;
    }
    .box-layout .page-wrapper .chat-menu.show, .box-layout.page-wrapper .chat-menu.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
    .box-layout .page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar, .box-layout.page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar {
      stroke-width: 23px !important;
    }
    .box-layout .page-wrapper .social-app-profile .box-col-4, .box-layout.page-wrapper .social-app-profile .box-col-4 {
      flex: 0 0 30%;
      max-width: 30%;
    }
    .box-layout .page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset, .box-layout.page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset {
      max-height: 165px;
    }
    .box-layout .page-wrapper .email-wrap .row .col-xl-6, .box-layout.page-wrapper .email-wrap .row .col-xl-6 {
      padding-left: 12px;
    }
    .box-layout .page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3, .box-layout.page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3 {
      padding-right: 12px;
    }
    .box-layout .page-wrapper .email-wrap .email-right-aside .email-body .inbox, .box-layout.page-wrapper .email-wrap .email-right-aside .email-body .inbox {
      height: 644px;
    }
    .box-layout .page-wrapper .email-wrap .email-content .email-top .user-emailid:after, .box-layout.page-wrapper .email-wrap .email-content .email-top .user-emailid:after {
      right: -10px;
    }
    .box-layout .page-wrapper .todo .notification-popup, .box-layout.page-wrapper .todo .notification-popup {
      right: 320px;
    }
    .box-layout .page-wrapper .touchspin, .box-layout.page-wrapper .touchspin {
      padding: 0 10px;
    }
    .box-layout .page-wrapper .vertical-menu-main, .box-layout.page-wrapper .vertical-menu-main {
      width: 1280px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    .box-layout .page-wrapper .vertical-menu-main .mega-menu, .box-layout.page-wrapper .vertical-menu-main .mega-menu {
      width: 1050px !important;
      max-width: 1050px !important;
      left: -320px !important;
    }
    .box-layout .page-wrapper .comingsoon video, .box-layout.page-wrapper .comingsoon video {
      min-width: 67%;
      width: 67%;
    }
    .text-purple{
      color:#9c27b0;
    }
    .text-orange{
      color:#ff9800;
    }
  }
  @media screen and (max-width: 1660px) and (min-width: 1440px) {
    .box-layout .calendar-sec {
      order: unset;
    }
    .box-layout .chart_data_right.second {
      display: none !important;
    }
  }
  
  /**=====================
      63. Box-layout CSS ends
  ==========================**/