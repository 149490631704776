/* ================================================================================
dialogs
================================================================================ */

.dialogs-sm {
    max-width: 500px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-main {
    max-width: 700px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-md {
    max-width: 900px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-lg {
    max-width: 1200px !important;
}

.dialogs-lg {
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

.custom-dialog-container .mat-dialog-container {
    overflow-y: hidden;
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

.custom-dialog-container-scroll {
    overflow-y: visible !important;
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

/* ================================================================================
menu transition
================================================================================ */

.layout-menu {
    transition: 0.5s;
}

.alert-light-warning {
    background-color: #fff6e3;
}

/* ================================================================================
dashboard
================================================================================ */

.primary .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(115, 102, 255, 0.1) 19.06%, rgba(115, 102, 255, 0) 79.03%) !important;
}

.secondary .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 51, 100, 0.1) 19.06%, rgba(255, 51, 100, 0) 79.03%) !important;
}

.success .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(84, 186, 74, 0.1) 19.06%, rgba(84, 186, 74, 0) 79.03%) !important;
}

.warning .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 170, 5, 0.1) 19.06%, rgba(255, 170, 5, 0) 79.03%) !important;
}

.small-widget {
    overflow: hidden;
}

.small-widget h4 {
    margin-bottom: -3px;
}

.small-widget i {
    font-weight: 700;
    font-size: 11px;
}

.small-widget .card-body {
    padding: 15px;
}

.small-widget .bg-gradient {
    width: 66px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

[dir=rtl] .small-widget .bg-gradient {
    right: unset;
    left: -12px;
    transform: translateY(-50%) scaleX(-1);
}

@media (max-width: 1399px) {
    .small-widget .bg-gradient {
        width: 60px;
        height: 60px;
    }
}

.small-widget .bg-gradient svg {
    width: 25px;
    height: 25px;
}

@media (max-width: 1399px) {
    .small-widget .bg-gradient svg {
        width: 22px;
        height: 22px;
    }
}

.small-widget:hover {
    transform: translateY(-5px);
    transition: 0.5s;
}

.small-widget:hover .bg-gradient svg {
    animation: tada 1.5s ease infinite;
}

.appointment-table table tr td {
    position: relative;
    border-top: none;
    padding: 3px 0;
    vertical-align: middle;
}

.appointment-table table tr td:first-child {
    width: 50px;
}

.appointment-table table tr td .status-circle {
    top: 1px;
    left: 35px;
    border-width: 3px;
}

.appointment-table table tr td p,
.appointment-table table tr td .btn {
    font-weight: 500;
    font-size: 12px;
}

.appointment-table table tr td .btn {
    padding: 4px 12px;
}

.appointment-table table tr .img-content-box a {
    color: var(--body-font-color);
    transition: 0.5s;
    display: inline-block !important;
}

// .appointment-table table tr .img-content-box span {
//     display: block;
// }

.appointment-table table tr:hover .img-content-box a {
    color: var(--theme-deafult);
    transition: 0.5s;
}

.appointment-table table tr:first-child td {
    padding-top: 0 !important;
}

.appointment-table table tr:last-child td {
    padding-bottom: 0 !important;
}

.appointment-table.customer-table table tr td {
    padding: 10px 0;
}

.appointment-table.customer-table table tr td img {
    min-width: 40px;
}


/* ================================================================================
stepper
================================================================================ */

.stepper-wrapper {
    font-family: Arial;
    display: flex;
    justify-content: space-between;
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #FFAB00;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #FFAB00;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #FFAB00;
    margin-bottom: 6px;
    color: white;
}

.stepper-item.active, .stepper-item.completed, .stepper-item.canceled {
    font-weight: bold;
}


/* .stepper-item.active .step-counter {
    background-color: #03c3ec;
} */

.stepper-item.completed .step-counter {
    background-color: #42d599;
    color: white;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #42d599;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 3;
}

.stepper-item.canceled .step-counter {
    background-color: #ff3e1d;
    color: white;
}

.stepper-item.canceled::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ff3e1d;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 3;
}

.stepper-item:first-child::before {
    content: none;
}

.stepper-item:last-child::after {
    content: none;
}

.stepper-img {
    width: 15%;
}

.img-header {
    width: 200px;
    height: auto;
}

/* ================================================================================
pulse
================================================================================ */

.vibrate {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    animation: anim-vibrate 1.3s infinite;
  
    @keyframes anim-vibrate {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgb(255, 0, 0);
      }
  
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 1rem rgba(0, 0, 0, 0);
      }
  
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    min-width: 250px !important;
  }